import { gql } from '@apollo/client';

const ADD_FIGURE = gql`mutation addFigure($versionId:Int!, $pageNo:Int!, $figureData:String){
	
	resourceComments{
		addFigure(resourceVersionId:$versionId, pageNo: $pageNo, figureData:$figureData)
		{
			author
			avatarUrl
			connectedCommentId
			created
			figureData
			id
			userId			
		}
	}
}

`;

export default ADD_FIGURE;
