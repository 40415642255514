import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import { Box, Grid, List, ListItem, Rating, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { STOCK_PRODUCT } from "../../../../constant/types";
import { INITIALIZE_PRODUCT_DOWNLOAD } from "../../../../graphql/mutations";
import { getSelectedSiteOrderGroupId, getSiteId } from "../../../../helpers/selectors";
import { useAddToShoppingCart, useSnackbar } from "../../../../hooks";
import { PrimaryButton } from "../../../common";
import ProductOutOfStock from "../../ProductOutOfStock";
import ProductTags from "../../ProductTags";
import ProductOrderButton from "../ProductOrderButton";
import ProductQuantity from "../ProductQuantity";
import RenderProductPrice from "../RenderProductPrice";

const MetadataListItem = ({ name, value }) => (
  <ListItem
    sx={{
      background: "#fff",
      borderBottom: "1px solid #f2f2f0",
      overflowWrap: "anywhere",
      padding: "3px 16px 3px 8px",
    }}
  >
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="body1" align="left" sx={{ fontSize: "14px", fontWeight: "400" }}>{name}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" align="left" sx={{ fontWeight: "500" }}>{value}</Typography>
      </Grid>
    </Grid>
  </ListItem>
);

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    position: "relative",
  },
  footer: {
    marginTop: 16,
    textAlign: "right",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "unset",
      width: "auto",
    },
  },
  footerButtons: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "2px",
    justifyContent: "flex-end",
  },
  metadata: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "auto",
  },
  ordering: {
    backgroundColor: "#fff",
    margin: "8px 0 16px 0",
    padding: "0 8px 8px 0",
    width: "100%",
  },
  tags: {
    marginTop: 20,
    position: "relative",
  },
  tagsTitle: {
    background: "#fff",
    fontSize: 10,
    fontWeight: 600,
    left: 10,
    margin: "20 0 0 0",
    marginBottom: 5,
    position: "absolute",
    textAlign: "center",
    top: -15,
    width: 50,
  },
}));

////const customMetadata = [
////  { label: "label_1", value: "value_1", display: true },
////  { label: "label_2", value: "value_2", display: false },
////  { label: "label_3", value: "value_3", display: true },
////  { label: "label_4", value: "value_4", display: false },
////  { label: "label_5", value: "value_5", display: true },
////];

const MetadataPanel = ({
  customMetadata,
  editMode,
  fromFileView,
  isImage,
  onAddToImageBasket,
  onClose,
  product,
  quantity,
  setQuantity,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();

  const [rating, setRating] = useState(0);

  const [initializeDownload] = useMutation(INITIALIZE_PRODUCT_DOWNLOAD);
  const siteId = useSelector(state => getSiteId(state));
  const orderGroupId = useSelector(state => getSelectedSiteOrderGroupId(state));
  const [addToShoppingCart] = useAddToShoppingCart();

  const {
    consented,
    minimumQuantity,
    productDisplaySettings,
    productType,
    quantities,
    stock,
    tags,
    userPermissions,
    vatPercentage,
  } = product || {};

  const { showFormat, showNumPages, showPrice } = productDisplaySettings || {};

  const display = {
    format: showFormat && product && product.format,
    numPages: showNumPages && (product?.numPages || "0") !== "0",
    price: showPrice,
    vatPercentage: !isImage && showPrice && vatPercentage,
    weight: (product?.weight || 0) !== 0,
  };

  const download = userPermissions?.download || fromFileView;
  const order = userPermissions?.order || false;

  const { stockBalance, temporaryOutOfStock } = stock || {};
  const { selectionType, valuesList } = quantities || {};

  const selectAll = event => {
    if (event && event.target && event.target.value) {
      event.target.select();
    }
  };

  const handleDownloadOnClick = async () => {
    try {
      const results = await initializeDownload({
        variables: {
          productId: product.id,
        },
      });
      if (results && results.data && results.data.initProductDownload) {
        const downloadUrl = results.data.initProductDownload;
        saveAs(downloadUrl);
      } else {
        console.log("Download Failure");
      }
    } catch (e) {
      console.log(`Error downloading product ${e}`);
    }
  };

  const quantityChange = event => {
    const newQuantity = Number(event.target.value);
    const isValidQuantity =
      Number.isSafeInteger(newQuantity) && newQuantity >= 0;
    setQuantity(isValidQuantity ? newQuantity : 0);
  };

  const handleBtnOrder = async () => {
    const item = {
      productId: product.id,
      name: product.name,
      quantity,
      dynamicFieldValues: null,
      propertyProductData: null,
      siteId,
      source: "PRODUCT_VIEW",
    };

    addToShoppingCart({
      input: {
        item,
        orderGroupId,
      },
      onError: () => {
        snackbar.workspaceError(<FormattedMessage id="product.failedToCart" />);
      },
      onSuccess: () => {
        alert.success(<FormattedMessage id="product.addedToCart" />);
        onClose?.();
      },
    });
  };

  const IconConsented = () => {
    let icon = null;
    let tooltipText = '';

    switch (consented) {
      case "NOT_SENT":
        return null;
      case "SENT":
        icon = <GppMaybeOutlinedIcon sx={{ color: "lightgray", verticalAlign: "middle" }} />;
        tooltipText = "Begäran om samtycke skickad";  // TODO
        break;
      case "DENIED":
        icon = <GppBadOutlinedIcon sx={{ color: "red", verticalAlign: "middle" }} />;
        tooltipText = "Samtycke avslaget";  // TODO
        break;
      case "CONSENTED":
        icon = <GppGoodOutlinedIcon sx={{ color: "green", verticalAlign: "middle" }} />;
        tooltipText = "Samtycke finns";  // TODO
        break;
      default:
        return null;
    }

    return (
      <Tooltip title={tooltipText}>
        {icon}
      </Tooltip>
    );
  }

  const description =
    product && product.detailedDescription
      ? product.detailedDescription
      : product.description;

  const metadataItems = useMemo(
    () => {
      if (!product) {
        return [];
      }

      const list = [];

      const addWithTextKey = (textKey, value, condition) => {
        addWithName(<FormattedMessage id={textKey} />, value, condition);
      };

      const addWithName = (name, value, condition) => {
        if (condition) {
          list.push({
            name,
            value,
          });
        }
      };

      const { articleNo, format, weight, material, unit, numPages, productResponsible, vatPercentage, pricesInclusiveFreight } = product;

      addWithTextKey("product.artno", articleNo, articleNo);
      addWithTextKey("product.format", format, display.format);
      addWithTextKey("product.weight", weight, display.weight);
      addWithTextKey("product.material", material, material);
      addWithTextKey("product.unit", unit, unit);
      addWithTextKey("product.pageRange", numPages, display.numPages);

      const haveCustomMetadata = (Array.isArray(customMetadata) && customMetadata.length > 0);

      if (haveCustomMetadata) {
        customMetadata.map(item => {
          addWithName(item.label, item.value, item.display);
        })
      }

      addWithTextKey("cooperation.productResponsible", productResponsible, productResponsible);
      addWithTextKey("product.vatPercentage", vatPercentage, display.vatPercentage);
      addWithTextKey("cart.price", pricesInclusiveFreight, pricesInclusiveFreight);

      ////const imageMetadata = {
      ////  date: "(date)",
      ////  contact: "(contact)",
      ////  model: "(model)",
      ////  location: "(location)",
      ////  occupation: "(occupation)",
      ////  photographer: "(photographer)",
      ////  isFreeToUser: true,
      ////  imageNo: "(imageNo)",
      ////};

      const { imageMetadata } = product;

      if (imageMetadata) {
        const { date, contact, model, location, occupation, photographer, isFreeToUser, imageNo } = imageMetadata;

        addWithTextKey("product.date", (<FormattedDate value={date} />), date);
        addWithTextKey("product.contact", contact, contact);
        addWithTextKey("product.model", model, model);
        addWithTextKey("product.location", location, location);
        addWithTextKey("product.occupation", occupation, occupation);
        addWithTextKey("product.photographer", photographer, photographer);
        addWithTextKey("metadata.royaltyFree", (
          isFreeToUser
            ? <FormattedMessage id="support.yes" />
            : <FormattedMessage id="btn.no" />
        ), true);
        addWithTextKey("product.imageNo", imageNo, imageNo);
      }

      const { statistics } = product;

      if (statistics) {
        const { downloadCount, shareCount, viewCount } = statistics;

        addWithTextKey("product.downloads", downloadCount, true);
        addWithTextKey("product.shares", shareCount, true);
        addWithTextKey("product.views", viewCount, true);
      }

      return list;
    },
    [customMetadata, display, product]
  );

  const handleRatingChange = useCallback((newRating) => { setRating(newRating); }, []);

  return (
    product &&
    <Box className={classes.container}>
      <Box className={classes.metadata}>

        <Typography variant="h6"
          sx={{
            color: "#111111",
            fontSize: "16px",
            fontWeight: "500",
            overflowWrap: "anywhere",
            paddingBottom: "2px",
          }}
        >
          <IconConsented /> {product.name}
        </Typography>

        <Typography variant="body1"
          sx={{
            color: "#111111",
            fontSize: description?.length <= 130 ? "14px" : "13px",
            fontWeight: "400",
            paddingBottom: "5px",
          }}
        >
          {description}
        </Typography>

        <List>
          {metadataItems.map((item, index) => (
            <MetadataListItem
              key={index}
              name={item.name}
              value={item.value}
            />
          ))}

          <MetadataListItem
            key={-1}
            name={<FormattedMessage id="product.rating" />}
            value={
              <Rating
                name="overall-rating"
                onChange={(event, newValue) => handleRatingChange(newValue)}
                value={rating}
                sx={{
                  fontSize: "1.2rem",
                }}
              />
            }
          />
        </List>

        {Array.isArray(tags) && tags.length > 0 && (
          <Box className={classes.tags}>
            <p className={classes.tagsTitle}>
              <FormattedMessage id="product.tagging" />
            </p>
            <ProductTags tags={tags} />
          </Box>
        )}

      </Box>

      <Box className={classes.footer}>

        {!editMode && order && (
          <Grid container spacing={1} className={classes.ordering}>
            <ProductQuantity
              valuesList={valuesList}
              selectionType={selectionType}
              quantity={quantity}
              onChange={quantityChange}
              onClick={selectAll}
              defaultValue={minimumQuantity}
            />

            {display.price && (
              <RenderProductPrice
                product={product}
                quantity={quantity}
              />
            )}
          </Grid>
        )}

        <Box className={classes.footerButtons}>
          {download && (
            <PrimaryButton onClick={handleDownloadOnClick}>
              <FormattedMessage id="btn.download" />
            </PrimaryButton>
          )}

          {isImage && (
            <PrimaryButton onClick={onAddToImageBasket}>
              <FormattedMessage id="product.addImage" />
            </PrimaryButton>
          )}

          {!editMode &&
            ((!stockBalance || stockBalance === 0 || temporaryOutOfStock) &&
              productType === STOCK_PRODUCT ? (
              <ProductOutOfStock />
            ) : (
              <>
                {order && (
                  <ProductOrderButton onClick={() => handleBtnOrder()} />
                )}
              </>
            ))}
        </Box>

      </Box>
    </Box>
  );
};

MetadataPanel.defaultProps = {
  customMetadata: null,
  editMode: false,
  fromFileView: false,
};

MetadataPanel.propTypes = {
  customMetadata: PropTypes.arrayOf(PropTypes.shape({})),
  editMode: PropTypes.bool,
  fromFileView: PropTypes.bool,
  isImage: PropTypes.bool.isRequired,
  onAddToImageBasket: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  product: PropTypes.shape({
    articleNo: PropTypes.string.isRequired,
    imageMetadata: PropTypes.shape({
      contact: PropTypes.string,
      date: PropTypes.string,
      model: PropTypes.string,
      location: PropTypes.string,
      occupation: PropTypes.string,
      photographer: PropTypes.string,
    }),
    unit: PropTypes.string,
    weight: PropTypes.number,
    material: PropTypes.string,
    numPages: PropTypes.string.isRequired,
    detailedDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    minimumPrice: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    productImageUri: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    quantities: PropTypes.shape({
      selectionType: PropTypes.string.isRequired,
      valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    statistics: PropTypes.shape({
      downloadCount: PropTypes.number,
      shareCount: PropTypes.number,
      viewCount: PropTypes.number,
    }),
    stock: PropTypes.shape({
      stockBalance: PropTypes.number.isRequired,
      temporaryOutOfStock: PropTypes.bool.isRequired,
    }),
    productDisplaySettings: PropTypes.shape({
      showFormat: PropTypes.bool,
      showNumPages: PropTypes.bool,
      showPrice: PropTypes.bool,
      showStockBalance: PropTypes.bool,
    }),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        tagId: PropTypes.number.isRequired,
        tagName: PropTypes.string.isRequired,
      })
    ).isRequired,
    userPermissions: PropTypes.shape({
      order: PropTypes.bool,
      download: PropTypes.bool,
    }),
    thumbnailImageUri: PropTypes.string,
  }).isRequired,
  quantity: PropTypes.number.isRequired,
  setQuantity: PropTypes.func.isRequired,
};

export default MetadataPanel;
