import React from 'react';
import { Group, Path } from 'react-konva';

const FolderUploadIcon = ({ x = 0, y = 0, width = 24, height = 24, fill = 'black', stroke = 'none', strokeWidth = 0, scale = { x: 1, y: 1 }, opacity = 1, listening = false, offsetX = 0, offsetY = 0, renderAsSVG = false }) => {
  return renderAsSVG ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      opacity={opacity}
    >
      <g>
        <path d="M11,17h2v-4.199l1.6,1.6L16,13l-4-4l-4,4l1.4,1.4l1.6-1.6V17z M4,20c-0.55,0-1.021-0.195-1.413-0.588&#10;&#9;C2.196,19.021,2,18.551,2,18V6c0-0.55,0.196-1.021,0.587-1.413S3.45,4,4,4h6l2,2h8c0.55,0,1.021,0.196,1.412,0.587&#10;&#9;C21.805,6.979,22,7.45,22,8v10c0,0.551-0.195,1.021-0.588,1.412C21.021,19.805,20.55,20,20,20H4z M4,18h16V8h-8.825l-2-2H4V18z&#10;&#9; M4,18V6V18z" />
      </g>
    </svg>
  ) : (
    <Group
      x={x}
      y={y}
      width={width}
      height={height}
      scale={scale}
      listening={listening}
      offsetX={offsetX}
      offsetY={offsetY}
      opacity={opacity}
    >
        <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} data="M11,17h2v-4.199l1.6,1.6L16,13l-4-4l-4,4l1.4,1.4l1.6-1.6V17z M4,20c-0.55,0-1.021-0.195-1.413-0.588&#10;&#9;C2.196,19.021,2,18.551,2,18V6c0-0.55,0.196-1.021,0.587-1.413S3.45,4,4,4h6l2,2h8c0.55,0,1.021,0.196,1.412,0.587&#10;&#9;C21.805,6.979,22,7.45,22,8v10c0,0.551-0.195,1.021-0.588,1.412C21.021,19.805,20.55,20,20,20H4z M4,18h16V8h-8.825l-2-2H4V18z&#10;&#9; M4,18V6V18z" />
    </Group>
  );
};

export default FolderUploadIcon;