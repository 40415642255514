import { useCallback, useEffect, useRef, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import { TreeItem, TreeView } from '@mui/lab';
import { ModalCompact, ModalCompactHead } from '../../../components/common';
import { useCommentsContext, usePaginationContext } from "../../../hooks/FullscreenAnnotationModal";
import AutocompleteComponent from '../subcomponents/AutocompleteComponent';
import CommentsPanel from "../subcomponents/CommentsPanel";
import siemensLogo from '../utils/SE_Logo_White.png';
import siemensBkg from '../utils/siemens_bkg_img.webp';
import DrawingEditor from './DrawingEditor';
import FileUploadComponent from './FileUploadComponent';

const initialViewportTransform = {
  x: 48,
  y: 0,
  scale: 1,
};

const treeData = {
  id: 'root',
  name: 'Chapters',
  children: [
    { id: 'chapter1', name: 'Chapter 1' },
    { id: 'chapter2', name: 'Chapter 2' },
    {
      id: 'chapter3',
      name: 'Chapter 3',
      children: [
        { id: 'section3.1', name: 'Section 3.1' },
        { id: 'section3.2', name: 'Section 3.2' },
      ],
    },
  ],
};

const SiemensEnergyLogo = () => (
  <Box sx={{
    background: "black",
    bottom: 0,
    marginRight: "auto",
    padding: "10px",
    paddingBottom: "5px",
    position: "relative",
    "& img": {
      width: "190px",
      height: "66px",
    }
  }}>
    <img alt="" src="https://www.siemens-energy.com/content/dam/siemensenergy-aem/images/logo/SE_Logo_White.png" />
  </Box>
);

const FullscreenFileUploadModal = ({ onClose, open }) => {

  const { clear: resetComments } = useCommentsContext();
  const { initialize: resetPagination } = usePaginationContext();

  const [annotations, setAnnotations] = useState([]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [uploadedFileMetadata, setUploadedFileMetadata] = useState(null);
  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
  const [viewportTransform, setViewportTransform] = useState(initialViewportTransform);

  const drawingEditorRef = useRef(null);
  const stageRef = useRef(null);

  const [expandedTreeNodes, setExpandedTreeNodes] = useState([]);
  const [selectedTreeNode, setSelectedTreeNode] = useState(undefined);

  // ----------------------------------------------------------------------------------------------------
  //

  const initialize = () => {
    setAnnotations([]);
    resetComments();
    resetPagination();
    resetViewportTransform();
  };

  const resetViewportTransform = () => {
    setViewportTransform(initialViewportTransform);
  };

  const handleFileOpened = ({ id, numPages, pageNumber }) => {
    if (numPages && numPages > 0) {
      resetComments();
      resetPagination({ pageCount: numPages, pageNo: pageNumber });
      resetViewportTransform();
    }
    else {
      initialize();
    }
  }

  //
  // ----------------------------------------------------------------------------------------------------

  const handleResize = useCallback(() => {
    if (drawingEditorRef.current) {
      const { width, height } = drawingEditorRef.current.getBoundingClientRect();
      setStageSize({ width, height });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const handleFileUploadSuccess = (metadata) => {
    setIsFileUploaded(true);
    setUploadedFileMetadata(metadata);
  };

  const handleNextClick = () => {
    setShowPdfViewer(true);
  };

  const handleClose = () => {
    setShowPdfViewer(false);
    setIsFileUploaded(false);
    onClose?.();
  };

  const renderTree = (node) => (
    <TreeItem key={node.id} nodeId={node.id} label={node.name}
      onClick={() => setSelectedTreeNode(node.id)}
    >
      {Array.isArray(node.children) && node.children.map((node) => renderTree(node))}
    </TreeItem>
  );

  const ChapterTree = () => (
    <>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>Select chapter / section</Typography>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expandedTreeNodes}
        onNodeToggle={(_, nodeIds) => setExpandedTreeNodes(nodeIds)}
        selected={selectedTreeNode}
        sx={{
          flexGrow: 1, maxWidth: 400, overflowY: 'auto',
          minHeight: "8rem", overflow: "hidden",
          "& .MuiTreeItem-label": {
            overflowWrap: "anywhere",
          },
        }}
      >
        {renderTree(treeData)}
      </TreeView>
    </>
  );

  const LeftColumn = () => (
    <Box sx={{ borderRight: '1px solid #ccc', display: "flex", flexDirection: "column", maxWidth: "25rem", minHeight: '19rem', minWidth: "245px", overflowY: 'auto', padding: 2 }}>
      <ChapterTree />
      <SiemensEnergyLogo />
    </Box>
  );

  return (
    <ModalCompact
      sx={
        !showPdfViewer && {
          backgroundColor: "#1b1534",
          backgroundImage: `url(${siemensBkg})`,
          backgroundSize: '130% 130%',
          backgroundPosition: "50% 50%",
          backgroundRepeat: 'no-repeat',
        }
      }
      fullScreen
      open={open}
      onClose={handleClose}
    >
      <ModalCompactHead title="Uppladdning" handleClose={handleClose} sx={{ color: "white" }} />
      <DialogContent sx={{ display: 'flex', height: '100vh', justifyContent: 'center', margin: 0, overflow: 'hidden', overflowY: 'auto', padding: 0 }}>
        <Box sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          transition: 'background-color 0.3s ease-in-out'
        }}>
          {!showPdfViewer && (
            // <LeftColumn />
            <img alt="" src={siemensLogo} style={{
              width: '80px',
              position: 'absolute',
              top: '35px',
              right: '35px',
            }} />
          )}
          {!showPdfViewer && (
            <Grid
              container
              direction="column"
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "10px",
              }}>
              <Grid item xs={2} sx={{ minWidth: "400px", padding: 3, borderRadius: "6px", backgroundColor: "#FFFFFF99", }}>
                <Typography variant="h6" sx={{ marginBottom: 0, marginTop: 0, color: "white" }}>Select chapter or section</Typography>
                <AutocompleteComponent sx={{ marginTop: "10px", marginBottom: "16px", padding: 0, paddingBottom: "16px", backgroundColor: "white" }} />
                <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden', padding: 0 }}>
                  <Typography variant="h6" sx={{ marginBottom: 0, marginTop: 1, color: "white" }}>Upload files</Typography>
                  <FileUploadComponent onUploadSuccess={handleFileUploadSuccess} />
                  {isFileUploaded && (
                    <Button variant="contained" onClick={handleNextClick} sx={{ background: '#8a00e5', marginTop: 2 }}>
                      Next
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
          {showPdfViewer && (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden', padding: 2 }}>
                <Box ref={drawingEditorRef} sx={{ flexGrow: 1, position: 'relative' }}>
                  <DrawingEditor
                    fileMetadata={uploadedFileMetadata}
                    stageSize={stageSize}
                    viewportTransform={viewportTransform}
                    setViewportTransform={setViewportTransform}
                    stageRef={stageRef}
                    onFileOpened={handleFileOpened}
                    onStageSizeChange={handleResize}
                    annotations={annotations}
                    setAnnotations={setAnnotations}
                  />
                </Box>
              </Box>
              <Box sx={{
                background: "#f2f2f0",
                maxWidth: '500px',
                minWidth: '300px',
                overflowY: 'auto',
                padding: 2,
                width: '25%',
              }}>
                <CommentsPanel />
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
    </ModalCompact>
  );
};

export default FullscreenFileUploadModal;
