import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, Tooltip } from "@mui/material";

const baseStyle = {
  background: "#fff",
  border: "2px solid #aaa",
  color: "#888",
  minWidth: "unset",
  opacity: 0.5,
  padding: "2px",
  position: "absolute",
  top: 0,
  zIndex: 1001,
  "&:hover": {
    background: "#fff",
    opacity: 1,
    transition: "0.5s",
  },
};

const getIcon = (chevron) => (chevron === "right") ? <ChevronRightIcon /> : (chevron === "left") ? <ChevronLeftIcon /> : undefined;

const TabMarker = ({
  chevron,
  onClick,
  placement,
  text,
  tooltip
}) => {

  let iconProps = {
    endIcon: undefined,
    startIcon: undefined,
  }

  let styleProps;
  let tooltipPlacement;

  if (placement === "left") {
    iconProps.endIcon = getIcon(chevron);
    styleProps = {
      ...baseStyle,
      borderRadius: "0 8px 8px 0",
      borderLeft: "none",
      left: "1px",
      "& .MuiButton-endIcon": {
        display: "none",
        marginLeft: 0,
      },
      "&:hover .MuiButton-endIcon": {
        display: "inherit",
      },
    };
    tooltipPlacement = "right";
  }
  else if (placement === "right") {
    iconProps.startIcon = getIcon(chevron);
    styleProps = {
      ...baseStyle,
      borderRadius: "8px 0 0 8px",
      borderRight: "none",
      right: "1px",
      "& .MuiButton-startIcon": {
        display: "none",
        marginRight: 0,
      },
      "&:hover .MuiButton-startIcon": {
        display: "inherit",
      },
    };
    tooltipPlacement = "left";
  }
  else {
    return;
  }

  return (
    <Tooltip
      arrow
      placement={tooltipPlacement}
      title={tooltip}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -8],
            },
          },
        ],
      }}
    >
      <Button
        {...iconProps}
        onClick={onClick}
        sx={styleProps}
      >
        {text}
      </Button>
    </Tooltip>
  );
};

export default TabMarker;
