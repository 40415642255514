import { forwardRef } from "react";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import MessageSection from "./MessageSection";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
  },
  dialog: {
    height: "unset",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const MobileMessageDialog = ({
  activeChannelId,
  activeConversationId,
  channel,
  channelLoading,
  conversation,
  conversationLoading,
  open,
  onClose,
}) => {
  const classes = useStyles();

  let name = "";
  if (channelLoading || conversationLoading) {
    name = "...";
  }
  if (activeChannelId && channel && channel.channelMessages) {
    name = "#" + channel.name;
  } else if (activeConversationId && conversation && conversation.messages) {
    name = conversation.members.map(n => "@" + n.name).join(", ");
  }

  return (
    <Dialog
      className={classes.dialog}
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            aria-label="close"
            color="inherit"
            edge="start"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {name}
          </Typography>
        </Toolbar>
      </AppBar>

      <MessageSection
        activeChannelId={activeChannelId}
        activeConversationId={activeConversationId}
        channel={channel}
        channelLoading={channelLoading}
        conversation={conversation}
        conversationLoading={conversationLoading}
        isMobile={true}
      />
    </Dialog>
  );
};

export default MobileMessageDialog;
