import React from 'react';
import { Group, Path } from 'react-konva';

const PreflightIcon = ({ x = 0, y = 0, width = 24, height = 24, fill = 'black', stroke = 'none', strokeWidth = 0, scale = { x: 1, y: 1 }, opacity = 1, listening = false, offsetX = 0, offsetY = 0, renderAsSVG = false }) => {
  return renderAsSVG ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      opacity={opacity}
    >
      <g>
        <path d="M2.401,4.161v15.678c0,2.182,1.775,3.957,3.959,3.957h11.281c2.182,0,3.957-1.775,3.957-3.957V7.604V6.997l-0.43-0.43
	l-5.936-5.934l-0.428-0.43h-0.607H6.36C4.177,0.204,2.401,1.979,2.401,4.161z M3.867,19.839V4.163c0-1.377,1.116-2.492,2.491-2.492
	h7.839v3.857c0,1.146,0.931,2.076,2.077,2.076h3.856v12.234c0,1.377-1.116,2.492-2.491,2.492H6.358
	C4.983,22.331,3.867,21.216,3.867,19.839L3.867,19.839z"/>
        <path d="M19.245,14.422l-1.604-1.838l0.224-2.431l-2.382-0.539l-1.242-2.108l-2.24,0.961L9.763,7.506l-1.246,2.1l-2.378,0.531
	l0.224,2.439L4.755,14.42l1.607,1.838l-0.224,2.434l2.378,0.543l1.245,2.102l2.24-0.965l2.24,0.959l1.242-2.102l2.382-0.54
	l-0.224-2.431L19.245,14.422z M10.705,18.021l-2.977-2.988l1.398-1.402l1.58,1.587l3.981-3.992l1.4,1.397L10.705,18.021z"/>
      </g>
    </svg>
  ) : (
    <Group
      x={x}
      y={y}
      width={width}
      height={height}
      scale={scale}
      listening={listening}
      offsetX={offsetX}
      offsetY={offsetY}
      opacity={opacity}
    >
      <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} data="M2.401,4.161v15.678c0,2.182,1.775,3.957,3.959,3.957h11.281c2.182,0,3.957-1.775,3.957-3.957V7.604V6.997l-0.43-0.43
	l-5.936-5.934l-0.428-0.43h-0.607H6.36C4.177,0.204,2.401,1.979,2.401,4.161z M3.867,19.839V4.163c0-1.377,1.116-2.492,2.491-2.492
	h7.839v3.857c0,1.146,0.931,2.076,2.077,2.076h3.856v12.234c0,1.377-1.116,2.492-2.491,2.492H6.358
	C4.983,22.331,3.867,21.216,3.867,19.839L3.867,19.839z"/>
      <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} data="M19.245,14.422l-1.604-1.838l0.224-2.431l-2.382-0.539l-1.242-2.108l-2.24,0.961L9.763,7.506l-1.246,2.1l-2.378,0.531
	l0.224,2.439L4.755,14.42l1.607,1.838l-0.224,2.434l2.378,0.543l1.245,2.102l2.24-0.965l2.24,0.959l1.242-2.102l2.382-0.54
	l-0.224-2.431L19.245,14.422z M10.705,18.021l-2.977-2.988l1.398-1.402l1.58,1.587l3.981-3.992l1.4,1.397L10.705,18.021z"/>
    </Group>
  );
};

export default PreflightIcon;