import { useMutation } from "@apollo/client";
import { INITIALIZE_ONE_STREAM_UPLOAD } from "../graphql/mutations";

// eslint-disable-next-line import/prefer-default-export
export const useGetOneStreamUploadUrl = ({ siteid, name, email, preset, costPlace }) => {
  const [initOneStreamUpload] = useMutation(INITIALIZE_ONE_STREAM_UPLOAD);

  const getOneStreamUploadUrl = () => {
    return initOneStreamUpload({
      variables: {
        siteid,
        name,
        email,
        preset,
        costPlace
      },
    });
  };

  return { execute: getOneStreamUploadUrl };
};
