import { useRef, useState } from "react";
import { Box, ClickAwayListener, Grow, Paper, Popper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ContactsWidget from "../widgets/ContactsWidget/ContactsWidget";

const useStyles = makeStyles(theme => ({
  popper: {
    background: theme.palette.component.pageBackground,
    boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    zIndex: 100,
  },
  wrapper: {
    "& nav": {
      display: "flex",
      flexDirection: "column",
      marginRight: "1em",
      maxHeight: "calc(100vh - 75px)",
      "@supports (height: 100dvh)": {        
        maxHeight: "calc(100dvh - 75px)",
      },
      maxWidth: "26em",
      minWidth: "16em",
      overflowY: "auto",
    },
  },
}));

const ContactsPopup = ({ children }) => {
  const anchorRef = useRef(null);
  const contactsRef = useRef(null);

  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const handleClickAway = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  const toggleOpen = () => setOpen(prev => !prev);

  return (
    <div>
      <div
        ref={anchorRef}
        onClick={toggleOpen}
      >
        {children}
      </div>
      <Popper
        anchorEl={anchorRef.current}
        className={classes.popper}
        disablePortal
        open={open}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: (placement === "bottom" ? "center top" : "center bottom")
            }}
          >
            <Paper className={classes.wrapper}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box ref={contactsRef}>
                  <ContactsWidget />
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default ContactsPopup;
