
import { Button, Box, Typography } from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const PollAnswer = ({ siteNewsId, poll, onUpdateVote, onVote }) => {

  const isMultipleChoice = poll.votingOptions === "SELECT_MULTIPLE";

  const handleVote = (choiceId) => {
    const updatedChoices = poll.choices.map((choice) => {
      if (choice.id === choiceId) {
        return {
          ...choice,
          selected: isMultipleChoice ? !choice.selected : true,
        };
      }
      return isMultipleChoice ? choice : { ...choice, selected: false };
    });


    onUpdateVote(siteNewsId, updatedChoices);


    if (!isMultipleChoice && onVote) {
      onVote(siteNewsId, updatedChoices.filter(choice => choice.selected));
    }
  };

  const handleSubmit = () => {
    // Simulate sending votes to the server here
    console.log("Finalized votes:", poll.choices.filter((choice) => choice.selected));
    onVote && onVote(siteNewsId, poll.choices.filter((choice) => choice.selected));
  };

  return (
    <Box sx={{ padding: 2 }}>
      {poll.choices.map((choice) => (
        <Box
          key={choice.id}
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: 1,
            backgroundColor: "#f0f0f0",
            borderRadius: "4px",
            overflow: "hidden",
            cursor: "pointer",
            padding: "8px",
          }}
          onClick={() => handleVote(choice.id)}
        >
          {isMultipleChoice ? (
            choice.selected ? (
              <CheckBoxIcon sx={{ marginRight: 1 }} />
            ) : (
                <CheckBoxOutlineBlankIcon sx={{ marginRight: 1 }} />
              )
          ) : choice.selected ? (
            <RadioButtonCheckedIcon sx={{ marginRight: 1 }} />
          ) : (
                <RadioButtonUncheckedIcon sx={{ marginRight: 1 }} />
              )}

          <Typography variant="body1" sx={{ flexGrow: 1 }}>
            {choice.text}
          </Typography>
        </Box>
      ))}

      {isMultipleChoice && (
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
          onClick={handleSubmit}
        >
          R�sta
        </Button>
      )}
    </Box>
  );
};

export default PollAnswer;