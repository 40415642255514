import { useCallback, useEffect, useRef, useState } from "react";
import { Stage } from "react-konva";

import StepDetailsTabs from "../subcomponents/OneStream/StepDetailsTabs";
import DrawingToolbar from '../subcomponents/DrawingToolbar';

import { TOOL_TYPE } from "../utils/types";
import { default as useOneStreamSteps } from "../utils/useSteps.js";
import useGetWorkflow from "../utils/useGetWorkflow.js";
import SideDrawer from "./SideDrawer";
import WorkFlowLayer from "./WorkFlowLayer";

const ConnectingObjects = ({ sidebarActive, workflowId = "workflow-001" }) => {
  const osSteps = useOneStreamSteps();
  const osWorkflow = useGetWorkflow();

  const [selectedStep, setSelectedStep] = useState(null);

  const [connections, setConnections] = useState([]);
  const [steps, setSteps] = useState({});

  const [openSideDrawer, setOpenSideDrawer] = useState(false);

  const [toolSettings, setToolSettings] = useState({
    tool: TOOL_TYPE.SELECT,
    subType: undefined,
  });

  const activeTool = toolSettings?.tool;
  const activeToolSubType = toolSettings?.subType;

  const [stagePos, setStagePos] = useState({ x: 0, y: 0 });
  const stageRef = useRef(null);

  useEffect(() => {
    const workflow = osWorkflow.getWorkflow(workflowId);
    if (!workflow.id) {
      console.info("** Could not get workflow.", { workflowId });
    }
    setConnections(workflow.connections);
    setSteps(workflow.steps);
  }, [workflowId]);

  const handleToolSelect = (e, toolType, subType) => {
    let newToolSettings = { ...toolSettings, tool: toolType, subType };
    setToolSettings(newToolSettings);
    setSelectedStep(null);
    updateCursor(toolType);
  };

  const updateCursor = useCallback((tool) => {
    if (stageRef.current) {
      const stage = stageRef.current;
      switch (tool) {
        case TOOL_TYPE.SELECT:
          stage.container().style.cursor = 'default';
          break;
        case TOOL_TYPE.PAN:
          stage.container().style.cursor = 'grab';
          break;
        case TOOL_TYPE.STEP:
          stage.container().style.cursor = 'crosshair';
          break;
        default:
          stage.container().style.cursor = 'default';
          break;
      }
    }
  }, []);

  useEffect(() => {
    updateCursor(toolSettings.tool);
  }, [toolSettings.tool, toolSettings.subType, updateCursor]);

  const handleStageClick = useCallback((e) => {
    if (e.target === e.target.getStage()) {
      setSelectedStep(null);
    }
  }, []);

  const handleStageDragStart = useCallback(() => {
    if (toolSettings.tool === TOOL_TYPE.PAN) {
      stageRef.current.container().style.cursor = 'grabbing';
    }
  }, [toolSettings.tool]);

  const handleStageDragEnd = useCallback(() => {
    if (toolSettings.tool === TOOL_TYPE.PAN) {
      stageRef.current.container().style.cursor = 'grab';
    }
  }, [toolSettings.tool]);

  const handleStageDragMove = useCallback((e) => {
    if (toolSettings.tool === TOOL_TYPE.PAN) {
      setStagePos(e.target.position());
    }
  }, [toolSettings.tool]);

  // Helper function to detect the closest connection point during hover  
  const getRelativePointerPosition = (node) => {
    const transform = node.getAbsoluteTransform().copy();
    transform.invert();
    const pos = node.getStage().getPointerPosition();
    return transform.point(pos);
  };

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [newStepId, setNewStepId] = useState(null);

  const addStep = (step) => {
    const { id } = step;
    setSteps(prev => ({
      ...prev,
      [id]: step,
    }));
  };

  const finishNewStep = () => {
    setNewStepId(null);
  }

  const startNewStep = useCallback((type, pos) => {
    const newStep = osSteps.create(type, pos);
    if (newStep) {
      addStep(newStep);
      setNewStepId(newStep.id);
    }
  }, []);

  const handleMouseDown = useCallback((e) => {
    switch (activeTool) {
      case TOOL_TYPE.STEP:
        const pos = getRelativePointerPosition(e.target.getStage());
        startNewStep(activeToolSubType, pos);
        break;

      case TOOL_TYPE.PAN:
      case TOOL_TYPE.SELECT:
      default:
        break;
    }
  }, [activeTool, activeToolSubType, startNewStep]);

  const handleDragStep = useCallback((e, stepId) => {
    if (stepId) {
      const pos = getRelativePointerPosition(e.target.getStage());
      setSteps(prev => {
        const step = prev[stepId];
        return {
          ...prev,
          [stepId]: {
            ...osSteps.updatePosition(step, pos),
          }
        };
      });
    }
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (activeTool === TOOL_TYPE.PAN && isMouseDown) {
      //handlePanMove(e);
    }
    else if (newStepId) {
      handleDragStep(e, newStepId);
    }
  }, [activeTool, isMouseDown, newStepId]);

  const handleMouseUp = useCallback((e) => {
    if (activeTool === TOOL_TYPE.PAN) {
      setIsMouseDown(false);
    }
    else if (newStepId) {
      finishNewStep();
    }
  }, [activeTool, newStepId]);

  return (
    <>
      <DrawingToolbar
        onSelect={handleToolSelect}
        showWorkflowTools
        toolSettings={toolSettings}
      />
      <Stage
        width={window.innerWidth - 160 - (openSideDrawer ? 220 : 0) - (sidebarActive ? 300 : 0)}
        height={window.innerHeight - 160}
        onClick={handleStageClick}
        onDragStart={handleStageDragStart}
        onDragEnd={handleStageDragEnd}
        onDragMove={handleStageDragMove}
        draggable={activeTool === TOOL_TYPE.PAN}
        x={stagePos.x}
        y={stagePos.y}
        ref={stageRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <WorkFlowLayer
          activeTool={activeTool}
          setOpenSideDrawer={setOpenSideDrawer}
          steps={steps}
          setSteps={setSteps}
          connections={connections}
          setConnections={setConnections}
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
        />
      </Stage>
      <SideDrawer
        onClose={() => setOpenSideDrawer(false)}
        show={openSideDrawer}
      >
        <StepDetailsTabs
          onChange={({ key, value }) => {
            if (selectedStep) {
              setSteps(prevSteps => ({
                ...prevSteps,
                [selectedStep]: {
                  ...prevSteps[selectedStep],
                  [key]: value,
                }
              }));
            }
          }}
          step={steps[selectedStep]}
        />
      </SideDrawer>
    </>
  );
};

export default ConnectingObjects;
