import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Messaging from "../../components/Messaging";
import {
  getChannels as channelsSelector,
  getConversations as conversationsSelector,
} from "../../helpers/messagingSelectors";
import { getSiteId as siteIdSelector } from "../../helpers/selectors";
import { useMessagingContext } from "../../hooks/Messaging";

const MessagingPage = ({
  /**
   * mapped prop - siteId
   */
  siteId,
  location,
}) => {
  const {
    haveChannels,
    haveConversations,
    selectChannel,
    selectConversation,
  } = useMessagingContext();

  const channels = useSelector(channelsSelector);
  const conversations = useSelector(conversationsSelector);

  const [selectedSiteId, setSelectedSiteId] = useState(siteId);

  const filteredChannels = () =>
    (selectedSiteId > 0)
      ? channels.filter(n => n.siteId === selectedSiteId)
      : channels;

  const selectFirstChannel = () => {
    const channels = filteredChannels();
    const channelId =
      Array.isArray(channels) && channels.length > 0
        ? channels[0].siteChannelId
        : null;
    selectChannel(channelId);
  };

  useEffect(() => {
    if (!haveChannels || !haveConversations) {
      return;
    }

    const conversationId = location?.state?.conversationId;

    if (selectedSiteId !== siteId || selectedSiteId == null && !conversationId) {
      console.info("** selecting first channel");
      selectFirstChannel();
    }
    else if (conversationId) {
      console.info("** selecting conversation", { conversationId });
      delete location.state.conversationId;
      selectConversation(conversationId);
    }
  }, [haveChannels, haveConversations, selectedSiteId]);

  return (
    <Messaging
      channels={filteredChannels()}
      conversations={conversations}
      setSiteId={setSelectedSiteId}
    />
  );
};

const mapStateToProps = state => {
  return {
    siteId: siteIdSelector(state),
  };
};

export default connect(mapStateToProps)(MessagingPage);
