import React from 'react';
import { Group, Path } from 'react-konva';

const SinglePageIcon = ({ x = 0, y = 0, width = 24, height = 24, fill = 'black', stroke = 'none', strokeWidth = 0, scale = { x: 1, y: 1 }, opacity = 1, listening = false, offsetX = 0, offsetY = 0, renderAsSVG = false }) => {
  return renderAsSVG ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      opacity={opacity}
    >
      <g>
        <path d="M2.401,4.161v15.678c0,2.183,1.775,3.957,3.959,3.957h11.281c2.182,0,3.957-1.774,3.957-3.957V7.604V6.997l-0.43-0.43
		l-5.936-5.934l-0.428-0.43h-0.607H6.36C4.177,0.204,2.401,1.979,2.401,4.161z M3.867,19.839V4.163c0-1.377,1.116-2.492,2.491-2.492
		h7.839v3.857c0,1.146,0.931,2.076,2.076,2.076h3.856v12.234c0,1.377-1.116,2.492-2.491,2.492H6.358
		C4.983,22.331,3.867,21.216,3.867,19.839L3.867,19.839z"/>
      </g>
    </svg>
  ) : (
    <Group
      x={x}
      y={y}
      width={width}
      height={height}
      scale={scale}
      listening={listening}
      offsetX={offsetX}
      offsetY={offsetY}
      opacity={opacity}
    >
      <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} data="M2.401,4.161v15.678c0,2.183,1.775,3.957,3.959,3.957h11.281c2.182,0,3.957-1.774,3.957-3.957V7.604V6.997l-0.43-0.43
		l-5.936-5.934l-0.428-0.43h-0.607H6.36C4.177,0.204,2.401,1.979,2.401,4.161z M3.867,19.839V4.163c0-1.377,1.116-2.492,2.491-2.492
		h7.839v3.857c0,1.146,0.931,2.076,2.076,2.076h3.856v12.234c0,1.377-1.116,2.492-2.491,2.492H6.358
		C4.983,22.331,3.867,21.216,3.867,19.839L3.867,19.839z"/>
    </Group>
  );
};

export default SinglePageIcon;