import { Rect, Text } from "react-konva";
import { SHAPESIZE } from "../../utils/config";
import { TOOL_TYPE } from "../../utils/types";
import TextWithBackground from "./functions/TextWithBackground";
import StepTypeIcon from "./StepTypeIcon";

const ConnectionBox = ({ activeTool, box, id, onSelection, onBoxDrag, onDblClick }) => {
  const { x, y, colour, name, notifications, type } = box;

  //const iconScale = 1.5;  
  const iconScale = (SHAPESIZE / 24) * 0.6;
  const iconWidth = 24;
  return (
    <>
      {/* Circle with a number above the node */}
      {notifications && (<>
        <Rect
          x={x - 10}   // Position it above and to the left
          y={y - 20}
          width={20}
          height={18}
          fill="#1976d2"
          strokeWidth={0} // border width
          stroke="white" // border color
          cornerRadius={4}
          shadowBlur={0}
          opacity={0.68}
        />
        <Text
          x={x - 15}   // Adjust position to center the text within the rect
          y={y - 17}
          text={notifications.length}  // Number to be displayed
          fontSize={14}
          fill="white"
          width={30}  // Ensures text is centered
          align="center"  // Centers the text within the given width
        />
      </>)}
      {/* The node (Rectangle) */}
      <Rect
        x={x}
        y={y}
        width={SHAPESIZE}
        height={SHAPESIZE}
        lineJoin={"round"}
        fill={colour}
        onClick={(e) => onSelection(e, id)}
        onDblClick={onDblClick}
        listening={activeTool === TOOL_TYPE.SELECT}
        draggable
        onDragMove={(e) => onBoxDrag(e, id)}
        perfectDrawEnabled={false}
        strokeWidth={0} // border width
        stroke={"white"}
        cornerRadius={6}
        shadowBlur={0}
        opacity={0.92}
      />
      <StepTypeIcon
        type={type}
        x={x + (SHAPESIZE / 2) - ((iconWidth * iconScale) / 2)}
        y={y + (SHAPESIZE / 2) - ((iconWidth * iconScale) / 2)}
        fill="white"
        scale={{ x: iconScale, y: iconScale }}
        opacity={0.5}
        listening={false}
      />
      {/* Label under each node using TextWithBackground */}
      <TextWithBackground
        x={x + SHAPESIZE / 2}
        y={y + SHAPESIZE + 20}
        text={name}
        fontSize={14}
        bgColor="white"
        textColor="black"
        opacity={0.4}
      />
    </>)
}

export default ConnectionBox;
