import { useMemo } from 'react';
import { Menu } from '@mui/material';
import { STEP_TEMPLATES } from "../../utils/config";
import { TOOL_TYPE } from "../../utils/types";
import StepTypeMenuIcon from "./StepTypeMenuIcon";
import StepTypeMenuItem from "./StepTypeMenuItem";

const StepTypeMenu = ({
  activeStepType,
  anchorEl,
  onClose,
  onSelect,
}) => {
  const stepTypeMenuItems = useMemo(() => {
    return Object
      .keys(STEP_TEMPLATES)
      .map((key) => {
        const { name, type } = STEP_TEMPLATES[key];
        return { text: name, type };
      });
  }, []);

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      variant="menu"
    >
      {stepTypeMenuItems
        .map(
          item =>
            <StepTypeMenuItem
              key={item.type}
              icon={<StepTypeMenuIcon type={item.type} />}
              onSelect={(e) => onSelect?.(e, TOOL_TYPE.STEP, item.type)}
              selected={activeStepType === item.type}
              text={item.text}
            />
        )
      }
    </Menu>
  );
};

export default StepTypeMenu;
