import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Channels from "./Channels";
import MessageSection from "./MessageSection";

const useStyles = makeStyles(theme => ({
  messagingContainer: {
    flex: 1,
    height: "100%",
  },
  messageSection: {
    display: "flex",
    flexGrow: 1,
  },
  channelsSection: {
    height: "100%",
  },
}));

const DesktopView = ({
  activeChannelId,
  activeConversationId,
  channel,
  channelLoading,
  channels,
  channelsLoading,
  conversation,
  conversationLoading,
  conversations,
  conversationsLoading,
  onChannelSelected,
  onConversationAdded,
  onConversationSelected,
  setSiteId,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={0} className={classes.messagingContainer}>
      <Grid item xs={12} md={2} className={classes.channelsSection}>
        <Channels
          activeChannelId={activeChannelId}
          activeConversationId={activeConversationId}
          channels={channels}
          channelsLoading={channelsLoading}
          conversations={conversations}
          conversationsLoading={conversationsLoading}
          onChannelSelected={onChannelSelected}
          onConversationAdded={onConversationAdded}
          onConversationSelected={onConversationSelected}
          setSiteId={setSiteId}
        />
      </Grid>
      <Grid item xs={12} md={10} className={classes.messageSection}>
        <MessageSection
          activeChannelId={activeChannelId}
          activeConversationId={activeConversationId}
          channel={channel}
          channelLoading={channelLoading}
          conversation={conversation}
          conversationLoading={conversationLoading}
        />
      </Grid>
    </Grid>
  );
};

export default DesktopView;
