import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import { Avatar, ClickAwayListener, Box, Divider, Grid, Grow, Paper, Popper, List, Typography, ListItem, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";

import SupportModal from "../common/SupportModal";

import AccountLogoutMenuItem from "./AccountLogoutMenuItem";
import AccountSettingsModal from "./AccountSettingsModal";
import AccountSettingsMenuItem from "./AccountSettingsMenuItem";
import AccountSupportMenuItem from "./AccountSupportMenuItem";
import MyConsentRequestItem from "./MyConsentRequestItem";
import MyMonitoringMenuItem from "./MyMonitoringMenuItem";
import MyNotificationHistoryMenuItem from "./MyNotificationHistoryMenuItem";
import MyOrderHistoryMenuItem from "./MyOrderHistoryMenuItem";
import MySharesMenuItem from "./MySharesMenuItem";
import MySentConsents from "./MySentConsents";

const useStyles = makeStyles(theme => ({
  root: {
    cursor: "pointer",
    position: "relative",
    marginLeft: "4px",
  },
  space: {
    marginRight: 8,
    marginLeft: 8,
  },
  displayName: {
    overflow: "hidden",
    maxHeight: "42px",
    fontSize: 12,
    lineHeight: 1.2,
    color: theme.palette.component.headerColor,
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.primary.contrastText,
    },
  },
  displayEmail: {
    color: theme.palette.component.headerColor,
    fontSize: 12,
    paddingLeft: "4px",
    paddingRight: "4px",
  },
  avatar: {
    position: "absolute",
    left: 0,
    margin: 6.5,
    width: 33,
    height: 33,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  arrow: {
    position: "absolute",
    right: 0,
    color: theme.palette.component.headerColor,
    paddingRight: "4px",
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.primary.contrastText,
    },
  },
  details: {
    background: theme.palette.component.headerBackground,
    boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    zIndex: 100,
    [theme.breakpoints.down("md")]: {
      left: "-60px !important",
    },
    [theme.breakpoints.down("md")]: {
      left: "-30px !important",
    },
    [theme.breakpoints.down("sm")]: {
      left: "0px !important",
    },
  },
  userInfo: {
    color: theme.palette.component.headerColor,
  },
  wrapper: {
    background: theme.palette.component.headerBackground,
  },
  detailName: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "normal",
  },
  paper: {
    margin: theme.spacing(1),
    position: "absolute",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const UserDetails = ({ viewer }) => {
  const { firstname, lastname, email, contact, username } = viewer;
  const { avatar } = useSelector(state => state.api.currentViewer.viewer);

  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openSupport, setOpenSupport] = useState(false);

  const classes = useStyles();

  function handleClickAway(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  const handleOpenSettings = () => {
    setOpenSettings(true);
    setOpen(false);
  };

  const handleOpenSupport = () => {
    setOpenSupport(true);
    setOpen(false);
  };

  /*   margin: 0px;
    font-size: 12px;
    font-family: Montserrat, "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.2;
    padding-left: 4px;
    padding-right: 4px;
    min-width: 50px;
Padding to the left and right of user name
Padding to the right of the arrow
Margin to the left from avatar to shopping basket, add about 4px extra.*/

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        ref={anchorRef}
        onClick={() => setOpen(prevOpen => !prevOpen)}
        sx={{ height: "46px" }}
      >
        <Avatar alt={firstname} src={avatar} className={classes.avatar} />
        <Box component="div" display="inline" sx={{ marginLeft: "42px", marginRight: "18px", paddingLeft: "4px", paddingRight:"4px" }}>
          <Typography variant="body1" className={classes.displayName}>
            {firstname && <span>{firstname}</span>}
            {firstname && lastname && <br />}
            {lastname && <span>{lastname}</span>}
          </Typography>
        </Box>
        <Box component="div" display="inline" className={classes.arrow}>
          {open ? <ArrowDropUp /> : <ArrowDropDown />}
        </Box>
      </Grid>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        className={classes.details}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper id="menu-list-grow" className={classes.wrapper}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box>
                  <Box
                    m={2}
                    className={classes.userInfo}
                    onClick={handleOpenSettings}
                  >
                    <Typography className={classes.detailName}>
                      {firstname && <span>{firstname}</span>}{" "}
                      {lastname && <span>{lastname}</span>}
                    </Typography>
                    <Typography className={classes.displayEmail}>
                      {email}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box>
                    <List
                      component="nav"
                      className={classes.list}
                      aria-label="main mailbox folders"
                    >
                      <AccountSettingsMenuItem handleOpenSettings={handleOpenSettings} />
                      <AccountSupportMenuItem handleOpenSupport={handleOpenSupport} />
                      <MyMonitoringMenuItem />
                      <MyNotificationHistoryMenuItem />
                      <MyConsentRequestItem />
                      <MySentConsents/>
                      <MyOrderHistoryMenuItem />
                      <MySharesMenuItem />
                    </List>
                  </Box>
                  <Divider />
                  <Box>
                    <List component="nav" className={classes.list}>
                      <AccountLogoutMenuItem />
                    </List>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <SupportModal open={openSupport} handleClose={() => setOpenSupport(false)} />
      {openSettings && (
        <AccountSettingsModal
          open={openSettings}
          handleClose={() => setOpenSettings(false)}
          initialValues={{ firstname, lastname, email, contact }}
          username={username}
        />
      )}
    </div>
  );
};

UserDetails.propTypes = {
  viewer: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    avatar: PropTypes.string,
    contact: PropTypes.string,
  }).isRequired,
};

export default UserDetails;
