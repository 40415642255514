import { useState } from "react";
import Draggable from "react-draggable";
import {
  CloudUploadOutlined as FileUploadIcon,
  DragIndicator as DragIndicatorIcon,
  EditOutlined as BorderColorIcon,
  FormatColorFillOutlined as FillColorIcon,
  LineWeight as LineWeightIcon,
  NavigationOutlined as SelectToolIcon,
  PanToolOutlined as PanToolIcon,
  PivotTableChart as OrientationIcon,
} from "@mui/icons-material";
import { Box, Divider, IconButton, Menu, MenuItem, Paper, Stack, Typography } from "@mui/material";
import { ANNOTATION_TYPE, STEP_TYPE, TOOL_TYPE } from "../utils/types";
import { AnnotationTypeMenu, AnnotationTypeMenuIcon, StepTypeMenu, StepTypeMenuIcon, ToolbarIconButton } from "./Toolbar";

const backgroundColorList = [
  { color: "black", label: "Black" },
  { color: "red", label: "Red" },
  { color: "green", label: "Green" },
  { color: "blue", label: "Blue" },
  { color: "yellow", label: "Yellow" },
];

const fillColorList = [
  { color: "transparent", label: "None" },
  { color: "black", label: "Black" },
  { color: "red", label: "Red" },
  { color: "blue", label: "Blue" },
  { color: "green", label: "Green" },
  { color: "yellow", label: "Yellow" },
];

const DrawingToolbar = ({
  onFileUpload,
  onSelect,
  setBorderColor,
  setFillColor,
  setLineWeight,
  showAnnotationTools,
  showWorkflowTools,
  toolSettings,
}) => {
  const { tool: activeToolType, subType: activeToolSubtype } = toolSettings;

  const [annotationTypeMenuAnchorEl, setAnnotationTypeMenuAnchorEl] = useState(null);
  const [stepTypeMenuAnchorEl, setStepTypeMenuAnchorEl] = useState(null);

  const [isSnapped, setIsSnapped] = useState(false);
  const [isVertical, setIsVertical] = useState(true);

  const [borderColorAnchorEl, setBorderColorAnchorEl] = useState(null);
  const [fillColorAnchorEl, setFillColorAnchorEl] = useState(null);
  const [lineWeightAnchorEl, setLineWeightAnchorEl] = useState(null);

  const [annotationToolSubType, setAnnotationToolSubType] = useState(ANNOTATION_TYPE.ARROW);
  const [stepToolSubType, setStepToolSubType] = useState(STEP_TYPE.CONTAINER);


  const handleLineWeightClick = (e) => {
    setLineWeightAnchorEl(e.currentTarget);
  };

  const handleLineWeightClose = (lineWeight) => {
    setLineWeightAnchorEl(null);
    if (lineWeight) {
      setLineWeight(lineWeight);
    }
  };


  const handleBorderColorClick = (e) => {
    setBorderColorAnchorEl(e.currentTarget);
  };

  const handleBorderColorClose = (borderColor) => {
    setBorderColorAnchorEl(null);
    if (borderColor) {
      setBorderColor(borderColor);
    }
  };


  const handleFillColorClick = (e) => {
    setFillColorAnchorEl(e.currentTarget);
  };

  const handleFillColorClose = (fillColor) => {
    setFillColorAnchorEl(null);
    if (fillColor) {
      setFillColor(fillColor);
    }
  };


  // Snap toolbar to the left side of the screen when dragged close enough
  const handleDragStop = (e, data) => {
    const snapThreshold = 50;
    if (data.x < snapThreshold && !isSnapped) {
      setIsSnapped(true);
      setIsVertical(true);
    } else if (isSnapped && data.x > snapThreshold) {
      setIsSnapped(false);
    }
  };

  const handleToolSelect = (e, toolType, subType) => {
    onSelect?.(e, toolType, subType);
    if (toolType === TOOL_TYPE.ANNOTATION) {
      setAnnotationToolSubType(subType);
    }
    else if (toolType === TOOL_TYPE.STEP) {
      setStepToolSubType(subType);
    }
    toggleAnnotationTypeMenu(null);
    toggleStepTypeMenu(null);
  }

  // Toggle orientation of the toolbar between vertical and horizontal
  const toggleOrientation = () => {
    setIsVertical(!isVertical);
    setIsSnapped(false);
  };

  const toggleAnnotationTypeMenu = (e) => {
    const target = e?.currentTarget;
    if (!!target && annotationToolSubType) {
      onSelect?.(e, TOOL_TYPE.ANNOTATION, annotationToolSubType);
    }
    setAnnotationTypeMenuAnchorEl(target);
  }

  const toggleStepTypeMenu = (e) => {
    const target = e?.currentTarget;
    if (!!target && stepToolSubType) {
      onSelect?.(e, TOOL_TYPE.STEP, stepToolSubType);
    }
    setStepTypeMenuAnchorEl(target);
  }

  const sxBorderRadius = {
    borderRadius: 1,
    "& .MuiTouchRipple-root .MuiTouchRipple-child": {
      borderRadius: 1,
    },
  };

  return (
    <Draggable
      handle=".handle"
      bounds="parent"
      onStop={handleDragStop}
      position={isSnapped ? { x: 0, y: 0 } : null}
    >
      <Paper
        elevation={3}
        sx={{
          position: 'absolute',
          top: 0, // initial position
          left: 0, // initial position (WF: 250)
          backgroundColor: 'rgba(211, 211, 211, 0.4)',
          '&:hover': {
            backgroundColor: 'rgba(211, 211, 211, 0.9)',
          },
          borderRadius: '4px',
          padding: '4px',
          display: 'flex',
          flexDirection: isVertical ? 'column' : 'row',
          zIndex: 1000,
        }}
      >
        <Box
          className="handle"
          sx={{
            cursor: 'move',
            marginRight: '0px',
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DragIndicatorIcon sx={{ transform: isVertical ? 'rotate(-90deg)' : 'rotate(0deg)' }} />
        </Box>

        <IconButton
          onClick={toggleOrientation}
          sx={{
            ...sxBorderRadius,
            color: 'rgba(0, 0, 0, 0.4)',
          }}
        >
          <OrientationIcon />
        </IconButton>

        {(!!setLineWeight || !!setBorderColor || !!setFillColor) && (
          <Divider orientation={isVertical ? 'horizontal' : 'vertical'} flexItem sx={{ my: 1, mx: 0.5 }} />
        )}

        {/* Line weight */}
        {!!setLineWeight && (
          <>
            <IconButton
              color="inherit"
              onClick={handleLineWeightClick}
              sx={{
                ...sxBorderRadius,
                maxWidth: "40px",
                padding: (isVertical ? '3px 8px 1px 8px' : '4px 9px 4px 9px'),
              }}
            >
              <Stack
                direction='column'
                spacing={'-5px'} // Adjust spacing between the text and box
                sx={{
                  padding: '0px',
                  alignItems: 'center', // Center content horizontally
                  justifyContent: 'center', // Center content vertically (if needed)
                  backgroundColor: 'transparent',
                  border: 'none',
                }}
              >
                <Typography variant="caption"
                  sx={{
                    fontSize: '10px',
                    fontWeight: '500',
                    textWrap: 'nowrap',
                  }}
                >
                  {toolSettings.lineWeight} px
                </Typography>
                <LineWeightIcon sx={{ fontSize: '18px', display: 'block', transform: 'scale(1.25, 1)', padding: '0px', margin: '0px' }} />
              </Stack>
            </IconButton>
            <Menu
              anchorEl={lineWeightAnchorEl}
              open={Boolean(lineWeightAnchorEl)}
              onClose={() => handleLineWeightClose()}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 18, 20].map((lineWeight) => (
                <MenuItem
                  key={lineWeight}
                  selected={lineWeight === toolSettings.lineWeight}
                  onClick={() => handleLineWeightClose(lineWeight)}
                >
                  {lineWeight}px
                </MenuItem>
              ))}
            </Menu>
          </>
        )}

        {/* Border color */}
        {!!setBorderColor && (
          <>
            <IconButton
              color="inherit"
              onClick={handleBorderColorClick}
              sx={{
                ...sxBorderRadius,
                padding: (isVertical ? '7px 8px 9px 8px' : '0px 11px 4px 9px'),
              }}
            >
              <Stack
                direction='column'
                spacing={'-2px'}
                sx={{
                  padding: '0px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'transparent',
                  border: 'none',
                }}
              >
                <BorderColorIcon sx={{
                  width: "20px",
                  margin: '0px',
                }} />
                <Box sx={{
                  width: '18px',
                  height: '4px',
                  backgroundColor: toolSettings.borderColor
                }} />
              </Stack>
            </IconButton>
            <Menu
              anchorEl={borderColorAnchorEl}
              open={Boolean(borderColorAnchorEl)}
              onClose={() => handleBorderColorClose()}
            >
              {backgroundColorList.map((item) => (
                <MenuItem
                  key={item.color}
                  selected={item.color === toolSettings.borderColor}
                  onClick={() => handleBorderColorClose(item.color)}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}

        {/* Fill color */}
        {!!setFillColor && (
          <>
            <IconButton
              color="inherit"
              onClick={handleFillColorClick}
              sx={{
                ...sxBorderRadius,
                padding: (isVertical ? '7px 8px 9px 8px' : '2px 11px 2px 9px'),
              }}
            >
              <Stack
                direction='column'
                spacing={'-5px'}
                sx={{
                  padding: '0px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'transparent',
                  border: 'none',
                }}
              >
                <FillColorIcon sx={{
                  width: "20px",
                  margin: '0px',
                }} />
                <Box sx={{
                  width: '18px',
                  height: '4px',
                  backgroundColor: toolSettings.fillColor === 'transparent' ? 'white' : toolSettings.fillColor,
                  border: toolSettings.fillColor === 'transparent' ? '1px solid black' : 'none',
                }} />
              </Stack>
            </IconButton>
            <Menu
              anchorEl={fillColorAnchorEl}
              open={Boolean(fillColorAnchorEl)}
              onClose={() => handleFillColorClose()}
            >
              {fillColorList.map((item) => (
                <MenuItem
                  key={item.color}
                  selected={item.color === toolSettings.fillColor}
                  onClick={() => handleFillColorClose(item.color)}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}

        <Divider orientation={isVertical ? 'horizontal' : 'vertical'} flexItem sx={{ my: 1, mx: 0.5 }} />

        <ToolbarIconButton
          activeToolType={activeToolType}
          onSelect={handleToolSelect}
          toolType={TOOL_TYPE.SELECT}
        >
          <SelectToolIcon sx={{ transform: 'rotate(-25deg)' }} />
        </ToolbarIconButton>

        <ToolbarIconButton
          activeToolType={activeToolType}
          onSelect={handleToolSelect}
          toolType={TOOL_TYPE.PAN}
        >
          <PanToolIcon />
        </ToolbarIconButton>

        {(showAnnotationTools || showWorkflowTools) && (
          <Divider orientation={isVertical ? 'horizontal' : 'vertical'} flexItem sx={{ my: 1, mx: 0.5 }} />
        )}

        {showAnnotationTools && (
          <>
            <ToolbarIconButton
              activeToolType={activeToolType}
              onSelect={toggleAnnotationTypeMenu}
              toolType={TOOL_TYPE.ANNOTATION}
              isMenuButton
            >
              <AnnotationTypeMenuIcon type={annotationToolSubType} />
            </ToolbarIconButton>

            <AnnotationTypeMenu
              activeAnnotationType={activeToolSubtype}
              anchorEl={annotationTypeMenuAnchorEl}
              onClose={() => toggleAnnotationTypeMenu(null)}
              onSelect={handleToolSelect}
              sx={{
                "& .MuiMenu-list": {
                  padding: "4px 0",
                },
                "& .MuiMenuItem-root": {
                  padding: "0 4px",
                  "&:hover": {
                    background: "none",
                  }
                }
              }}
            />
          </>
        )}

        {showWorkflowTools && (
          <>
            <ToolbarIconButton
              activeToolType={activeToolType}
              onSelect={toggleStepTypeMenu}
              toolType={TOOL_TYPE.STEP}
              isMenuButton
            >
              {/*<StepTypeMenuIcon type={activeToolSubtype} />*/}
              <StepTypeMenuIcon type={stepToolSubType} />
            </ToolbarIconButton>

            <StepTypeMenu
              activeStepType={activeToolSubtype}
              anchorEl={stepTypeMenuAnchorEl}
              onClose={() => toggleStepTypeMenu(null)}
              onSelect={handleToolSelect}
            />
          </>
        )}

        {!!onFileUpload && (
          <>
            <Divider orientation={isVertical ? 'horizontal' : 'vertical'} flexItem sx={{ my: 1, mx: 0.5 }} />

            <IconButton
              color="inherit"
              component="label"
              sx={{
                ...sxBorderRadius,
                color: 'rgba(200, 0, 0, 0.7)',
                padding: (isVertical ? '7px 8px 9px 8px' : '2px 11px 2px 9px'),
              }}
            >
              <FileUploadIcon />
              <input type="file" accept="application/pdf,image/*" hidden onChange={onFileUpload} />
            </IconButton>
          </>
        )}

      </Paper>

    </Draggable>
  );
};

export default DrawingToolbar;
