import { debounce, orderBy, partition } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { useMutation } from "@apollo/client";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CREATE_CONVERSATION as CREATE_DIRECT_MESSAGE_CONVERSATION } from "../../../graphql/mutations/DirectMessageContext";
import { getConversations as conversationsSelector } from "../../../helpers/messagingSelectors";
import { useMessagingContext, useSearchContacts } from "../../../hooks/Messaging";
import CustomTextField from "../../common/TextField";
import StartConversationModal from "../StartConversationModal";
import ConversationContainer from "./ConversationContainer";
import HeaderList from "./HeaderList";

const useStyles = makeStyles(theme => ({
  panelRoot: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  panelSummaryRoot: {
    minHeight: 30,
    padding: 0,
    "&.Mui-expanded": {
      margin: "0 !important",
      minHeight: 30,
    },
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
      cursor: "default",
      margin: "0 !important",
      paddingLeft: 20,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0 !important",
      minHeight: 30,
    },
  },
  searchField: {
    backgroundColor: "#fff",
    margin: "0 0 0.5rem 0",
  },
}));

const canSearch = (value) => (value && value.length > 2);

const createConversationItem = (user) => ({
  //directMessageConversationId: null,
  //lastMessage: null,
  //lastMessageId: null,
  //latestReadMessageId: null,
  //latestReadMessageTime: null,
  members: [{
    avatarUrl: user.avatarUrl,
    name: `${user.firstname} ${user.lastname}`,
    userId: user.userId,
    username: user.username,
  }],
  unreadMessageCount: 0,
});

const MessagingContacts = ({
  /**
   * mapped prop - conversations
   */
  conversations,
  onConversationAdded,
  onConversationSelected,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { conversationsLoading } = useMessagingContext();

  const [conversationSearchResult, setConversationSearchResult] = useState();
  const [searching, setSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [showStartConversationModal, setShowStartConversationModal] = useState(false);

  const [createConversation] = useMutation(CREATE_DIRECT_MESSAGE_CONVERSATION);

  const [searchContacts] = useSearchContacts({
    onCompleted: (data, searchTerm) => {
      if (searchTerm !== searchValue) {
        setSearching(false);
        return;
      }

      try {
        const searchResult = data.map(user => createConversationItem(user));
        const sortedResult = sortConversations(searchResult)
        setConversationSearchResult(sortedResult);
      }
      catch (e) {
        console?.error("** could not process the search result", { e });
        setConversationSearchResult([]);
        setSearching(false);
      }
    },
    onError: (error, searchTerm) => {
      setConversationSearchResult([]);
    },
  });

  const debouncedSearch = useCallback(
    debounce(value => {
      setSearching(true);
      searchContacts(value);
    }, 800),
    []
  );

  useEffect(() => {
    setSearching(false);
  }, [conversationSearchResult]);

  const handleAddConversation = async (userIds) => {
    try {
      const res = await createConversation({
        variables: {
          userIds,
        },
      });
      if (
        res &&
        res.data &&
        res.data.directMessageContext &&
        res.data.directMessageContext.createConversation
      ) {
        onConversationAdded({
          conversation: res.data.directMessageContext.createConversation,
        });
      }
    } catch (err) {
      console.error("error >", err);
    }

    setShowStartConversationModal(false);
  };

  const handleSearchValueChange = async (e) => {
    const { value } = e?.target;
    setSearchValue(value);
    if (canSearch(value)) {
      debouncedSearch(value);
    }
    else {
      setConversationSearchResult();
    }
  };

  const sortConversations = (conversations) => {
    if (!Array.isArray(conversations)) {
      return [];
    }
    let filteredConversations = [...conversations];

    const partitions = partition(filteredConversations, (c) => (c.lastMessage == null));

    const activeConversations = orderBy(partitions[1], [c => moment(c.lastMessage.messageTime)], ['desc']);
    const emptyConversations = orderBy(partitions[0], [c => c.members[0].name.toLowerCase()], ['asc']);

    return [...activeConversations, ...emptyConversations];
  };

  const sortedConversations = useMemo(() => {
    return sortConversations(conversations);
  }, [conversations]);

  return (
    <>
      <Accordion classes={{ root: classes.panelRoot }} expanded>
        <AccordionSummary classes={{ root: classes.panelSummaryRoot }}>
          <HeaderList
            iconOnClick={() => setShowStartConversationModal(true)}
            title={<FormattedMessage id="widget.directMessages" />}
          />
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 0 }}>
          <CustomTextField customClass={classes.searchField}
            endAdornment={
              (searching && canSearch(searchValue))
                ? (<CircularProgress size={"1rem"} />)
                : (<i className={`nc-icon nc-zoom-split`} />)
            }
            onChange={handleSearchValueChange}
            placeholder={intl.formatMessage({ id: "nav.search" })}
            value={searchValue}
          />
          <ConversationContainer
            conversations={!!conversationSearchResult ? conversationSearchResult : sortedConversations}
            loading={conversationsLoading}
            onAddConversation={handleAddConversation}
            onConversationSelected={onConversationSelected}
          />
        </AccordionDetails>
      </Accordion>
      <StartConversationModal
        handleClose={() => setShowStartConversationModal(false)}
        onAddConversation={handleAddConversation}
        open={showStartConversationModal}
      />
    </>
  );
};

MessagingContacts.defaultProps = {
  conversations: [],
};

MessagingContacts.propTypes = {
  conversations: PropTypes.arrayOf(PropTypes.shape({})),
  onConversationAdded: PropTypes.func.isRequired,
  onConversationSelected: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    conversations: conversationsSelector(state),
  };
};

export default connect(mapStateToProps)(MessagingContacts);
