import React, { memo } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LinkWidget from "../../widgets/LinkWidget/LinkWidget";
import ContactsWidget from "../../widgets/ContactsWidget/ContactsWidget";
import NewsPostVertical from "../../widgets/NewsPostVertical";
import NewsSiteWidget from "../../widgets/NewsPostBig";
import RecentlyModifiedWidget from "../../widgets/RecentlyModifiedWidget";
import TextEditor from "../../widgets/InformationWidget/TextEditor";
import WorkspaceLinkWidget from "../../widgets/WorkspaceLinkWidget/WorkspaceLinkWidget";
import WorkspaceWidget from "../../widgets/WorkspaceWidget";
import YouTubeWidget from "../../widgets/YoutubeWidget/YouTubeWidget";
import ImageGalleryWidget from "../../widgets/ImageGalleryWidget"
import NewsFlowWidget from "../../widgets/NewsFlowWidget"
import ActionBar from "./ActionBar";
import { WIDGET_TYPES } from "../../../constant/widgets";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles(theme => ({
  gridLayout: {
    "& > .react-draggable .widgetTitle": {
      cursor: "grab",
    },
    "& > .react-draggable-dragging": {
      cursor: "grabbing",
    },
  },
  hidden: {
    display: "none",
  },
}));

const GridLayout = ({
  canEditDashboard,
  configuring,
  configuringWidgetIndex,
  disabled,
  isEditing,
  layouts,
  onConfigureWidget,
  onLayoutChanged,
  onSetShowTextEditor,
  onTextEditorCancel,
  onTextEditorEdit,
  onTextEditorUpdate,
  onWidgetAdd,
  onWidgetRemove,
  showTextEditor,
  projectId,
  siteId
}) => {
  const classes = useStyles();
  

  const handleChangeLayout = cols => {
    onLayoutChanged({ cols });
  };

  const handleDrop = (layout, layoutItem, event) => {
    var siteWidgetJSON = event.dataTransfer.getData("siteWidget");

    if (siteWidgetJSON.length > 0) {
      let data = JSON.parse(siteWidgetJSON);

      delete data.image;

      data.i = `${data.i}-${Date.now()}`; // !!! OK
      data.x = layoutItem.x;
      data.y = layoutItem.y;

      onWidgetAdd?.({ layoutItem: data });
    } else {
      event.dataTransfer.dropEffect = "none";
    }
  };

  const handleDropDragOver = e => {
    console.log("** [GridLayout] handleDropDragOver", { e, w: e?.w, h: e?.h });
  };

  return (
    <ResponsiveReactGridLayout
      className={classes.gridLayout}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      //breakpoints={{ lg: 1200, md: 996, sm: 768, sx: 480, xss: 0 }}
      draggableHandle=".widgetTitle"
      isDroppable={!showTextEditor && isEditing}
      isResizable={isEditing}
      layouts={layouts}
      measureBeforeMount={true}
      onDragStop={handleChangeLayout}
      onDrop={handleDrop}
      onDropDragOver={handleDropDragOver}
      onResizeStop={handleChangeLayout}
      rowHeight={30}
      useCSSTransforms={false}
    >
      {layouts.lg.map((widgetItem, index) => {
        const showActionBar = isEditing || widgetItem.showTitleBar;

        const showCogWheel =
          !disabled &&
          isEditing &&
          !widgetItem.isConfigured &&
          ["link", "page-link", "video", "workspace"].includes(widgetItem.type);

        const showCogWheelForTextEditor =
          !disabled &&
          isEditing &&
          widgetItem.type === WIDGET_TYPES.TEXTEDITOR &&
          !widgetItem.textContent &&
          !showTextEditor;

        delete widgetItem.image;

        if (widgetItem.type === WIDGET_TYPES.YOUTUBE) {
          delete widgetItem.borderRadiusBottomLeft;
          delete widgetItem.borderRadiusBottomRight;
          delete widgetItem.borderRadiusTopLeft;
          delete widgetItem.borderRadiusTopRight;
        }

        const isDraggable =
          !configuring &&
          !disabled &&
          isEditing &&
          !(showTextEditor && widgetItem.type === WIDGET_TYPES.TEXTEDITOR);

        const key = Math.random(); // !!!

        return (
          <Box
            className="widgetItem"
            data-grid={{
              w: widgetItem.w,
              h: widgetItem.h,
              x: widgetItem.x,
              y: widgetItem.y,
              minW: widgetItem.minW,
              minH: widgetItem.minH,
              maxW: widgetItem.maxW,
              maxH: widgetItem.maxH,
              isDraggable: isDraggable,
              isResizable: isEditing,
              type: widgetItem.type,
            }}
            key={key}
            style={{
              background: widgetItem.bgColor || "#fff",
              borderBottomLeftRadius: `${widgetItem.borderRadiusBottomLeft}px`,
              borderBottomRightRadius: `${widgetItem.borderRadiusBottomRight}px`,
              borderColor: `${widgetItem.borderColor}`,
              borderStyle: "solid",
              borderTopLeftRadius: `${widgetItem.borderRadiusTopLeft}px`,
              borderTopRightRadius: `${widgetItem.borderRadiusTopRight}px`,
              borderWidth: `${widgetItem.borderWidth}px`,
              boxShadow: isEditing ? "0 6px 10px -4px rgb(0 0 0 / 15%)" : "none",
              color: widgetItem.fontColor,
            }}
          >
            {showActionBar && (
              <ActionBar
                disableConfigure={
                  configuring && index === configuringWidgetIndex
                }
                disabled={disabled}
                disableRemove={configuring}
                index={index}
                layout={widgetItem}
                onConfigure={onConfigureWidget}
                onRemove={() => onWidgetRemove?.({ layoutIndex: index })}
                showActions={isEditing}
                showTextEditIcon={widgetItem.type === WIDGET_TYPES.TEXTEDITOR}
                onSetShowTextEditor={onSetShowTextEditor}
              />
            )}
            <Box
              className="widgetBody"
              style={{
                backgroundImage: widgetItem.bgImage ? widgetItem.bgImage : "",
                height: isEditing || widgetItem.showTitleBar ? "93%" : "99.5%",
                marginTop:
                  isEditing || widgetItem.showTitleBar ? "26px" : "0px",
                overflow:
                  widgetItem.type === WIDGET_TYPES.TEXTEDITOR &&
                    widgetItem.h <= 5 &&
                    showTextEditor
                    ? "inherit"
                    : "auto",
              }}
            >

              {widgetItem.type == WIDGET_TYPES.NEWS_COLUMN && (
                <NewsPostVertical layout={widgetItem} projectId={projectId} siteId={siteId} />
              )}

              {widgetItem.type == WIDGET_TYPES.WORKSPACES && !showCogWheel && (
                <WorkspaceWidget layout={widgetItem} />
              )}

              {widgetItem.type == WIDGET_TYPES.IMAGE_GALLERY && (
                <ImageGalleryWidget widgetItem={widgetItem} />
              )}

              {widgetItem.type == WIDGET_TYPES.RECENTLY_MODIFIED_FILES && !showCogWheel && (
                <RecentlyModifiedWidget layout={widgetItem} />
              )}

              {widgetItem.type == WIDGET_TYPES.NEWS_BIG && (
                <NewsSiteWidget layout={widgetItem} projectId={projectId} siteId={siteId} />
              )}
              {widgetItem.type == WIDGET_TYPES.NEWS_FLOW && (
                <NewsFlowWidget layout={widgetItem} projectId={projectId} siteId={siteId}/>
                )
              }
              {widgetItem.type == WIDGET_TYPES.TEXTEDITOR &&
                !showCogWheelForTextEditor && (
                  <TextEditor
                    canEditDashboard={canEditDashboard}
                    index={index}
                    isEditing={isEditing && index === configuringWidgetIndex}
                    layout={widgetItem}
                    onCancel={onTextEditorCancel}
                    onEdit={onTextEditorEdit}
                    onUpdate={onTextEditorUpdate}
                    overflowEditor={
                      widgetItem.h <= 5
                    }
                    showEditor={
                      showTextEditor && index === configuringWidgetIndex
                    }
                    textContent={widgetItem.textContent}
                  />
                )
              }

              {widgetItem.type == WIDGET_TYPES.LINK && !showCogWheel && (
                <LinkWidget layout={widgetItem} />
              )}

              {widgetItem.type == WIDGET_TYPES.WORKSPACE && !showCogWheel && (
                <WorkspaceLinkWidget workspace={widgetItem.workspace} />
              )}

              {widgetItem.type == WIDGET_TYPES.YOUTUBE && (
                <YouTubeWidget
                  hl={widgetItem.hl}
                  videoUrl={widgetItem.videoUrl}
                />
              )}

              {widgetItem.type == WIDGET_TYPES.CONTACTS && (
                <ContactsWidget />
              )}

              {(showCogWheel || showCogWheelForTextEditor) && (
                <Box
                  className="configure"
                  onClick={() => onConfigureWidget(widgetItem, index)}
                >
                  <i className="fas fa-cogs fa-5x" />
                </Box>
              )}
            </Box>
          </Box>
        );
      })}
    </ResponsiveReactGridLayout>
  );
};

GridLayout.defaultProps = {
  layouts: {
    lg: [],
  },
};

GridLayout.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  layouts: PropTypes.shape({
    lg: PropTypes.instanceOf(Array),
  }),
  onConfigureWidget: PropTypes.func.isRequired,
};

export default memo(GridLayout);
