import { useSubscription } from "@apollo/client";
import { MESSAGE_EVENTS_SUBSCRIPTION } from "../../graphql/subscription";

const MessageEventType = Object.freeze({
  DIRECT_MESSAGE: "DIRECT_MESSAGE",
  SITE_CHANNEL_MESSAGE: "SITE_CHANNEL_MESSAGE",
  UNKNOWN: "UNKNOWN",
});

const extractEvent = (data) => data.messageEvents;

const useMessageEventsSubscription = ({ onDirectMessage, onError, onSiteChannelMessage }) => {

  const { loading } = useSubscription(
    MESSAGE_EVENTS_SUBSCRIPTION,
    {
      onComplete: () => {
        console?.info("** Completed subscription: MessageEventSubscription");
      },
      onData: (options) => {
        console?.info("** Subscription update: MessageEventSubscription (1)", { options });

        const event = extractEvent(options.data.data);

        console?.info("** Subscription update: MessageEventSubscription (2)", { event });

        const { messageEventType } = event;

        switch (messageEventType) {
          case MessageEventType.DIRECT_MESSAGE:
            onDirectMessage?.(event.directMessage);
            break;

          case MessageEventType.SITE_CHANNEL_MESSAGE:
            onSiteChannelMessage?.(event.siteChannelMessage);
            break;

          default:
            console?.warn("** Unhandled message event type", { messageEventType });
            break;
        }
      },
      onError: (error) => {
        console?.error("** Subscription error: MessageEventSubscription", { error });

        onError?.(error);
      },
      variables: {
        authToken: localStorage.getItem("access_token"),
      },
    });

  return { loading };

};

export default useMessageEventsSubscription;
