import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Input, Box, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import OneStreamLabel from "./OneStreamLabel";
import { Chip } from "../common";

const useStyles = makeStyles(theme => ({
  formControlUpload: {
    borderRadius: 4,
    border: "1px solid #ddd",
    padding: 10,
    marginBottom: 20,
  },
  btnFile: {
    padding: "3px 10px",
    fontSize: 10,
    fontWeight: 400,
    textTransform: "none",
    boxShadow: "none",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      opacity: 0.8,
    },
  },
  input: {
    display: "none",
  },
  chipCont: { marginTop: 15 },
}));

const OneStreamUpload = ({
  uploadedFile,
  handleOnSelectUploadFile,
  handleRemoveUploadedFile,
}) => {
  const classes = useStyles();

  return (
    <>
      <OneStreamLabel message={<FormattedMessage id="sorting.Upload" />} />
      <Box className={classes.formControlUpload}>
        <Input
          className={classes.input}
          id="contained-button-file"
          type="file"
          onChange={e => handleOnSelectUploadFile(e.target.files)}
        />
        <label htmlFor="contained-button-file">
          <Button className={classes.btnFile} component="span">
            <FormattedMessage id="btn.selectFile" />
          </Button>
        </label>

        
        <div className={classes.chipCont}>
          {uploadedFile !== "" && (
              <Chip
              key={uploadedFile}
              label={uploadedFile}
              onDelete={() => handleRemoveUploadedFile()}
              color="light" />
          )}
          </div>
        
      </Box>
    </>
  );
};

OneStreamUpload.defaultProps = {
  uploadedFile: [],
};

OneStreamUpload.propTypes = {
  handleOnSelectUploadFile: PropTypes.func.isRequired,
  uploadedFile: PropTypes.arrayOf(PropTypes.shape({})),
  handleRemoveUploadedFile: PropTypes.func.isRequired,
};

export default OneStreamUpload;
