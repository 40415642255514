import React, { useState, useEffect, lazy, Suspense } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Icon, IconButton } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import CustomTextField from "../common/TextField";
import CustomSelect from "../common/CustomSelect";
import { languageOptions } from "./helpers";
import { ErrorText } from "../common";
import { setLocale } from "../../actions";
//import PasswordStrength from "../PasswordStrength";

const PasswordStrength = lazy(() => import("../PasswordStrength"));

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 12,
    transform: "translate(14px, 13px) scale(1)",
  },
  password: {
    position: "relative",
  },
  eyeIconBtn: {
    position: "absolute",
    right: "0",
    top: "1rem",
  },
  eyeIcon: {
    width: 25,
    fontSize: 18,
  },
  eyeIconShow: {
    opacity: 0.5,
  },
}));

const StepOne = ({
  stepOneValues,
  setStepOneValues,
  confirmPassword,
  setConfirmPassword,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(true);
  const [lessthan8, setLessThan8] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const customLabelClass = classes.label;

  const handleChange = e => {
    setLessThan8(false);

    const { name, value } = e.target;
    setStepOneValues({ ...stepOneValues, [name]: value });
    
    if (name === "culture") {
      dispatch(setLocale({ culture: value }));
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(prevState => !prevState);
  };

  const toggleShowRepeatPassword = () => {
    setShowRepeatPassword(prevState => !prevState);
  };

  const handleConfirmPassword = e => {
    setLessThan8(false);
    const { value } = e.target;

    setConfirmPassword(value);

    if (stepOneValues.password !== value) {
      return setIsValid(false);
    }
    setIsValid(true);
  };

  useEffect(() => {
    if (stepOneValues.password && stepOneValues.password.length < 8) {
      setLessThan8(true);
    }

    if (
      stepOneValues.password &&
      confirmPassword &&
      stepOneValues.password !== confirmPassword
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [stepOneValues.password, confirmPassword]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}></Grid>
      <Grid item xs={12} md={6}>
        <CustomSelect
          name="culture"
          inputLabel={<FormattedMessage id="createAccount.language" />}
          options={languageOptions}
          onChange={handleChange}
          value={stepOneValues.culture}
          inputLabelCustomClass={customLabelClass}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <CustomTextField
          name="firstname"
          label={<FormattedMessage id="createAccount.firstname" />}
          onChange={handleChange}
          value={stepOneValues.firstname}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomTextField
          name="lastname"
          label={<FormattedMessage id="createAccount.lastname" />}
          onChange={handleChange}
          value={stepOneValues.lastname}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <CustomTextField
          name="username"
          label={<FormattedMessage id="createAccount.email" />}
          onChange={handleChange}
          value={stepOneValues.username}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomTextField
          name="contact"
          label={<FormattedMessage id="createAccount.telephone" />}
          onChange={handleChange}
          value={stepOneValues.contact}
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.password}>
        <CustomTextField
          name="password"
          label={<FormattedMessage id="createAccount.newPassword" />}
          onChange={handleChange}
          value={stepOneValues.password}
          type={showPassword ? "text" : "password"}
        />
        <IconButton
          className={classes.eyeIconBtn}
          onClick={() => toggleShowPassword()}
        >
          <Icon
            className={clsx([
              "fa fa-eye",
              classes.eyeIcon,
              showPassword && classes.eyeIconShow,
            ])}
          />
        </IconButton>

      </Grid>




      <Grid item xs={12} md={6} className={classes.password}>
        <CustomTextField
          label={<FormattedMessage id="createAccount.repeatPassword" />}
          onChange={e => handleConfirmPassword(e)}
          value={confirmPassword}
          type={showRepeatPassword ? "text" : "password"}
        />
        <IconButton
          className={classes.eyeIconBtn}
          onClick={() => toggleShowRepeatPassword()}
        >
          <Icon
            className={clsx([
              "fa fa-eye",
              classes.eyeIcon,
              showRepeatPassword && classes.eyeIconShow,
            ])}
          />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        {lessthan8 && (
          <ErrorText
            message={<FormattedMessage id="settings.lessThan8" />}
            fontSize={12}
          />
        )}
        {!isValid && (
          <ErrorText
            message={<FormattedMessage id="createAccount.passwordMatch" />}
            fontSize={12}
          />
        )}
      </Grid>

      <Grid item xs={12} md={12} className={classes.password}>
        <Suspense><PasswordStrength width={100} password={stepOneValues.password} /></Suspense>
      </Grid>




    </Grid>
  );
};

StepOne.defaultProps = {
  stepOneValues: {},
  confirmPassword: null,
};

StepOne.propTypes = {
  stepOneValues: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    contact: PropTypes.string,
    language: PropTypes.string,
  }),
  setStepOneValues: PropTypes.func.isRequired,
  confirmPassword: PropTypes.string,
  setConfirmPassword: PropTypes.func.isRequired,
};
export default StepOne;
