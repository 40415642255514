import { useState } from "react";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { IconButton, Box, ListItem, Divider, List, ListItemText, ListItemButton } from "@mui/material";
import ConnectingObjects from "../../../pages/OneStream/pages/ConnectingObjects2";
import WorkflowSelector from "../../../pages/OneStream/subcomponents/OneStream/WorkflowSelector";
import SimpleModalCompact from "../../common/ModalCompact/SimpleModalCompact";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PushPinIcon from "@mui/icons-material/PushPin";
import ListItemIcon from "@mui/material/ListItemIcon";

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(3)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(3)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme, open }) => ({
  zIndex: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 0),
  transition: theme.transitions.create(["margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginRight: open ? "0px" : "-8px",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const OneStreamFlowModal = ({ open: showModal, onClose }) => {

  const [btnClicked, setBtnClicked] = useState(true);
  const [open, setOpen] = useState(true);
  const [selectedWorkFlowId, setSelectedWorkFlowId] = useState("workflow-001");

  const handleMouseLeave = () => {
    if (!btnClicked) {
      setOpen(false);
      setBtnClicked(false);
    }
  };

  const handleDrawerToggle = () => {
    if (!btnClicked && !open) {
      setOpen(!open);
      setBtnClicked(true);
    }
    if (open && btnClicked) {
      setOpen(!open);
      setBtnClicked(false);
    }
    if (open && !btnClicked) {
      setBtnClicked(true);
    }
  };

  const handleMouseEnter = (event) => {
    const isMouseOnDrawerHeader = event.target.closest(".MuiDrawerHeader");
    if (!isMouseOnDrawerHeader) {
      setOpen(true);
    }
  };

  return (
    <SimpleModalCompact fullScreen={true} open={showModal} onClose={onClose} title="OneStream flow editor">
      <Box sx={{ display: "flex" }}>
        <Drawer variant="permanent"
          open={open}
          onMouseLeave={handleMouseLeave}
          PaperProps={{
            elevation: 1,
            square: false,
            sx: {
              height: 'calc(100vh - 160px)',
              top: "30px",
              zIndex: 2,
            },
          }}>

          <DrawerHeader open={open} className="MuiDrawerHeader">
            <IconButton onClick={handleDrawerToggle}>
              {open ? (
                btnClicked ? (
                  <ChevronLeftIcon
                    sx={{ opacity: 0.4, "&:hover": { opacity: 1 } }}
                  />
                ) : (
                  <PushPinIcon
                    sx={{
                      fontSize: "70%",
                      marginTop: "5px",
                      marginBottom: "2px",
                    }}
                  />
                )
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Box
            onMouseEnter={handleMouseEnter}
            sx={{
              height: "100%",
              marginTop: "-30px",
              zIndex: 1,
              opacity: open ? 1 : 0,
            }}
          >
            <List>
              {[
                { text: "Startsida", icon: <HomeOutlinedIcon /> },
                { text: "Flöden", icon: <NotificationsNoneOutlinedIcon /> }].map(({ text, icon: Icon }, index) => (
                  <ListItem key={text} disablePadding sx={{ display: "block", marginTop: "0px", marginBottom: "0px", padding: 0 }}>
                    <ListItemButton
                      sx={{
                        minHeight: 34,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          marginRight: "8px",
                        }}
                      >
                        {Icon}
                      </ListItemIcon>
                      <ListItemText primary={text} sx={{ marginTop: "0px", marginBottom: "0px" }} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
            {open && (
              <>
                <Divider sx={{ marginTop: "8px", marginBottom: "22px" }} />

              </>
            )}
            <WorkflowSelector
              onSelect={(n) => setSelectedWorkFlowId(n)}
              selectedWorkflowId={selectedWorkFlowId}
              size="small"
              sx={{ display: "inherit", marginLeft: "20px", marginRight: "20px" }}
            />
          </Box>

        </Drawer>
        <ConnectingObjects sidebarActive={open} workflowId={selectedWorkFlowId} />
      </Box>
    </SimpleModalCompact>)
}

export default OneStreamFlowModal;