import { SvgIcon } from '@mui/material';
import {
  ContainerIcon,
  FolderIcon,
  FolderUploadIcon,
  FolderZIPIcon,
  PreflightIcon,
  TemplateIcon,
  XMLIcon,
} from '../../icons';
import { STEP_TYPE } from "../../utils/types";

const stepTypeMenuIcons = {
  [STEP_TYPE.CONTAINER]: <SvgIcon><ContainerIcon fill="inherit" stroke="inherit" renderAsSVG /></SvgIcon>,
  [STEP_TYPE.FOLDER]: <SvgIcon><FolderIcon fill="inherit" stroke="inherit" renderAsSVG /></SvgIcon>,
  [STEP_TYPE.FOLDER_UPLOAD]: <SvgIcon><FolderUploadIcon fill="inherit" stroke="inherit" renderAsSVG /></SvgIcon>,
  [STEP_TYPE.FOLDER_ZIP]: <SvgIcon><FolderZIPIcon fill="inherit" stroke="inherit" renderAsSVG /></SvgIcon>,
  [STEP_TYPE.PREFLIGHT]: <SvgIcon><PreflightIcon fill="inherit" stroke="inherit" renderAsSVG /></SvgIcon>,
  [STEP_TYPE.TEMPLATES]: <SvgIcon><TemplateIcon fill="inherit" stroke="inherit" renderAsSVG /></SvgIcon>,
  [STEP_TYPE.XML_LOADING]: <SvgIcon><XMLIcon fill="inherit" stroke="inherit" renderAsSVG /></SvgIcon>,
};

const StepTypeMenuIcon = ({ type }) => (
  stepTypeMenuIcons[type] || stepTypeMenuIcons[STEP_TYPE.CONTAINER]
);

export default StepTypeMenuIcon;
