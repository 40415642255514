import { Box, Typography } from "@mui/material";

const PollResult = ({ poll }) => {
  return (
    <>
      {poll.choices.map((choice, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: 1,
            backgroundColor: "#f0f0f0",
            borderRadius: "4px",
            overflow: "hidden",
          }}
        >
          {/* Label Box */}
          <Box sx={{ width: "100px", flexShrink: 0, textAlign: "right", paddingRight: 1 }}>
            <Typography variant="body2" color="textPrimary">
              {choice.text}
            </Typography>
          </Box>

          {/* Percentage Bar */}
          <Box
            sx={{
              flexGrow: 1,
              height: "24px",
              maxWidth: "100%",
              backgroundColor: "#e0e0e0",
              borderRadius: "4px",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: `${choice.percentage}%`,
                minWidth: choice.percentage > 0 ? "20px" : "0px", // Ensures visibility for small percentages
                backgroundColor: "#4caf50",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontSize: "0.8rem",
              }}
            >
              {choice.percentage > 0 ? `${choice.percentage}%` : ""}
            </Box>

            {/* Fallback text display for 0% */}
            {choice.percentage === 0 && (
              <Typography variant="body2" color="textPrimary" sx={{ paddingLeft: 1 }}>
                0%
              </Typography>
            )}
          </Box>
        </Box>
      ))}
    </>
  );
};

export default PollResult;