import EmojiPicker from 'emoji-picker-react';
import { useEffect, useRef, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import PollIcon from '@mui/icons-material/Poll';
import SettingsIcon from '@mui/icons-material/Settings';
import { setRef, Box, Button, Checkbox, FormControlLabel, IconButton, Popper, Stack, TextField, Typography } from "@mui/material";
import { useSnackbar } from "../../../hooks";
import {
  useClearNewsPostFileUploadSession,
  useCreateNewsPostFileUploadSession,
} from "../../../hooks/News";
import Avatar from "../../common/Avatar";
import SimpleModalCompact from "../../common/ModalCompact/SimpleModalCompact";
import ImagesPanel from "./Images/ImagesPanel";
import MentionEditor from "./MentionEditor";
import NewsFlowPostToolbar from "./NewsFlowPostToolbar";

const NewsFlowPostModal = ({ modalState, setModalState, user, onSave, isSaving }) => {

  const [emoticonAnchorEl, setEmoticonAnchorEl] = useState(null); // Combine state management

  const intl = useIntl();
  const snackbar = useSnackbar();

  const [images, setImages] = useState([]);
  const [imagesPanelOpen, setImagesPanelOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);  // State for settings panel

  const [newsPostFileUploadSession, setNewsPostFileUploadSession] = useState(null);

  const editorRef = useRef(null);

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  const handleEmojiSelect = (emojiObject) => {
    if (editorRef.current) {
      editorRef.current?.insertEmoticon(emojiObject.emoji);
    }
    setEmoticonAnchorEl(null); // Close picker after selection
  };

  const handleVotingOptionsToggle = () => {
    setModalState(prevState => ({
      ...prevState,
      poll: {
        ...prevState.poll,
        votingOptions: prevState.poll.votingOptions === "SELECT_MULTIPLE" ? "SELECT_ONE" : "SELECT_MULTIPLE"
      }
    }));
  };

  const handleAllowNewChoicesToggle = () => {
    setModalState(prevState => ({
      ...prevState,
      poll: {
        ...prevState.poll,
        allowNewChoices: !prevState.poll.allowNewChoices
      }
    }));
  };

  const handleRemovePoll = () => {
    setModalState({ ...modalState, poll: null });
  }

  const handleAddPoll = async () => {
    if (modalState.poll == undefined) {
      let newPoll = {
        choices: ["", "", ""],
        votingOptions: "SELECT_ONE",  // Default to single choice
        allowNewChoices: false
      };

      setModalState({ ...modalState, poll: newPoll });
    }
  }

  const handleRemoveChoice = (index) => {
    if (modalState.poll) {
      const updatedChoices = modalState.poll.choices.filter((_, i) => i !== index);
      setModalState({
        ...modalState,
        poll: {
          ...modalState.poll,
          choices: updatedChoices
        }
      });
    }
  };

  const handleAddChoice = () => {
    if (modalState.poll) {
      const updatedChoices = [...modalState.poll.choices, ""];
      setModalState({
        ...modalState,
        poll: {
          ...modalState.poll,
          choices: updatedChoices
        }
      });
    }
  };

  const handleChoiceChange = (index, newValue) => {
    if (modalState.poll) {
      const updatedChoices = modalState.poll.choices.map((choice, i) =>
        i === index ? newValue : choice
      );

      setModalState({
        ...modalState,
        poll: {
          ...modalState.poll,
          choices: updatedChoices
        }
      });
    }
  };

  const handleAction = async (action) => {
    switch (action) {
      case "open_images_panel":
        setImagesPanelOpen(true);
        break;
      case "close_images_panel":
        setImagesPanelOpen(false);
        if (images.length > 0) {
          await clearNewsPostFileUploadSession(newsPostFileUploadSession);
        }
        break;
      case "add_poll":
        await handleAddPoll();
        break;
      default:
        console?.warn("** unhandled action", { action });
        break;
    }
  };

  const updateSessionState = (session) => {
    if (typeof session !== "undefined") {
      setNewsPostFileUploadSession(session);
    }
    setImages([]);
  }

  const [clearNewsPostFileUploadSession] = useClearNewsPostFileUploadSession({
    onCleared: (session) => {
      updateSessionState();
    },
    onError: () => {
      console.log("** failed to clear session");
      updateSessionState();
    },
  });

  const [createNewsPostFileUploadSession] = useCreateNewsPostFileUploadSession({
    onCreated: (session) => {
      updateSessionState(session);
    },
    onError: () => {
      console.log("** failed to create session");
      updateSessionState(null);
    },
  });

  useEffect(() => {
    if (modalState.open) {
      if (!newsPostFileUploadSession) {
        createNewsPostFileUploadSession();
      }
      if (modalState.imageAttachments) {
        setImagesPanelOpen(modalState.imageAttachments.fileIds.length > 0);
      }
    }
    else if (newsPostFileUploadSession && !modalState.imageAttachments) {
      createNewsPostFileUploadSession();
      setImagesPanelOpen(false);
    }
  }, [modalState.open, modalState.imageAttachments]);

  useEffect(() => {
    const session = newsPostFileUploadSession;
    const fileIds = images.map(n => n.fileId);
    setModalState((prev) => ({
      ...prev,
      imageAttachments: {
        session,
        fileIds,
      },
    }));
  }, [images]);

  const handleSave = () => {
    onSave?.();
  };

  return (
    <SimpleModalCompact
      open={modalState.open}
      onClose={() => setModalState({ ...modalState, open: false })}
      title={
        <Box display="flex" alignItems="center">
          <Avatar url={user.avatar} author={`${user.firstname} ${user.lastname}`} sx={{ marginRight: 2 }} />
          <Box fontWeight="bold">{user.firstname} {user.lastname}</Box>
        </Box>
      }
    >
      <Stack spacing={2} sx={{ padding: 1, minWidth: "366px", maxWidth: "500px", margin: "auto" }}>

        <Box>

          <MentionEditor
            ref={(editor) => {
              if (editor) {
                editorRef.current = editor;
              }
            }}
            onBlur={(newContent) => setModalState({ ...modalState, content: newContent })}
          />

          <Button
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 0,
              minWidth: 'auto',
              backgroundColor: 'transparent',
              cursor: 'pointer'
            }}
            onClick={(e) => setEmoticonAnchorEl(emoticonAnchorEl ? null : e.currentTarget)}
          >
            <InsertEmoticonIcon />
          </Button>

          <Popper
            open={Boolean(emoticonAnchorEl)}
            anchorEl={emoticonAnchorEl}
            placement="bottom-start"
            style={{ position: 'absolute', zIndex: 1300 }}
          >
            <EmojiPicker
              ref={(element) => setRef(element)}
              onEmojiClick={(emoji) => handleEmojiSelect(emoji)}
              searchDisabled={true}
              previewConfig={{ showPreview: false }}
              emojiStyle="native"
              emojiVersion="4.0" height={300} width={300}
              style={{
                '--epr-emoji-size': '20px',  // This should apply the size
                '--epr-emoji-gap': '4px',    // This controls the gap between emojis
              }}
            />
          </Popper>

        </Box>

        {imagesPanelOpen && (
          <ImagesPanel
            disabled={isSaving}
            images={images}
            newsPostFileUploadSession={newsPostFileUploadSession}
            onClose={() => handleAction("close_images_panel")}
            setImages={setImages}
            snackbar={snackbar}
          />
        )}

        {modalState.poll && (
          <Box sx={{ display: "flex", flexDirection: "column", marginTop: 2, border: 1, borderRadius: 2, padding: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PollIcon sx={{ color: "orange", marginRight: 1 }} />
                <Typography variant="subtitle1">
                  <FormattedMessage id="newsFlowWidget.polls.heading" />
                </Typography>
              </Box>

              <Box>
                <IconButton
                  onClick={handleRemovePoll}
                  color="primary"
                  size="small"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>

            {modalState.poll.choices.map((choice, index) => (
              <Box key={index} display="flex" alignItems="center" sx={{ marginTop: 2 }}>
                <TextField
                  defaultValue={choice}
                  onChange={(e) => handleChoiceChange(index, e.target.value)}
                  variant="outlined"
                  placeholder={intl.formatMessage({ id: "newsFlowWidget.polls.option" }) + " " + (index + 1)}
                  size="small"
                  sx={{ flexGrow: 1, marginRight: 1 }}
                />
                <IconButton onClick={() => handleRemoveChoice(index)} color="primary" size="small">
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}

            <Box>
              <Button onClick={handleAddChoice}>
                <FormattedMessage id="newsFlowWidget.polls.buttons.addRow" />
              </Button>
              <IconButton onClick={toggleSettings} color="primary" size="small">
                <SettingsIcon />
              </IconButton>

              {settingsOpen && (
                <Box sx={{ padding: 2, border: '1px solid #ddd', borderRadius: 1, marginBottom: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={modalState.poll?.votingOptions === "SELECT_MULTIPLE"}
                        onChange={handleVotingOptionsToggle}
                      />
                    }
                    label={<FormattedMessage id="newsFlowWidget.polls.buttons.allowMultipleChoices" />}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={modalState.poll?.allowNewChoices || false}
                        onChange={handleAllowNewChoicesToggle}
                      />
                    }
                    label={<FormattedMessage id="newsFlowWidget.polls.buttons.allowNewChoices" />}
                  />
                </Box>
              )}
            </Box>
          </Box>
        )}

        <NewsFlowPostToolbar disabled={isSaving} onAction={handleAction} />

        <Box>
          <Button onClick={handleSave} variant="contained" color="primary" sx={{ width: "100%" }} disabled={isSaving}>
            <FormattedMessage id="newsFlowWidget.polls.buttons.send" />
          </Button>
        </Box>
      </Stack>
    </SimpleModalCompact>
  );
}

export default NewsFlowPostModal;
