import { gql } from '@apollo/client';
import { SiteChannelMessageFragment } from "../fragments/MessagingFragments";

const getAllSiteChannels = gql`
  query getAllSiteChannels {
    siteChannels {
      allChannels {
        latestMessage {
          ...SiteChannelMessageFragment
        }
        latestMessageId
        latestMessageTime
        latestReadMessageId
        latestReadMessageTime
        name
        private
        siteChannelId
        siteId
        unreadMessageCount
        workspaceName
      }
    }
  }
  ${SiteChannelMessageFragment}
`;

export default getAllSiteChannels;
