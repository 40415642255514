import { gql } from '@apollo/client';

const SiteNewsComment = gql`
fragment SiteNewsCommentProperties on NewsComment {
	commentDate
	commentId
	avatarUrl
	text
	likes {
		userId
		name
		emoticon
		isCurrentUser
	}
	username
	__typename
}  
`;

export default SiteNewsComment;
