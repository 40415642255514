import {
  CallMade as AnnotationArrowIcon,
  CropDin as AnnotationRectangleIcon,
  FilterDrama as AnnotationCloudIcon,
  PanoramaFishEye as AnnotationEllipseIcon,
} from "@mui/icons-material";
import { ANNOTATION_TYPE } from "../../utils/types";

const annotationTypeMenuIcons = {
  [ANNOTATION_TYPE.ARROW]: <AnnotationArrowIcon />,
  [ANNOTATION_TYPE.CLOUD]: <AnnotationCloudIcon />,
  [ANNOTATION_TYPE.ELLIPSE]: <AnnotationEllipseIcon />,
  [ANNOTATION_TYPE.RECTANGLE]: <AnnotationRectangleIcon />,
};

const AnnotationTypeMenuIcon = ({ type }) => (
  annotationTypeMenuIcons[type] || annotationTypeMenuIcons[ANNOTATION_TYPE.ARROW]
);

export default AnnotationTypeMenuIcon;
