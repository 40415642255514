import { gql } from '@apollo/client';

/**
 *  @deprecated Will be replaced by @see {@link GET_ALL_SITE_CHANNELS}.
 */
const getSiteChannels = gql`
  query getSiteChannels($siteId: Int!) {
    siteChannels {
      channels(siteId: $siteId) {
        name
        private
        siteChannelId
        siteId
        workspaceName
        unreadMessageCount
        latestMessageId
        latestReadMessageId
        latestReadMessageTime
      }
    }
  }
`;

export default getSiteChannels;
