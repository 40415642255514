/**
 * Annotation specific tools (subtypes of TOOL_TYPE.ANNOTATION).
 */
const ANNOTATION_TYPE = Object.freeze({
  ARROW: "annotation-arrow",
  CLOUD: "annotation-cloud",
  ELLIPSE: "annotation-ellipse",
  RECTANGLE: "annotation-rectangle",
});

/**
 * Flow designer specific tools (subtypes of TOOL_TYPE.STEP).
 */
const STEP_TYPE = Object.freeze({
  CONTAINER: "step-container",
  FOLDER: "step-folder",
  FOLDER_UPLOAD: "step-folder-upload",
  FOLDER_ZIP: "step-folder-zip",
  PREFLIGHT: "step-preflight",
  TEMPLATES: "step-templates",
  XML_LOADING: "step-xml-loading",
  PRINT: "stem-print",
});

/**
 * Tool types for annotations and flow designer.
 */
const TOOL_TYPE = Object.freeze({
  ANNOTATION: "tool-annotation",
  PAN: "tool-pan",
  SELECT: "tool-select",
  STEP: "tool-step",
});

/**
 * File types.
 */
const FILE_TYPE = Object.freeze({
  /**
   * Image file.
   */
  IMAGE: "image",
  /**
   * PDF file (Portable Document Format).
   */
  PDF: "pdf",
});

/**
 * Page view types.
 */
const PAGE_MODE_TYPE = Object.freeze({
  /**
   * Double sided page view.
   */
  DOUBLE: "double",
  /**
   * Single sided page view.
   */
  SINGLE: "single",
});

export {
  ANNOTATION_TYPE,
  STEP_TYPE,
  TOOL_TYPE,
};

export {
  FILE_TYPE,
  PAGE_MODE_TYPE,
};