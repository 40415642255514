import { useMutation } from "@apollo/client";
import { CREATE_NEWS_POST_FILE_UPLOAD_SESSION } from "../../graphql/mutations/NewsContext";

const extractResult = (data) => data?.newsContext?.createNewsPostFileUploadSession;

const useCreateNewsPostFileUploadSession = ({ onCreated, onError } = {}) => {

  const [createNewsPostFileUploadSessionMutation, { data, error, loading }] = useMutation(
    CREATE_NEWS_POST_FILE_UPLOAD_SESSION,
    {
      errorPolicy: "none",
      onCompleted: (data) => {
        const result = extractResult(data);
        console?.info("** useCreateNewsPostFileUploadSession - completed", { result });
        const { success } = result;
        if (success) {
          const { session } = result;
          onCreated?.(session);
        }
        else {
          const { errorResult } = result;
          console?.error("** useCreateNewsPostFileUploadSession - error", { errorResult });
          onError?.();
        }
      },
      onError: (error) => {
        console?.error("** useCreateNewsPostFileUploadSession - error", { error });
        onError?.(error);
      },
    }
  );

  const executeCreateNewsPostFileUploadSessionMutation = async () => (
    await createNewsPostFileUploadSessionMutation()
  );

  return [
    executeCreateNewsPostFileUploadSessionMutation,
    {
      data: extractResult(data),
      error,
      loading,
    }
  ];

};

export default useCreateNewsPostFileUploadSession;
