import { useContext } from "react";
import { paginationContext } from "../../context/FullscreenAnnotationModal/PaginationContext";

export const usePaginationContext = () => {
  const context = useContext(paginationContext);

  if (context === undefined) {
    throw new Error(
      "Hook usePaginationContext is used outside of its Provider."
    );
  }

  return context;
};
