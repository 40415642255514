import React from "react";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Box from "@mui/material/Box";
import { Icon } from "@mui/material";
import DropzoneComponent from "react-dropzone-component";
import "../../../node_modules/react-dropzone-component/styles/filepicker.css";
import "../../../node_modules/dropzone/dist/min/dropzone.min.css";

import { DROPZONE_PARALLEL_UPLOADS } from "../../constant";

const useStyles = makeStyles(theme => ({
  dzWrap: {
    "& .dropzone": {
      backgroundColor: theme.palette.component.fileUploadBackground,
      padding: "0 20px",
      textAlign: "center",
      border: "2px dashed #ddd",
      borderRadius: 4,
      marginTop: 10,
    },
    "& .dz-message": {
      color: theme.palette.component.productBoxColor,
      fontFamily: '"Montserrat", "Helvetica Neue", "Arial", sans-serif',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    "& .dz-image": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  icon: {
    verticalAlign: "middle",
    marginRight: 20,
    color: theme.palette.common.white,
    backgroundColor: "#cecece",
    padding: 10,
    borderRadius: "50%",
    width: "auto",
    height: "auto",
  },
  spinner: {
    marginTop: 5,
    color: theme.palette.primary.main,
  },
}));
const DropzoneSection = ({ setNewUploadModal, handleOnSelectUploadFile, dropzoneRef }) => {

  const classes = useStyles();

  const handleUploadFiles = files => {
    handleOnSelectUploadFile(files);
    setNewUploadModal(true);
  };
    let myDropzone;

  const initCallback = dropzone => {
    myDropzone = dropzone;
  };
  const componentConfig = {
    postUrl: "-",
  };

  const djsConfig = {
    maxFilesize: 4000,
    uploadMultiple: true,
    parallelUploads: DROPZONE_PARALLEL_UPLOADS,
    params: {
      name: "testfile",
    },
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className="dz-preview dz-file-preview" />
    ),
  };

  const eventHandlers = {
    init: dropzone => {
      dropzoneRef.current = dropzone; 
      initCallback(dropzone);
    },
    processingmultiple: files => {
      handleUploadFiles(files);
    },
  };

  return (
    <Box className={classes.dzWrap}>
      <DropzoneComponent
        config={componentConfig}
        djsConfig={djsConfig}
        eventHandlers={eventHandlers}
      >
        <div className="dz-message">
          <Icon className={clsx(["fa fa-upload", classes.icon])} />
          Dra och släpp dokument eller klicka här för att ladda upp..
        </div>
      </DropzoneComponent>
    </Box>
  );
};

DropzoneSection.defaultProps = {
  uploadFiles: () => {},
};

DropzoneSection.propTypes = {
  handleOnSelectUploadFile: PropTypes.func.isRequired,
  setNewUploadModal: PropTypes.func.isRequired,
};

export default DropzoneSection;
