import { gql } from '@apollo/client';

const UPDATE_COMMENT_LIKE = gql`
  mutation UpdateCommentLike($commentId: Int!, $emoticon: Int!) {
    newsContext {
      updateCommentLike(commentId: $commentId, emoticon: $emoticon) {
			emoticon
			isCurrentUser
			name
			userId
      }
    }
  }
`;

export default UPDATE_COMMENT_LIKE;
