import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  LinearProgress,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

const sxPreflightCheck = { display: "inline-block", verticalAlign: "middle", margin: "2px 2px 2px 0px" };

const PreflightCheck = ({ status }) => {
  if (status === 'loading') return <CircularProgress size={20} sx={sxPreflightCheck} />;
  if (status === 'approved') return <CheckCircleOutlineOutlinedIcon style={{ color: '#0b906d' }} sx={sxPreflightCheck} />;
  if (status === 'warning') return <ErrorOutlineOutlinedIcon style={{ color: '#e6ab34' }} sx={sxPreflightCheck} />;
  return <ReportProblemOutlinedIcon style={{ color: '#df3d1a' }} sx={sxPreflightCheck} />;
};

const FileUploadComponent = ({ onUploadSuccess }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [preflightStatuses, setPreflightStatuses] = useState({
    format: 'loading',
    readability: 'loading',
    layout: 'loading',
    resolution: 'loading',
    fonts: 'loading',
    pages: 'loading',
  });

  const simulatePreflightCheck = useCallback(() => {
    const checks = [
      { key: 'format', delay: 1000, status: 'approved' },
      { key: 'readability', delay: 1500, status: 'approved' },
      { key: 'layout', delay: 2000, status: 'warning' },
      { key: 'resolution', delay: 2500, status: 'approved' },
      { key: 'fonts', delay: 3000, status: 'approved' },
      { key: 'pages', delay: 3500, status: 'approved' },
    ];

    checks.forEach(check => {
      setTimeout(() => {
        setPreflightStatuses(prev => ({
          ...prev,
          [check.key]: check.status,
        }));

        if (check.key === 'pages') {
          setTimeout(() => {
            if (selectedFile) {
              const metadata = {
                name: selectedFile.name,
                size: selectedFile.size,
                type: selectedFile.type,
                lastModified: selectedFile.lastModified,
                file: selectedFile // Include the actual file object
              };
              onUploadSuccess(metadata);
            } else {
              console.error('File not selected');
            }
          }, 1000);
        }
      }, check.delay);
    });
  }, [selectedFile, onUploadSuccess]);

  const handleUpload = useCallback(() => {
    if (!selectedFile) return;

    setIsUploading(true);
    setUploadProgress(0);

    const interval = setInterval(() => {
      setUploadProgress(oldProgress => {
        if (oldProgress === 100) {
          clearInterval(interval);
          setIsUploading(false);
          simulatePreflightCheck();
          return 100;
        }
        return Math.min(oldProgress + 10, 100);
      });
    }, 300);
  }, [selectedFile, simulatePreflightCheck]);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'application/pdf': ['.pdf'] },
    onDrop
  });

  useEffect(() => {
    if (selectedFile) {
      handleUpload();
    }
  }, [selectedFile]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box {...getRootProps()} sx={{ background: "#f7f7f7", border: '2px dashed #ccc', borderRadius: '8px', padding: 2, fontSize: '16px', textAlign: 'center', cursor: 'pointer' }}>
        <input {...getInputProps()} />
        <Typography sx={{ fontSize: '18px', marginBottom: 2 }}>Drop files here</Typography>
        <Typography sx={{ fontSize: '18px', marginBottom: 2 }}>or</Typography>
        <Button variant="contained" component="label" sx={{ background: '#8a00e5' }}>
          Browse files
          {/*<input*/}
          {/*  type="file"*/}
          {/*  hidden*/}
          {/*  accept="application/pdf"*/}
          {/*  onChange={e => {*/}
          {/*    if (e.target.files[0]) {*/}
          {/*      setSelectedFile(e.target.files[0]);*/}
          {/*    }*/}
          {/*  }}*/}
          {/*/>*/}
        </Button>

        {selectedFile && (
          <Box sx={{ marginTop: 1 }}>
            <Typography variant="body1">Selected File: {selectedFile.name}</Typography>
            <Typography variant="body1" sx={{ fontSize: "12px" }}>Size: {(selectedFile.size / 1024 / 1024).toFixed(2)} MB</Typography>
            <Typography variant="body1" sx={{ fontSize: "12px" }}>Type: {selectedFile.type}</Typography>
            {/*<Button variant="contained" onClick={handleUpload} sx={{ marginTop: 2 }}>*/}
            {/*  Upload File*/}
            {/*</Button>*/}
          </Box>
        )}

        {isUploading && (
          <Box sx={{ marginTop: 1, width: '100%' }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
            <Typography variant="body2" sx={{ marginTop: 1, marginBottom: 0 }}>Uploading... {uploadProgress}%</Typography>
          </Box>
        )}
      </Box>
      {uploadProgress === 100 && (
        <Typography variant="h6" sx={{ marginBottom: 0, marginTop: 1, color: "white" }}>Checking files</Typography>
      )}
      {uploadProgress === 100 && (
        <Box sx={{ marginTop: 0, padding: 2, background: '#f7f7f7', borderRadius: '6px', border: "1px solid #ccc" }}>
          <Box sx={{ marginTop: 0 }}>
            {Object.keys(preflightStatuses).map((key) => (
              <Typography key={key} variant="body1" sx={{fontSize:"14px"}}>
                <PreflightCheck status={preflightStatuses[key]} /> <Typography variant="span" sx={{ verticalAlign: "middle" }}>{key.charAt(0).toUpperCase() + key.slice(1)} check</Typography>
              </Typography>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FileUploadComponent;