import { useEffect, useState } from "react";
import { MenuItem, Select, TextField, Typography } from "@mui/material";
import ColorPicker from "../../../../../components/common/ColorPicker";
import { STEP_TYPE } from "../../../utils/types";

const SettingsTab = ({ classes, onChange, step }) => {
  const [internalValue, setInternalValue] = useState(step);

  const handlePropertyChange = (key, value) => onChange?.({ key, value });

  const updateInternalValue = (key, value) => {
    setInternalValue(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    setInternalValue({
      name: (step?.name || ""),
      colour: (step?.colour || ""),
    });
  }, [step]);

  return internalValue && (
    <>
      {step && (
        <>
          <Typography className={classes.label}>Namn</Typography>
          <TextField
            fullWidth
            onBlur={(e) => {
              handlePropertyChange("name", e.target.value);
            }}
            onChange={(e) => {
              updateInternalValue("name", e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handlePropertyChange("name", e.target.value);
              }
            }}
            size="small"
            value={internalValue.name}
          />

          <Typography className={classes.label}>Bakgrundsfärg</Typography>
          <ColorPicker
            onChange={(e) => {
              if (e?.hex) {
                handlePropertyChange("colour", e.hex);
              }
            }}
            size={{
              width: "2.654em",
              height: "2.654em",
            }}
            value={internalValue.colour}
          />
        </>
      )}

      {(step?.type === STEP_TYPE.PREFLIGHT) && (
        <>
          <Typography className={classes.label}>Preflightprofil</Typography>
          <Select
            fullWidth
            onChange={
              (e) => {
                const { value } = e?.target;
                handlePropertyChange("preflightProfileId", value);
              }
            }
            size="small"
            value={step.preflightProfileId}
          >
            <MenuItem value="preflight-profile-001">Färg (color)</MenuItem>
            <MenuItem value="preflight-profile-002">Gråskala (gray scale)</MenuItem>
          </Select>
        </>
      )}

      {(step?.type === STEP_TYPE.TEMPLATES) && (
        <>
          <Typography className={classes.label}>Mall</Typography>
          <Select
            fullWidth
            onChange={
              (e) => {
                const { value } = e?.target;
                handlePropertyChange("templateId", value);
              }
            }
            size="small"
            value={step.templateId}
          >
            <MenuItem value="template-001">Pärminnehållsförteckning (för alla pärmar)</MenuItem>
            <MenuItem value="template-002">Blockinnehållsförteckning (för en pärm)</MenuItem>
            <MenuItem value="template-003">Mellanlägg</MenuItem>
            <MenuItem value="template-004">Försättsblad</MenuItem>
          </Select>
        </>
      )}
    </>
  );
};

export default SettingsTab;
