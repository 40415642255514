import clsx from "clsx";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { contextMenu } from "react-contexify";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { DELETE_USER } from "../../../graphql/mutations/CompanyAdminContext";
import { GET_GROUP_MEMBERS } from "../../../graphql/queries";
import { getAdminSelectedCompanyId } from "../../../helpers/adminSelectors";
import getGroupDataStructure from "../../../helpers/getGroupTreeDataStructure";
import { useGetCompanyGroups } from "../../../hooks";

import ConfirmDeleteModal from "../../common/ConfirmDeleteModal";
import CustomSelect from "../../common/CustomSelect";
import Pagination from "../../common/Pagination";
import CustomTextField from "../../common/TextField";

import AccountSettingsModal from "../Users/AccountSettingsModal";

import AllUsersGroup from "./AllUsersGroup";
import ContextMenu from "./ContextMenu";
import EditAddressModal from "./EditAdressModal";
import { GROUPS } from "./helpers";
import MemberBox from "./MemberBox";
import UnassignedGroup from "./UnassignedGroup";
import UserGroupTree from "./UserGroupTree";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  select: {
    width: 160,
    marginLeft: 7,
    marginRight: 7,
  },
  textField: {
    width: 300,
    marginTop: 0,
    marginLeft: 7,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
  },
  headSubTitle: {
    fontSize: 11,
  },
  addLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginLeft: "auto",
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  body: {
    padding: 20,
    "& .MuiGrid-container": {
      marginTop: 0,
      marginBottom: 0,
    },
    "& .MuiGrid-container > .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  foot: {
    padding: "15px 20px",
    borderTop: "2px solid #F5F5F5",
  },
  btnSave: {
    padding: "10px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  borderLeft: {
    borderLeft: "1px solid #efefef",
  },
  pagination: {
    marginLeft: "auto",
  },
  divider: {
    borderTop: "1px solid",
    borderTopColor: theme.palette.component.detailedSearchBorder,
    marginTop: 5,
    paddingTop: 5,
    textAlign: "right",
  },
  newGroupBtn: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
    textTransform: "unset",
    borderRadius: "15px",
    padding: 4,
    float: "right",
  },
  newGroupBtnCont: {
    fontSize: 14,
    color: theme.palette.primary.contrastText,
  },
  membersContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const UserGroups = () => {
  const classes = useStyles();
  const intl = useIntl();
  const alert = useAlert();

  const { groups } = useSelector(state => state.api.companyAdmin.companyGroups);
  const companyId = useSelector(getAdminSelectedCompanyId);
  const roles = useSelector(state => state.api.companyAdmin.companyRoles.roles);
  const lang = useSelector(state => state.ui.locale.culture);

  useEffect(() => {
    const tempOption = {
      label: intl.formatMessage({ id: "user.allRoles" }),
      value: 0,
    };
    const tempRoles = [...roles];

    tempRoles.unshift(tempOption);
    setRoleOptions(tempRoles);
  }, [roles, lang]);

  const { execute: getCompanyGroups, loading } = useGetCompanyGroups();

  const [groupTree, setGroupTree] = useState({});
  const [openedNodes, setOpenedNodes] = useState([]);
  const [selectedNode, setSelectedNode] = useState({});
  
  const [isNewRootGroupMode, setIsNewRootGroupMode] = useState(false);

  const [selectedGroupId, setSelectedGroupId] = useState(0);
  const [members, setMembers] = useState([]);

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);

  const [refreshSearch, setRefreshSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [companyRoleId, setCompanyRoleId] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [editAddressModal, setEditAddressModal] = useState(false);

  const [nameModal, setNameModal] = useState(false);
  const [openRenameModal, setOpenRenameModal] = useState(false);

  const contextMenuId = "admin_groups_menu";

  const handleOpenDeleteModal = userId => {
    setSelectedUserId(userId);
    setDeleteModal(true);
  };

  const [deleteUser, { loading: deletingUser }] = useMutation(DELETE_USER);

  const handleDeleteUser = async () => {
    if (members.findIndex(member => member.userId === selectedUserId) < 0) {
      console.warn("Not a member:", selectedUserId);
      return;
    }

    try {
      const res = await deleteUser({ variables: { userId: selectedUserId } });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.users &&
        res.data.companyAdminContext.users.deleteUser
      ) {
        alert.success(
          <FormattedMessage id="userAccount.success.userDeleted" />
        );
        removeMember(selectedUserId);
      } else {
        handleDeleteError();
      }
      setSelectedUserId(null);
      setDeleteModal(false);
    } catch (err) {
      console.error("error >", err);
      handleDeleteError();
    }
  };

  const handleDeleteError = () => {
    const alertError = id => {
      const specific = intl.formatMessage({ id });
      const couldNotDelete = intl.formatMessage({
        id: "userAccount.errors.couldNotDelete",
      });
      alert.error(`${specific}\n${couldNotDelete}`);
    };

    alertError("userAccount.errors.genericError");
  };

  const handleFetchCompanyGroups = () => {
    getCompanyGroups({ variables: { companyId } });
  };

  const [
    getGroupMembers,
    { data: groupMembersData, loading: groupMembersLoading },
  ] = useLazyQuery(GET_GROUP_MEMBERS);

  const fetchGroupMembers = params => {
    const { page = 0, perPage = 25 } = { ...params };

    setPage(page);
    setPerPage(perPage);

    getGroupMembers({
      variables: {
        companyId: companyId,
        groupId: selectedGroupId,
        companyRoleId,
        searchTerm,
        offset: page * perPage,
        pagesize: perPage,
      },
    });
  };

  useEffect(() => {
    if (selectedGroupId !== null && companyId !== null) {
      fetchGroupMembers({ page: 0, perPage });
      setSelectedMembers([]);
    }
  }, [companyId, companyRoleId, searchTerm, selectedGroupId, refreshSearch]);

  useEffect(() => {
    if (companyId) {
      handleFetchCompanyGroups();
    }
  }, [companyId]);

  useEffect(() => {
    if (Array.isArray(groups)) {
      const newGroupTree = getGroupDataStructure({ groups });
      setGroupTree(newGroupTree);
    }
  }, [groups]);

  useEffect(() => {
    if (
      groupMembersData &&
      groupMembersData.companyAdminContext &&
      groupMembersData.companyAdminContext.companyGroupSettings &&
      groupMembersData.companyAdminContext.companyGroupSettings
        .getGroupMembers &&
      Array.isArray(
        groupMembersData.companyAdminContext.companyGroupSettings
          .getGroupMembers.companyUsersListItems
      )
    ) {
      var {
        companyUsersListItems,
        errorResult,
        success,
        totalUserCount,
      } = groupMembersData.companyAdminContext.companyGroupSettings.getGroupMembers;

      setMembers(companyUsersListItems);
      setTotalCount(totalUserCount);
    }
  }, [groupMembersData]);

  const handleOpenNode = id => {
    const nodeIndex = openedNodes.indexOf(id);
    if (nodeIndex === -1) {
      setOpenedNodes([...openedNodes, id]);
    } else {
      const tempOpenedNodes = openedNodes.filter(x => x !== id);
      setOpenedNodes(tempOpenedNodes);
    }
    setSelectedMembers([]);
  };

  const deleteSelectedGroup = () => {
    let groupId = selectedGroupId;
    let node = groupTree[`g_${groupId}`];
    while (!!node) {
      if (node.groupId === selectedNode.groupId) {
        groupId = selectedNode.parentGroupId;
        break;
      }
      groupId = node.parentGroupId;
      node = groupTree[`g_${groupId}`];
    }

    removeGroupNode(selectedNode);

    setSelectedNode({});
    setSelectedGroupId(groupId ?? GROUPS.UNASSIGNED_USERS);
    setSelectedMembers([]);
  };

  const removeGroupNode = node => {
    const { parentGroupId, groupId } = node;
    let newGroupTree = { ...groupTree };
    let newChildGroupIds = [];
    let newChildGroups = [];
    if (parentGroupId) {
      if (
        Array.isArray(newGroupTree[`g_${parentGroupId}`].childGroupIds) &&
        newGroupTree[`g_${parentGroupId}`].childGroupIds.length > 0
      ) {
        newChildGroupIds = newGroupTree[
          `g_${parentGroupId}`
        ].childGroupIds.filter(x => x !== groupId);

        newChildGroups = newGroupTree[`g_${parentGroupId}`].childGroups.filter(
          x => x.groupId !== groupId
        );
      }

      const newGroupData = {
        ...newGroupTree[`g_${parentGroupId}`],
        childGroupIds: newChildGroupIds,
        childGroups: newChildGroups,
      };

      newGroupTree = {
        ...newGroupTree,
        [`g_${parentGroupId}`]: newGroupData,
      };
    } else {
      delete newGroupTree[`g_${groupId}`];
    }

    setGroupTree(newGroupTree);
  };

  const createNewGroup = newGroup => {
    const { parentGroupId: groupId } = newGroup || {};
    const { groupId: newGroupId } = newGroup;

    let newGroupTree = { ...groupTree };

    let newChildGroups = [newGroup];
    let newChildGroupIds = [newGroupId];
    if (groupId) {
      if (
        Array.isArray(newGroupTree[`g_${groupId}`].childGroups) &&
        newGroupTree[`g_${groupId}`].childGroups.length > 0
      ) {
        newChildGroups = [
          ...newChildGroups,
          ...newGroupTree[`g_${groupId}`].childGroups,
        ];

        newChildGroupIds = [
          ...newChildGroupIds,
          ...newGroupTree[`g_${groupId}`].childGroupIds,
        ];
      }

      const newGroupData = {
        ...newGroupTree[`g_${groupId}`],
        childGroupIds: newChildGroupIds,
        childGroups: newChildGroups,
      };

      newGroupTree = {
        ...newGroupTree,
        [`g_${newGroupId}`]: newGroup,
        [`g_${groupId}`]: newGroupData,
      };
    } else {
      newGroupTree = {
        [`g_${newGroupId}`]: { ...newGroup, isRoot: true },
        ...groupTree,
      };
    }

    setGroupTree(newGroupTree);
  };

  const renameUserGroup = params => {
    const { groupId, newName } = { ...params };

    const updatedNode = {
      ...groupTree[`g_${groupId}`],
      name: newName,
    };

    const newGroupTree = {
      ...groupTree,
      [`g_${groupId}`]: updatedNode,
    };

    setGroupTree(newGroupTree);
  };

  const handleContextMenu = (e, node) => {
    e.preventDefault();
    e.stopPropagation();
    contextMenu.show({
      id: contextMenuId,
      event: e,
    });
    if (selectedNode !== node) {
      setSelectedNode(node);
    }
  };

  const onGroupRowClick = group => {
    var groupId = typeof group === "number" ? group : group.groupId;
    setSelectedGroupId(groupId);
    setSelectedMembers([]);
  };

  const handleError = (name, errorResult) => {
    const { data: errorData, errorCode } = errorResult;

    console.error(errorData.errorMessage);
  };

  const handeSelectRole = e => {
    const { value } = e.target;
    setCompanyRoleId(value);
  };

  const debounceHandleSearchTerm = useCallback(
    debounce(value => {
      setSearchTerm(value);
    }, 1000),
    []
  );

  const refreshMembersList = () => {
    setRefreshSearch(!refreshSearch);
  };

  const handleSearch = value => {
    setSearchValue(value);
    debounceHandleSearchTerm(value);
  };

  const handleClose = () => {
    setEditAddressModal(false);
  };

  const handleChangePage = (event, newPage) => {
    fetchGroupMembers({ page: newPage, perPage });
  };

  const handleChangeRowsPerPage = event => {
    const newPerPage = parseInt(event.target.value, 10);
    fetchGroupMembers({ page: 0, perPage: newPerPage });
  };

  const handleAddNewUser = e => {
    handleOpenUserModal("add", null);
  };

  const handleAddNewUserGroup = e => {
    setIsNewRootGroupMode(true);
    setNameModal(true);
  };

  const handleUsersMoved = params => {
    const { userIds, targetGroupId } = params;
    setSelectedMembers([]);
    const tempMembers = members.filter(
      member => !userIds.includes(member.userId)
    );
    setMembers(tempMembers);
  };

  const removeMember = userId => {
    setSelectedMembers([]);
    const tempMembers = members.filter(member => member.userId !== userId);
    setMembers(tempMembers);
  };

  const handleOpenUserModal = (type, userId = null) => {
    setSelectedUserId(userId);
    setUserModal(true);
  };

  const handleCloseUserModal = result => {
    setUserModal(false);
    setSelectedUserId(null);

    if (!!result && (result.userCreated || result.userUpdated)) {
      if (result.userCreated) {
        if (
          selectedGroupId === GROUPS.ALL_USERS ||
          selectedGroupId === GROUPS.UNASSIGNED_USERS
        ) {
          refreshMembersList();
        }
      } else if (result.userUpdated) {
        refreshMembersList();
      }
    }
  };

  return (
    !!companyId && (
      <Box className={classes.root}>
        <Box className={classes.head}>
          <Typography className={classes.headTitle}>
            <FormattedMessage id="user.users" />
            <Typography className={classes.headSubTitle}>
              ( <FormattedMessage id="userGroups.dragUsersIntoGroups" /> )
            </Typography>
          </Typography>
          <CustomSelect
            customClass={classes.select}
            inputLabel={intl.formatMessage({ id: "common.select" })}
            value={companyRoleId}
            options={roleOptions}
            onChange={handeSelectRole}
          />
          <CustomTextField
            label={<FormattedMessage id="btn.search" />}
            customClass={classes.textField}
            value={searchValue}
            onChange={e => handleSearch(e.target.value)}
          />
          {members.length > 0 && (
            <Box className={classes.pagination}>
              <Pagination
                onChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
                page={page}
                perPage={perPage}
                totalCount={totalCount}
              />
            </Box>
          )}

          <Button className={classes.addLink} onClick={handleAddNewUser}>
            <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
            <FormattedMessage id="admin.addNewUser" />
          </Button>
        </Box>
        <Box className={classes.body}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              {loading && <CircularProgress size={18} />}

              {!loading && (
                <>
                  <AllUsersGroup
                    selectedGroupId={selectedGroupId}
                    onGroupRowClick={onGroupRowClick}
                  />

                  <UnassignedGroup
                    selectedGroupId={selectedGroupId}
                    handleError={handleError}
                    companyId={companyId}
                    onGroupRowClick={onGroupRowClick}
                    onUsersMoved={handleUsersMoved}
                  />

                  <div className={classes.divider}>
                    <IconButton
                      className={classes.newGroupBtn}
                      variant="outlined"
                      size="small"
                      onClick={handleAddNewUserGroup}
                      title={intl.formatMessage({
                        id: "userGroups.addNewUserGroup",
                      })}
                    >
                      <Icon
                        className={clsx(
                          ["fa fa-plus"],
                          classes.newGroupBtnCont
                        )}
                      />
                    </IconButton>
                  </div>

                  <UserGroupTree
                    groupTree={groupTree}
                    setGroupTree={setGroupTree}
                    handleOpenNode={handleOpenNode}
                    moveFolderEnabled
                    openedNodes={openedNodes}
                    setOpenedNodes={setOpenedNodes}
                    handleContextMenu={handleContextMenu}                                                            
                    selectedFolderIds={[selectedGroupId]}
                    onGroupRowClick={onGroupRowClick}
                    onUsersMoved={handleUsersMoved}                    
                    refetch={handleFetchCompanyGroups}
                  />
                </>
              )}

              <ContextMenu
                contextMenuId={contextMenuId}
                createNewGroup={createNewGroup}
                renameUserGroup={renameUserGroup}
                deleteSelectedGroup={deleteSelectedGroup}
                group={selectedNode}                
                handleError={handleError}
                companyId={companyId}
                setEditAddressModal={setEditAddressModal}
                nameModal={nameModal}
                setNameModal={setNameModal}
                openRenameModal={openRenameModal}
                setOpenRenameModal={setOpenRenameModal}
                setIsNewRootGroupMode={setIsNewRootGroupMode}
                isNewRootGroupMode={isNewRootGroupMode}
              />
            </Grid>
            <Grid item xs={12} md={9} className={classes.borderLeft}>
              <Grid container spacing={3} className={classes.membersContainer}>
                {groupMembersLoading && <CircularProgress size={18} />}
                {!groupMembersLoading && (
                  <>
                    {members.map(member => {
                      return (
                        <MemberBox
                          key={`user-${member.userId}`}
                          member={member}
                          handleOpenUserModal={handleOpenUserModal}
                          handleOpenDeleteModal={handleOpenDeleteModal}
                          setSelectedMembers={setSelectedMembers}
                          selectedMembers={selectedMembers}
                          setOpenedNodes={setOpenedNodes}
                          setSelectedGroupId={setSelectedGroupId}
                        />
                      );
                    })}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {editAddressModal && (
          <EditAddressModal
            open={editAddressModal}
            handleClose={handleClose}
            groupId={selectedNode.groupId}
          />
        )}

        {userModal && (
          <AccountSettingsModal
            open={userModal}
            handleClose={handleCloseUserModal}
            selectedUserId={selectedUserId}
            companyId={companyId}
          />
        )}

        {deleteModal && (
          <ConfirmDeleteModal
            open={deleteModal}
            loading={deletingUser}
            setOpen={setDeleteModal}
            onConfirmCloseModal={handleDeleteUser}
          />
        )}
      </Box>
    )
  );
};

export default UserGroups;
