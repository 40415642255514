import { useEffect, useState } from "react";
import { TextField, Typography } from "@mui/material";

const InstructionsTab = ({ classes, onChange, step }) => {
  const [internalValue, setInternalValue] = useState(step);

  const handlePropertyChange = (key, value) => onChange?.({ key, value });

  const updateInternalValue = (key, value) => {
    setInternalValue(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    setInternalValue({
      instructions: (step?.instructions || ""),
    });
  }, [step]);

  return internalValue && (
    <>
      {step && (
        <>
          <Typography className={classes.label}>Instruktioner</Typography>
          <TextField
            fullWidth
            maxRows={15}
            minRows={5}
            multiline
            onBlur={(e) => {
              handlePropertyChange("instructions", e.target.value);
            }}
            onChange={(e) => {
              updateInternalValue("instructions", e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handlePropertyChange("instructions", e.target.value);
              }
            }}
            size="small"
            value={internalValue.instructions}
          />
        </>
      )}
    </>
  );
};

export default InstructionsTab;
