import React from "react";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography,Icon } from "@mui/material";
import Avatar from "../../common/Avatar";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  newsContainer: {
    paddingTop: 15,
    background: props => props.contentBG || theme.palette.background.paper,
  },
  header: {
    padding: "20px",
    textAlign: "center",
  },
  headerTitle: {
    fontSize: 28,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  headerSubTitle: {
    fontSize: 22,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  mainImg: {
    width: "100%",
    borderRadius: 0,
    paddingTop: "0px",
    height: "254px",
    objectFit: "cover",
    objectPosition: " 20% 10%",
  },
  authorBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 15,
  },
  authorName: {
    fontSize: 14,
    fontWeight: 600,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  newsTitle: {
    fontSize: 22,
    fontWeight: 600,
    marginBottom: 10,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
    textAlign: "center",
  },
  newsDate: {
    fontSize: 14,
    textAlign: "center",
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  ingress: {
    fontSize: 16,
    lineHeight: "28px",
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
    paddingTop: 0,
    paddingRight: 15,
    paddingBottom: 15,
    paddingLeft: 15,
    marginTop: 10,
    textAlign: "center",
  },
  contents: {
    paddingTop: 15,
    marginTop: -4,
  },
  arrowIcon: {
    fontSize: 14,
    marginLeft: 3,
  },
  joinNowContainer: {
    paddingTop: 5,
    paddingBottom: 15,
  },
  joinNow: {
    fontSize: 14,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
}));

const HeaderOne = ({ news, customStyles, onClickReadMore }) => {
  const classes = useStyles(customStyles);

  const {
    author,
    avatarUrl,
    createdDate,
    image,
    itemType,
    thumbnail,
    leadText,
    title,
    newsPost,
  } = news || {};

  const handleOnClick = (newpost) => {
    if (onClickReadMore) {
      onClickReadMore(newpost);
    }
  }

  return (
    <Grid
      alignItems="center"
      className={classes.newsContainer}
      container
      justifyContent="center"
    >
      {thumbnail && (
        <Grid item xs={12}>
          <img src={thumbnail} alt={title} className={classes.mainImg} />
        </Grid>
      )}
      {news && (
        <Grid item xs={12} className={classes.contents}>
          <Box>
            <Typography className={classes.newsTitle}>{title}</Typography>
          </Box>
          <Box className={classes.authorBox}>
            <Box xs={12} md={2}>
              {author && <Avatar author={author} url={avatarUrl} />}
            </Box>
            <Box xs={12} md={10}>
              <Typography className={classes.authorName}> {author}</Typography>
              <Typography className={classes.newsDate}>
                {moment(createdDate)
                  .format("L")
                  .toString()}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.ingress}>
            <Typography dangerouslySetInnerHTML={{ __html: leadText }} />
          </Box>
          
          {newsPost && (
            <Typography className={classes.joinNow} onClick={n => handleOnClick(news)}>
              <FormattedMessage id="product.readMore" /> <Icon className={clsx(["fas fa-angle-double-right", classes.arrowIcon])} />
            </Typography>)}
          </Grid>
      )}
    </Grid>
  );
};

export default HeaderOne;
