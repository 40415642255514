import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const CREATE_NEWS_POST_FILE_UPLOAD_SESSION = gql`
  mutation CreateNewsPostFileUploadSession {
    newsContext {
      createNewsPostFileUploadSession {
        success
        session
        errorResult {
          ...ErrorResultFields
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default CREATE_NEWS_POST_FILE_UPLOAD_SESSION;
