import { gql } from '@apollo/client';

const ADD_COMMENT = gql`mutation addComment($versionId:Int!, $pageNo:Int!, $comment:String){
	resourceComments{
		addComment(resourceVersionId:$versionId, pageNo: $pageNo, comment: $comment){
			author
			avatarUrl
			comment
			page
			pageNo
			resourceVersionCommentId
			resourceVersionId
			statusId
			timestamp
			userId
			versionCommentNumber
		}
	}
}
`;

export default ADD_COMMENT;
