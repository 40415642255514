import { TOOL_TYPE } from "./types";

const getCursorStyle = (toolType) => {
  switch (toolType) {
    case TOOL_TYPE.ANNOTATION:
    case TOOL_TYPE.STEP:
      return 'crosshair';

    case TOOL_TYPE.PAN:
      return 'grab';

    case TOOL_TYPE.SELECT:
    default:
      return 'default';
  }
};

const getRelativePointerPosition = (node) => {
  const transform = node.getAbsoluteTransform().copy();
  transform.invert();
  const pos = node.getStage().getPointerPosition();
  return transform.point(pos);
};

export {
  getCursorStyle,
  getRelativePointerPosition,
};
