import { FormattedMessage } from "react-intl";
import { Icon, Typography } from "@mui/material";

const UploadIcon = () => (
  <Icon
    className="fa fa-upload"
    sx={{
      backgroundColor: "#cecece",
      borderRadius: "50%",
      color: "#fff",
      height: "auto",
      margin: "5px",
      padding: "10px",
      verticalAlign: "middle",
      width: "auto",
    }}
  />
);

const FileUploadMessage = () => (
  <>
    <UploadIcon />
    <Typography component="p" sx={{ fontSize: "larger", fontWeight: "600" }}>
      <FormattedMessage id="newsFlowWidget.images.uploadMessage.addImages" />
    </Typography>
    <Typography component="p" sx={{ fontSize: "smaller" }}>
      <FormattedMessage id="newsFlowWidget.images.uploadMessage.orDragAndDrop" />
    </Typography>
  </>
);

export default FileUploadMessage;
