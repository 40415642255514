import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import { Paper, Box, Typography, Button } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import Icon from "@mui/material/Icon";
import { useQuery } from "@apollo/client";
import { GET_MONITORING_ITEMS } from "../../graphql/queries";
import { CustomSelect } from "../../components/common";
import MonitoringModal from "../../components/ProductsMainView/MonitoringModal";
import MonitoringTable from "../../components/MyMonitoring/MonitoringTable";
import { getSiteId } from "../../helpers/selectors";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
  },
  paperHead: {
    padding: theme.spacing(2),
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 600,
    marginRight: 10,
  },
  btnAdd: {
    padding: "10px 15px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.common.white,
    fontSize: 12,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.light, 0.8),
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 10,
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
  },
  selectWrap: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    width: 300,
    "& .MuiIconButton-root": {
      padding: 5,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.common.white,
    },
  },
  paperBody: {
    padding: theme.spacing(4),
  },
}));

const MyMonitoring = () => {
  const classes = useStyles();
  const intl = useIntl();
  const lang = useSelector(state => state.ui.locale.culture);
  const siteId = useSelector(state => getSiteId(state));
  const workspaces = useSelector(state =>
    state.api.currentViewer &&
    state.api.currentViewer.viewer &&
    state.api.currentViewer.viewer.sites
      ? state.api.currentViewer.viewer.sites
      : []
  );

  const [workspaceOptions, setWorkspaceOptions] = useState([]);
  const [filter, setFilter] = useState(siteId ?? 0);
  const [open, setOpen] = useState(false);
  const [monitoringItems, setMonitoringItems] = useState([]);
  const { data: monitoringData, loading, refetch } = useQuery(
    GET_MONITORING_ITEMS,
    {
      variables: { siteId: filter },
    }
  );

  useEffect(() => {
    if (
      monitoringData &&
      monitoringData.monitoringContext &&
      monitoringData.monitoringContext.listing &&
      monitoringData.monitoringContext.listing.items
    ) {
      setMonitoringItems(monitoringData.monitoringContext.listing.items);
    }
  }, [monitoringData]);

  useEffect(() => {
    if (workspaces && Array.isArray(workspaces)) {
      const tempOptions = workspaces.map(x => {
        return { label: x.name, value: x.id };
      });

      tempOptions.unshift({
        label: intl.formatMessage({ id: "monitoring.allWorkspaces" }),
        value: 0,
      });

      setWorkspaceOptions(tempOptions);
    }
  }, [workspaces, lang]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    refetch();
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Box className={classes.paperHead}>
          <Typography variant="h1" component="h2" className={classes.title}>
            <FormattedMessage id="monitoring.pageTitle" />
          </Typography>
          <Button className={classes.btnAdd} onClick={handleOpen}>
            <FormattedMessage id="btn.createMonitoring" />
            <Icon className={clsx(["fa fa-plus-circle", classes.btnIcon])} />
          </Button>
          <Box className={classes.selectWrap}>
            <CustomSelect
              inputLabel={<FormattedMessage id="monitoring.filter" />}
              options={workspaceOptions}
              onChange={e => setFilter(e.target.value)}
              value={filter}
            />
          </Box>
        </Box>
        <Box className={classes.paperBody}>
          <MonitoringTable
            monitoringItems={monitoringItems}
            loading={loading}
            refetchMonitoring={refetch}
          />
        </Box>
      </Paper>
      {open && (
        <MonitoringModal
          handleClose={handleClose}
          initialSiteId={siteId}
          open={open}
        />
      )}
    </div>
  );
};

export default MyMonitoring;
