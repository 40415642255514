import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const INIT_NEWS_POST_FILE_UPLOAD = gql`
  mutation InitNewsPostFileUpload($session: ID!) {
    newsContext {
      initNewsPostFileUpload(session: $session) {
        success
        session
        uploadUrl
        errorResult {
          ...ErrorResultFields
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default INIT_NEWS_POST_FILE_UPLOAD;
