import { useLazyQuery } from "@apollo/client";
import { GET_SITE_CHANNEL } from "../../graphql/queries";

const extractChannelData = (data) => data?.siteChannels?.channel;

const useSiteChannelData = ({ onCompleted, onError } = {}) => {

  const [getSiteChannelQuery, { data, error, loading }] = useLazyQuery(
    GET_SITE_CHANNEL,
    {
      errorPolicy: "none",
      onCompleted: (data) => {
        const channel = extractChannelData(data);
        console?.info("** Completed query: SiteChannel", { channel });
        onCompleted?.(channel);
      },
      onError: (error) => {
        console?.error("** Query error: SiteChannel", { error });
        onError?.(error);
      },
    }
  );

  const executeGetSiteChannelQuery = async (channelId, last = 50) => (
    await getSiteChannelQuery({
      variables: {
        channelId,
        last,
      }
    })
  );

  return [
    executeGetSiteChannelQuery,
    {
      data: extractChannelData(data),
      error,
      loading,
    }
  ];

};

export default useSiteChannelData;
