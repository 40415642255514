import { STEP_TEMPLATES } from "./config";

const calcNewPosition = (step, centerPos) => {
  const { width, height } = step;
  const { x, y } = centerPos;
  return {
    x: x - width / 2,
    y: y - height / 2,
  };
}

const create = (type, pos) => {
  const stepTemplate = STEP_TEMPLATES[type]; // type, name, colour, width, height
  if (!stepTemplate) {
    return;
  }

  const id = createId();

  return updatePosition({ ...stepTemplate, id }, pos);
};

const createId = () => Date.now().toString();

const updatePosition = (step, centerPos) => ({
  ...step,
  ...calcNewPosition(step, centerPos),
});

const useSteps = () => ({
  create,
  updatePosition,
});

export default useSteps;
