import React from "react";
import clsx from "clsx";
import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogTitle, IconButton, Typography  } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
  modalHeader: {
    color: theme.palette.primary.main,
    display: "flex",
    minHeight: 26,
    padding: "0px 35px 0px 10px",
    marginTop: "-4px",
    [theme.breakpoints.down("sm")]: {
      minHeight: 24,
    },
    "& .MuiTypography-root": {
      alignItems: "center",
      display: "flex",
    },
  },
  modalTitle: {
    display: "inline-block",
    fontSize: 15,
    fontWeight: 600,
    lineHeight: 2.2,    
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "normal",
    },
  },
  buttonContainer: {
    alignItems: "center",
    display: "flex",
    height: "calc(10px * 2.2 + 0px)",
    margin: 0,
    position: "absolute",
    right: 5,
    [theme.breakpoints.down("sm")]: {
      height: "calc(10px * 2.2 + 0px)",
    },
  },
}));

const ModalCompactHead = ({ children, customClass, handleClose, title }) => {
  const classes = useStyles();
  const theme = useTheme();
  const closeButtonTheme = {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "normal",
    },
  };

  return (
    <>
      <DialogTitle
        classes={{ root: classes.modalHeader }}
        className={clsx(["draggable-dialog-title", customClass])}
      >
        <Typography className={classes.modalTitle} component="div">
          {title}
        </Typography>
        {children}
      </DialogTitle>
      <Box className={classes.buttonContainer}>
        {!!handleClose && (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={closeButtonTheme}
          >
            <CloseIcon sx={closeButtonTheme} />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default ModalCompactHead;
