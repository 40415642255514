import React, { useState } from "react";
import { Button, Popper, Box, Typography } from "@mui/material";
import Avatar from "../../common/Avatar";

const Mention = ({ mentionId, mentions, fontColor }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const mentionData = mentions;

  return (
    <>
      <Typography component="span"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          padding: 0,
          minWidth: "auto",
          textTransform: "none",
          color: fontColor || "blue",
          fontWeight:600
        }}
      >
        @{mentionData.name}
      </Typography>
      <Popper open={open} anchorEl={anchorEl} placement="top" disablePortal>
        <Box
          sx={{
            p: 1,
            border: "1px solid #ddd",
            borderRadius: 1,
            backgroundColor: "#fff",
            boxShadow: 3,            
            display: "flex",
            alignItems: "center", // Vertically center content
            gap: 1, // Add spacing between avatar and text
          }}
        >
          <Avatar author={mentionData.username || "H H"} url={mentionData.avatarUrl} />
          <Box sx={{ color: "#000"}}>
            <Typography variant="body2" sx={{ fontWeight: 500 }} component="div">
              {mentionData.name}
            </Typography>
            <Typography variant="caption" color="textSecondary" component="div">
              @{mentionData.username}
            </Typography>
          </Box>
        </Box>
      </Popper>
    </>
  );
};

export default Mention;