import React, { useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import MessageBox from "./MessageBox";
import MessageArea from "./MessageArea";
import { getCurrentUserId } from "../../../helpers/selectors";

const useStyles = makeStyles(theme => ({
  channelName: {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 5,
  },
  iconLink: {
    fontSize: 10,
    color: "#888",
    marginLeft: 10,
    padding: 0,
    minWidth: "unset",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
    marginRight: 5,
  },
  searchField: {
    visibility: "hidden",
    marginTop: 0,
    marginLeft: 15,
    borderRadius: 4,
    backgroundColor: "rgba(222, 222, 222, 0.45)",
    width: 260,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  infoLink: {
    color: theme.palette.primary.main,
    marginLeft: "auto",
    padding: 0,
    minWidth: "unset",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  iconInfo: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 16,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    flex: 1,
  },
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  head: {
    padding: "10px 20px",
    backgroundColor: theme.palette.common.white,
    borderBottom: "1px solid #E4E4E4",
    display: "flex",
    alignItems: "center",
  },
  foot: {
    padding: "0 20px 10px 20px",
  },
}));

const MessageSection = ({
  activeChannelId,
  activeConversationId,
  channel = {},
  channelLoading,
  conversation,
  conversationLoading,
  currentUserId,
  isMobile = false
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const messagesEndRef = useRef(null);

  let name = "",
    messages = [];

  if (activeChannelId && channel && channel.channelMessages) {
    name = "#" + channel.name;
    messages = channel.channelMessages.items || [];
  } else if (activeConversationId && conversation && conversation.messages) {
    name = conversation.members.map(n => "@" + n.name).join(", ");
    messages = conversation.messages.items || [];
  }

  const initialSuggestion = useMemo(() => {
    const lastMessages = messages.slice(-50); // Get last 50 messages
    const uniqueAuthors = new Map();

    lastMessages.forEach(({ author, authorUserId, authorUsername, avatarUrl }) => {
      if (!uniqueAuthors.has(authorUserId)) {
        uniqueAuthors.set(authorUserId, {
          name: author,
          avatar: avatarUrl,
          userId: authorUserId,
        });
      }
    });

    return Array.from(uniqueAuthors.values());
  }, [messages]);

  return (
    <Box className={classes.root}>
      {!isMobile && (<Box className={classes.head}>
        <Typography className={classes.channelName}>{name}</Typography>
      </Box>)}
      <MessageArea
        messages={messages}
        disabled={
          Boolean(activeChannelId) === Boolean(activeConversationId) ||
          conversationLoading ||
          channelLoading
        }
        currentUserId={currentUserId}
        messagesEndRef={messagesEndRef}
        initialSuggestion
      />
      <Box className={classes.foot}>
        <MessageBox
          activeChannelId={activeChannelId}
          activeConversationId={activeConversationId}
          disabled={conversationLoading}
          mentionSuggestions={initialSuggestion }
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    currentUserId: getCurrentUserId(state),
  };
};

MessageSection.defaultProps = {
  activeChannelId: null,
  activeConversationId: null,
  channel: {},
  channelLoading: false,
  conversation: null,
  conversationLoading: false,
};

MessageSection.propTypes = {
  activeChannelId: PropTypes.number,
  activeConversationId: PropTypes.number,
  channel: PropTypes.shape({
    name: PropTypes.string,
  }),
  channelLoading: PropTypes.bool,
  conversation: PropTypes.shape({}),
  conversationLoading: PropTypes.bool,
  currentUserId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(MessageSection);
