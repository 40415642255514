import { useHistory } from "react-router-dom";
import PageRoute from "../../../constant/pageRouteDictionary.json";
import { useMessagingContext } from "../../../hooks/Messaging";
import MessagingContacts from "../../Messaging/Channels/MessagingContacts";

const ContactsWidget = () => {

  const history = useHistory();

  const { addConversation } = useMessagingContext();

  const handleConversationAdded = ({ conversation }) => {
    if (addConversation(conversation)) {
      const { directMessageConversationId } = conversation;
      navigateToMessagingPage(directMessageConversationId);
    }
  };

  const navigateToMessagingPage = (conversationId) => {
    history.push({
      pathname: PageRoute["MESSAGING"],
      state: { conversationId }
    });
  };

  return (
    <MessagingContacts
      onConversationAdded={handleConversationAdded}
      onConversationSelected={navigateToMessagingPage}
    />
  );
};

export default ContactsWidget;
