import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";
import { Grid, Box, Typography, Divider } from "@mui/material";
import CustomSelect from "../../../common/CustomSelect";
import { FieldLabel, TextField, ErrorText } from "../../../common";

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: 25,
    "& .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 3,
    },
  },
  text: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginBottom: 8,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
  disabledSelect: {
    "& .MuiSelect-select.Mui-disabled": {
      backgroundColor: "#f3f3f3",
      opacity: "initial",
      cursor: "not-allowed",
    },
    "& fieldset > legend": {
      width: 0,
    },
    "& > label": {
      display: "none",
    },
  },
}));

const themes = [
  { label: "Kommunal", value: "Kommunal" },
  { label: "Link22", value: "Link22" },
  { label: "UniQueue", value: "UQ" },
  { label: "Orange", value: "Orange" },
  { label: "Blue", value: "Blue" },
  { label: "Custom theme", value: "custom" },
];

const ColumnThree = ({
  accountSettings,
  setAccountSettings,
  passwordError,
  incorrectPassword,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    allowEditSubscriptionLevel,
    password,
    passwordRepeat,
    currentPassword,
    selectedTheme,
    companyRoleName,
    subscriptionLevel,
    email,
  } = accountSettings || {};

  const [language, setLanguage] = useState("sv-SE");

  const handleChange = e => {
    const { name, value } = e.target;
    setAccountSettings({ ...accountSettings, [name]: value });
  };

  const handleViewerTheme = value => {
    setAccountSettings({ ...accountSettings, selectedTheme: value });
  };

  const handleChangeSubscription = e => {
    const { value } = e.target;
    setAccountSettings({ ...accountSettings, subscriptionLevel: value });
  };

  const handleChangeLanguage = event => {
    setLanguage(event.target.value);
  };

  useEffect(() => {
    if (subscriptionLevel) {
      setAccountSettings({ ...accountSettings, subscriptionLevel });
    }
  }, [subscriptionLevel]);

  return (
    <Fragment>
      <Grid item xs={12} md={12}>
        <Box flex={2}>
          <Grid container spacing={1} className={classes.formControl}>
            <Typography className={classes.text}>
              <FormattedMessage id="settings.userDetails" />
            </Typography>
            <Grid container spacing={1} className={classes.formControl}>
              <Grid item xs={12} md={3}>
                <FieldLabel>
                  <FormattedMessage id="settings.userDetails" />
                </FieldLabel>
                <TextField
                  type="text"
                  name="userDetails"
                  disabled
                  value={email}
                />
              </Grid>            
              <Grid item xs={12} md={3}>
                <FieldLabel>
                  <FormattedMessage id="settings.role" />
                </FieldLabel>
                <TextField
                  type="text"
                  name="role"
                  disabled
                  value={companyRoleName}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Box style={{ marginBottom: 4 }}>
                  <FieldLabel>
                    <FormattedMessage id="settings.userSubscriptionLevel" />
                  </FieldLabel>
                </Box>
                <CustomSelect
                  customClass={
                    allowEditSubscriptionLevel ? "" : classes.disabledSelect
                  }
                  disabled={!allowEditSubscriptionLevel}
                  inputLabel={intl.formatMessage({ id: "common.select" })}
                  options={[
                    { label: "Free", value: "FREE" },
                    { label: "Mini", value: "MINI" },
                    { label: "Standard", value: "STANDARD" },
                    { label: "Business", value: "BUSINESS" },
                    { label: "Pro", value: "PRO" },
                    { label: "Premium", value: "PREMIUM" },
                  ]}
                  value={subscriptionLevel || "FREE"}
                  onChange={handleChangeSubscription}
                />
              </Grid>
            </Grid>
            {/*<Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Typography className={classes.text}>
                  <FormattedMessage id="settings.accountSub" />
                </Typography>
                <FieldLabel>
                  <FormattedMessage id="settings.currentPassword" />
                </FieldLabel>
                <TextField
                  type="password"
                  name="currentPassword"
                  value={currentPassword || ""}
                  onChange={handleChange}
                />
                {incorrectPassword && (
                  <ErrorText
                    message={
                      <FormattedMessage id="settings.incorrectPassword" />
                    }
                    fontSize={11}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <br />
            </Grid>
            <Grid container spacing={1} className={classes.formControl}>
              <Grid item xs={12} md={6}>
                <FieldLabel>
                  <FormattedMessage id="shares.passwordNew" />
                </FieldLabel>
                <TextField
                  type="password"
                  name="password"
                  value={password || ""}
                  onChange={handleChange}
                />
                <ErrorText message={passwordError} fontSize={11} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldLabel>
                  <FormattedMessage id="settings.passwordRepeat" />
                </FieldLabel>
                <TextField
                  type="password"
                  name="passwordRepeat"
                  value={passwordRepeat || ""}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>*/}
          </Grid>
        </Box>
        <Divider className={classes.divider} />
        <Box flex={3}>
          <Grid container spacing={1} className={classes.formControl}>
            <Typography className={classes.text}>
              <FormattedMessage id="settings.userDisplaySetting" />
            </Typography>
            <Grid container spacing={1} className={classes.formControl}>
              <Grid item xs={12} md={3}>
                <Box style={{ marginBottom: 4 }}>
                  <FieldLabel>
                    <FormattedMessage id="settings.language" />
                  </FieldLabel>
                </Box>
                <CustomSelect
                  inputLabel={intl.formatMessage({ id: "common.select" })}
                  options={[
                    {
                      label: intl.formatMessage({
                        id: "common.languageEnglish",
                      }),
                      value: "en-US",
                    },
                    {
                      label: intl.formatMessage({
                        id: "common.languageSwedish",
                      }),
                      value: "sv-SE",
                    },
                  ]}
                  value={language}
                  onChange={handleChangeLanguage}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Box style={{ marginBottom: 4 }}>
                  <FieldLabel>
                    <FormattedMessage id="settings.useTheme" />
                  </FieldLabel>
                </Box>
                <CustomSelect
                  defaultOption={intl.formatMessage({
                    id: "settings.defaultTheme",
                    value: "Default",
                  })}
                  inputLabel={intl.formatMessage({ id: "common.select" })}
                  options={themes}
                  onChange={e => handleViewerTheme(e.target.value)}
                  value={selectedTheme || ""}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default ColumnThree;

ColumnThree.defaultProps = {
  passwordError: "",
};

ColumnThree.propTypes = {
  passwordError: PropTypes.string,
  incorrectPassword: PropTypes.bool.isRequired,
};
