import { gql } from '@apollo/client';
import SiteNewsComment from "./SiteNewsComment";

const SiteNewsItem = gql`
fragment SiteNewsItemProperties on NewsItem {
	comments {
		...SiteNewsCommentProperties
	}
	allowComments
	author
	avatarUrl
	category
	createdDate
	id
	image
	leadText
	siteId
	siteId
	template
	thumbnail
	title
	viewCount
	likes {
		emoticon
		isCurrentUser
		name
		userId
	}
  images {
    imageUrl
  }
	__typename
}
  ${SiteNewsComment}
`;

export default SiteNewsItem;
