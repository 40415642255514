import { gql } from '@apollo/client';
import Poll from "../../fragments/Poll";

const GET_NEWS_FOR_SITE = gql`
query GetNewsForSite(
	$siteId: Int!
	$projectId: Int
	$offset: Int!
	$pageSize: Int!
) {
	newsContext {
		getNewsForSite(
			siteId: $siteId
			projectId: $projectId
			offset: $offset
			pageSize: $pageSize
		) {
			author
			authorId
			avatarUrl
			category
			createdDate
			id
			image
			itemType
			leadText
			site
			thumbnail
			title
			viewCount
			newsPost
			poll {
				...PollFragment
			}
			likes {
				userId
				name
				emoticon
				isCurrentUser
			}
			mentions {
				avatarUrl
				mentionId
				name
				username
				userId
			}
			comments {
				commentDate
				text
				username
				author
				authorId
				avatarUrl
				commentId
				likes {
					userId
					name
					emoticon
					isCurrentUser
				}
				mentions {
					avatarUrl
					mentionId
					name
					username
					userId
				}
			}
      images {
        imageUrl
      }
		}
	}
}
  ${Poll}
`;


export default GET_NEWS_FOR_SITE;
