import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MonthSelector from "./common/MonthSelector";
import * as Sections from "./common/sections";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    borderRadius: 10,
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
  },
  sectionGridItem: {
    backgroundColor: theme.palette.background.lightGray,
    border: "1px solid #EEE",
    borderRadius: 10,
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    margin: "0.5em 0",
  },
  sectionDetails: {
    marginTop: "0.5rem",
    "& .MuiTable-root": {
      width: "initial",
    },
    "& .MuiTableCell-root": {
      borderBottom: "0 none",
      fontSize: 12,
      padding: "0 3em 0 0",
    },
    "& .MuiTableRow-root > td:last-of-type": {
      paddingRight: 0,
    },
    "& .MuiTableRow-root > th:last-of-type": {
      paddingRight: 0,
    },
    "& .MuiTableCell-body": {
      verticalAlign: "top",
    },
    "& .MuiTableCell-head": {
      lineHeight: "inherit",
      verticalAlign: "bottom",
    },
  },
  sectionDetailsLink: {
    "&.MuiLink-button": {
      color: theme.palette.common.link,
      fontSize: "80%",
      fontWeight: 600,
      marginTop: "0.5rem",
      whiteSpace: "nowrap",
    },
  },
  sectionSummary: {
    "& .MuiTable-root": {},
    "& .MuiTableCell-root": {
      borderBottom: "0 none",
      fontSize: 12,
      padding: "0 3em 0 0",
    },
    "& .MuiTableRow-root > td:last-of-type": {
      paddingRight: 0,
    },
    "& .MuiTableRow-root > th:last-of-type": {
      paddingRight: 0,
    },
    "& .MuiTableCell-body": {
      verticalAlign: "top",
    },
    "& .MuiTableCell-head": {
      lineHeight: "inherit",
      verticalAlign: "bottom",
    },
  },
  sectionTitle: {
    fontSize: 16,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 600,
  },
  headTitle: {
    fontWeight: "inherit",
  },
  headSubtitle: {
    fontWeight: "initial",
    opacity: 0.8,
  },
  body: {
    padding: 20,
  },
  foot: {
    padding: "15px 20px",
    borderTop: "2px solid #F5F5F5",
  },
  changeButton: {
    "&.MuiButton-text": {
      color: "inherit",
      fontSize: "90%",
      marginLeft: "0.5em",
      padding: "2px",
    },
    "&.MuiButton-text .MuiButton-label": {
      color: "inherit",
      fontWeight: "initial",
      textTransform: "initial",
    },
    "&.MuiButton-text .MuiButton-label .MuiIcon-root": {
      fontSize: "120%",
    },
  },
}));

const Subscriptions = () => {
  const classes = useStyles();

  const lang = useSelector(state => state.ui.locale.culture);

  const [startDate, setStartDate] = useState();

  const onMonthSelectorChange = update => {
    console.log("** onMonthSelectorChange", { update });
    setStartDate(update.startDate);
  };

  const renderHeader = () => {
    return (
      <Box className={classes.head}>
        <Typography className={classes.headTitle}>
          <FormattedMessage id="admin.subscriptions.title" />
        </Typography>
        <Typography className={classes.headSubtitle}>
          &nbsp;|&nbsp;
          <FormattedMessage id="admin.subscriptions.subTitle" />
        </Typography>
      </Box>
    );
  };

  const renderFooter = () => {
    return false && <Box className={classes.foot} align="right"></Box>;
  };

  return (
    <Box className={classes.root}>
      {renderHeader()}
      <Box className={classes.body}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <MonthSelector lang={lang} onChange={onMonthSelectorChange} />
          </Grid>
          <Grid item xs={12} className={classes.sectionGridItem}>
            <Sections.SubscriptionLevels classes={classes} />
          </Grid>
          <Grid item xs={12} className={classes.sectionGridItem}>
            <Sections.StorageSpace classes={classes} />
          </Grid>
          <Grid item xs={12} className={classes.sectionGridItem}>
            <Sections.VariableCosts classes={classes} />
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.sectionGridItem}
            style={{ marginBottom: 0 }}
          >
            <Sections.Modules classes={classes} />
          </Grid>
        </Grid>
      </Box>
      {renderFooter()}
    </Box>
  );
};

export default Subscriptions;
