import { useState, useEffect, useRef } from "react";
import { Paper, Card, CardContent, Typography, Box, CardHeader, IconButton } from '@mui/material';
import { cloneDeep } from "lodash";
import { TaskAction } from "../helper";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import UserField from "../TaskDetails/UserField";
import { useDrag, DragPreviewImage } from 'react-dnd'

const KanbanBoardItem = ({ task, userLookup, onTaskAction, enableEdit, taskDetailsColumns }) => {

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: "KANBAN_ITEM",
    item: task,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    }),
  }), [task])

  const removeTreeProperties = (item) => {
    delete item.ancestors;
    delete item.parentNode;
    delete item.treeXLevel;
    delete item.treeYLevel;

    return item;
  };

  const handleShowDetails = () => {
    onTaskAction({ action: TaskAction.EDIT, columns: taskDetailsColumns, data: { task: task, taskId: task.id } })
  }

  const [elevation, setElevation] = useState(0);
   
    return (<>
    <Paper ref={drag} elevation={elevation}
      onMouseEnter={() => setElevation(2)}
      onMouseLeave={() => setElevation(0)}

      sx={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        marginBottom: "0.5em",
        padding: 1,
        fontSize: "13px",
        position: 'relative'
      }}

      >{task.name}

        <IconButton
          onClick={handleShowDetails}
          style={{
            padding: "6px",
            position: "absolute",
            right: "10px",
            top: "0px"            
          }}>
          <ChevronRightIcon />
        </IconButton>

        <UserField
          editable={enableEdit}
          name="assignments"
          onChange={({ name, value }) => {
            let clonedTask = removeTreeProperties(cloneDeep(task));
            clonedTask[name] = value ? [value] : [];
            onTaskAction({
              action: TaskAction.UPDATE,
              columns: null, //ska skicka in kolumns men det anv�nds bara f�r TaskAction.Edit
              data: {
                task: clonedTask,
              },
            });
          }}
          userLookup={userLookup}
          value={task.assignments ? task.assignments[0] : null}
        />

      </Paper></>);


  {/*

    <Card ref={drag} style={{
    opacity: isDragging ? 0.5 : 1,    
    cursor: 'move',
    marginBottom: "0.5em",    
  }}>
    <CardHeader      
      titleTypographyProps={{
        fontSize: 12,
      }}
      title={task.name}
    />
    <CardContent sx={{border:"solid 1px red"} }>

    </CardContent>
  </Card>);*/ }
};

export default KanbanBoardItem;