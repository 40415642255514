import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { Box, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  notifyWrap: {
    //[theme.breakpoints.up("md")]: {
    //  paddingLeft: "10px",
    //  paddingRight: "5px",
    //},
    paddingLeft: "10px",
    paddingRight: "5px",
  },
  notify: {
    position: "relative",
    cursor: "pointer",
  },
  notifyIcon: {
    fontSize: 16,
    cursor: "pointer",
  },
  badge: {
    backgroundColor: theme.palette.component.badgeBackground,
    position: "absolute",
    textAlign: "center",
    color: theme.palette.component.badgeColor,
    fontSize: 10,
    borderRadius: "50%",
    minWidth: "17px",
    padding: "2px 5px",
    fontWeight: "700",
    left: 10,
    bottom: 9,
    cursor: "pointer",
  },
}));

/**
 * Image basket notification icon.
 * 
 * @remarks Displays the value of `counter` in a badge when `counter` > 0.
 *
 * @param {number} counter - Value to display.
 * @param {function} onClick - Handler for click events.
 */
const ImageBasketNotifyIcon = ({ counter, onClick }) => (
  <NotifyIcon
    counter={counter}
    ncIcon="nc-album-2"
    onClick={onClick}
    title={<FormattedMessage id="context.imageBasket" />}
  />
);

/**
 * Message notification icon.
 *
 * @remarks Displays the value of `counter` in a badge when `counter` > 0.
 *
 * @param {number} counter - Value to display.
 * @param {function} onClick - Handler for click events.
 */
const MessageNotifyIcon = ({ counter, onClick }) => (
  <NotifyIcon
    counter={counter}
    ncIcon="nc-chat-33"
    onClick={onClick}
    title={<FormattedMessage id="widget.messaging" />}
  />
);

/**
 * Shopping cart notification icon.
 *
 * @remarks Displays the value of `counter` in a badge when `counter` > 0.
 *
 * @param {number} counter - Value to display.
 * @param {function} onClick - Handler for click events.
 */
const ShoppingCartNotifyIcon = ({ counter, onClick }) => (
  <NotifyIcon
    counter={counter}
    ncIcon="nc-cart-simple"
    onClick={onClick}
    title={<FormattedMessage id="context.shoppingCart" />}
  />
);

const NotifyIcon = ({ counter, ncIcon, onClick, title }) => {
  const classes = useStyles();

  return (
    <Box className={classes.notifyWrap}>
      <Tooltip
        placement="bottom"
        title={title}
      >
        <div
          className={classes.notify}
          onClick={onClick}
        >
          <i className={clsx(["nc-icon", ncIcon, classes.notifyIcon])} />
          {counter > 0 &&
            <span className={classes.badge}>
              {counter}
            </span>
          }
        </div>
      </Tooltip>
    </Box>
  );
};

export { ImageBasketNotifyIcon, MessageNotifyIcon, ShoppingCartNotifyIcon };
