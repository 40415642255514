import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { parseISO } from "date-fns";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {  
  DatePicker,
} from "@mui/x-date-pickers";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiInputBase-root": {
      "& .MuiIconButton-root": {
        padding: 4,
      },
      "& .MuiInputBase-input": {
        color: "#66615b",
        fontSize: 12,
        height: "100%",
        padding: 10,
        paddingRight: 0,
      }
    }
  },
  //formControl: {
  //  fontSize: 12,
  //  padding: 10,
  //  paddingRight: 0,
  //  height: "100%",
  //  color: "#66615b",
  //},
  //rootDate: {
  //  marginTop: 0,
  //  marginBottom: 0,
  //  "& .MuiInputAdornment-positionEnd": {
  //    marginLeft: 0,
  //  },
  //  "& .MuiOutlinedInput-adornedEnd": {
  //    paddingRight: 5,
  //  },
  //  "& .MuiIconButton-root": {
  //    padding: 4,
  //  },
  //},
}));

const DateFull = ({
  allowNull,
  customLabelClass,
  disablePortal,
  emptyLabel,
  label,
  locale,
  name,
  setDate,
  shrink,
  value,
  variant,
  ...props
}) => {
  const classes = useStyles();
  const intl = useIntl();

  let date;

  if (allowNull && value === null) {
    date = value;
  }
  else if (typeof value === 'string' || value instanceof String) {
    date = parseISO(value);
  }
  else {
    date = value || new Date();
  }

  return (<DatePicker
    className={classes.root}
    label={label}
    localeText={{
      clearButtonLabel: intl.formatMessage({id: "common.clear"}),
    }}
    onChange={setDate}
    slotProps={{
      actionBar: {
        actions: ['clear']
      }
    }}
    sx={{ width: "100%" }}
    value={date}
    {...props}
  />);
    //autoOk
    //disableToolbar
    //name={name}
    //variant={variant || "inline"}
    //clearable
    //inputVariant="outlined"
    //emptyLabel={emptyLabel}
    //format="yyyy-MM-dd"
    ///margin="normal"
    //
    //views={["year", "month", "date"]}
    //
    ///KeyboardButtonProps={{
    //  "aria-label": "change date",
    //}}
    //PopoverProps={{
    //  disablePortal,
    //}}
    //keyboardIcon={<Event />}
    //InputProps={{
    //  classes: { input: classes.formControl },
    //}}
    //InputLabelProps={{
    //  classes: {
    //    root: customLabelClass || classes.labelRoot,
    //  },
    //  shrink,
    //}}
    //fullWidth
    //className={classes.rootDate}
    //
    //invalidDateMessage={intl.formatMessage({
    //  id: "common.invalidDate",
    //})}
    //cancelLabel={intl.formatMessage({
     // id: "common.cancel",
    //})}
    //clearLabel={intl.formatMessage({
    //  id: "common.clear",
    //})}
    //{...props}     
};

DateFull.defaultProps = {
  allowNull: false,
  disablePortal: false,
  emptyLabel: "",
  locale: null,
  name: "",
  value: null,
  variant: "",
};

DateFull.propTypes = {
  allowNull: PropTypes.bool,
  //customLabelClass
  disablePortal: PropTypes.bool,
  emptyLabel: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  locale: PropTypes.shape({
    culture: PropTypes.string.isRequired,
  }),
  name: PropTypes.string,
  setDate: PropTypes.func.isRequired,
  //shrink
  //value: PropTypes.instanceOf(Date),
  variant: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    locale: state.ui.locale,
  };
};

export default connect(mapStateToProps)(DateFull);
