import React from "react";
import { Provider as AlertProvider } from "react-alert";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";

import moment from "moment";
import "moment/locale/sv";
import PropTypes from "prop-types";
import "typeface-roboto";

import { ApolloProvider } from '@apollo/client';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import AlertTemplate from "../components/common/Alert";
import options from "../components/common/Alert/options";
import MessagingProvider from "../context/MessagingContext";
import SearchProvider from "../context/DetailedSearchContext";
import SnackbarProvider from "../context/SnackbarContext";
import TermsOfUseProvider from "../context/TermsOfUseContext";
import { client } from "../graphql/client";
import { getLocaleLanguage } from "../helpers/get";
import { getThemeName } from "../helpers/selectors";
import { AuthProvider } from "../providers/authProvider";
import PageRoute from "../routes/index";
import theme from "../theme/index";
import { EN, SV } from "../translation";

import BrowserContainer from "./BrowserContainer";
import DeepLinkProvider from "../context/DeepLinkContext";

const messages = { "en-US": EN, "sv-SE": SV };

const Page = ({ accessToken, locale, themeName, toggleTheme }) => {
  const useTheme = toggleTheme || themeName;

  var adapterLocale = getLocaleLanguage(locale.culture);

  if (moment.locale() !== adapterLocale.code) {
    moment.locale(adapterLocale.code);
  }

  return (
    <ThemeProvider theme={theme(useTheme)}>
      <StyledEngineProvider injectFirst>
        <IntlProvider locale={locale.culture} messages={messages[locale.culture]}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
            <ApolloProvider client={client}>
              <AuthProvider>
                <AlertProvider template={AlertTemplate} {...options}>
                  <SnackbarProvider>
                    <DeepLinkProvider>
                      <BrowserContainer accessToken={accessToken}>
                        <TermsOfUseProvider>
                          <MessagingProvider>
                            <SearchProvider>
                              {PageRoute}
                            </SearchProvider>
                          </MessagingProvider>
                        </TermsOfUseProvider>
                      </BrowserContainer>
                    </DeepLinkProvider>
                  </SnackbarProvider>
                </AlertProvider>
              </AuthProvider>
            </ApolloProvider>
          </LocalizationProvider>
        </IntlProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken,
    locale: state.ui.locale,
    themeName: getThemeName(state),
    toggleTheme: state.ui.toggleThemes.theme,
  };
};

Page.defaultProps = {
  accessToken: "",
  themeName: null,
};

Page.propTypes = {
  accessToken: PropTypes.string,
  locale: PropTypes.shape({
    culture: PropTypes.string.isRequired,
  }).isRequired,
  themeName: PropTypes.string,
  toggleTheme: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(Page);
