import { handleActions } from 'redux-actions';
import {
  setChannels,
  setChannelsLoading,
  setChannelsError,
} from "../../../../actions";

const initialState = {
  items: [],
  loading: false,
  error: null,
};

export default handleActions(
  {
    [setChannels]: (state, { payload: { items } }) => ({
      ...state,
      items,
    }),
    [setChannelsLoading]: (state, { payload: { loading } }) => ({
      ...state,
      loading,
    }),
    [setChannelsError]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
  },
  initialState
);