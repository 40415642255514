import React from 'react';
import { Group, Path } from 'react-konva';

const TemplateIcon = ({ x = 0, y = 0, width = 24, height = 24, fill = 'black', stroke = 'none', strokeWidth = 0, scale = { x: 1, y: 1 }, opacity = 1, listening = false, offsetX = 0, offsetY = 0, renderAsSVG = false }) => {
  return renderAsSVG ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      opacity={opacity}
    >
      <g>
        <path d="M19.817,1.023H4.25c-1.228,0-2.227,1.038-2.227,2.313V19.75c0,1.275,0.999,2.313,2.227,2.313h15.567
		c1.228,0,2.227-1.037,2.227-2.313V3.336C22.044,2.061,21.045,1.023,19.817,1.023z M20.591,19.75c0,0.474-0.347,0.859-0.773,0.859
		H4.25c-0.426,0-0.773-0.386-0.773-0.859V3.336c0-0.474,0.347-0.859,0.773-0.859h15.567c0.427,0,0.773,0.385,0.773,0.859V19.75z"/>
        <path d="M10.459,8.939H6.439c-0.276,0-0.5,0.224-0.5,0.5v8.047c0,0.276,0.224,0.5,0.5,0.5h4.021c0.276,0,0.5-0.224,0.5-0.5V9.439
		C10.959,9.163,10.736,8.939,10.459,8.939z M9.959,16.986H6.939V9.939h3.021V16.986z"/>
        <path d="M17.709,4.958H6.439c-0.276,0-0.5,0.224-0.5,0.5v0.5c0,0.276,0.224,0.5,0.5,0.5h11.27c0.276,0,0.5-0.224,0.5-0.5v-0.5
		C18.209,5.182,17.985,4.958,17.709,4.958z"/>
        <path d="M17.709,8.939h-4.02c-0.276,0-0.5,0.224-0.5,0.5v8.047c0,0.276,0.224,0.5,0.5,0.5h4.02c0.276,0,0.5-0.224,0.5-0.5V9.439
		C18.209,9.163,17.985,8.939,17.709,8.939z M17.209,16.986h-3.02V9.939h3.02V16.986z"/>
      </g>
    </svg>
  ) : (
    <Group
      x={x}
      y={y}
      width={width}
      height={height}
      scale={scale}
      listening={listening}
      offsetX={offsetX}
      offsetY={offsetY}
      opacity={opacity}
    >
      <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} data="M19.817,1.023H4.25c-1.228,0-2.227,1.038-2.227,2.313V19.75c0,1.275,0.999,2.313,2.227,2.313h15.567
		c1.228,0,2.227-1.037,2.227-2.313V3.336C22.044,2.061,21.045,1.023,19.817,1.023z M20.591,19.75c0,0.474-0.347,0.859-0.773,0.859
		H4.25c-0.426,0-0.773-0.386-0.773-0.859V3.336c0-0.474,0.347-0.859,0.773-0.859h15.567c0.427,0,0.773,0.385,0.773,0.859V19.75z"/>
      <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} data="M10.459,8.939H6.439c-0.276,0-0.5,0.224-0.5,0.5v8.047c0,0.276,0.224,0.5,0.5,0.5h4.021c0.276,0,0.5-0.224,0.5-0.5V9.439
		C10.959,9.163,10.736,8.939,10.459,8.939z M9.959,16.986H6.939V9.939h3.021V16.986z"/>
      <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} data="M17.709,4.958H6.439c-0.276,0-0.5,0.224-0.5,0.5v0.5c0,0.276,0.224,0.5,0.5,0.5h11.27c0.276,0,0.5-0.224,0.5-0.5v-0.5
		C18.209,5.182,17.985,4.958,17.709,4.958z"/>
      <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} data="M17.709,8.939h-4.02c-0.276,0-0.5,0.224-0.5,0.5v8.047c0,0.276,0.224,0.5,0.5,0.5h4.02c0.276,0,0.5-0.224,0.5-0.5V9.439
		C18.209,9.163,17.985,8.939,17.709,8.939z M17.209,16.986h-3.02V9.939h3.02V16.986z"/>
    </Group>
  );
};

export default TemplateIcon;