import { gql } from '@apollo/client';

const getCommentsAndFigures = gql`query getCommentsAndFigures($productId: ID!){
	productComments(productId: $productId){
		versionId
		versionNumber
		comments{
			author
			avatarUrl
			comment
			page
			pageNo
			resourceVersionCommentId
			resourceVersionId
			statusId
			timestamp
			versionCommentNumber
		}
		figures{
			author
			avatarUrl
			connectedCommentId
			created
			figureData
			id
			userId
		}
	}
}`;

export default getCommentsAndFigures;
