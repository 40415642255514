import { Box, Typography } from "@mui/material";
import { emoticonMap } from "./emoticonMap";

const EmoticonDisplay = ({ likes }) => {
  
  const reactionCounts = likes.reduce((acc, like) => {
    acc[like.emoticon] = (acc[like.emoticon] || 0) + 1;
    return acc;
  }, {});

  return (
    <Box display="flex" alignItems="center" gap={2}>
      {emoticonMap.map(({ emoticon, emoji }) => {
        const count = reactionCounts[emoticon] || 0;

        
        return count > 0 ? (
          <Box key={emoticon} display="flex" alignItems="center">

            <Typography variant="body2">{emoji}</Typography>
            <Typography variant="body2" fontWeight="bold" ml={0.25}>
              {count}
            </Typography>
          </Box>
        ) : null;
      })}
    </Box>
  );
};

export default EmoticonDisplay;