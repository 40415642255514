import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";
import Poll from "../../fragments/Poll";

const CREATE_NEWSPOST = gql`
 mutation CreateNewsPost($input: NewsInput) {
	newsContext {
		createNewsPost(input: $input) {
			errorResult {
				...ErrorResultFields
			}
			success
			result {
				author
				avatarUrl
				category
				createdDate
				id
				image
				itemType
				leadText
				site
				thumbnail
				title
				viewCount
				newsPost
				poll {
					...PollFragment
				}
				likes {
					userId
					name
					emoticon
					isCurrentUser
				}
				mentions {
					avatarUrl
					mentionId
					name
					username
					userId
				}
				comments {
					commentDate
					text
					username
					author
					authorId
					avatarUrl
					commentId
					mentions {
						avatarUrl
						mentionId
						name
						username
						userId
					}
					likes {
						userId
						name
						emoticon
						isCurrentUser
					}
				}
        images {
          imageUrl
        }
			}
		}
	}
}
  ${ErrorResultFragment}
  ${Poll}
`;

export default CREATE_NEWSPOST;
