import React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { bindActionCreators } from "redux";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { setLocale } from "../../../../actions";

import PublicNavMenuList from "./PublicNavMenuList";
import LanguageSelector from "../../NavBar/NavbarDrawer/LanguageSelector";

const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
    backgroundColor: theme.palette.primary.main,
    height: "100%",
  },
  menuButton: {
    color: theme.palette.component.hamburger,
    padding: 0,
    marginRight: 10,
    [theme.breakpoints.down("sm")]: {
      marginRight: 8,
      flex: 1,
    },
  },
  linkWrapper: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
  },
  divider: {
    marginBottom: 15,
    marginTop: 15,
    backgroundColor: "#6b3b49",
  },
  ProfileMargin: {
    margin: "5px 0 15px 0",
  },
}));

const PublicNavbarMobileDrawer = ({
  // eslint-disable-next-line no-shadow
  setLocale,
  locale,
}) => {
  const classes = useStyles();

  const [anchorLang, setAnchorLang] = React.useState(null);
  const handleLang = event => {
    setAnchorLang(event.currentTarget);
  };
  const handleCloseLang = ({ culture }) => {
    if (culture) {
      setLocale({ culture });
    }
    setAnchorLang(null);
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = () => (
    <div className={classes.list} role="presentation">
      <Box pl={3} className={classes.linkWrapper}>
        <PublicNavMenuList />
        <Divider className={classes.divider} />
        <LanguageSelector
          handleLang={handleLang}
          anchorLang={anchorLang}
          handleCloseLang={handleCloseLang}
          locale={locale}
        />
      </Box>
    </div>
  );
  return (
    <div>
      <IconButton
        className={classes.menuButton}
        onClick={toggleDrawer("left", true)}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        open={state.left}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
        swipeAreaWidth={5}
      >
        {sideList("left")}
      </SwipeableDrawer>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    locale: state.ui.locale,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setLocale,
    },
    dispatch
  );
};

PublicNavbarMobileDrawer.defaultProps = {
  locale: null,
  setLocale: null,
};

PublicNavbarMobileDrawer.propTypes = {
  setLocale: PropTypes.func,
  locale: PropTypes.shape({
    culture: PropTypes.string.isRequired,
  }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicNavbarMobileDrawer);
