import { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { ArrowBackIos, ArrowForwardIos, DragIndicator, ZoomIn, ZoomOut } from "@mui/icons-material";
import { Box, Divider, InputBase, IconButton, Paper } from "@mui/material";
import { usePaginationContext } from "../../../hooks/FullscreenAnnotationModal";
import { DoublePageIcon, SinglePageIcon } from "../icons";
import {
  FILE_TYPE as FileType,
  PAGE_MODE_TYPE as PageModeType,
} from "../utils/types";

const ZoomToolbox = ({
  fileType,
  handleZoom,
  handleZoomIn,
  handleZoomOut,
  zoom,
  pageMode,
  setPageMode
}) => {
  const [inputZoom, setInputZoom] = useState(zoom);

  const { nextPage, pageCount: numPages, pageNo: pdfPageNumber, previousPage } = usePaginationContext();

  // Sync input field with zoom level whenever zoom changes
  useEffect(() => {
    setInputZoom(zoom);
  }, [zoom]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value)) {  // Allow only numeric input
      setInputZoom(value);
    }
  };

  const applyZoom = () => {
    const parsedValue = parseFloat(inputZoom);
    if (!isNaN(parsedValue) && parsedValue >= 10 && parsedValue <= 2500) {
      const newScale = parsedValue / 100;
      handleZoom(newScale);
    } else {
      setInputZoom(zoom);
    }
  };

  const handleInputBlur = () => {
    applyZoom();  // Apply zoom when input loses focus
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      applyZoom();  // Apply zoom when Enter is pressed
    }
  };

  const handleZoomOutClick = () => {
    handleZoomOut();
  };

  const handleZoomInClick = () => {
    handleZoomIn();
  };

  const togglePageMode = () => {
    setPageMode(prev =>
      prev === PageModeType.DOUBLE
        ? PageModeType.SINGLE
        : PageModeType.DOUBLE
    );
  };

  const iconColor = "rgba(0, 0, 0, 0.7)";

  return (
    <Draggable bounds="parent" handle=".handle">
      <Paper
        elevation={3}
        sx={{
          position: 'absolute',
          bottom: 60,
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: 'rgba(211, 211, 211, 0.4)',
          '&:hover': {
            backgroundColor: 'rgba(211, 211, 211, 0.9)',
          },
          borderRadius: '4px',
          padding: '4px',
          display: 'flex',
          alignItems: 'center',
          zIndex: 1000,
        }}
      >
        <Box
          className="handle"
          sx={{
            cursor: 'move',
            marginRight: '0px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DragIndicator sx={{ color: 'rgba(0, 0, 0, 0.4)' }} />
        </Box>{numPages > 1 && (
          <>
            <Divider orientation={'vertical'} flexItem sx={{ my: 1, mx: 0.5 }} />
            <Box ml="-6px">
              <IconButton color="inherit" onClick={previousPage} disabled={pdfPageNumber <= 1} sx={{ color: iconColor }}>
                <ArrowBackIos viewBox='-6 0 24 24' />
              </IconButton>
            </Box>
            <Box ml="-12px">
              <IconButton
                onClick={togglePageMode}
                sx={{
                  color: iconColor,
                  aspectRatio: 1,
                  boxSizing: "border-box",
                  maxHeight: "40px",
                }}
              >
                {(pageMode === PageModeType.SINGLE) && <SinglePageIcon height={18} renderAsSVG fill="currentColor" />}
                {(pageMode === PageModeType.DOUBLE) && <DoublePageIcon height={18} renderAsSVG fill="currentColor" />}
              </IconButton>
            </Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              color: iconColor,
            }}>
              {pdfPageNumber}/{numPages}
            </Box>
            <Box mr="-6px">
              <IconButton color="inherit" onClick={nextPage} disabled={pdfPageNumber >= numPages} sx={{ color: iconColor }}>
                <ArrowForwardIos />
              </IconButton>
            </Box>
          </>
        )}
        <Divider orientation={'vertical'} flexItem sx={{ my: 1, mx: 0.5 }} />
        <IconButton
          onClick={handleZoomOutClick}
          sx={{
            color: iconColor,
            marginRight: '-3px',
          }}
        >
          <ZoomOut />
        </IconButton>
        <InputBase
          value={inputZoom}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}  // Listen for Enter key
          inputProps={{
            'aria-label': 'zoom percentage',
            sx: {
              width: '34px',
              color: iconColor,
              mx: 0.2,
              fontWeight: 'bold',
              textAlign: "right",
              "&::placeholder": {
                textAlign: "right",
              },
            },
          }}
          endAdornment={<Box component="span" sx={{ ml: 0.1 }}>%</Box>}
        />

        <IconButton
          onClick={handleZoomInClick}
          sx={{
            color: iconColor,
            marginLeft: '2px',
          }}
        >
          <ZoomIn />
        </IconButton>
      </Paper>
    </Draggable>
  );
};

export default ZoomToolbox;
