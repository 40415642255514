import React, { useState, useRef, useEffect } from "react";
import { Button, Popper, Box, Fade, useMediaQuery } from "@mui/material";
import { emoticonMap } from "./emoticonMap";

const ReactionButton = ({ postId, onLikePost }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedReaction, setSelectedReaction] = useState(null);
  const isMobile = useMediaQuery("(hover: none)");
  const popperTimeoutRef = useRef(null); // Ref to hold the timeout ID
  const popperHoveredRef = useRef(false); // Ref to track hover state

  const handleQuickLike = () => {
    if (selectedReaction === 1) {
      // Remove thumbs-up if already selected
      onLikePost(postId, 0);
      setSelectedReaction(null);
    } else {
      // Quick thumbs-up
      onLikePost(postId, 1);
      setSelectedReaction(1);
    }
  };

  const handleToggle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleReactionClick = (reaction) => {
    const newReaction = selectedReaction === reaction.emoticon ? null : reaction.emoticon;
    const reactionId = newReaction ? reaction.emoticon : 0;

    onLikePost(postId, reactionId);
    setSelectedReaction(newReaction);
    setAnchorEl(null); // Close after selecting a reaction
  };

  const resetCloseTimeout = () => {
    if (popperTimeoutRef.current) {
      clearTimeout(popperTimeoutRef.current);
    }
    if (anchorEl) {
      popperTimeoutRef.current = setTimeout(() => {
        if (!popperHoveredRef.current) {
          setAnchorEl(null);
        }
      }, 3000);
    }
  };

  const handleMouseEnter = () => {
    popperHoveredRef.current = true;
    if (popperTimeoutRef.current) {
      clearTimeout(popperTimeoutRef.current); // Prevent closing while hovering
    }
  };

  const handleMouseLeave = () => {
    popperHoveredRef.current = false;
    resetCloseTimeout(); // Restart the close timer
  };

  useEffect(() => {
    resetCloseTimeout(); // Set or reset timeout when Popper opens or closes
    return () => {
      if (popperTimeoutRef.current) {
        clearTimeout(popperTimeoutRef.current); // Cleanup timeout on unmount
      }
    };
  }, [anchorEl]);

  return (
    <>
      <Button
        variant="text"
        onClick={isMobile ? handleToggle : handleQuickLike}
        onMouseEnter={!isMobile ? handleToggle : undefined}
        sx={{
          fontSize: "12px",
          ml: 2,
          minWidth: 0,
          p: 0,
          lineHeight: 1,
          textTransform: "none",
          textDecoration: selectedReaction ? "underline" : "none",
        }}
      >
        Gilla
      </Button>

      {/* Popper for reaction selection */}
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        placement="top-start"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Box
              sx={{
                display: "flex",
                p: 1,
                bgcolor: "background.paper",
                borderRadius: "5px",
                boxShadow: 3,
              }}
            >
              {emoticonMap.map((reaction) => (
                <Button key={reaction.emoticon} onClick={() => handleReactionClick(reaction)} sx={{ minWidth: 0 }}>
                  {reaction.emoji}
                </Button>
              ))}
            </Box>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default ReactionButton;