import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import { useSelector } from "react-redux";
import { Paper, Box, Typography, Button, Grid } from "@mui/material";
import clsx from "clsx";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";

import OneStreamTable from "../../components/OneStream/OneStreamTable";
import OneStreamDropzone from "../../components/OneStream/OneStreamDropzone";
import NewUploadModal from "../../components/OneStream/NewUploadModal";
import { useGetOneStreamUploadUrl, useGetOneStreamJobs } from "../../hooks";
import Pagination from "../../components/common/Pagination";
import { getSiteId } from "../../helpers/selectors";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
  },
  paperHead: {
    padding: theme.spacing(2),
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  title: {
    color: theme.palette.component.productBoxColor,
    fontSize: 18,
    fontWeight: 600,
    marginRight: 10,
  },
  btnAdd: {
    padding: "10px 15px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.common.white,
    fontSize: 12,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.light, 0.8),
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 10,
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
  },
  selectWrap: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    width: 300,
    "& .MuiIconButton-root": {
      padding: 5,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.common.white,
    },
  },
  paperBody: {
    padding: theme.spacing(4),
    paddingTop: 10,
    paddingBottom: 20,
    "& .dropzone": {
      backgroundColor: theme.palette.common.white,
      marginTop: 15,
    },
  },
  paginationWrapper: {
    marginLeft: "auto",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 8,
      marginTop: 8,
      marginLeft: 0,
    },
  },
}));

const OneStream = () => {
  const classes = useStyles();
  const dropzoneRef = useRef(null);
  const alert = useAlert();
  const totalCount = useSelector(
    state => state.api.oneStream.oneStreamJobs.total
  );



  const siteId = useSelector(state => getSiteId(state));

  
  const [newUploadModal, setNewUploadModal] = useState(false);

  const [uploadedFile, setUploadedFile] = useState(null); // New state for the file

  const handleOnSelectUploadFile = file => {
    setUploadedFile(file); 
    setNewUploadModal(true); 
  };

  const closeUploadModal = () => {

    if (dropzoneRef.current) {
      dropzoneRef.current.removeAllFiles(); // Clear dropzone files
    }

    setNewUploadModal(false);
    setUploadedFile(null); 
  };


  const [refreshDropzone, setRefreshDropzone] = useState(false);


  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25); // !!!
  const [sortField, setSortField] = useState("uploadedDate");
  const [ascending, setAscending] = useState(false);

  const handlePageSizeChange = (e) => {
    setPage(0);
    setPageSize(e.target.value);
  };

  const { execute: getOneStream } = useGetOneStreamJobs({
    siteId,
    pageSize,
    offset: page * pageSize,
    sortField,
    ascending,
  });

  useEffect(() => {
    if (siteId) {
      getOneStream();
    }
  }, [siteId]);

  useEffect(() => {
    setRefreshDropzone(false);
  }, [refreshDropzone]);

  const updateSort = (field, asc) => {
    setSortField(field);
    setAscending(asc);
    getOneStream();
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          <Paper className={classes.paper} elevation={0}>
            <Box className={classes.paperHead}>
              <Typography variant="h1" component="h2" className={classes.title}>
                OneStream
              </Typography>
              <Button
                className={classes.btnAdd}
                onClick={() => setNewUploadModal(true)}
              >
                <FormattedMessage id="onestream.newUpload" />
                <Icon
                  className={clsx(["fa fa-plus-circle", classes.btnIcon])}
                />
              </Button>
              {totalCount > pageSize && (
                <div className={classes.paginationWrapper}>
                  <Pagination
                    onChange={handlePageSizeChange}
                    onPageChange={(e, p) => setPage(p)}
                    page={page}
                    perPage={pageSize}
                    totalCount={totalCount}
                  />
                </div>
              )}
            </Box>
            <Box className={classes.paperBody}>
              <OneStreamTable
                sortField={sortField}
                updateSort={updateSort}
                ascending={ascending}
              />
              <OneStreamDropzone
                setNewUploadModal={setNewUploadModal}
                handleOnSelectUploadFile={handleOnSelectUploadFile}
                dropzoneRef={dropzoneRef}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
        {newUploadModal && (
          <NewUploadModal
            open={newUploadModal}
            onClose={closeUploadModal}
            uploadedFile={uploadedFile}
            siteId={siteId}
            onRefreshTable={getOneStream}
          />)}
    </div>
  );
};

export default OneStream;
