import React, { useEffect, useCallback, useState } from "react";

import {Circle, Group, Layer, Path } from 'react-konva';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import OrthogonalConnector from '../subcomponents/OrthogonalConnector';

import { Border } from "../subcomponents/OneStream/Border";
import ConnectionBox from "../subcomponents/OneStream/ConnectionBox";

import { SHAPESIZE } from "../utils/config"; // OneStream
import { NODEOFFSET } from "../utils/config"; //OneStream
import { TOOL_TYPE} from "../utils/types";

import calcStepPosFromNode from "../utils/calcStepPosFromNode"; // OneStream

const WorkFlowLayer = ({ activeTool, setOpenSideDrawer,
  steps, setSteps, connections,
  setConnections, selectedStep, setSelectedStep }) => {

  const [activeConnection, setActiveConnection] = useState(null);
  const [connectionPreview, setConnectionPreview] = useState(null); 
  const [dragStartInfo, setDragStartInfo] = useState(null);  
  const [hoverIndicators, setHoverIndicators] = useState(null);

  const getNodePosition = useCallback((stepId, position) => {
    const step = steps[stepId];
    switch (position) {
      case 'left': return { x: step.x - NODEOFFSET, y: step.y + SHAPESIZE / 2 };
      case 'top': return { x: step.x + SHAPESIZE / 2, y: step.y - NODEOFFSET };
      case 'right': return { x: step.x + SHAPESIZE + NODEOFFSET, y: step.y + SHAPESIZE / 2 };
      case 'bottom': return { x: step.x + SHAPESIZE / 2, y: step.y + SHAPESIZE + NODEOFFSET };
      default: return { x: step.x + SHAPESIZE / 2, y: step.y + SHAPESIZE / 2 };
    }
  }, [steps]);

  const createPathData = useCallback((start, end, startPosition, endPosition, isDragging = false) => {
    // Define shapes based on the start and end points
    const shapeSize = SHAPESIZE; // Adjust this value as needed
    const shapeA = calcStepPosFromNode(start, startPosition, shapeSize, shapeSize);
    const shapeB = isDragging ? {
      left: end.x,
      top: end.y,
      width: 1,
      height: 1,
    } : calcStepPosFromNode(end, endPosition, shapeSize, shapeSize);

    // Calculate the end position when dragging
    if (isDragging) {
      const dx = end.x - start.x;
      const dy = end.y - start.y;
      if (Math.abs(dx) > Math.abs(dy)) {
        endPosition = dx > 0 ? 'left' : 'right';
      } else {
        endPosition = dy > 0 ? 'top' : 'bottom';
      }
    }

    // Get the connector path
    const path = OrthogonalConnector.route({
      pointA: { shape: shapeA, side: startPosition, distance: 0.5 },
      pointB: { shape: shapeB, side: endPosition, distance: 0.5 },
      shapeMargin: 20,
      globalBoundsMargin: 24,
      globalBounds: { left: 0, top: 0, width: window.innerWidth, height: window.innerHeight },
    });

    // Convert the path to SVG path data with rounded corners
    if (path.length > 0) {
      const radius = 10; // Adjust this value to change the roundness of corners
      let svgPath = `M ${path[0].x} ${path[0].y}`;

      for (let i = 1; i < path.length - 1; i++) {
        const prev = path[i - 1];
        const current = path[i];
        const next = path[i + 1];

        // Calculate the direction vectors
        const v1 = { x: current.x - prev.x, y: current.y - prev.y };
        const v2 = { x: next.x - current.x, y: next.y - current.y };

        // Normalize the vectors
        const length1 = Math.sqrt(v1.x * v1.x + v1.y * v1.y);
        const length2 = Math.sqrt(v2.x * v2.x + v2.y * v2.y);
        v1.x /= length1; v1.y /= length1;
        v2.x /= length2; v2.y /= length2;

        // Calculate the corner points
        const cornerStart = {
          x: current.x - v1.x * Math.min(radius, length1 / 2),
          y: current.y - v1.y * Math.min(radius, length1 / 2)
        };
        const cornerEnd = {
          x: current.x + v2.x * Math.min(radius, length2 / 2),
          y: current.y + v2.y * Math.min(radius, length2 / 2)
        };

        // Add the line to the corner start and the quadratic curve
        svgPath += ` L ${cornerStart.x} ${cornerStart.y} Q ${current.x} ${current.y} ${cornerEnd.x} ${cornerEnd.y}`;
      }

      // Add the final line
      svgPath += ` L ${path[path.length - 1].x} ${path[path.length - 1].y}`;

      return svgPath;
    }

    return '';
  }, []);

  const handleSelection = useCallback((event, id) => {
    event.cancelBubble = true;
    if (activeTool === TOOL_TYPE.SELECT) {
      setSelectedStep(selectedStep === id ? null : id);
    }
  }, [activeTool, selectedStep]);

  const detectClosestSide = useCallback((position, shapeSize) => {
    const center = { x: position.x + shapeSize / 2, y: position.y + shapeSize / 2 };
    const distances = {
      left: Math.abs(position.x - center.x),
      right: Math.abs(position.x + shapeSize - center.x),
      top: Math.abs(position.y - center.y),
      bottom: Math.abs(position.y + shapeSize - center.y),
    };
    return Object.keys(distances).reduce((a, b) => distances[a] < distances[b] ? a : b);
  }, []);


  const detectConnection = useCallback((transformedPosition, currentId, steps) => {
    const hoverAreaSize = SHAPESIZE + 40; // Increased hover area
    const hoverOffset = 20; // Half of the additional hover area

    for (const [stepId, step] of Object.entries(steps)) {
      if (stepId !== currentId) {
        // Check if pointer is within the expanded hover area
        if (
          transformedPosition.x >= step.x - hoverOffset &&
          transformedPosition.x <= step.x + hoverAreaSize - hoverOffset &&
          transformedPosition.y >= step.y - hoverOffset &&
          transformedPosition.y <= step.y + hoverAreaSize - hoverOffset
        ) {
          return {
            id: stepId,
            step: step,
            position: {
              x: transformedPosition.x,
              y: transformedPosition.y
            }
          };
        }
      }
    }
    return null;
  }, []);

  const handleAnchorDragStart = useCallback((e, id, position) => {
    const nodePosition = getNodePosition(id, position);

    // Check if there's an existing connection at this anchor
    const existingConnection = connections.find(conn =>
      (conn.from.id === id && conn.from.position === position) ||
      (conn.to.id === id && conn.to.position === position)
    );

    if (existingConnection) {
      // If there's an existing connection, set it as active and remove it from connections
      setActiveConnection(existingConnection);
      setConnections(prevConnections =>
        prevConnections.filter(conn => conn !== existingConnection)
      );

      // Set the drag start info to the other end of the connection
      const dragStartId = existingConnection.from.id === id ? existingConnection.to.id : existingConnection.from.id;
      const dragStartPosition = existingConnection.from.id === id ? existingConnection.to.position : existingConnection.from.position;
      const dragStartNodePosition = getNodePosition(dragStartId, dragStartPosition);

      setDragStartInfo({ id: dragStartId, position: dragStartPosition, ...dragStartNodePosition });
    } else {
      // If it's a new connection, proceed as before
      setActiveConnection(null);
      setDragStartInfo({ id, position, ...nodePosition });
    }

    setConnectionPreview(
      <Path
        x={0}
        y={0}
        data={`M ${nodePosition.x} ${nodePosition.y} L ${nodePosition.x} ${nodePosition.y}`}
        stroke="black"
        strokeWidth={2}
      />
    );
  }, [connections, getNodePosition]);

  const handleAnchorDragMove = useCallback((e) => {
    if (!dragStartInfo) return;

    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();

    // Transform the pointer position to account for stage scale and position
    const transform = stage.getAbsoluteTransform().copy();
    transform.invert();
    const transformedPosition = transform.point(pointerPosition);

    // Detect potential connection target while dragging
    const connectionTarget = detectConnection(transformedPosition, dragStartInfo.id, steps);

    // Get the start point of the connection
    const startPoint = getNodePosition(dragStartInfo.id, dragStartInfo.position);

    if (connectionTarget) {
      const { id: targetId } = connectionTarget;
      const sides = ['left', 'top', 'right', 'bottom'];
      const closestSide = sides.reduce((closest, side) => {
        const nodePos = getNodePosition(targetId, side);
        const distanceToCurrent = Math.hypot(transformedPosition.x - nodePos.x, transformedPosition.y - nodePos.y);
        const distanceToClosest = Math.hypot(
          transformedPosition.x - (closest ? getNodePosition(targetId, closest.side).x : Infinity),
          transformedPosition.y - (closest ? getNodePosition(targetId, closest.side).y : Infinity)
        );
        return distanceToCurrent < distanceToClosest ? { side, pos: nodePos } : closest;
      }, null);

      if (closestSide) {
        // Draw preview to the closest connection point
        const endPoint = getNodePosition(targetId, closestSide.side);
        const pathData = createPathData(
          startPoint,
          endPoint,
          dragStartInfo.position,
          closestSide.side
        );

        // Show hover indicators
        setHoverIndicators(
          <Circle
            x={endPoint.x}
            y={endPoint.y}
            radius={5}
            fill="orange"
            listening={false}
          />
        );

        setConnectionPreview(
          <Path
            x={0}
            y={0}
            data={pathData}
            stroke="black"
            strokeWidth={2}
            lineCap='round'
            lineJoin='round'
          />
        );
      }
    } else {
      // When not connecting to a target, use the mouse position directly
      const endPoint = {
        x: transformedPosition.x,
        y: transformedPosition.y
      };

      const pathData = createPathData(
        startPoint,
        endPoint,
        dragStartInfo.position,
        "",
        true
      );

      setHoverIndicators(null);
      setConnectionPreview(
        <Path
          x={0}
          y={0}
          data={pathData}
          stroke="black"
          strokeWidth={2}
          lineCap='round'
          lineJoin='round'
        />
      );
    }
  }, [dragStartInfo, steps, getNodePosition, createPathData, detectConnection]);

  const handleAnchorDragEnd = useCallback((e) => {
    if (!dragStartInfo) return;

    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();

    // Transform the pointer position to account for stage scale and position
    const transform = stage.getAbsoluteTransform().copy();
    transform.invert();
    const transformedPosition = transform.point(pointerPosition);

    const connectionTo = detectConnection(transformedPosition, dragStartInfo.id, steps);

    if (connectionTo !== null) {
      const { id: toId } = connectionTo;
      const startNode = getNodePosition(dragStartInfo.id, dragStartInfo.position);

      // Determine the closest side of the target object
      const sides = ['left', 'top', 'right', 'bottom'];
      const closestSide = sides.reduce((closest, side) => {
        const nodePos = getNodePosition(toId, side);
        const distanceToCurrent = Math.hypot(transformedPosition.x - nodePos.x, transformedPosition.y - nodePos.y);
        const distanceToClosest = Math.hypot(transformedPosition.x - closest.x, transformedPosition.y - closest.y);
        return distanceToCurrent < distanceToClosest ? { side, ...nodePos } : closest;
      }, { side: 'left', ...getNodePosition(toId, 'left') });

      const endNode = getNodePosition(toId, closestSide.side);
      const pathData = createPathData(startNode, endNode, dragStartInfo.position, closestSide.side);

      const newConnection = {
        from: { id: dragStartInfo.id, position: dragStartInfo.position },
        to: { id: toId, position: closestSide.side },
        pathData: pathData,
      };

      setConnections(prevConnections => [...prevConnections, newConnection]);
    }

    // Clean up
    setConnectionPreview(null);
    setDragStartInfo(null);
    setHoverIndicators(null);
    setActiveConnection(null);
  }, [dragStartInfo, steps, getNodePosition, createPathData, detectConnection]);

  const handleStepDrag = useCallback((e, key) => {
    if (activeTool === TOOL_TYPE.SELECT) {
      const position = e.target.position();
      setSteps(prevSteps => ({
        ...prevSteps,
        [key]: {
          ...prevSteps[key],
          ...position
        }
      }));

      // Update connection preview if dragging a connected step
      if (activeConnection && dragStartInfo) {
        const endPosition = detectClosestSide(position, SHAPESIZE);
        const pathData = createPathData(
          { x: dragStartInfo.x, y: dragStartInfo.y },
          { x: position.x, y: position.y },
          dragStartInfo.position,
          endPosition,
        );
        setConnectionPreview(
          <Path
            x={0}
            y={0}
            data={pathData}
            stroke="black"
            strokeWidth={2}
            lineCap='round'
            lineJoin='round'
          />
        );
      }
    }
  }, [activeTool, activeConnection, createPathData, dragStartInfo, detectClosestSide]);

  const borders =
    selectedStep !== null && steps[selectedStep] ? (
      <Border
        id={selectedStep}
        step={steps[selectedStep]}
        onAnchorDragEnd={handleAnchorDragEnd}
        onAnchorDragMove={handleAnchorDragMove}
        onAnchorDragStart={handleAnchorDragStart}
      />
    ) : null;

  const connectionObjs = connections.map((connection, index) => {
    const fromNode = getNodePosition(connection.from.id, connection.from.position);
    const toNode = getNodePosition(connection.to.id, connection.to.position);
    const pathData = createPathData(fromNode, toNode, connection.from.position, connection.to.position);
    return (
      <Group key={`${connection.from.id}-${connection.to.id}-${index}`}>
        {/* The actual connection path */}
        <Path
          data={pathData}
          stroke="orange"
          strokeWidth={5}
          lineCap='round'
          lineJoin='round'
          listening={false}
        />
      </Group>
    );
  });

  const drawBoxes = Object
    .keys(steps)
    .map((key, index) => (
      <ConnectionBox
        activeTool={activeTool}
        key={key}
        onBoxDrag={(e) => handleStepDrag(e, key)}
        onSelection={(e) => handleSelection(e, key)}
        id={key}
        box={steps[key]}
        onDblClick={() => {
          setOpenSideDrawer(true);
          setSelectedStep(key);
        }}
      />
    ));

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Delete' && selectedStep && activeTool === TOOL_TYPE.SELECT) {
      setSteps(prevSteps => {
        const { [selectedStep]: deletedStep, ...remainingSteps } = prevSteps;
        return remainingSteps;
      });
      setConnections(prevConnections =>
        prevConnections.filter(conn =>
          conn.from.id !== selectedStep && conn.to.id !== selectedStep
        )
      );
      setSelectedStep(null);
    }
  }, [selectedStep, activeTool]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return <Layer listening={activeTool === TOOL_TYPE.SELECT}>
    {borders}
    {connectionObjs}
    {drawBoxes}
    {connectionPreview}
    {hoverIndicators}
  </Layer>
}

export default WorkFlowLayer;