import { useLazyQuery } from "@apollo/client";
import { GET_All_SITE_CHANNELS } from "../../graphql/queries";

const extractAllChannels = (data) => data?.siteChannels?.allChannels;

const useAllSiteChannels = ({ onCompleted, onError } = {}) => {

  const [getAllSiteChannelsQuery, { data, error, loading }] = useLazyQuery(
    GET_All_SITE_CHANNELS,
    {
      errorPolicy: "none",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const channels = extractAllChannels(data);
        console?.info("** Completed query: SiteChannels", { channels });
        onCompleted?.(channels);
      },
      onError: (error) => {
        console?.error("** Query error: SiteChannels", { error });
        onError?.(error);
      },
    }
  );

  const getAllSiteChannels = async () => {
    await getAllSiteChannelsQuery();
  };

  return [
    getAllSiteChannels,
    {
      data: extractAllChannels(data),
      error,
      loading,
    }
  ];

};

export default useAllSiteChannels;
