import { useContext } from "react";
import { messagingContext } from "../../context/MessagingContext";

const useMessagingContext = () => {
  const context = useContext(messagingContext);

  if (context === undefined) {
    throw new Error(
      "Hook useMessagingContext is used outside of its Provider."
    );
  }

  return context;
};

export default useMessagingContext;
