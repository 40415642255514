import React from "react";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import DashboardWidget from "../../widgets/ContainerWidget/DashboardWidget";
import DashboardWidgetBody from "../../widgets/ContainerWidget/DashboardWidgetBody";
import JoditTextEditor from "../../common/JoditTextEditor";

const useStyles = makeStyles(theme => ({
  //editor: {
  //  border: `1px solid ${theme.palette.background.contrastGray}`,
  //  padding: 10,
  //  minHeight: 100,
  //  background: theme.palette.background.paper,
  //},
  //editIconWrapper: {
  //  marginLeft: "auto",
  //},
  content: {
    cursor: props => props.cursor,
  },
  //loading: {
  //  marginTop: 50,
  //},
  textEditor: {
    top: 0,
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 1,
  },
  htmlContent: {
    textShadow: props => props.textShadow,
  },
  configure: {
    //height: "70vh",
  },
}));

const createCustomStyles = props => {
  return {
    cursor: props.allowEditing ? "pointer" : "inherit",
    textShadow: `${props.textShadowH}px ${props.textShadowV}px ${props.textShadowBlur}px ${props.textShadowColor}`,
  };
};

const getStyleProps = ({ allowEditing, layout }) => {
  const { textShadowBlur, textShadowColor, textShadowH, textShadowV } =
    layout || {};

  return {
    allowEditing,
    textShadowBlur: textShadowBlur || 0,
    textShadowColor: rgbColorOrDefault(textShadowColor, "transparent"),
    textShadowH: textShadowH || 0,
    textShadowV: textShadowV || 0,
  };
};

const rgbColorOrDefault = (color, defaultColor) =>
  typeof color === "object" && "r" in color
    ? `rgb(${color.r || 0} ${color.g || 0} ${color.b || 0} / ${color.a || 1})`
    : defaultColor;

const TextEditor = ({
  canEditDashboard,
  index,
  isEditing,
  layout,
  onEdit,
  onCancel,
  onUpdate,
  overflowEditor,
  showEditor,
  textContent,
}) => {
  const allowEditing = canEditDashboard && isEditing;
  const styleProps = getStyleProps({ allowEditing, layout });
  const customStyles = createCustomStyles(styleProps);
  const classes = useStyles(customStyles);

  const handleBlur = async newContent => {
    if (allowEditing) {
      onUpdate(index, newContent);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleEditTextContent = () => {
    if (allowEditing) {
      onEdit(index);
    }
  };

  return (
    <DashboardWidget>
      <DashboardWidgetBody>
        <Box
          className={clsx([
            classes.content,
            showEditor && overflowEditor && classes.textEditor,
          ])}
          onClick={
            allowEditing && !showEditor ? handleEditTextContent : undefined
          }
        >
          {showEditor && (
            <JoditTextEditor
              onSave={handleBlur}
              onCancel={handleCancel}
              value={textContent}
            />
          )}

          {!showEditor && (
            <>
              {textContent && (
                <div
                  className={classes.htmlContent}
                  dangerouslySetInnerHTML={{
                    __html: textContent,
                  }}
                />
              )}

              {false && !textContent && (
                <Grid
                  alignItems="center"
                  className={classes.configure}
                  container
                  justifyContent="center"
                  onClick={allowEditing ? handleEditTextContent : undefined}
                >
                  <i className="fas fa-cogs fa-5x" />
                </Grid>
              )}
            </>
          )}
        </Box>
      </DashboardWidgetBody>
    </DashboardWidget>
  );
};

export default TextEditor;
