import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FileUploadSelector from "./FileUploadSelector";

const useStyles = makeStyles(theme => ({
  dzWrap: {
    "& .dropzone": {
      background: "transparent",
      padding: 0,
    },
    "& .dz-message": {
      background: "inherit",
      margin: "0 !important",
      position: "relative",
      minHeight: "127px",
    },
  },
  dzWrapNoChildren: ({ disabled }) => ({
    width: "100%",
    "& .dropzone": {
      backgroundColor: "inherit",
      height: "100%",
      borderStyle: disabled ? "solid" : undefined,
      opacity: disabled ? 0.5 : undefined,
      padding: 0,
      "&:hover": {
        borderStyle: !disabled ? "solid" : undefined,
        "& .dz-message": {
          opacity: !disabled ? 0.5 : undefined,
          position: "relative",
          minHeight: "127px",
        }
      },
    },
    "& .dz-message": {
      background: "inherit",
      color: theme.palette.component.productBoxColor,
      fontFamily: '"Montserrat", "Helvetica Neue", "Arial", sans-serif',
      textAlign: "left",

      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",

      margin: 0,
      padding: "8px",

      position: "relative",
      minHeight: "127px",

      width: "100%",
      height: "100%",

      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
  }),
}));

const FileUpload = ({ buttonBar, children, clickable, disabled, message, onAcceptedFile, onRejectedFile }) => {
  const classes = useStyles({ disabled });

  const handleAcceptedFile = async (file) => {
    await onAcceptedFile?.(file);
  };

  const handleRejectedFile = async (file) => {
    await onRejectedFile?.(file);
  };

  const haveChildren = !!children;

  return (
    <Box className={haveChildren ? classes.dzWrap : classes.dzWrapNoChildren}>
      {buttonBar}
      <FileUploadSelector
        disabled={disabled}
        clickable={clickable}
        onAcceptedFile={handleAcceptedFile}
        onRejectedFile={handleRejectedFile}
      >
        <Box className="dz-message">
          {haveChildren && children}
          {!haveChildren && message}
        </Box>
      </FileUploadSelector>
    </Box>
  );
};

export default FileUpload;
