import { gql } from '@apollo/client';

const Poll = gql`
fragment PollFragment on Poll {
	allowNewChoices
	votingOptions
	choices {
		author {
			avatarUrl
			name
			username
		}
		id
		numVotes
		percentage
		text
	}
	hasVoted
	id
	numVotes
}`;

export default Poll;