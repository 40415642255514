import { useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  FILE_TYPE as FileType,
  PAGE_MODE_TYPE as PageModeType,
} from "../utils/types";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const FileViewer = ({
  file,
  fileType,
  handleImageLoad,
  handlePageRenderSuccess,
  handlePdfLoadSuccess,
  imageDimensions,
  imgRef,
  numPages,
  pageMode,
  pdfDimensions,
  pdfPageNumber,
  onPageLoad,
  viewportTransform,
}) => {

  const rightCanvasRef = useRef(null);
  const leftCanvasRef = useRef(null);

  const handleRightPageRenderSuccess = () => {

    if (rightCanvasRef.current) {
      const canvas = rightCanvasRef.current.querySelector('canvas');
      if (canvas) {
        canvas.style.border = '1px solid black';

        renderTrioHoles(canvas, true, viewportTransform.scale);
      }
    }

  };

  const handlePageLoad = (page, isLeftPage) => {
    if (isLeftPage) {
      onPageLoad(page.width, page.height);
    }
  }

  const handleLeftPageRenderSuccess = () => {

    if (leftCanvasRef.current) {
      const canvas = leftCanvasRef.current.querySelector('canvas');
      if (canvas) {

        canvas.style.border = '1px solid black';

        if (pageMode === PageModeType.SINGLE) {
          renderTrioHoles(canvas, pdfPageNumber % 2 == 1, viewportTransform.scale);
        }
        else {
          renderTrioHoles(canvas, false, viewportTransform.scale);
        }
      }
    }

  };

  const renderTrioHoles = (canvas, right, scale) => {

    function mm2px(mm) {
      var scale_mm2px = scale * 612 / 215.9;
      return scale_mm2px * mm;
    }

    function drawHole(ctx, x, y, radius) {
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();
    }

    var ctx = canvas.getContext("2d");
    var width = canvas.width;

    var radius = mm2px(3);
    var height = mm2px(279.4);
    var dx = mm2px(11.5);
    var dy = (canvas.height - height) / 2;
    var x = right ? dx : width - dx;

    var y1 = (92 / 297) * height + dy;
    var y2 = (113 / 297) * height + dy;
    var y3 = (184 / 297) * height + dy;
    var y4 = (205 / 297) * height + dy;

    ctx.save();

    ctx.strokeStyle = "black";
    ctx.fillStyle = "white";

    drawHole(ctx, x, y1, radius);
    drawHole(ctx, x, y2, radius);
    drawHole(ctx, x, y3, radius);
    drawHole(ctx, x, y4, radius);

    ctx.restore();
  }

  console.log(fileType);

  if (fileType === FileType.PDF) {

    let currentPage = pdfPageNumber;
    if (pageMode == PageModeType.DOUBLE && pdfPageNumber % 2 == 1) {
      currentPage = pdfPageNumber - 1;
    }

    return (
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        transform: `translate(${viewportTransform.x}px, ${viewportTransform.y}px)`,
        transformOrigin: 'top left',
      }}>
        <Document
          file={file}
          onLoadSuccess={handlePdfLoadSuccess}
        >
          {pageMode === PageModeType.SINGLE && (
            <Page
              inputRef={leftCanvasRef}
              pageNumber={currentPage}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              onRenderSuccess={handleLeftPageRenderSuccess}
              scale={viewportTransform.scale}
              onLoadSuccess={(page) => handlePageLoad(page, true)}
            />
          )}
          {pageMode === PageModeType.DOUBLE && (
            <div style={{ display: 'flex' }}>
              {currentPage > 0 && (
                <Page
                  inputRef={leftCanvasRef}
                  pageNumber={currentPage}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  onRenderSuccess={handleLeftPageRenderSuccess}
                  scale={viewportTransform.scale}
                  onLoadSuccess={(page) => handlePageLoad(page, true)}
                />
              )}
              {currentPage + 1 <= numPages && (
                <Page
                  inputRef={rightCanvasRef}
                  pageNumber={currentPage + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  onRenderSuccess={handleRightPageRenderSuccess}
                  scale={viewportTransform.scale}
                  onLoadSuccess={(page) => handlePageLoad(page, false)}
                />
              )}
            </div>
          )}
        </Document>
      </div>
    );

  } else if (fileType === FileType.IMAGE) {

    let currentPage = pdfPageNumber || 1;

    if (pageMode === PageModeType.DOUBLE && currentPage % 2 === 1) {
      currentPage = pdfPageNumber - 1;
    }

    const isDoubleMode = pageMode === PageModeType.DOUBLE;
    const containerWidth = isDoubleMode ? imageDimensions.width * 2 : imageDimensions.width;
    const containerHeight = imageDimensions.height;

    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          transform: `translate(${viewportTransform.x}px, ${viewportTransform.y}px)`,
          transformOrigin: 'top left',
          display: 'flex',
          flexDirection: isDoubleMode ? 'row' : 'column',
          width: `${containerWidth}px`,
          height: `${containerHeight}px`,
        }}
      >
        {/* Render the left image (or single image in SINGLE mode) */}
        {currentPage > 0 && (
          <img
            ref={imgRef}
            src={`${file}&pageno=${currentPage}`}
            alt={`Page ${currentPage}`}
            style={{
              width: isDoubleMode ? '50%' : '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            onLoad={handleImageLoad}
          />
        )}
        {/* Conditionally render the right image for DOUBLE mode */}
        {isDoubleMode && currentPage + 1 <= numPages && (
          <img
            src={`${file}&pageno=${currentPage + 1}`}
            alt={`Page ${currentPage + 1}`}
            style={{
              width: '50%',
              height: '100%',
              objectFit: 'contain',
            }}
            onLoad={handleImageLoad}
          />
        )}
      </div>
    );

  }


  return <p>Apa</p>;
};

export default FileViewer;
