import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Avatar,
  Badge,
  Box,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  OutlinedInput,
  Typography,
  Select,
  MenuItem,
  Rating,
} from '@mui/material';

const Comment = ({ comment, index, currentPage }) => {

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };


  return (
    <Badge
      key={comment.id}
      badgeContent={`${currentPage}.${comment.versionCommentNumber}`}
      sx={{
        width: '100%',
        "& .MuiBadge-badge": {
          color: "white",
          backgroundColor: "#356897"
        }
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ListItem
        sx={{
          bgcolor: index % 2 === 0 ? '#ffffff' : '#6398c814',
          borderRadius: '8px',
          border: "1px solid #dedede",
          mb: 2,
          p: 2,
        }}
      >
        <ListItemAvatar sx={{ minWidth: 56 }}>
          <Avatar
            src={comment.avatarUrl}
            sx={{
              width: 48,
              height: 48,
              backgroundColor: index % 2 === 0 ? "#a8c3e0" : "#6398c8",
              color: index % 2 === 0 ? "#333333" : "#ffffff"
            }}
          >
            {getInitials(comment.author)}
          </Avatar>
        </ListItemAvatar>
        <Box sx={{ ml: 2, width: '100%' }}>
          <Box display="flex" alignItems="center" mb={0.5}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 600,
                color: index % 2 === 0 ? '#222222' : '#222222',
                mr: 1
              }}
            >
              {comment.author}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontWeight: 500,
                color: index % 2 === 0 ? 'rgba(51, 51, 51, 0.98)' : 'rgba(51, 51, 51, 0.98)'
              }}
            >
              {new Date(comment.timestamp).toLocaleString()}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{ color: index % 2 === 0 ? '#111111' : '#111111' }}
          >
            {comment.comment}
          </Typography>
        </Box>
      </ListItem>
    </Badge>
  );
}

export default Comment;