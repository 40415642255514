import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setChannels as actionSetChannels,
  setConversations as actionSetConversations,
} from '../../actions';
import {
  getChannels as channelsSelector,
  getConversations as conversationsSelector,
} from "../../helpers/messagingSelectors";
import {
  useAllConversations,
  useAllSiteChannels,
  useConversationData,
  useConversationUpdateSubscription,
  useDirectMessageReadConfirmation,
  useMessageEventsSubscription,
  useSiteChannelData,
  useSiteChannelEventsSubscription,
  useSiteChannelMessageReadConfirmation,
  useUnreadDirectMessagesCounter,
  useUnreadSiteChannelMessagesCounter,
} from "../../hooks/Messaging";
import { AuthContext } from "../../providers/authProvider";

export const messagingContext = createContext();

const { Provider } = messagingContext;

const _initialState = {
  haveChannels: false,
  haveConversations: false,
  unreadChannelMessagesCount: 0,
  unreadConversationMessagesCount: 0,
  unreadMessagesCount: 0,
};

const MessagingProvider = ({ children }) => {

  const { isAuthenticated } = useContext(AuthContext);

  const dispatch = useDispatch();

  const unreadDirectMessagesCounter = useUnreadDirectMessagesCounter();
  const unreadSiteChannelMessagesCounter = useUnreadSiteChannelMessagesCounter();

  const channels = useSelector(channelsSelector);
  const conversations = useSelector(conversationsSelector);

  const [activeChannel, setActiveChannel] = useState(null);
  const [activeChannelId, setActiveChannelId] = useState(null);

  const [activeConversation, setActiveConversation] = useState(null);
  const [activeConversationId, setActiveConversationId] = useState(null);

  const [confirmDirectMessageRead] = useDirectMessageReadConfirmation();
  const [confirmSiteChannelMessageRead] = useSiteChannelMessageReadConfirmation();

  const [state, setState] = useState(_initialState);

  const dingSound = new Audio('/ding.mp3');

  const playDing = () => {
    dingSound.play();
  };

  const getUnreadMessageCounts = useCallback(() => {
    const unreadChannelMessagesCount = (channels || []).reduce((sum, channel) => sum + channel.unreadMessageCount, 0);
    const unreadConversationMessagesCount = (conversations || []).reduce((sum, conversation) => sum + conversation.unreadMessageCount, 0);
    const unreadMessagesCount = unreadChannelMessagesCount + unreadConversationMessagesCount;

    return {
      unreadChannelMessagesCount,
      unreadConversationMessagesCount,
      unreadMessagesCount,
    };
  }, [channels, conversations]);

  const { loading: messageEventsSubscriptionLoading } = useMessageEventsSubscription({
    onDirectMessage: (directMessage) => {
      updateConversation(directMessage);
    },
    onError: (error) => {
      // TODO
    },
    onSiteChannelMessage: (siteChannelMessage) => {
      updateSiteChannel(siteChannelMessage);
    },
  });

  const { loading: loadingMessageEventsSubscription } = useConversationUpdateSubscription({
    onUpdate: (conversation) => {
      addConversation(conversation);
    },
  });

  const { loading: loadingSiteChannelEventsSubscription } = useSiteChannelEventsSubscription({
    onUpdate: (event) => {
      getSiteChannels();  // TODO: Add channel instead of reloading.
    },
  });

  const [getConversations, { loading: conversationsLoading }] = useAllConversations({
    onCompleted: (conversations) => {
      dispatch(actionSetConversations({ items: conversations }));
      setState(prev => {
        return ({
          ...prev,
          haveConversations: true,
        });
      });
    },
    onError: (error) => {
      // TODO
    }
  });

  const [getSiteChannels, { loading: channelsLoading }] = useAllSiteChannels({
    onCompleted: (channels) => {
      dispatch(actionSetChannels({ items: channels }));
      setState(prev => {
        return ({
          ...prev,
          haveChannels: true,
        });
      });
    },
    onError: (error) => {
      // TODO
    }
  });

  const [getConversationData, { loading: activeConversationLoading }] = useConversationData({
    onCompleted: (conversationData) => {
      const { directMessageConversationId: conversationId } = conversationData;

      unreadDirectMessagesCounter.reset(conversationId);

      var index = conversations.findIndex(n => n.directMessageConversationId === conversationId);
      if (index < 0) {
        console?.error("** Conversation not found", { conversationId });
      }
      else {
        var items = [...conversations];
        items[index].messages = conversationData.messages;
        dispatch(actionSetConversations({ items }));
      }

      setActiveConversation(conversationData);
    },
    onError: (error) => {
      setActiveConversation(null);
      // TODO
    },
  });

  const [getSiteChannelData, { loading: activeChannelLoading }] = useSiteChannelData({
    onCompleted: (channelData) => {
      const { siteChannelId } = channelData;

      unreadSiteChannelMessagesCounter.reset(siteChannelId);

      var index = channels.findIndex(n => n.siteChannelId === siteChannelId);
      if (index < 0) {
        console?.error("** Site channel not found", { siteChannelId });
      }
      else {
        var items = [...channels];
        items[index].channelMessages = channelData.channelMessages;
        dispatch(actionSetChannels({ items }));
      }

      setActiveChannel(channelData);
    },
    onError: (error) => {
      setActiveChannel(null);
      // TODO
    },
  });

  const addConversation = conversation => {
    const { directMessageConversationId: conversationId } = conversation;
    if (!conversations) {
      console?.info("** Conversations not loaded yet. No need to add this conversation.", { conversationId });
      return false;
    }
    const index = conversations.findIndex(
      n => n.directMessageConversationId === conversationId
    );
    const isNew = (index === -1);
    if (isNew) {
      var tempConversations = [...conversations];
      tempConversations.splice(0, 0, conversation);
      dispatch(actionSetConversations({ items: tempConversations }));
    }
    return isNew;
  };

  // TODO: Method for adding a channel (see "addConversation").

  const selectChannel = async (channelId) => {
    setActiveChannelId(channelId);
    setActiveConversationId(null);
  };

  const selectConversation = async (conversationId) => {
    setActiveChannelId(null);
    setActiveConversationId(conversationId);
  };

  const updateConversation = async (directMessage) => {
    const { conversationId } = directMessage;
    if (!conversationId) {
      console?.info("** invalid message", { directMessage });
      return;
    }

    const index = conversations.findIndex(n => n.directMessageConversationId === conversationId);
    if (index === -1) {
      console?.info("** conversation not found", { conversationId });
      return;
    }

    const isActiveConversation = (conversationId === activeConversationId);

    const conversation = conversations[index];

    if (!!conversation?.messages?.items) {
      const messageExists = !!conversation.messages.items.find(x => x.id === directMessage.id);
      if (messageExists) {
        console?.info("** message already exists", { directMessage });
        return;
      }
    }

    var tempConversation = { ...conversation };
    if (isActiveConversation) {
      tempConversation.unreadMessageCount = 0;
    }
    else {
      tempConversation.unreadMessageCount++;
    }
    const existingMessageItems = tempConversation?.messages?.items;
    if (!!existingMessageItems) {
      tempConversation.messages.items = [...existingMessageItems, directMessage];
    }

    tempConversation.lastMessage = { ...directMessage };

    var tempConversations = [...conversations];
    tempConversations.splice(index, 1, { ...tempConversation });

    dispatch(actionSetConversations({ items: tempConversations }));

    if (isActiveConversation) {
      await confirmDirectMessageRead(directMessage.id);
    } else {
      playDing();
    }
  };

  const updateSiteChannel = async (siteChannelMessage) => {
    const { siteChannelId } = siteChannelMessage;
    if (!siteChannelId) {
      console?.info("** invalid message", { siteChannelMessage });
      return;
    }

    const index = channels.findIndex(n => n.siteChannelId === siteChannelId);
    if (index === -1) {
      console?.info("** channel not found", { siteChannelId });
      return;
    }

    const isActiveChannel = (siteChannelId === activeChannelId);

    const channel = channels[index];

    if (!!channel?.channelMessages?.items) {
      const messageExists = !!channel.channelMessages.items.find(x => x.id === siteChannelMessage.id);
      if (messageExists) {
        console?.info("** message already exists", { siteChannelMessage });
        return;
      }
    }

    var tempChannel = { ...channel };
    if (isActiveChannel) {
      tempChannel.unreadMessageCount = 0;
    }
    else {
      tempChannel.unreadMessageCount++;
    }
    const existingMessageItems = tempChannel?.channelMessages?.items;
    if (!!existingMessageItems) {
      tempChannel.channelMessages.items = [...existingMessageItems, siteChannelMessage];
    }

    tempChannel.lastMessage = { ...siteChannelMessage };

    var tempChannels = [...channels];
    tempChannels.splice(index, 1, { ...tempChannel });

    dispatch(actionSetChannels({ items: tempChannels }));

    if (isActiveChannel) {
      await confirmSiteChannelMessageRead(siteChannelMessage.id);
    }
  };

  useEffect(() => {
    const channelId = activeChannelId;

    if (!channelId) {
      setActiveChannel(null);
      return;
    }

    var channelIndex = channels.findIndex(n => n.siteChannelId === channelId);
    if (channelIndex < 0) {
      setActiveChannel(null);
      return;
    }

    const { channelMessages } = channels[channelIndex];
    const cachedItems = channelMessages?.items;

    if (Array.isArray(cachedItems)) {
      console?.info("** using cached site channel messages", { channelId });
      setActiveChannel(channels[channelIndex]);
    }
    else {
      console?.info("** fetching site channel messages", { channelId });
      getSiteChannelData(channelId);
    }
  }, [activeChannelId]);

  useEffect(() => {
    const conversationId = activeConversationId;

    if (!conversationId) {
      setActiveConversation(null);
      return;
    }

    var conversationIndex = conversations.findIndex(n => n.directMessageConversationId === conversationId);
    if (conversationIndex < 0) {
      setActiveConversation(null);
      return;
    }

    const { messages } = conversations[conversationIndex];
    const cachedItems = messages?.items;

    if (Array.isArray(cachedItems)) {
      console?.info("** using cached direct messages", { conversationId });
      setActiveConversation(conversations[conversationIndex]);
    }
    else {
      console?.info("** fetching direct messages", { conversationId });
      getConversationData(conversationId);
    }
  }, [activeConversationId]);

  useEffect(() => {
    if (!!activeChannelId) {
      var index = channels.findIndex(n => n.siteChannelId === activeChannelId);
      if (index < 0) {
        console?.info("** Site channel not found", { activeChannelId });
        setActiveChannelId(null);
      }
      else {
        console?.info("** refreshing channel data", { activeChannelId });
        setActiveChannel({ ...channels[index] });
      }
    }

    if (!!activeConversationId) {
      var index = conversations.findIndex(n => n.directMessageConversationId === activeConversationId);
      if (index < 0) {
        console?.info("** Conversation not found", { activeConversationId });
        setActiveConversationId(null);
      }
      else {
        console?.info("** refreshing conversation data", { activeConversationId });
        setActiveConversation({ ...conversations[index] });
      }
    }

    setState(prev => {
      const unreadMessagesCounts = getUnreadMessageCounts();
      return ({
        ...prev,
        ...unreadMessagesCounts,
      });
    });
  }, [channels, conversations]);

  useEffect(() => {
    if (activeChannel?.unreadMessageCount) {
      unreadSiteChannelMessagesCounter.reset(activeChannel.siteChannelId);
    }
  }, [activeChannel]);

  useEffect(() => {
    if (activeConversation?.unreadMessageCount) {
      unreadDirectMessagesCounter.reset(activeConversation.directMessageConversationId);
    }
  }, [activeConversation]);

  useEffect(() => {
    if (isAuthenticated()) {
      getConversations();
      getSiteChannels();
    }
  }, []);

  return (
    <Provider value={{
      ...state,
      activeChannel,
      activeChannelId,
      activeChannelLoading,
      activeConversation,
      activeConversationId,
      activeConversationLoading,
      addConversation,
      channelsLoading,
      conversationsLoading,
      selectChannel,
      selectConversation,
    }}>
      {children}
    </Provider>
  );
};

export default MessagingProvider;
