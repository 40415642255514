import { useMemo } from "react";
import { MenuItem, Select, Typography } from "@mui/material";
import useGetWorkflow from "../../utils/useGetWorkflow.js";

const WorkflowSelector = ({
  onSelect,
  selectedWorkflowId,
  size,
  sx,
}) => {
  const osWorkflow = useGetWorkflow();

  const workflows = useMemo(() => {
    return osWorkflow.listWorkflows();
  }, []);

  return (
    <>
      <Typography
        sx={{
          fontSize: "small",
          ml: 3,
        }}
      >
        Arbetsflöde
      </Typography>
      <Select
        onChange={
          (e) => {
            const { value } = e?.target;
            onSelect?.(value);
          }
        }
        size={size}
        sx={{ ...sx }}
        value={selectedWorkflowId}
      >
        {workflows
          .map(
            workflow =>
              <MenuItem
                key={workflow.id}
                value={workflow.id}
              >
                {workflow.name}
              </MenuItem>
          )
        }
      </Select>
    </>
  );
};

export default WorkflowSelector;
