import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  swatch: {
    position: "relative",
    cursor: "pointer",
    padding: 3,
    height: props => props.height,
    width: props => props.width,
    border: "1px solid #bebebe",
    borderRadius: 3,
    display: "inline-block",
  },
  swatchColor: {
    display: "flex",
    width: "100%",
    height: "100%",
    borderRadius: 3,
    background: props => props.backgroundColor,
  },
  colorPicker: {
    position: "absolute",
    right: -221,
    top: -129,
    zIndex: 20000,
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}));

const createStylesProps = ({ color, size }) => ({
  backgroundColor: rgbColorOrDefault(color, color),
  height: size?.height || 15,
  width: size?.width || 15,
});


const rgbColorOrDefault = (color, defaultColor) =>
  typeof color === "object" && "r" in color
    ? `rgb(${color.r || 0} ${color.g || 0} ${color.b || 0} / ${color.a || 1})`
    : defaultColor;

const ColorPicker = ({ disabled, onChange, size, value }) => {
  const stylesProps = createStylesProps({ color: value, size });

  const classes = useStyles(stylesProps);

  const [internalValue, setInternalValue] = useState(value);
  const [open, setOpen] = useState(false);

  const changeColor = (colors, event) => {
    setInternalValue(colors);
  }

  const onClickAway = () => {
    setOpen(false);
    onChange?.(internalValue);
  }

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box onClick={() => !disabled && setOpen(true)} className={classes.swatch} sx={size}>
        <span className={classes.swatchColor} />
      </Box>
      {open && (
        <Box className={classes.popover}>
          <Box className={classes.cover} onClick={onClickAway} />
          <SketchPicker color={internalValue} onChange={changeColor} />
        </Box>
      )}
    </Box>
  );
};

export default ColorPicker;
