import PropTypes from "prop-types";

import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { useQuery } from "@apollo/client";
import { AppBar, Link, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";

import ContactsPopup from "../../Messaging/ContactsPopup";

import {
  cartInitialize,
  expandItem,
  imageBasketInitialize,
  setProductView,
  toggleCart,
  toggleDetails,
} from "../../../actions";

import {
  getCompanyLogo,
  getSelectedSiteOrderGroupId,
  getThemeName,
} from "../../../helpers/selectors";

import { getCart } from "../../../graphql/queries/getCart";
import { getImageBasket } from "../../../graphql/queries/getImageBasket";
import getProductView from "../../../graphql/queries/productView";
import { useMessagingContext } from "../../../hooks/Messaging";

import { Cart } from "../../Cart";
import { HeaderSearch, WorkspaceNew } from "../../common";
import SiteEventsWatcher from "../../SiteEventsWatcher";
import UserDetails from "../../UserDetails";

import NavbarMobileDrawer from "../NavBar/NavbarDrawer";
import MobileMenu from "../NavBar/NavbarMobile";

import PageRoute from "../../../constant/pageRouteDictionary.json";

import {
  ImageBasketNotifyIcon,
  MessageNotifyIcon,
  ShoppingCartNotifyIcon,
} from "./NotifyIcons";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    boxShadow: "unset",
    minHeight: 60,
  },
  header: {
    backgroundColor: theme.palette.component.headerBackground,
    color: theme.palette.component.headerColor,
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
    },
    minHeight: 60,
    width: "100%",
    zIndex: 1100
  },
  toolbar: {
    minHeight: 53,
    padding: "3px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 10px",
      flexWrap: "wrap",
      minHeight: 40,
    },
  },
  navbrandWrap: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  navbrand: {
    objectFit: "contain",
    maxWidth: 160,
    maxHeight: 58,
    borderRadius: 0,
    marginRight: "1em",
    [theme.breakpoints.down("sm")]: {
      width: 100,
      backgroundSize: "contain",
      marginRight: 8,
    },
  },
  notification: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  hideProfileOnMobile: {
    [theme.breakpoints.down("md")]: {
      // marginLeft: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Header = ({
  api,
  companyLogo,
  cartInitialize,
  cartItems,
  fileGroups,
  history,
  imageBasketInitialize,
  imageBasketItems,
  oneStreamGroups,
  orderGroupId,
  selectedWorkspace,
  setProductView,
  structure,
  toggleCart,
  toggleDetails,
  ui,
  workspaceName,
}) => {
  const classes = useStyles();

  const { unreadMessagesCount } = useMessagingContext();

  const company = useSelector(
    state => state.api.currentViewer.viewer.defaultTheme
  );

  const dispatch = useDispatch();

  const { data: imageBasketData, refetch: refetchImageBasket } = useQuery(
    getImageBasket,
    {
      variables: { orderGroupId: 0 },
    }
  );

  const { data, refetch: refetchGetCart } = useQuery(getCart, {
    skip: !selectedWorkspace.id,
  });
  const { data: productViewData, loading } = useQuery(getProductView, {
    variables: {
      siteId: selectedWorkspace.id,
    },
    skip: !selectedWorkspace.id,
  });

  const handleDeleteItem = async ({ index }) => {
    const cartItemsTemp = [...cartItems];
    cartItemsTemp.splice(index, 1);
    // const cartItemsToSave = cartItemsTemp.map(item => ({
    //   productId: item.productId,
    //   quantity: item.quantity,
    // }));

    cartInitialize({
      items: cartItemsTemp,
    });
  };

  useEffect(() => {
    if (productViewData && !loading) {
      setProductView(productViewData);
    }
  }, [productViewData]);

  /* 
      When cartItems changes (specifically targeting the product quantity in checkout),
      will refetch the entire cart items to get the updated the price.
      TODO: Future work, will remove this use effect function as Backend will return the updated price
      or entire updated product item when quantity changes. 
   */
  // TODO: This keeps on looping
  // useEffect(() => {
  //   if (cartItems && selectedWorkspace && selectedWorkspace.id) {
  //     refetchGetCart();
  //   }
  // }, [cartItems]);

  useEffect(() => {
    if (
      imageBasketData &&
      imageBasketData.imageBank &&
      imageBasketData.imageBank.items.length > 0
    ) {
      const { items } = imageBasketData.imageBank;
      imageBasketInitialize({ items });
    }
  }, [imageBasketData]);

  useEffect(() => {
    if (data && data.shoppingCartContent) {
      const { groups = [] } = data.shoppingCartContent || {};
      const orderGroup = groups.find(x => x.orderGroupId === orderGroupId);
      const { items = [], oneStreamGroups = [], fileGroups = [] } =
        orderGroup || {};

      cartInitialize({
        items,
        fileGroups,
        oneStreamGroups,
      });
    }
  }, [data, company]);

  const shoppingCartItemCount = selectedWorkspace.id ? (cartItems.length + fileGroups.length + oneStreamGroups.length) : 0;

  return (
    <div className={classes.root}>
      <AppBar position="relative" elevation={0} className={classes.header}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.sectionMobile}>
            <NavbarMobileDrawer />
          </div>
          <Link href="/" className={classes.navbrandWrap}>
            <img src={companyLogo} alt="Logo" className={classes.navbrand} />
          </Link>
          <WorkspaceNew
            workspaceName={workspaceName}
            workspaces={structure}
          />
          <HeaderSearch
            toggleDetails={toggleDetails}
            searchDetails={ui.toggleDetails}
          />

          <div className={classes.notification}>
            <ContactsPopup>
              <MessageNotifyIcon counter={unreadMessagesCount} />
            </ContactsPopup>

            <ImageBasketNotifyIcon
              counter={imageBasketItems.length}
              onClick={() => history.push(PageRoute.IMAGE_BASKET)}
            />
            <ShoppingCartNotifyIcon
              counter={shoppingCartItemCount}
              onClick={() => toggleCart({ name: "Cart" })}
            />
          </div>

          {ui.toggleCart.open && (
            <Cart
              miniBasketIsOpen={ui.toggleCart.open}
              toggleCart={toggleCart}
              handleDeleteItem={handleDeleteItem}
              cartItems={cartItems}
              fileGroups={fileGroups}
              oneStreamGroups={oneStreamGroups}
              selectedWorkspace={selectedWorkspace}
              history={history}
            />
          )}

          <div className={classes.hideProfileOnMobile}>
            <UserDetails viewer={api.currentViewer.viewer} />
          </div>
          <MobileMenu
            totalCartItems={cartItems.length}
            totalImageItems={imageBasketItems.length}
            unreadMessagesCount={unreadMessagesCount}
          />
        </Toolbar>
        <SiteEventsWatcher />
      </AppBar>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    api: state.api,
    cartItems: state.api.cart.cartItems,
    companyLogo: getCompanyLogo(state),
    fileGroups: state.api.cart.fileGroups,
    imageBasketItems: state.api.imageBasket.imageBasketItems,
    oneStreamGroups: state.api.cart.oneStreamGroups,
    orderGroupId: getSelectedSiteOrderGroupId(state),
    selectedWorkspace: state.ui.toggleWorkspaces.workspace,
    structure:
      state.api.currentViewer &&
        state.api.currentViewer.viewer &&
        state.api.currentViewer.viewer.structure &&
        state.api.currentViewer.viewer.structure.items &&
        state.api.currentViewer.viewer.structure.items.length > 0
        ? state.api.currentViewer.viewer.structure.items
        : [],
    themeName: getThemeName(state),
    ui: state.ui,
    workspaceName: state.ui.toggleWorkspaces.workspace.name,
    workspaces:
      state.api.currentViewer &&
        state.api.currentViewer.viewer &&
        state.api.currentViewer.viewer.sites
        ? state.api.currentViewer.viewer.sites
        : [],
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      cartInitialize,
      expandItem,
      imageBasketInitialize,
      setProductView,
      toggleCart,
      toggleDetails,
    },
    dispatch
  );
};

Header.defaultProps = {
  fileGroups: [],
  history: {
    push: () => {
      console.log("History is null");
    },
  },
  oneStreamGroups: [],
  orderGroupId: null,
  selectedWorkspace: {
    id: null,
    name: null,
  },
  workspaceName: null,
};

Header.propTypes = {
  api: PropTypes.shape({
    currentViewer: PropTypes.shape({
      viewer: PropTypes.shape({
        avatar: PropTypes.string,
        email: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
      }),
    }),
  }).isRequired,
  cartInitialize: PropTypes.func.isRequired,
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
      quantity: PropTypes.number,
    })
  ).isRequired,
  companyLogo: PropTypes.string.isRequired,
  expandItem: PropTypes.func.isRequired,
  fileGroups: PropTypes.arrayOf(
    PropTypes.shape({
      folderId: PropTypes.number,
      items: PropTypes.arrayOf(PropTypes.shape({})),
      packageId: PropTypes.string,
      packageName: PropTypes.string,
    })
  ),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  imageBasketInitialize: PropTypes.func.isRequired,
  imageBasketItems: PropTypes.arrayOf(
    PropTypes.shape({
      imageName: PropTypes.string,
      itemId: PropTypes.number,
      productId: PropTypes.string,
    })
  ).isRequired,
  oneStreamGroups: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})),
      packageId: PropTypes.string,
      packageName: PropTypes.string,
    })
  ),
  orderGroupId: PropTypes.number,
  selectedWorkspace: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  setProductView: PropTypes.func.isRequired,
  toggleCart: PropTypes.func.isRequired,
  toggleDetails: PropTypes.func.isRequired,
  ui: PropTypes.shape({
    toggleCart: PropTypes.shape({
      open: PropTypes.bool.isRequired,
    }).isRequired,
    toggleDetails: PropTypes.shape({
      showDetails: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  workspaceName: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
