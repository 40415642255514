import { STEP_TYPE } from "./types";

export const SHAPESIZE = 60;

const WIDTH = SHAPESIZE;
const HEIGHT = SHAPESIZE;

export const NODEOFFSET = 10;

export const INITIAL_STATE = {
  connections: [
    { from: { id: "upload", position: "right" }, to: { id: "step1", position: "left" } },
    { from: { id: "step1", position: "right" }, to: { id: "step2", position: "left" } },
    { from: { id: "step2", position: "right" }, to: { id: "step3", position: "left" } },
    //{ from: { id: "step3", position: "right" }, to: { id: "step4", position: "left" } },

    { from: { id: "step3", position: "right" }, to: { id: "step4_1", position: "left" } },
    { from: { id: "step4_1", position: "bottom" }, to: { id: "step4_2", position: "top" } },
    { from: { id: "step4_2", position: "bottom" }, to: { id: "step4_3", position: "top" } },
    { from: { id: "step4_1", position: "right" }, to: { id: "step5", position: "left" } },

    //{ from: { id: "step4", position: "right" }, to: { id: "step5", position: "left" } },

    { from: { id: "step5", position: "right" }, to: { id: "step6", position: "left" } },
    { from: { id: "step6", position: "right" }, to: { id: "step7", position: "left" } },
    { from: { id: "step2", position: "bottom" }, to: { id: "error", position: "top" } },
  ],
  steps: {
    upload: {
      id: "upload",
      x: 50,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#ecdc02",
      name: "Filuppladdning",
      type: STEP_TYPE.FOLDER_UPLOAD,
    },
    step1: {
      id: "step1",
      x: 200,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#990",
      name: "XML-inläsning",
      type: STEP_TYPE.XML_LOADING,
    },
    step2: {
      id: "step2",
      x: 350,
      y: 70,
      width: WIDTH,
      height: HEIGHT,
      colour: "#f57f20",
      name: "Preflight",
      type: STEP_TYPE.PREFLIGHT,
    },
    step3: {
      id: "step3",
      x: 500,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#00aeef",
      name: "Pärmuppdelning",
      type: STEP_TYPE.CONTAINER,
    },
    step4_1: {
      id: "step4_1",
      x: 700,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#28f",
      name: "Mall 1",
      type: STEP_TYPE.TEMPLATES,
    },
    step4_2: {
      id: "step4_2",
      x: 700,
      y: 175,
      width: WIDTH,
      height: HEIGHT,
      colour: "#28f",
      name: "Mall 2",
      type: STEP_TYPE.TEMPLATES,
    },
    step4_3: {
      id: "step4_3",
      x: 700,
      y: 350,
      width: WIDTH,
      height: HEIGHT,
      colour: "#28f",
      name: "Mall 3",
      type: STEP_TYPE.TEMPLATES,
    },
    step5: {
      id: "step5",
      x: 840,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#00aeef",
      name: "Pdf merge",
      type: STEP_TYPE.CONTAINER,
    },
    step6: {
      id: "step6",
      x: 1000,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#93ca30",
      name: "Jdf Zip",
      type: STEP_TYPE.FOLDER_ZIP,
    },
    step7: {
      id: "step7",
      x: 1120,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#93ca30",
      name: "Utskrift",
      type: STEP_TYPE.PRINT,
    },
    error: {
      id: "error",
      x: 380,
      y: 220,
      width: WIDTH,
      height: HEIGHT,
      colour: "#f05",
      name: "Preflightfel",
      type: STEP_TYPE.CONTAINER,
      notifications: ["Fel i pdf: E1B16455443.pdf"]
    },
  }
};

export const STEP_TEMPLATES = {
  [STEP_TYPE.CONTAINER]: {
    type: STEP_TYPE.CONTAINER,
    name: "Container",
    colour: "#ccc",
    width: WIDTH,
    height: HEIGHT,
  },
  [STEP_TYPE.FOLDER]: {
    type: STEP_TYPE.FOLDER,
    name: "Mapp",
    colour: "#ecdc02",
    width: WIDTH,
    height: HEIGHT,
  },
  [STEP_TYPE.FOLDER_UPLOAD]: {
    type: STEP_TYPE.FOLDER_UPLOAD,
    name: "Filuppladdning",
    colour: "#ecdc02",
    width: WIDTH,
    height: HEIGHT,
  },
  [STEP_TYPE.FOLDER_ZIP]: {
    type: STEP_TYPE.FOLDER_ZIP,
    name: "ZIP-arkiv",
    colour: "#93ca30",
    width: WIDTH,
    height: HEIGHT,
  },
  [STEP_TYPE.PREFLIGHT]: {
    type: STEP_TYPE.PREFLIGHT,
    name: "Preflight",
    colour: "#f57f20",
    width: WIDTH,
    height: HEIGHT,
  },
  [STEP_TYPE.TEMPLATES]: {
    type: STEP_TYPE.TEMPLATES,
    name: "Mallar",
    colour: "#28f",
    width: WIDTH,
    height: HEIGHT,
  },
  [STEP_TYPE.XML_LOADING]: {
    type: STEP_TYPE.XML_LOADING,
    name: "XML-inläsning",
    colour: "#990",
    width: WIDTH,
    height: HEIGHT,
  },
};
