import { useState } from "react";
import { Box, Typography, Grid, Divider, Button, Tooltip  } from "@mui/material";
import Avatar from "../../common/Avatar";
import moment from "moment";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { useMutation } from "@apollo/client";
import { UPDATE_COMMENT_LIKE } from "../../../graphql/mutations/NewsContext";
import EmoticonDisplay from "./EmoticonDisplay";
import ReactionButton from "./ReactionButton";
import Mention from "./Mention"; 

// Function to calculate time difference in a user-friendly format
const getTimeAgo = (date) => {
  const now = moment();
  const duration = moment.duration(now.diff(moment(date)));
  if (duration.asMinutes() < 60) return "< 1 h";
  if (duration.asHours() < 24) return `${Math.floor(duration.asHours())} h`;
  if (duration.asDays() < 30) return `${Math.floor(duration.asDays())} d`;
  if (duration.asMonths() < 36) return `${Math.floor(duration.asMonths())} m`;
  return `${Math.floor(duration.asYears())} y`;
};




const Comment = ({ comment, onLikeComment }) => {

  const renderTextWithMentions = (text, mentions) => {
    const mentionRegex = /<@m(\d+)>/g;
    const parts = [];
    let lastIndex = 0;
    let match;

    // Parse and split text into parts
    while ((match = mentionRegex.exec(text)) !== null) {
      const mentionId = parseInt(match[1], 10);
      const mentionData = mentions.find((m) => m.mentionId === mentionId);

      // Push preceding text
      if (match.index > lastIndex) {
        parts.push(text.substring(lastIndex, match.index));
      }

      // Push Mention component if mention data exists
      if (mentionData) {
        parts.push(
          <Mention
            key={mentionId}
            mentionId={mentionId}
            mentions={mentionData}
          />
        );
      } else {
        // If no mention data, keep the raw mention text
        parts.push(match[0]);
      }

      lastIndex = match.index + match[0].length;
    }

    // Push remaining text
    if (lastIndex < text.length) {
      parts.push(text.substring(lastIndex));
    }

    return parts;
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        borderRadius: 1,
        p: 1,
        display: "flex",
        flexDirection: "column",
        mb: 0.5,
      }}
    >
      {/* Author and Avatar */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Avatar
          author={comment.username}
          url={comment.avatarUrl}
          sx={{ width: 32, height: 32, mr: 1 }}
        />
        <Typography
          sx={{ fontSize: "15px", fontWeight: 500, lineHeight: 1 }}
        >
          {comment.author}
        </Typography>
      </Box>

      {/* Comment Text */}
      <Typography
        sx={{
          fontSize: "13px",
          lineHeight: 1.2,
          whiteSpace: "pre-wrap",
          mb: 1,
        }}
      >
        {renderTextWithMentions(comment.text, comment.mentions)}
      </Typography>

      {/* Footer */}
      <Grid container alignItems="center" justifyContent="flex-end">
        <Grid item xs={1}>
          <Tooltip title={moment(comment.commentDate).format("LLLL")} arrow>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ fontSize: "12px" }}
            >
              {getTimeAgo(comment.commentDate)}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={1}>
          <ReactionButton postId={comment.commentId} onLikePost={onLikeComment} />
        </Grid>
        <Grid
          item
          xs={10}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <EmoticonDisplay likes={comment.likes} />
        </Grid>
      </Grid>
    </Box>
  );
};


const Comments = ({ comments, setNews }) => {
  const [showAllComments, setShowAllComments] = useState(false);
  const [likeComment] = useMutation(UPDATE_COMMENT_LIKE);

  const handleShowAllComments = () => {
    setShowAllComments(true);
  };

  const handleLikeComment = async (commentId, emoticon) => {
    try {
      
      const { data } = await likeComment({
        variables: {
          commentId: commentId,
          emoticon,
        },
      });

      const updatedReaction = data.newsContext.updateCommentLike;

      setNews((prevNews) =>
        prevNews.map((newsItem) => ({
          ...newsItem,
          comments: newsItem.comments.map((c) => {
            if (c.commentId === commentId) {
              const updatedLikes =
                emoticon === 0
                  ? c.likes.filter((like) => like.userId !== updatedReaction.userId)
                  : [
                    ...c.likes.filter((like) => like.userId !== updatedReaction.userId),
                    updatedReaction,
                  ];
              return { ...c, likes: updatedLikes };
            }
            return c;
          }),
        }))
      );
    } catch (error) {
      console.error("Error updating comment like:", error);
    }
  };

  return (
    comments && comments.length > 0 && (
      <Box sx={{ mt: 2 }}>
        {!showAllComments && comments.length > 1 && (
          <Button onClick={handleShowAllComments} sx={{ textAlign: "left", pl: 0, mb: 1, textTransform: "none"  }}>
            Visa fler kommentarer ({comments.length})
          </Button>
          
        )}

        {showAllComments
          ? comments.map((comment, index) => <Comment key={index} onLikeComment={(postId, emoticon) => handleLikeComment(postId, emoticon)} comment={comment} />)
          : <Comment onLikeComment={(postId, emoticon) => handleLikeComment(postId, emoticon)} comment={comments[comments.length - 1]} />
        }

        <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      </Box>
    )
  );
};

export default Comments;