import { gql } from '@apollo/client';
import Poll from "../../fragments/Poll";

const VOTE_NEWSPOST = gql`
  mutation CreateNewsPost($input: NewsVoteInput) {
    newsContext {
      vote(input: $input) {
          ...PollFragment
          
        }
      }
    }
  ${Poll}
`;

export default VOTE_NEWSPOST;