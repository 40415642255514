import { gql } from '@apollo/client';
import MessageAttachmentFragment from "./MessageAttachment";
import MessagePartFragment from "./MessagePart";

const DirectMessageFragment = gql`
  fragment DirectMessageFragment on DirectMessage {
    conversationId
    author
    authorUserId
    authorUsername
    avatarUrl
    files {
      ...MessageAttachmentFragment
    }
    id
    messageparts {
      ...MessagePartFragment
    }
    messageTime
    __typename
  }
  ${MessageAttachmentFragment}
  ${MessagePartFragment}
`;

export default DirectMessageFragment;
