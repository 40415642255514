import { handleActions } from 'redux-actions';
import {
  setConversations,
  setConversationsLoading,
  setConversationsError,
} from "../../../../actions";

const initialState = {
  items: null,
  loading: false,
  error: null,
};

export default handleActions(
  {
    [setConversations]: (state, { payload: { items } }) => ({
      ...state,
      items,
    }),
    [setConversationsLoading]: (state, { payload: { loading } }) => ({
      ...state,
      loading,
    }),
    [setConversationsError]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
  },
  initialState
);