import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Login as LoginIcon } from "@mui/icons-material";
import { Box, Button, Card, CircularProgress, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { setLocale } from "../../actions";
import { ADD_USER, CREATE_USER } from "../../graphql/public/mutations";
import { USER_DETAILS } from "../../graphql/public/queries";
import { client } from "../../graphql/publicClient";
import { useSnackbar } from "../../hooks";
import CustomSelect from "../common/CustomSelect";
import {
  languageOptions,
  stepOneInitialValues,
  stepTwoInitialValues,
  stepThreeInitialValues,
} from "./helpers";
import ConfirmationModal from "./ConfirmationModal";
import Footer from "./Footer";
import Notice from "./Notice";
import Stepper from "./Stepper";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20,
    position: "relative",
  },
  container: {
    marginTop: 10,
    padding: "20px 30px 30px 30px",
    background:
      "linear-gradient(180deg, rgba(247,247,247,1) 0%, rgba(223,223,223,0) 6%)",
  },
  comment: {
    fontSize: 14,
    marginTop: 50,
  },
  card: {
    width: 700,
    margin: "30px auto 0 auto",
    padding: 0,
  },
  header: {
    padding: 30,
  },
  loading: {
    textAlign: "center",
    position: "absolute",
    left: "0",
    right: "0",
  },
  errorGrid: {
    flexDirection: "row",
    "& h6, & p": {
      margin: "0.5em 0",
    },
    "& h6": {
      color: theme.palette.error.dark,
      fontWeight: 800,
    },
    "& [role='button']": {
      display: "flex",
      width: "100%",
      "&:focus, &:hover": {
        color: theme.palette.primary.contrastText,
      },
    },
    [theme.breakpoints.down("760")]: {
      alignItems: "stretch",
      flexDirection: "column-reverse",
      "& h6": {
        margin: 0,
      },
    },
  },
  label: {
    fontSize: 12,
    transform: "translate(14px, 13px) scale(1)",
  },
}));

const LoginButton = () => (
  <Button
    endIcon={<LoginIcon />}
    href="/"
    role="button"
    size="small"
    variant="contained"
  >
    <FormattedMessage id="btn.login" />
  </Button>
);

const ErrorGrid = ({ className, languageSelector }) => (
  <Grid container
    className={className}
    columns={2}
    p={2}
    spacing={2}
  >
    <Grid item flexGrow={1} xs={1}>
      <Typography component="h6">
        <FormattedMessage id="createAccount.error.invalidInvitation" />
      </Typography>
      <Typography component="p">
        <FormattedMessage id="createAccount.error.loginRedirection" />:
      </Typography>
      <LoginButton />
    </Grid>
    <Grid item flexGrow={1} xs={1}>
      {languageSelector}
    </Grid>
  </Grid>
);

const STEP_ONE = <FormattedMessage id="createAccount.stepOne" />;
const STEP_TWO = <FormattedMessage id="createAccount.stepTwo" />;
const STEP_THREE = <FormattedMessage id="createAccount.stepThree" />;

const CreateAccount = () => {
  const snackbar = useSnackbar();
  const classes = useStyles();
  const alert = useAlert();
  const dispatch = useDispatch();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const token = query.get("token");

  const [steps] = useState([STEP_ONE, STEP_TWO, STEP_THREE]);
  const [activeStep, setActiveStep] = useState(0);

  const [selectedCompany, setSelectedCompany] = useState({});
  const [searchedCompany, setSearchedCompany] = useState({});
  const [useSearched, setUseSearched] = useState(false);

  

  const [companies, setCompanies] = useState([]);
  const [stepOneValues, setStepOneValues] = useState(stepOneInitialValues);
  const [stepTwoValues, setStepTwoValues] = useState(stepTwoInitialValues);
  const [stepThreeValues, setStepThreeValues] = useState(
    stepThreeInitialValues
  );
  const [userDetails, setUserDetails] = useState({});
  const [confirmPassword, setConfirmPassword] = useState("");

  const [successModal, setSuccessModal] = useState(false);

  const convertComment = message => {
    let newComment = "";
    if (message) {
      newComment = message.replace(/(?:\r\n|\r|\n)/g, "<br>");
    }

    return newComment;
  };
  

  const {
    isKnownUser,
    comment,
    availableSubscriptionLevels,
    requiredSubscriptionLevel,
  } = userDetails;
  const showComment = isKnownUser || activeStep === 0;
  const [createUser] = useMutation(CREATE_USER, { client });
  const [addUser] = useMutation(ADD_USER, { client });



  const [getUserDetails, { data, loading, error }] = useLazyQuery(
    USER_DETAILS,
    {
      variables: { token },
      client,
      skip: !token,
    }
  );

  useEffect(() => {
    if (token) {
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (data && data.userDetails) {

      const user = data.userDetails;
      if (user.isKnownUser) {
        setActiveStep(2);
      }
      const tempValues = { ...stepOneInitialValues, username: user.username };
      setStepOneValues(tempValues);
      setStepThreeValues({
        ...stepThreeInitialValues,
        subscription: user.requiredSubscriptionLevel,
        userCompanyPaysForItself: user.otherCompannyHasPaymentLiability,
      });

      setCompanies(user.suggestedCompanies);
      let highlighted = user.suggestedCompanies.filter(n => n.isHighlighted)
      if (highlighted && highlighted.length > 0) {
        setSelectedCompany(highlighted[0]);
      }
      stepTwoValues.companyName = user.companyName || setStepTwoValues.companyName;
      setStepTwoValues(stepTwoValues);


      setUserDetails(user);
    }
  }, [data]);

  const handleSubmit = async () => {
    if (isKnownUser) {
      try {
        const res = await addUser({ variables: { token } });

        if (res && res.data && res.data.addUser) {
          const { success } = res.data.addUser;
          if (success) {
            setSuccessModal(true);
          } else {
            alert.error(
              <FormattedMessage id="createAccount.errorCreatingAccount" />
            );
          }
        }
      } catch (err) {
        console.error("error >", err);
      }
    }

    if (!isKnownUser) {
      const params = {
        ...stepOneValues,
        publicCompanyId: searchedCompany.publicCompanyId || selectedCompany.publicCompanyId,
        company: { ...stepTwoValues },
        subScriptionLevel: stepThreeValues.subscription,
        userCompanyPaysForItself: stepThreeValues.userCompanyPaysForItself,
      };

      delete params.username;
      
      try {
        const res = await createUser({
          variables: {
            token,
            user: params,
          },
        });

        if (res && res.data && res.data.createUser) {
          const { success } = res.data.createUser;
          if (success) {
            setSuccessModal(true);
          } else {
            alert.error(
              <FormattedMessage id="createAccount.errorCreatingAccount" />
            );
          }
        }
      } catch (err) {
        console.error("error >", err);
      }
    }
  };

  const handleChangeLanguage = e => {
    const { /*name,*/ value } = e.target;

    dispatch(setLocale({ culture : value }));
  };

  const customLabelClass = classes.label;

  const langauge = useSelector(state => state.ui.locale.culture);

  return (
    <Grid className={classes.root}>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress size={100} />
        </div>
      )}

      {!loading && (
        <Card className={classes.card}>
          {error ? (
            <ErrorGrid
              className={classes.errorGrid}
              languageSelector={
                <CustomSelect
                  name="culture"
                  inputLabel={<FormattedMessage id="createAccount.language" />}
                  options={languageOptions}
                  onChange={handleChangeLanguage}
                  value={langauge}
                  inputLabelCustomClass={customLabelClass}
                />
              }
            />
          ) : (
              <>
                <div className={classes.header}>
                  <Notice userDetails={userDetails} />
                  {!isKnownUser && (
                    <Stepper activeStep={activeStep} steps={steps} />
                  )}
                </div>
                <Box className={classes.container}>
                  {activeStep === 0 && (
                    <StepOne
                      stepOneValues={stepOneValues}
                      setStepOneValues={setStepOneValues}
                      confirmPassword={confirmPassword}
                      setConfirmPassword={setConfirmPassword}
                    />
                  )}

                  {activeStep === 1 && (
                    <StepTwo
                      token={token}
                      selectedCompany={selectedCompany}
                      searchedCompany={searchedCompany}
                      companies={companies}
                      setSelectedCompany={setSelectedCompany}
                      setSearchedCompany={setSearchedCompany}
                      useSearched={useSearched }
                      setUseSearched={setUseSearched }
                      
                      stepTwoValues={stepTwoValues}
                      setStepTwoValues={setStepTwoValues}
                      setCompanies={setCompanies}
                      snackbar={snackbar}
                    />
                  )}

                  {activeStep === 2 && (
                    <StepThree
                      stepThreeValues={stepThreeValues}
                      setStepThreeValues={setStepThreeValues}
                      availableSubscriptionLevels={availableSubscriptionLevels}
                      requiredSubscriptionLevel={requiredSubscriptionLevel}
                    />
                  )}
                  {showComment && (
                    <div className={classes.comment}>
                      <Typography>
                        <span
                          className={classes.folderName}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: convertComment(comment) }}
                        />
                      </Typography>
                    </div>
                  )}
                </Box>
                <Footer
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  stepOneValues={stepOneValues}
                  steps={steps}
                  stepThreeValues={stepThreeValues}
                  stepTwoValues={stepTwoValues}
                  searchedCompany={searchedCompany}
                  selectedCompany={selectedCompany}
                  isKnownUser={isKnownUser}
                  handleSubmit={handleSubmit}


                />
              </>
            )}

          {successModal && <ConfirmationModal open={successModal} />}
        </Card>
      )}
    </Grid>
  );
};

export default CreateAccount;
