import React from "react";

import { Arrow, Circle, Layer, Rect, Shape, Transformer } from 'react-konva';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useCommentsContext } from "../../../hooks/FullscreenAnnotationModal";

import { drawCloud } from '../utils/cloudAlgorithm';

import {
  ANNOTATION_TYPE,
  FILE_TYPE as FileType,
  PAGE_MODE_TYPE as PageModeType,
  TOOL_TYPE,
} from "../utils/types";


const AnnotationLayer = ({
  activeTool,
  //annotations,
  //setAnnotations,
  pdfPageNumber,
  viewportTransform,
  setToolSettings,
  toolSettings,
  setSelectedId,
  pageMode,
  pdfDimensions,
  newAnnotation,
  transformerRef
  }) => {

  const { annotations, addAnnotation, updateAnnotation } = useCommentsContext();
  

  const getTransformedPosition = (e) => {
    const node = e.target;
    const stage = node.getStage();
    const stageTransform = stage.getAbsoluteTransform().copy();
    stageTransform.invert();
    const pos = node.absolutePosition();
    const stagePos = stageTransform.point(pos);

    return { node, stagePos };
  };

  const handleTransformEnd = (e, id) => {

    const { node, stagePos } = getTransformedPosition(e);

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    node.scaleX(1);
    node.scaleY(1);

    const updatedAnnotation = annotations.map(ann =>
      ann.id === id ? {
        ...ann,
        x: stagePos.x,
        y: stagePos.y,
        width: Math.max(5, node.width() * scaleX),
        height: Math.max(5, node.height() * scaleY),
      } : ann
    );

    updateAnnotation(id, updatedAnnotation);

    
  };

  const handleSelect = (id) => {
    if (activeTool !== TOOL_TYPE.SELECT) {
      return;
    }

    setSelectedId(id);
    const selectedAnnotation = annotations.find(ann => ann.id === id);
    if (selectedAnnotation) {

      let newToolSettings = {
        ...toolSettings,
        tool: TOOL_TYPE.SELECT,
        lineWeight: selectedAnnotation.lineWeight,
        borderColor: selectedAnnotation.borderColor,
        fillColor: selectedAnnotation.fillColor || 'transparent'
      }

      setToolSettings(newToolSettings);
    }
  };

  const handleDragStart = (e, id) => {

    if (activeTool === TOOL_TYPE.SELECT) return true;

    //We should not be here....
  };

  const handleDragEnd = (e, id) => {
    if (activeTool !== TOOL_TYPE.SELECT) return false;

    const { node, stagePos } = getTransformedPosition(e);

    const updatedAnnotations = annotations.map(ann =>
      ann.id === id ? { ...ann, x: stagePos.x, y: stagePos.y } : ann
    );

    updateAnnotation(id, updatedAnnotations);    
  };

  const drawShape = (shape) => {

    //If we are drawing to double-sided mode, then we need to correct for the page
    //but not on page 1!!
    let x = shape.x;
    if (pageMode === PageModeType.DOUBLE) {
      if (shape.pageNo % 2 === 1) {
        x = shape.x + (pdfDimensions.width / viewportTransform.scale);
      }
    }

    const sharedProps = {
      key: shape.id,
      id: shape.id,
      draggable: true,
      x: x,
      y: shape.y,
      page: shape.pageNo,
      onClick: () => handleSelect(shape.id),
      onTap: () => handleSelect(shape.id),
      onDragStart: (e) => handleDragStart(e, shape.id),
      onDragEnd: (e) => handleDragEnd(e, shape.id),
      onTransformEnd: (e) => handleTransformEnd(e, shape.id),
      strokeWidth: shape.lineWeight,
      stroke: shape.borderColor,
      fill: shape.fillColor || toolSettings.fillColor,
    };

    switch (shape.subType) {

      case ANNOTATION_TYPE.RECTANGLE:
        return (<>
          <Rect {...sharedProps} width={shape.width} height={shape.height} />
          {/*<Text
          x={x}   // Adjust position to center the text within the rect
          y={shape.y}
          text={shape.page}  // Number to be displayed
          fontSize={14}
          fill="black"
        />*/}</>);

      case ANNOTATION_TYPE.ELLIPSE:
        return (<Circle {...sharedProps} radius={Math.abs(shape.width) / 2} />);

      case ANNOTATION_TYPE.ARROW:
        return (<Arrow {...sharedProps} points={[0, 0, shape.width, shape.height]} />);

      case ANNOTATION_TYPE.CLOUD:
        return (
          <Shape {...sharedProps}

            width={shape.width}
            height={shape.height}

            hitFunc={(context, konvaShape) => {
              const width = konvaShape.width();
              const height = konvaShape.height();

              context.beginPath();
              context.moveTo(0, 0);
              context.rect(0, 0, width, height);
              context.closePath();

              // (!) Konva specific method, it is very important
              context.fillStrokeShape(konvaShape);
            }}

            sceneFunc={(context, konvaShape) => {
              const radius = 20;
              const width = konvaShape.width() - radius;
              const height = konvaShape.height() - radius;

              drawCloud(context, { width, height }, {
                fill: shape.fillColor || toolSettings.fillColor,
                outline: shape.borderColor,
                incise: 15,
                radius: radius,
                overlap: 0.8333,
                stretch: false,
              });

            }}
          />
        );

      default:
        return null;
    }
  };

  return (<Layer listening={activeTool === TOOL_TYPE.SELECT}>
    {annotations
      .filter(annotation => {
        
        if (pageMode === PageModeType.SINGLE) {

          // For "single" mode, return annotations for the current page
          return annotation.pageNo === pdfPageNumber;

        } else if (pageMode === PageModeType.DOUBLE) {

          if (pdfPageNumber === 1) {
            // Special case for the first page in double mode
            return annotation.pageNo === 1;
          } else if (pdfPageNumber % 2 === 0) {
            // For even pageNumber, get annotations for this page and the next one
            return annotation.page === pdfPageNumber || annotation.pageNo === pdfPageNumber + 1;
          } else {
            // For odd pageNumber, get annotations for this page and the previous one
            return annotation.page === pdfPageNumber || annotation.pageNo === pdfPageNumber - 1;
          }
        }
        // Add a fallback return statement in case pageMode doesn't match any condition
        return false;
      })
      .map((annotation) => (
        <React.Fragment key={annotation.id}>
          {drawShape(annotation)}
        </React.Fragment>
      ))
    }
    {newAnnotation && drawShape(newAnnotation)}
    <Transformer ref={transformerRef} />
  </Layer>);
}

export default AnnotationLayer;