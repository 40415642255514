import { isArray } from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { setConversations } from '../../actions'; 
import { getConversations as conversationsSelector } from "../../helpers/messagingSelectors";

const useUnreadDirectMessagesCounter = () => {
  const dispatch = useDispatch();

  const conversations = useSelector(conversationsSelector);

  const updateCounter = (action, conversationId) => {
    if (!isArray(conversations)) {
      return;
    }

    var index = conversations.findIndex(n => n.directMessageConversationId === conversationId);
    if (index < 0) {
      return;
    }

    var tempConversations = [...conversations];
    var tempConversation = { ...tempConversations[index] };

    switch (action) {
      case "increase":
        tempConversation.unreadMessageCount++;
        break;
      case "reset":
        tempConversation.unreadMessageCount = 0;
        break;
      default:
        console.warn("** Unhandled action", { action, conversationId });
        return;
    }

    tempConversations.splice(index, 1, tempConversation);
    dispatch(setConversations({ items: tempConversations }));
  };

  return {
    increase: (conversationId) => updateCounter("increase", conversationId),
    reset: (conversationId) => updateCounter("reset", conversationId),
  };
};

export default useUnreadDirectMessagesCounter;
