import { gql } from '@apollo/client';

const messagingContactsLookup = gql`
  query MessagingContactsLookup($searchTerm: String) {
    productEdit {
      companyAnsvarigLookup(searchTerm: $searchTerm) {
        #user
        userId
        #username
        firstname
        lastname
        avatarUrl
        #groupName
      }
    }
  }
`;
export default messagingContactsLookup;
