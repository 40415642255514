import PropTypes from "prop-types";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  List,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CREATE_CONVERSATION as CREATE_DIRECT_MESSAGE_CONVERSATION } from "../../../graphql/mutations/DirectMessageContext";
import { useMessagingContext } from "../../../hooks/Messaging";
import ChannelsSkeleton from "../../Skeleton/ChannelsSkeleton";
import ChannelHead from "./ChannelHead";
import ChannelListItem from "./ChannelListItem";
import ChannelsMiscPopover from "./ChannelsMiscPopover";
import ConversationContainer from "./ConversationContainer";
import CreateChannelModal from "./CreateChannelModal";
import HeaderList from "./HeaderList";
import ShowChannelAndWorkspaces from "./ShowChannelAndWorkspaces";
import StartConversationModal from "../StartConversationModal";

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.common.white,
    borderRight: "1px solid #E4E4E4",
    height: "100%",
  },
  head: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 15,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  iconBtn: {
    padding: 4,
    marginLeft: "auto",
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 14,
  },
  panelRoot: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  panelSummaryRoot: {
    padding: 0,
    paddingLeft: 10,
    minHeight: 30,
    "&.Mui-expanded": {
      margin: "0 !important",
      minHeight: 30,
    },
    "& .MuiAccordionSummary-content": {
      margin: "0 !important",
      alignItems: "center",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0 !important",
      minHeight: 30,
    },
  },
  panelDetailsRoot: {
    padding: 0,
    flexDirection: "column",
  },
  usersListPanel: {
    padding: "8px 0 24px 0px",
  },
  groupLabel: {
    fontSize: 12,
    fontWeight: 600,
  },
  expandIcon: {
    order: -1,
    padding: 0,
    marginRight: 5,
  },
  listRoot: {
    padding: 0,
  },
  directMessagesContainer: {
    flex: 1,
  },
  divider: {
    margin: "10px 0",
  },
  headDivider: {
    margin: "9px 0 0 0",
  },
  channelsBodyContainer: { overflow: "auto" },
  unreadMessageCount: {
    backgroundColor: theme.palette.component.badgeBackground,
    borderRadius: "50%",
    color: theme.palette.component.badgeColor,
    fontSize: 10,
    fontWeight: 700,
    margin: "auto",
    marginLeft: "1em",
    marginTop: "1px",
    padding: "2px 7px",
    position: "absolute",
    textAlign: "center",
  },
}));

const TabLabel = ({ classes, counter, messageId }) => (
  <Box component="span" sx={{ display: "inline-block" }}>
    <FormattedMessage id={messageId} />
    {(counter > 0) && (
      <Box component="span" className={classes.unreadMessageCount}>
        {counter}
      </Box>
    )}
  </Box>
);

const Channels = ({
  activeChannelId,
  activeConversationId,
  channels,
  channelsLoading,
  conversations,
  conversationsLoading,
  onChannelSelected,
  onConversationAdded,
  onConversationSelected,
  setSiteId,
}) => {
  const classes = useStyles();

  const {
    haveChannels,
    unreadChannelMessagesCount,
    unreadConversationMessagesCount,
  } = useMessagingContext();

  const initialWorkspaceName = useSelector(
    state => state.ui.toggleWorkspaces.workspace.name
  );
  const workspaces = useSelector(
    state => state.api.currentViewer.viewer.structure.items
  );
  const sites = useSelector(state => state.api.currentViewer.viewer.sites);

  const [createConversation] = useMutation(CREATE_DIRECT_MESSAGE_CONVERSATION);

  const [workspaceName, setWorkspaceName] = useState(initialWorkspaceName);

  const [workspaceSelector, setWorkspaceSelector] = useState(false);

  const handleToggle = () => {
    setWorkspaceSelector(prevState => !prevState);
  };

  const handleOnClickSite = site => {
    let found = {};
    if (site.siteId !== 0) {
      found = sites.find(x => x.id === site.siteId);
    } else {
      found = { ...site, id: 0 };
    }

    const { id, name } = found;

    setWorkspaceName(name);
    setWorkspaceSelector(false);

    setSiteId(id);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickSetting = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const settingPopoverId = open ? "settings-popover" : undefined;

  const [openCreateChannel, setOpenCreateChannel] = useState(false);
  const [startConversationVisible, setStartConversationVisible] = useState(
    false
  );

  const handleCloseStartConversation = () => {
    setStartConversationVisible(false);
  };

  const handleOpenStartConversation = () => {
    setStartConversationVisible(true);
  };

  const handleOpenCreateChannel = () => {
    setOpenCreateChannel(true);
  };
  const handleCloseCreateChannel = () => {
    setOpenCreateChannel(false);
  };

  const handleAddConversation = async userIds => {
    try {
      const res = await createConversation({
        variables: { userIds },
      });
      if (
        res &&
        res.data &&
        res.data.directMessageContext &&
        res.data.directMessageContext.createConversation
      ) {
        onConversationAdded({
          conversation: res.data.directMessageContext.createConversation,
        });
      }
    } catch (err) {
      console.error("error >", err);
    }

    setStartConversationVisible(false);
  };

  const [anchorElChannelsMisc, setAnchorElChannelsMisc] = useState(null);
  const channelsMiscVisible = Boolean(anchorElChannelsMisc);
  const id = channelsMiscVisible ? "channels-misc-popover" : undefined;

  const handleOpenChannelsMisc = event => {
    setAnchorElChannelsMisc(event.currentTarget);
  };
  const handleCloseChannelsMisc = () => {
    setAnchorElChannelsMisc(null);
  };

  const onClickCreateChannel = () => {
    handleOpenCreateChannel();
    handleCloseChannelsMisc();
  };

  return (
    <>
      <Box className={classes.root}>
        {false && (
          <Box>
            <ChannelHead
              handleClose={handleClose}
              anchorEl={anchorEl}
              open={open}
              id={settingPopoverId}
              handleOpenCreateChannel={handleOpenCreateChannel}
              handleClickSetting={handleClickSetting}
            />
          </Box>
        )}
        <Box className={classes.headDivider} />
        <ShowChannelAndWorkspaces
          workspaces={workspaces}
          workspaceName={workspaceName}
          onClick={handleOnClickSite}
          handleToggle={handleToggle}
          open={workspaceSelector}
          setOpen={setWorkspaceSelector}
        />
        <Divider className={classes.divider} />
        <Box className={classes.channelsBodyContainer}>
          {haveChannels && (
            <Box>
              <Accordion
                classes={{ root: classes.panelRoot }}
              >
                <AccordionSummary
                  classes={{
                    root: classes.panelSummaryRoot,
                    expandIcon: classes.expandIcon,
                  }}
                  expandIcon={<ArrowDropDownIcon />}
                >
                  <HeaderList
                    hideButton
                    iconOnClick={handleOpenChannelsMisc}
                    title={
                      <TabLabel
                        classes={classes}
                        counter={unreadChannelMessagesCount}
                        messageId="widget.channels"
                      />
                    }
                  />
                </AccordionSummary>
                <AccordionDetails
                  classes={{ root: classes.panelDetailsRoot }}
                >
                  {channelsLoading ? (
                    <ChannelsSkeleton />
                  ) : (
                    <List className={classes.listRoot}>
                      {channels.map(x => (
                        <ChannelListItem
                          key={x.siteChannelId}
                          activeChannelId={activeChannelId}
                          channel={x}
                          onChannelSelected={onChannelSelected}
                        />
                      ))}
                    </List>
                  )}
                </AccordionDetails>
              </Accordion>
              <Divider className={classes.divider} />
            </Box>
          )}
          <Box className={classes.directMessagesContainer}>
            <Accordion
              classes={{ root: classes.panelRoot }}
              defaultExpanded
            >
              <AccordionSummary
                classes={{
                  root: classes.panelSummaryRoot,
                  expandIcon: classes.expandIcon,
                }}
                expandIcon={<ArrowDropDownIcon />}
              >
                <HeaderList
                  iconOnClick={handleOpenStartConversation}
                  title={
                    <TabLabel
                      classes={classes}
                      counter={unreadConversationMessagesCount}
                      messageId="widget.directMessages"
                    />
                  }
                />
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.usersListPanel }}>
                {
                  <ConversationContainer
                    activeConversationId={activeConversationId}
                    conversations={conversations}
                    loading={conversationsLoading}
                    onAddConversation={handleAddConversation}
                    onConversationSelected={onConversationSelected}
                  />
                }
              </AccordionDetails>
            </Accordion>
            <Divider className={classes.divider} />
          </Box>
        </Box>
      </Box>
      <CreateChannelModal
        open={openCreateChannel}
        handleClose={handleCloseCreateChannel}
      />
      <StartConversationModal
        open={startConversationVisible}
        handleClose={handleCloseStartConversation}
        onAddConversation={handleAddConversation}
      />
      <ChannelsMiscPopover
        id={id}
        open={channelsMiscVisible}
        anchorEl={anchorElChannelsMisc}
        handleClose={handleCloseChannelsMisc}
        onClickCreateChannel={onClickCreateChannel}
      />
    </>
  );
};

Channels.defaultProps = {
  activeChannelId: null,
  activeConversationId: null,
  channels: [],
  channelsLoading: false,
  conversations: [],
  conversationsLoading: false,
};

Channels.propTypes = {
  activeChannelId: PropTypes.number,
  activeConversationId: PropTypes.number,
  channels: PropTypes.arrayOf(PropTypes.shape({})),
  channelsLoading: PropTypes.bool,
  conversations: PropTypes.arrayOf(PropTypes.shape({})),
  conversationsLoading: PropTypes.bool,
  onChannelSelected: PropTypes.func.isRequired,
  onConversationAdded: PropTypes.func.isRequired,
  onConversationSelected: PropTypes.func.isRequired,
  setSiteId: PropTypes.func.isRequired,
};

export default Channels;
