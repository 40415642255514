import cloneDeep from "lodash/cloneDeep";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useSubscription } from "@apollo/client";
import { Box, CircularProgress, Grid, IconButton, Stack, Tab, Tabs, Typography } from "@mui/material";
import { GET_PROJECT_EVENTS } from "../../graphql/subscription";
import { useSnackbar } from "../../hooks";
import {
  useCreateTask,
  useDeleteTask,
  useGetProjectAndTasks,
  useMoveTask,
  useReportTime,
  useUpdateTask,
  useDeleteTime
} from "../../hooks/Projects";
import ConfirmDeleteModal from "../common/ConfirmDeleteModal";
import GoogleDocumentViewer from "./GoogleDocumentViewer";
import HorizontalStepper from "./HorizontalStepper";
import ImageListTitlebarBelow from "./ImageList/ImageListTitlebarBelow";
import ProjectOverview from "./ProjectOverview";
import TableView from "./TableView";
import TaskDetailsBase from "./TaskDetails/TaskDetailsBase";
import TaskDetailsColumn from "./TaskDetails/TaskDetailsColumn";
import TaskDetailsDrawer from "./TaskDetailsDrawer";
import WorkspaceDashboard from "../WorkspaceDashboard";
import { TaskAction, TimeLogAction } from "./helper";
import ProjectModal from "./ProjectModal/ProjectModal";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Kanban from "./Kanban";
import TaskDetailsModal from "./TaskDetailsModal";
import FileViewer from "./FileViewer";

const sxTab = { textTransform: "none", fontSize: "12px", minHeight: "28px", height: "28px", paddingBottom: 0, marginTop: "0px", paddingTop: "2px" };
const sxTabs = { minHeight: "28px", height: "28px" };

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, bgColor, AdjustForGrid, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ margin: "32px -24px 8px -24px", padding: "0px 24px 0px 24px", borderRadius: "0 0 4px 4px", background: bgColor ? bgColor : "#f4f3ed", minHeight: "100%", height: "100%", }}
    >
      {value === index && (
        <Box component="div">
          {children}
        </Box>
      )}
    </div>
  );
}

const ProjectContent = ({ enableEdit = true, projectId, siteId }) => {
  const intl = useIntl();
  const location = useLocation();
  const snackbar = useSnackbar();

  const [project, setProject] = useState();
  const [showConfirmDeleteTaskModal, setShowConfirmDeleteTaskModal] = useState(false);
  const [showTaskDetails, setShowTaskDetails] = useState(false);
  const [showTaskDetailsModal, setShowTaskDetailsModal] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [showProjectEditModal, setShowProjectEditModal] = useState(false);
  const [taskActionData, setTaskActionData] = useState();
  const [taskDetails, setTaskDetails] = useState();
  const [taskDetailsColumns, setTaskDetailsColumns] = useState();
  const [tasks, setTasks] = useState([]);
  const [userLookup, setUserLookup] = useState([]);

  const [getProjectAndTasks, { data: projectData, error: projectError, loading: projectLoading }] = useGetProjectAndTasks();
  const [createTask, { loading: taskCreateLoading }] = useCreateTask();
  const [deleteTask, { loading: taskDeleteLoading }] = useDeleteTask();
  const [moveTask, { loading: taskMoveLoading }] = useMoveTask();
  const [updateTask, { loading: taskUpdateLoading }] = useUpdateTask();
  const [reportTime, { loading: timeReportLoading }] = useReportTime();
  const [deleteTime, { loading: timeReportDeleteLoading }] = useDeleteTime();


  const accessToken = localStorage.getItem("access_token");

  const { data: eventData, loading: eventLoading } = useSubscription(GET_PROJECT_EVENTS, {
    variables: {
      projectId: projectId,
      authToken: accessToken,
    },
    onData: function (update) {

      if (update?.data?.data?.projectEvents) {

        let data = update?.data?.data?.projectEvents;
        console.log({ data });

        switch (data.eventType) {
          case "TASK_UPDATED":
            const updatedTask = data.task;
            const updatedTasks = tasks.map(task =>
              task.id === updatedTask.id ? updatedTask : task
            );

            setTasks(updatedTasks);

            if (taskDetails.id == updatedTask.id) {              
              setTaskDetails({ ...taskDetails, comments : updatedTask.comments  });
            }            

            break;
          case "TASK_CREATED":
            const newTask = data.task;
            if (!tasks.find(task => task.id === newTask.id)) {
              setTasks(prevTasks => [newTask, ...prevTasks]);
            }
            break;
          case "TASK_DELETED":
            const deletedTaskId = data.taskId;
            setTasks(prevTasks => prevTasks.filter(task => task.id !== deletedTaskId));
            break;
          default:
            break;
        }
      }
    }
  });

  useEffect(() => {
    if (projectId) {
      getProjectAndTasks({ variables: { projectId } });
      handleHideTaskDetails();
    }
  }, [projectId]);

  useEffect(() => {
    if (projectLoading) {
      clearProjectAndTasks();
    }
    else if (projectError) {
      console.error("** [ProjectContent] Error while fetching project:", projectError);
      clearProjectAndTasks();
    }
    else {
      const projectResult = projectData?.projects?.getProject;
      const tasksResult = projectData?.projects?.getTasks?.tasks || [];
      const userLookupResult = projectData?.projects?.listProjectUserCandidates || [];

      setUserLookup(userLookupResult);

      if (projectResult && tasksResult) {
        setProject(projectResult);
        setTasks(tasksResult);
      }
      else {
        clearProjectAndTasks();
      }
    }
  }, [projectData, projectError, projectLoading]);

  useEffect(() => {
    if (!taskDeleteLoading) {
      setShowConfirmDeleteTaskModal(false);
    }
  }, [taskDeleteLoading]);

  const clearProjectAndTasks = () => {
    setProject();
    setTasks([]);
  }

  const handleOpenProjectDialog = () => {
    setShowProjectEditModal(true);
  }

  const handleUpdatedProject = (project) => {

    setShowProjectEditModal(false);
    setProject(project);
  }

  const handleConfirmDeleteTask = () => {
    if (!!taskActionData) {
      const { action } = taskActionData;
      if (action === TaskAction.DELETE) {
        const { taskId } = taskActionData;
        handleDeleteTask(taskId);
      }
      else {
        console.warn(`** Expected action "${TaskAction.DELETE}", but got "${action}".`);
      }
    }
  };

  const handleDeleteTask = async (taskId) => {
    const handleDeleteError = (err = "") => {
      console.error("** error >", err);
      snackbar.error(<FormattedMessage id="common.genericErrorMessage" />);
    };

    const findTask = id => tasks.find(n => n.id === id);
    const findChildTasksIds = id => tasks.filter(n => n.parentTaskId === id).map(n => n.id);

    const getDescendantTaskIds = id => {
      let taskIds = findChildTasksIds(id);
      let taskId, i = 0;

      while (taskId = taskIds[i++]) {
        taskIds.push(...findChildTasksIds(taskId));
      }

      return taskIds;
    };

    const removeTask = id => {
      if (!findTask(id)) {
        console.warn("** Task not found!", { id });
        return;
      }

      var taskIds = [id, ...getDescendantTaskIds(id)];

      setTasks((prev) => prev.filter(task => !taskIds.includes(task.id)));
    };

    const index = tasks.findIndex(n => n.id === taskId);

    if (index < 0) {
      handleDeleteError("task not deleted (not found)");
      return;
    }

    await deleteTask({ projectId, taskId })
      .then(result => {
        if (result.success) {
          removeTask(taskId);
          handleHideTaskDetails();
        }
        else {
          handleDeleteError("task not deleted");
        }
      })
      .catch(err => {
        handleDeleteError(err);
      });
  };

  const handleMoveTask = async (taskActionData) => {
    const handleMoveError = (err = "", ...rest) => {
      console.error("** error >", err, ...rest);
      snackbar.error(<FormattedMessage id="common.genericErrorMessage" />);
    };

    const moveLeft = (s, t, parentTaskId) => {
      var first = tasks.slice(0, t);
      var task = tasks[s];
      task.parentTaskId = parentTaskId;
      var middle = tasks.slice(t, s);
      var last = tasks.slice(s + 1);
      setTasks([...first, task, ...middle, ...last]);
    };

    const moveRight = (s, t, parentTaskId) => {
      var first = tasks.slice(0, s);
      var task = tasks[s];
      task.parentTaskId = parentTaskId;
      var middle = tasks.slice(s + 1, t + 1);
      var last = tasks.slice(t + 1);
      setTasks([...first, ...middle, task, ...last]);
    };

    const moveTaskInTaskList = (sourceIndex, targetIndex, parentTaskId) => {
      return (sourceIndex < targetIndex)
        ? moveRight(sourceIndex, targetIndex, parentTaskId)
        : moveLeft(sourceIndex, targetIndex + 1, parentTaskId);
    };

    const { action } = taskActionData;

    if (action === TaskAction.MOVE) {

      const { parentTaskId, insertAfterTaskId, taskId } = taskActionData;

      if (insertAfterTaskId === taskId) {
        //console.info("** did not move (same source and target)");
        return;
      }

      const sourceIndex = tasks.findIndex(n => n.id === taskId);
      if (sourceIndex < 0) {
        handleMoveError("task not moved (not found)", { sourceIndex });
        return;
      }

      const targetTaskId = insertAfterTaskId ?? parentTaskId;

      const targetIndex = targetTaskId
        ? tasks.findIndex(n => n.id === targetTaskId)
        : -1;

      if (targetTaskId && targetIndex < 0) {
        handleMoveError("task not moved (invalid target)", { targetIndex });
        return;
      }

      if (targetIndex === sourceIndex) {
        //console.info("** did not move (same source and target)");
        return;
      }

      await moveTask({ projectId, parentTaskId, insertAfterTaskId, taskId })
        .then(result => {
          if (result.success) {
            moveTaskInTaskList(sourceIndex, targetIndex, parentTaskId);
            handleHideTaskDetails();
          }
          else {
            handleMoveError("task not moved");
          }
        })
        .catch(err => {
          handleMoveError(err);
        });

    } else {
      console.warn("** unhandled action", { action });
    }
  };

  const cloneTask = (item) => {
    const task = cloneDeep(item);

    delete task.createdBy;
    delete task.id;
    delete task.nodes;
    delete task.parentTaskId;
    delete task.projectId;
    delete task.timestamp;
    delete task.reportedTime;
    delete task.reportedTimeLog;
    delete task.comments;

    if (task.assignments) {
      task.assignments = task.assignments.map(n => ({ userId: n.userId }));
    }

    return task;
  };

  const handleSaveDetails = async ({ item, immediateTaskActionData }) => {
    const handleSaveError = (err = "") => {
      console.error("** error >", err);
      snackbar.error(<FormattedMessage id="common.errorOnSaving" />);
    };

    const insertTask = (index, task) => {
      var first = tasks.slice(0, index);
      var last = tasks.slice(index);
      setTasks([...first, task, ...last]);
    };

    const replaceTask = (index, task) => {
      var first = tasks.slice(0, index);
      var last = tasks.slice(index + 1);
      setTasks([...first, task, ...last]);
    };

    const { action } = (immediateTaskActionData || taskActionData);

    if (action === TaskAction.ADD || action === TaskAction.INSERT_NEW) {

      const { parentTaskId, insertAfterTaskId } = (immediateTaskActionData || taskActionData);

      const taskId = insertAfterTaskId ?? parentTaskId;

      let index = 0;
      
      if (action === TaskAction.INSERT_NEW) {
        index = tasks.length;
      }

      if (taskId) {
        index = tasks.findIndex(n => n.id === taskId) + 1;
        if (index < 1) {
          handleSaveError("task not created (invalid insertion point)");
          return;
        }
      }

      const task = cloneTask(item);

      await createTask({ projectId, parentTaskId, insertAfterTaskId, task })
        .then(result => {
          if (result.success) {
            insertTask(index, result.task);
            handleHideTaskDetails();
          }
          else {
            handleSaveError("task not created");
          }
        })
        .catch(err => {
          handleSaveError(err);
        });

    }
    else if (action === TaskAction.EDIT) {

      var index = tasks.findIndex(n => n.id === item.id);
      if (index < 0) {
        handleSaveError("task not found");
        return;
      }

      const { taskId } = (immediateTaskActionData || taskActionData);

      const task = cloneTask(item);

      await updateTask({ projectId, taskId, task })
        .then(result => {
          if (result.success) {
            replaceTask(index, result.task);
            handleHideTaskDetails();
          }
          else {
            handleSaveError("task not updated");
          }
        })
        .catch(err => {
          handleSaveError(err);
        });

    }
    else {
      console.warn("** unhandled action", { action });
    }
  };

  const handleHideTaskDetails = () => {
    handleShowTaskDetails(null, null);
  };

  const handleShowTaskDetails = (item, columns) => {
    if (!item) {
      setShowTaskDetails(false);
      setTaskActionData();
    }
    else if (showTaskDetails) {
      if (taskDetails && taskDetails.id === item.id && item.id !== 0) {
        setShowTaskDetails(false);
        setTaskActionData();
      }
      else {
        setTaskDetails(item);
        setTaskDetailsColumns(columns);
      }
    }
    else {
      setShowTaskDetails(true);
      setTaskDetails(item);
      setTaskDetailsColumns(columns);
    }
  };

  const handleShowTaskDetailsModal = (item, columns) => {

    setShowTaskDetailsModal(true);
    setShowTaskDetails(false);

  }

  const handleTimeLogAction = async (action, data) => {

    const replaceTask = (index, task) => {
      var first = tasks.slice(0, index);
      var last = tasks.slice(index + 1);
      setTasks([...first, task, ...last]);
    };

    if (action == TimeLogAction.ADD) {

      await reportTime({ id: 0, comment: "", projectId: data.task.projectId, taskId: data.task.id, reportedTime: data.minutes })
        .then(result => {
          
          if (result.success) {
            var index = tasks.findIndex(n => n.id === data.task.id);

            if (index < 0) {
              handleSaveError("task not found");
              return;
            }

            const task = cloneDeep(tasks[index]);

            task.reportedTimeLog.unshift(result.timeReport);

            task.reportedTime += result.timeReport.reportedMinutes;

            replaceTask(index, task);

            if (taskDetails && taskDetails.taskId == task.taskId) {

              var clonedDetails = cloneDeep(taskDetails);
              clonedDetails.reportedTimeLog = task.reportedTimeLog;
              clonedDetails.reportedTime = task.reportedTime;
              setTaskDetails(clonedDetails);
            }

          } else {
            handleSaveError("task not updated");
          }
        })
        .catch(err => {
          handleSaveError(err);
        });

      // ({ id, comment, projectId, taskId, reportedTime })
    }
    else if (action == TimeLogAction.UPDATE) {

      //onTimeLogAction(TimeLogAction.UPDATE, { task, reportItem, minutes, date });

      await reportTime({
        id: data.reportItem.id, comment: "",
        projectId: data.task.projectId, taskId: data.task.id,
        reportedTime: data.minutes,
        reportedAt: data.date
      })
        .then(result => {

          var index = tasks.findIndex(n => n.id === data.task.id);

          if (index < 0) {
            handleSaveError("task not found");
            return;
          }

          const task = cloneDeep(tasks[index]);

          const logIndex = task.reportedTimeLog.findIndex(log => log.id === result.timeReport.id);

          if (logIndex >= 0) {
            // Update the existing time log entry
            task.reportedTimeLog[logIndex].reportedMinutes = result.timeReport.reportedMinutes;
            task.reportedTimeLog[logIndex].reportedAt = result.timeReport.reportedAt;

            // Update the total reported time
            // Adjust the total reported time by adding the difference between the new and old reported times            

            const oldReportedTime = task.reportedTimeLog[logIndex].reportedMinutes;
            task.reportedTime += (result.timeReport.reportedMinutes - oldReportedTime);

            replaceTask(index, task);

            if (taskDetails && taskDetails.taskId == task.taskId) {

              var clonedDetails = cloneDeep(taskDetails);
              clonedDetails.reportedTimeLog = task.reportedTimeLog;
              clonedDetails.reportedTime = task.reportedTime;
              setTaskDetails(clonedDetails);
            }

          } else {
            handleSaveError("time log not found");
          }
        })
        .catch(err => {
          handleSaveError(err);
        });
    }
    else if (action == TimeLogAction.DELETE) {
      // handle delete action

      await deleteTime({ taskId: data.task.id, reportItemId: data.timeLogId }).
        then(result => {          
          if (result) {

            var index = tasks.findIndex(n => n.id === data.task.id);

            if (index < 0) {
              handleSaveError("task not found");
              return;
            }

            const task = cloneDeep(tasks[index]);
            const logIndex = task.reportedTimeLog.findIndex(log => log.id === data.timeLogId);


            if (logIndex >= 0) {
              // Remove the specific time log entry
              const logToBeDeleted = task.reportedTimeLog[logIndex];
              task.reportedTime -= logToBeDeleted.reportedMinutes;
              task.reportedTimeLog.splice(logIndex, 1);
            } else {
              handleSaveError("time log not found");
              return;
            }

            replaceTask(index, task);


            if (taskDetails && taskDetails.taskId == task.taskId) {

              var clonedDetails = cloneDeep(taskDetails);
              clonedDetails.reportedTimeLog = task.reportedTimeLog;
              clonedDetails.reportedTime = task.reportedTime;
              setTaskDetails(clonedDetails);
            }

          }

        }).catch(err => {
          handleSaveError(err);
        });


        

    }
  };

  const handleTaskAction = ({ action, columns, data }) => {
    switch (action) {
      case TaskAction.INSERT_NEW:        
        handleSaveDetails({ item: data.task, immediateTaskActionData: { action, ...data } });
        break;
      case TaskAction.ADD:
      case TaskAction.EDIT:
        setTaskActionData({ action, ...data });
        handleShowTaskDetails(data.task, columns);
        break;
      case TaskAction.DELETE:
        setTaskActionData({ action, ...data });
        setShowConfirmDeleteTaskModal(true);
        break;
      case TaskAction.MOVE:
        setTaskActionData({ action, ...data });
        handleMoveTask({ action, ...data });
        break;
      case TaskAction.UPDATE:
        handleUpdateTask({ item: data.task });
        break;
      default:
        setTaskActionData();
        console.warn("** unhandled action", { action });
    }
  };

  const handleSaveError = (err = "") => {
    console.error("** error >", err);
    snackbar.error(<FormattedMessage id="common.errorOnSaving" />);
  };



  const handleUpdateTask = async ({ item }) => {

    const replaceTask = (index, task) => {
      var first = tasks.slice(0, index);
      var last = tasks.slice(index + 1);
      setTasks([...first, task, ...last]);
    };

    var index = tasks.findIndex(n => n.id === item.id);
    if (index < 0) {
      handleSaveError("task not found");
      return;
    }

    const task = cloneTask(item);
    const taskId = item.id;

    await updateTask({ projectId, taskId, task })
      .then(result => {
        if (result.success) {
          replaceTask(index, result.task);
        }
        else {
          handleSaveError("task not updated");
        }
      })
      .catch(err => {
        handleSaveError(err);
      });
  };

  if (projectError || projectLoading || !project) {
    return (
      <Box color="text.secondary" component="h1" m="auto">
        {projectLoading &&
          <CircularProgress color="inherit" size={60} sx={{ mr: 1 }} />
        }
        {(projectError || !projectLoading && (projectData?.projects?.getProject?.projectId !== projectId)) &&
          <FormattedMessage id="projectSelector.noProject" />
        }
      </Box>
    );
  };

  return (
    <Box component="div" sx={{ flexGrow: 1, display: "flex", flexDirection: "column", p: 3, }}>
      <Stack direction="row">
        <Typography variant="h5" gutterBottom sx={{ fontSize: "28px", fontWeight: 500, padding: "0px", margin: "0px" }}>{project?.name}</Typography><IconButton onClick={handleOpenProjectDialog} sx={{ padding: "6px", opacity: "0.4", "&:hover": {opacity: "1"},}}><EditOutlinedIcon /></IconButton>
      </Stack>
      <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "14px", textTransform: "none", paddingTop: "0px", marginBottom: "0px", paddingBottom: "6px" }} dangerouslySetInnerHTML={{ __html: project?.description }}></Typography>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Project Menu scrollable auto"
            sx={sxTabs}
          >
            <Tab sx={sxTab} label={intl.formatMessage({ id: "project.tabs.overview" })} {...a11yProps(0)} />
            <Tab sx={sxTab} label={intl.formatMessage({ id: "project.tabs.tasks" })} {...a11yProps(1)} />
            <Tab sx={sxTab} label={intl.formatMessage({ id: "project.tabs.files" })} {...a11yProps(2)} />            
            <Tab sx={sxTab} label={intl.formatMessage({ id: "project.tabs.statusUpdate" })} {...a11yProps(3)} />
            <Tab sx={sxTab} label="Kanban" {...a11yProps(4)}/>
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0} bgColor="#f4f3ed" AdjustForGrid={true}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item container xs={8} spacing={2}>
              <Grid item xs={12}>
                <HorizontalStepper />
              </Grid>
              <Grid item xs={12}>
                <ImageListTitlebarBelow />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <ProjectOverview />
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1} bgColor="#ffffff">
          <TableView
            disabled={taskCreateLoading || taskDeleteLoading || taskMoveLoading || taskUpdateLoading}
            enableEdit={enableEdit}
            onProjectUpdate={handleUpdatedProject}
            onTaskAction={handleTaskAction}
            onTimeLogAction={handleTimeLogAction}
            project={project}
            tasks={tasks}
            userLookup={userLookup}
          />
          <TaskDetailsDrawer
            onClose={handleHideTaskDetails}
            show={showTaskDetails}
            onShowDetailsModal={handleShowTaskDetailsModal}
          >
            {showTaskDetails &&
              <TaskDetailsBase
                renderComponent={TaskDetailsColumn}
                columns={taskDetailsColumns}
                currentProject={project}
                disabled={taskCreateLoading || taskDeleteLoading || taskMoveLoading || taskUpdateLoading}
                enableEdit={enableEdit}
                item={taskDetails}
                onSave={handleSaveDetails}
                userLookup={userLookup}
                onTimeLogAction={handleTimeLogAction}
              />
            }
          </TaskDetailsDrawer>
          {showTaskDetailsModal && (<TaskDetailsModal
            open={showTaskDetailsModal}
            task={taskDetails}
            onClose={n => setShowTaskDetailsModal(false)}

            columns={taskDetailsColumns}
            currentProject={project}
            disabled={taskCreateLoading || taskDeleteLoading || taskMoveLoading || taskUpdateLoading}
            enableEdit={enableEdit}
            
            onSave={handleSaveDetails}
            userLookup={userLookup}
            onTimeLogAction={handleTimeLogAction}
            //open, onClose, task, currentProject, columns, disabled, enableEdit, onSave, userLookup, onTimeLogAction 

          />)}

          {showConfirmDeleteTaskModal && (
            <ConfirmDeleteModal
              loading={taskDeleteLoading}
              onConfirmCloseModal={handleConfirmDeleteTask}
              open={showConfirmDeleteTaskModal}
              setOpen={setShowConfirmDeleteTaskModal}
            />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2} bgColor="#ffffff">
          <GoogleDocumentViewer />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={3} bgColor="#ffffff">
          {/*<WorkspaceDashboard siteId={siteId} projectId={projectId} route={location.pathname} />*/}
          {project && (            
            <FileViewer folderId={56194}/>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={4} bgColor="#ffffff">
          <Kanban enableEdit={enableEdit}
            onProjectUpdate={handleUpdatedProject}
            onTaskAction={handleTaskAction}
            onTimeLogAction={handleTimeLogAction}
            project={project}
            tasks={tasks}
            userLookup={userLookup}/>
          <TaskDetailsDrawer
            onClose={handleHideTaskDetails}
            show={showTaskDetails}
          >
            {showTaskDetails &&
              <TaskDetailsBase
                columns={taskDetailsColumns}
                currentProject={project}
                disabled={taskCreateLoading || taskDeleteLoading || taskMoveLoading || taskUpdateLoading}
                enableEdit={enableEdit}
                item={taskDetails}
                onSave={handleSaveDetails}
                userLookup={userLookup}
                onTimeLogAction={handleTimeLogAction}
              />
            }
          </TaskDetailsDrawer>

          </CustomTabPanel>
      </Box>

      {showProjectEditModal && (<ProjectModal project={project} open={showProjectEditModal} onSave={handleUpdatedProject} onClose={n => setShowProjectEditModal(false)} />)}

    </Box>
  );
};

export default ProjectContent;
