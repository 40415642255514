import React from 'react';
import { Group, Path } from 'react-konva';

const DoublePageIcon = ({ x = 0, y = 0, width = 36, height = 24, fill = 'black', stroke = 'none', strokeWidth = 0, scale = { x: 1, y: 1 }, opacity = 1, listening = false, offsetX = 0, offsetY = 0, renderAsSVG = false }) => {
  return renderAsSVG ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="-12 0 36 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      opacity={opacity}
    >
      <g>
        <path d="M23.57,6.567l-5.936-5.934l-0.428-0.43H16.6H9.762c-1.333,0-2.51,0.668-3.228,1.682C5.817,0.871,4.64,0.204,3.307,0.203&#10;&#9;h-7.837h-0.607l-0.428,0.43l-5.936,5.934l-0.43,0.43v0.607v12.235c0,2.183,1.775,3.957,3.957,3.957H3.307&#10;&#9;c1.334,0,2.51-0.667,3.228-1.68c0.718,1.013,1.894,1.68,3.228,1.68h10.281c2.182,0,3.957-1.774,3.957-3.957V7.604V6.997L23.57,6.567&#10;&#9;z M5.799,19.839c0,1.377-1.116,2.492-2.491,2.491H-7.972c-1.375,0-2.491-1.115-2.491-2.492V7.604h3.856&#10;&#9;c1.146,0,2.076-0.93,2.076-2.076V1.671h7.839c1.375,0,2.491,1.115,2.491,2.492V19.839z M22.532,19.838&#10;&#9;c0,1.377-1.116,2.492-2.491,2.492H9.76c-1.375,0.001-2.491-1.114-2.491-2.491V4.163c0-1.377,1.116-2.492,2.491-2.492H16.6v3.857&#10;&#9;c0,1.146,0.931,2.076,2.076,2.076h3.856V19.838z" />
      </g>
    </svg>
  ) : (
    <Group
      x={x}
      y={y}
      width={width}
      height={height}
      scale={scale}
      listening={listening}
      offsetX={offsetX}
      offsetY={offsetY}
      opacity={opacity}
    >
      <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} data="M23.57,6.567l-5.936-5.934l-0.428-0.43H16.6H9.762c-1.333,0-2.51,0.668-3.228,1.682C5.817,0.871,4.64,0.204,3.307,0.203&#10;&#9;h-7.837h-0.607l-0.428,0.43l-5.936,5.934l-0.43,0.43v0.607v12.235c0,2.183,1.775,3.957,3.957,3.957H3.307&#10;&#9;c1.334,0,2.51-0.667,3.228-1.68c0.718,1.013,1.894,1.68,3.228,1.68h10.281c2.182,0,3.957-1.774,3.957-3.957V7.604V6.997L23.57,6.567&#10;&#9;z M5.799,19.839c0,1.377-1.116,2.492-2.491,2.491H-7.972c-1.375,0-2.491-1.115-2.491-2.492V7.604h3.856&#10;&#9;c1.146,0,2.076-0.93,2.076-2.076V1.671h7.839c1.375,0,2.491,1.115,2.491,2.492V19.839z M22.532,19.838&#10;&#9;c0,1.377-1.116,2.492-2.491,2.492H9.76c-1.375,0.001-2.491-1.114-2.491-2.491V4.163c0-1.377,1.116-2.492,2.491-2.492H16.6v3.857&#10;&#9;c0,1.146,0.931,2.076,2.076,2.076h3.856V19.838z" />
    </Group>
  );
};

export default DoublePageIcon;