import Konva from "konva";
import { useMemo } from "react";
import { Rect, Text } from "react-konva";

const TextWithBackground = ({ x, y, text, fontSize = 14, padding = 4, bgColor = "white", textColor = "black", opacity = 0.8 }) => {

  const { textWidth, textHeight } = useMemo(() => {
    const { width, height } = new Konva.Text({ fontSize, text: "" }).measureSize(text);
    return {
      textWidth: width,
      textHeight: height,
    };
  }, [fontSize, text]);

  const pos = {
    x: x - textWidth / 2 - padding,
    y: y - textHeight / 2 - padding,
  };

  const size = {
    width: textWidth + 2 * padding,
    height: textHeight + 2 * padding,
  };

  return (
    <>
      {/* Background rectangle */}
      <Rect
        {...pos}
        {...size}
        cornerRadius={4}
        fill={bgColor}
        listening={false}
        opacity={opacity}
      />
      {/* Text */}
      <Text
        {...pos}
        {...size}
        align="center"
        fill={textColor}
        fontSize={fontSize}
        listening={false}
        text={text}
        verticalAlign="middle"
      />
    </>
  );
};

export default TextWithBackground;
