import React from 'react';
import { Group, Path } from 'react-konva';

const XMLIcon = ({ x = 0, y = 0, width = 24, height = 24, fill = 'black', stroke = 'none', strokeWidth = 0, scale = { x: 1, y: 1 }, opacity = 1, listening = false, offsetX = 0, offsetY = 0, renderAsSVG = false }) => {
  return renderAsSVG ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      opacity={opacity}
    >
      <g>
        <path d="M2.401,4.161v15.678c0,2.182,1.775,3.957,3.959,3.957h11.281c2.182,0,3.957-1.775,3.957-3.957V7.604V6.997l-0.43-0.43
		l-5.936-5.934l-0.428-0.43h-0.607H6.36C4.177,0.204,2.401,1.979,2.401,4.161z M3.867,19.839V4.163c0-1.377,1.116-2.492,2.491-2.492
		h7.839v3.857c0,1.146,0.931,2.076,2.077,2.076h3.856v12.234c0,1.377-1.116,2.492-2.491,2.492H6.358
		C4.983,22.331,3.867,21.216,3.867,19.839L3.867,19.839z"/>
        <path d="M7.719,13.914l1.153-1.683c0.073-0.109,0.11-0.223,0.11-0.334c0-0.274-0.216-0.49-0.513-0.49
		c-0.186,0-0.335,0.081-0.453,0.26l-0.908,1.338H7.093l-0.907-1.338c-0.121-0.179-0.269-0.26-0.455-0.26
		c-0.297,0-0.513,0.216-0.513,0.49c0,0.111,0.037,0.223,0.111,0.334l1.145,1.683l-1.235,1.813c-0.074,0.114-0.111,0.226-0.111,0.334
		c0,0.276,0.217,0.492,0.514,0.492c0.185,0,0.335-0.081,0.454-0.26l0.996-1.474h0.014l0.999,1.474
		c0.118,0.177,0.268,0.26,0.453,0.26c0.297,0,0.514-0.216,0.514-0.492c0-0.111-0.036-0.223-0.111-0.334L7.719,13.914z"/>
        <path d="M13.908,11.405c-0.326,0-0.506,0.179-0.669,0.566l-1.055,2.44h-0.029l-1.08-2.44c-0.17-0.388-0.348-0.566-0.675-0.566
		c-0.365,0-0.604,0.253-0.604,0.655v3.965c0,0.335,0.209,0.527,0.507,0.527c0.282,0,0.491-0.192,0.491-0.527v-2.439h0.037
		l0.787,1.815c0.158,0.355,0.283,0.46,0.536,0.46s0.38-0.104,0.536-0.46l0.781-1.815h0.036v2.439c0,0.335,0.202,0.527,0.5,0.527
		s0.497-0.192,0.497-0.527V12.06C14.503,11.66,14.272,11.405,13.908,11.405z"/>
        <path d="M18.41,15.535h-1.8c-0.03,0-0.046-0.017-0.046-0.044v-3.503c0-0.351-0.23-0.581-0.544-0.581
		c-0.317,0-0.548,0.23-0.548,0.581v3.972c0,0.327,0.214,0.55,0.548,0.55h2.39c0.334,0,0.55-0.199,0.55-0.489
		C18.96,15.737,18.744,15.535,18.41,15.535z"/>
      </g>
    </svg>
  ) : (
    <Group
      x={x}
      y={y}
      width={width}
      height={height}
      scale={scale}
      listening={listening}
      offsetX={offsetX}
      offsetY={offsetY}
      opacity={opacity}
    >
      <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} data="M2.401,4.161v15.678c0,2.182,1.775,3.957,3.959,3.957h11.281c2.182,0,3.957-1.775,3.957-3.957V7.604V6.997l-0.43-0.43
		l-5.936-5.934l-0.428-0.43h-0.607H6.36C4.177,0.204,2.401,1.979,2.401,4.161z M3.867,19.839V4.163c0-1.377,1.116-2.492,2.491-2.492
		h7.839v3.857c0,1.146,0.931,2.076,2.077,2.076h3.856v12.234c0,1.377-1.116,2.492-2.491,2.492H6.358
		C4.983,22.331,3.867,21.216,3.867,19.839L3.867,19.839z"/>
      <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} data="M7.719,13.914l1.153-1.683c0.073-0.109,0.11-0.223,0.11-0.334c0-0.274-0.216-0.49-0.513-0.49
		c-0.186,0-0.335,0.081-0.453,0.26l-0.908,1.338H7.093l-0.907-1.338c-0.121-0.179-0.269-0.26-0.455-0.26
		c-0.297,0-0.513,0.216-0.513,0.49c0,0.111,0.037,0.223,0.111,0.334l1.145,1.683l-1.235,1.813c-0.074,0.114-0.111,0.226-0.111,0.334
		c0,0.276,0.217,0.492,0.514,0.492c0.185,0,0.335-0.081,0.454-0.26l0.996-1.474h0.014l0.999,1.474
		c0.118,0.177,0.268,0.26,0.453,0.26c0.297,0,0.514-0.216,0.514-0.492c0-0.111-0.036-0.223-0.111-0.334L7.719,13.914z"/>
      <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} data="M13.908,11.405c-0.326,0-0.506,0.179-0.669,0.566l-1.055,2.44h-0.029l-1.08-2.44c-0.17-0.388-0.348-0.566-0.675-0.566
		c-0.365,0-0.604,0.253-0.604,0.655v3.965c0,0.335,0.209,0.527,0.507,0.527c0.282,0,0.491-0.192,0.491-0.527v-2.439h0.037
		l0.787,1.815c0.158,0.355,0.283,0.46,0.536,0.46s0.38-0.104,0.536-0.46l0.781-1.815h0.036v2.439c0,0.335,0.202,0.527,0.5,0.527
		s0.497-0.192,0.497-0.527V12.06C14.503,11.66,14.272,11.405,13.908,11.405z"/>
      <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} data="M18.41,15.535h-1.8c-0.03,0-0.046-0.017-0.046-0.044v-3.503c0-0.351-0.23-0.581-0.544-0.581
		c-0.317,0-0.548,0.23-0.548,0.581v3.972c0,0.327,0.214,0.55,0.548,0.55h2.39c0.334,0,0.55-0.199,0.55-0.489
		C18.96,15.737,18.744,15.535,18.41,15.535z"/>
    </Group>
  );
};

export default XMLIcon;