import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const CLEAR_NEWS_POST_FILE_UPLOAD_SESSION = gql`
  mutation ClearNewsPostFileUploadSession($session: ID!) {
    newsContext {
      clearNewsPostFileUploadSession(
			  session: $session
		  ) {
        success
        session
        errorResult {
          ...ErrorResultFields
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default CLEAR_NEWS_POST_FILE_UPLOAD_SESSION;
