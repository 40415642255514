import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Icon } from "@mui/material";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import IconLink from "../../common/FormControl/IconLink";

const useStyles = makeStyles(theme => ({
  actionContainer: {
    textAlign: "right",
    flex: 1,
  },
  iconBackground: {
    backgroundColor: "white",
    borderRadius: "50%",
    border: "solid white 5px",
  },
  iconUnlock: {
    padding: 0,
    minWidth: "unset",
    color: theme.palette.secondary.main,
  },
  iconLock: {
    padding: 0,
    minWidth: "unset",
    color: theme.palette.secondary.dark,
    fontSize: 12,
  },
}));

const ProductTableItemActionsCell = ({
  canLock,
  canDownloadFilesAndFolders,
  handleDeleteModalOpen,
  handleDownloadResource,
  handleLockResource,
  id,
  itemCanBeUpdated,
  locked,
  lockedByAnotherUser,
  lockedByUsername,
  onOpenProductEditModal,
  lockedByFolder,
  name,
  hovered,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.actionContainer} display="flex">
      {hovered && (
        <>
          <IconLink
            icon="fas fa-thumbs-up"
            placement="top"
            title={<FormattedMessage id="btn.download" />}            
            customClass={classes.iconBackground}/>

          {canDownloadFilesAndFolders && (
            <IconLink
              icon="fa fa-download"
              placement="top"
              title={<FormattedMessage id="btn.download" />}
              onClick={() => handleDownloadResource()}
              customClass={classes.iconBackground}
            />
          )}
          {itemCanBeUpdated && (
            <IconLink
              icon="fa fa-edit"
              placement="top"
              title={<FormattedMessage id="product.edit" />}
              onClick={onOpenProductEditModal}
              customClass={classes.iconBackground}
            />
          )}

          {canLock && (
            <IconLink
              onClick={() =>
                handleLockResource(
                  id,
                  name,
                  locked,
                  lockedByUsername,
                  lockedByAnotherUser
                )
              }
              icon={`fas ${locked ? "fa-lock" : "fa-unlock"}`}
              customClass={
                locked
                  ? [classes.iconLock, classes.iconBackground]
                  : [classes.iconUnlock, classes.iconBackground]
              }
              placement="top"
              title={<FormattedMessage id="product.lockUnlock" />}
              disabled={lockedByFolder}
            />
          )}
          {itemCanBeUpdated && (
            <IconLink
              icon="fas fa-trash"
              placement="top"
              title={<FormattedMessage id="context.delete" />}
              onClick={() => handleDeleteModalOpen(id)}
              customClass={classes.iconBackground}
            />
          )}
        </>
      )}

      {locked && !hovered && (
        <Icon className={clsx(["fas fa-lock", classes.iconLock])} />
      )}
    </Box>
  );
};

ProductTableItemActionsCell.defaultProps = {
  lockedByFolder: false,
  lockedByUsername: "",
  name: "",
};

ProductTableItemActionsCell.propTypes = {
  name: PropTypes.string,
  canLock: PropTypes.bool.isRequired,
  canDownloadFilesAndFolders: PropTypes.bool.isRequired,
  handleDeleteModalOpen: PropTypes.func.isRequired,
  handleDownloadResource: PropTypes.func.isRequired,
  handleLockResource: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  itemCanBeUpdated: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
  lockedByUsername: PropTypes.string,
  lockedByAnotherUser: PropTypes.bool.isRequired,
  onOpenProductEditModal: PropTypes.func.isRequired,
  lockedByFolder: PropTypes.bool,
};

export default memo(ProductTableItemActionsCell);
