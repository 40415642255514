import React from "react";
import { Line, Path } from "react-konva";
import { SHAPESIZE } from "../../utils/config";
import { Anchor } from "./Anchor";

const CORNER_RADIUS = 6; // Adjust this to match your Step's corner radius

function getRoundedRectPath(width, height, radius) {
  return [
    'M', radius, 0,
    'L', width - radius, 0,
    'Q', width, 0, width, radius,
    'L', width, height - radius,
    'Q', width, height, width - radius, height,
    'L', radius, height,
    'Q', 0, height, 0, height - radius,
    'L', 0, radius,
    'Q', 0, 0, radius, 0
  ].join(' ');
};

// Define the points for a rectangular border
const borderPoints = [
  0, 0,
  SHAPESIZE, 0,
  SHAPESIZE, SHAPESIZE,
  0, SHAPESIZE,
  0, 0
];

function getAnchorPoints(x, y) {
  const halfSize = SHAPESIZE / 2;
  return [
    { x: x - 10, y: y + halfSize, position: "left" },
    { x: x + halfSize, y: y - 10, position: "top" },
    { x: x + SHAPESIZE + 10, y: y + halfSize, position: "right" },
    { x: x + halfSize, y: y + SHAPESIZE + 10, position: "bottom" }
  ];
}

export function Border({ step, id, onAnchorDragStart, onAnchorDragMove, onAnchorDragEnd }) {
  const { x, y } = step;
  const anchorPoints = getAnchorPoints(x, y);
  const roundedRectPath = getRoundedRectPath(SHAPESIZE, SHAPESIZE, CORNER_RADIUS);

  const anchors = anchorPoints.map((position, index) => (
    <Anchor
      key={`anchor-${index}`}
      id={id}
      x={position.x}
      y={position.y}
      position={position.position}
      onDragStart={(e) => onAnchorDragStart(e, id, position.position)}
      onDragMove={(e) => onAnchorDragMove(e, id, position.position)}
      onDragEnd={(e) => onAnchorDragEnd(e, id, position.position)}
    />
  ));

  return (
    <>
      {/* <Line
        x={x}
        y={y}
        data={roundedRectPath}
        stroke="black"
        strokeWidth={2}
        perfectDrawEnabled={false}
        opacity={0.8}
        closed={true}
      /> */}
      <Path
        x={x}
        y={y}
        data={roundedRectPath}
        stroke="black"
        strokeWidth={2}
        perfectDrawEnabled={false}
        opacity={0.4}
        closed={true}
      />
      {anchors}
    </>
  );
}