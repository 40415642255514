import React from "react";
import YouTube from "react-youtube";
import PropTypes from "prop-types";
import DashboardWidget from "../../widgets/ContainerWidget/DashboardWidget";
import DashboardWidgetBody from "../../widgets/ContainerWidget/DashboardWidgetBody";

const YouTubeWidget = ({ hl, videoUrl }) => {
  const onReady = e => {
    e.target.pauseVideo();
  };

  const opts = {
    playerVars: {
      cc_load_policy: 1,
      cc_lang_pref: "sv",
      hl,
    },
    width: "100%",
  };

  const extractVideoId = videoUrl => {
    const res = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/.exec(
      videoUrl
    );
    const videoId = (res && res[1]) || "";
    return videoId;
  };



  return (
    <DashboardWidget>      
      <DashboardWidgetBody>        
        <YouTube onReady={onReady} opts={opts} videoId={extractVideoId(videoUrl) || ""} />
      </DashboardWidgetBody>
    </DashboardWidget>
  );
};

YouTubeWidget.defaultProps = {
  videoId: "",
};

YouTubeWidget.propTypes = {
  hl: PropTypes.string.isRequired,
  videoId: PropTypes.string,
};

export default YouTubeWidget;
