export const languageOptions = [
  { label: "Svenska", value: "sv-SE" },
  { label: "English", value: "en-US" },
];

export const stepOneInitialValues = {
  username: "",
  password: "",
  firstname: "",
  lastname: "",
  contact: "",
  culture: "sv-SE",
};

export const stepTwoInitialValues = {
  companyName: "",
  orgnNo: "",
  street: "",
  zip: "",
  city: "",
};

export const stepThreeInitialValues = {
  subscription: "",
  paymentMethod: "INVOICE",
  userCompanyPaysForItself: false,
};
