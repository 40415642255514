import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useAlert } from "react-alert";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import { Button, IconButton } from "@mui/material";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import {
  ADD_ONE_STREAM_TO_CART,
  DELETE_ONE_STREAM_JOB,
} from "../../graphql/mutations";
import OneStreamToolTip from "./OneStreamToolTip";
import { FormattedDate } from "../common";
import StatusModal from "./StatusModal";
import WarningModal from "./WarningModal";
import { setOneStreamJobs, cartInitialize } from "../../actions";
import {
  getSelectedSiteOrderGroupId,
  getSiteId,
} from "../../helpers/selectors";
import ConfirmDeleteModal from "../common/ConfirmDeleteModal";
import Tooltip from "@mui/material/Tooltip";
import { ManualTypeOptions } from "./helpers";

const useStyles = makeStyles(theme => ({
  td: {
    fontSize: 11,
    color: theme.palette.text.primary,
    paddingTop: 12,
    paddingBottom: 12,
  },
  link: {
    color: theme.palette.common.link,
    textTransform: "none",
    padding: 0,
    fontSize: 11,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  action: {
    padding: 5,
  },
  icon: {
    color: theme.palette.common.link,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  iconTrash: {
    color: theme.palette.common.red,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  btnAdd: {
    padding: "7px 12px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.common.white,
    fontSize: 10,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    marginRight: 5,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.light, 0.8),
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
  btnIcon: {
    fontSize: 10,
    marginRight: 8,
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
  },
  statusCountOk: {
    color: "#37910f",
    marginLeft: 5,
    "& .MuiIcon-root": {
      width: "unset",
      height: "unset",
      overflow: "unset",
      fontSize: 11,
      marginRight: 3,
    },
  },
  statusCountIssues: {
    color: "#ef8157",
    marginLeft: 5,
    "& .MuiIcon-root": {
      width: "unset",
      height: "unset",
      overflow: "unset",
      fontSize: 11,
      marginRight: 3,
    },
  },
  btnOverview: {
    padding: "7px 12px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.common.white,
    fontSize: 10,
    backgroundColor: "#fbc658",
    minWidth: "unset",
    borderRadius: 4,
    marginRight: 5,
    "&:hover": {
      backgroundColor: alpha("#fbc658", 0.8),
    },
    "& .MuiIcon-root": {
      width: "unset",
      height: "unset",
      overflow: "unset",
      fontSize: 11,
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
}));

const OneStreamTableItem = ({ job, idx, jobs }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useAlert();

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const {
    errorFiles,
    oneStreamJobId,
    warningFiles,
    oKFiles,
    jobName,
    latestStatus,
    latestMessage,
    totalFiles,
    uploadDate,
    username,
    jobType,
    costPlace
  } = job;
  const ordergroupId = useSelector(state => getSelectedSiteOrderGroupId(state));
  const siteId = useSelector(state => getSiteId(state));
  const total = useSelector(state => state.api.oneStream.oneStreamJobs.total);

  const [deleteJob] = useMutation(DELETE_ONE_STREAM_JOB);
  const [addOneStreamToCart] = useMutation(ADD_ONE_STREAM_TO_CART);

  const handleRemoveJob = async () => {
    const tempValue = [...jobs];
    tempValue.splice(idx, 1);

    const items = { total, result: tempValue };

    try {
      const res = await deleteJob({ variables: { oneStreamJobId } });
      if (
        res &&
        res.data &&
        res.data.oneStreamContext &&
        res.data.oneStreamContext.deleteJob
      ) {
        const { success } = res.data.oneStreamContext.deleteJob;
        if (success) {
          alert.success(<FormattedMessage id="onestream.jobDeleted" />);
          dispatch(setOneStreamJobs({ items }));
        } else {
          alert.error(<FormattedMessage id="onestream.failedToDeleteJob" />);
        }

        setConfirmDeleteModal(false);
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const [openStatus, setOpenStatus] = useState(false);
  const handleOpenStatus = () => {
    setOpenStatus(true);
  };
  const handleCloseStatus = () => {
    setOpenStatus(false);
  };

  const [openWarning, setOpenWarning] = useState(false);
  const handleOpenWarning = () => {
    setOpenWarning(true);
  };
  const handleCloseWarning = () => {
    setOpenWarning(false);
  };

  const handleAddToCart = async () => {
    try {
      const res = await addOneStreamToCart({
        variables: {
          ordergroupId,
          siteId,
          oneStreamJobId,
        },
      });

      if (
        res &&
        res.data &&
        res.data.shoppingCartContext &&
        res.data.shoppingCartContext.addOneStreamToCart
      ) {
        const {
          success,
          result,
        } = res.data.shoppingCartContext.addOneStreamToCart;
        if (success) {
          alert.success(<FormattedMessage id="product.addedToCart" />);
          const { groups = [] } = result;
          const orderGroup = groups.find(x => x.orderGroupId === ordergroupId);
          const { items = [], fileGroups = [], oneStreamGroups = [] } =
            orderGroup || {};

          dispatch(cartInitialize({ items, fileGroups, oneStreamGroups }));
        } else {
          alert.error(<FormattedMessage id="product.failedToCart" />);
        }
        handleCloseWarning();
      }
    } catch (err) {
      console.error("error ", err);
    }
  };

  return (
        <TableRow>
          <TableCell className={classes.td}>{jobName}</TableCell>
          <TableCell className={classes.td}> {ManualTypeOptions.find(option => option.value === jobType)?.label || jobType} </TableCell>
          <TableCell className={classes.td}>{costPlace}</TableCell>
          <TableCell className={classes.td}>{username}</TableCell>
          <TableCell className={classes.td}>
            <FormattedDate value={uploadDate} />
          </TableCell>
          <TableCell className={classes.td}>
            <FormattedDate value={latestStatus} />
          </TableCell>
          <TableCell className={classes.td}>{latestMessage}</TableCell>
          <TableCell className={classes.td}>
            <OneStreamToolTip
              warningFiles={warningFiles}
              errorFiles={errorFiles}
              handleOpenStatus={handleOpenStatus}
            />
            <span className={classes.statusCountOk}>
              <Icon className={clsx(["fa fa-thumbs-up", classes.iconOk])} />
              {oKFiles}
            </span>
            <span className={classes.statusCountIssues}>
              <Icon
                className={clsx(["fa fa-thumbs-down", classes.iconIssues])}
              />
              {errorFiles}
            </span>
          </TableCell>
          <TableCell className={classes.td} align="right">
            <Button className={classes.btnOverview}>
              <Icon className="fas fa-stream" />
            </Button>
            <Button
              className={classes.btnAdd}
              onClick={() =>
                warningFiles > 0 || errorFiles > 0
                  ? handleOpenWarning()
                  : handleAddToCart()
              }
            >
              <Icon
                className={clsx(["fas fa-shopping-cart", classes.btnIcon])}
              />
              <FormattedMessage id="context.addCart" />
            </Button>
            <Tooltip title={jobName}>
              <IconButton
                className={classes.action}
                onClick={() => setConfirmDeleteModal(true)}
              >
                <Icon
                  className={clsx(["fa fa-trash-alt", classes.iconTrash])}
                />
              </IconButton>
        </Tooltip>

        {openStatus && (
          <StatusModal
            open={openStatus}
            handleClose={handleCloseStatus}
            id={oneStreamJobId}
          />
        )}

        {openWarning && (
          <WarningModal
            open={openWarning}
            handleClose={handleCloseWarning}
            totalFiles={totalFiles}
            oKFiles={oKFiles}
            errorFiles={errorFiles}
            handleAddToCart={handleAddToCart}
          />
        )}

        {confirmDeleteModal && (
          // TODO:Skicka med text p� jobbnamnet h�r!
          <ConfirmDeleteModal
            open={confirmDeleteModal}
            setOpen={setConfirmDeleteModal}
            onConfirmCloseModal={handleRemoveJob}
          />
        )}
          </TableCell>
        </TableRow>
  );
};

OneStreamTableItem.defaultProps = {
  job: {},
  idx: null,
};

OneStreamTableItem.propTypes = {
  job: PropTypes.shape({
    errorFiles: PropTypes.number,
    oneStreamJobId: PropTypes.string,
    jobName: PropTypes.string,
    latestStatus: PropTypes.string,
    latestMessage: PropTypes.string,
    totalFiles: PropTypes.number,
    username: PropTypes.string,
    jobStatus: PropTypes.string,
    warningFiles: PropTypes.number,
    oKFiles: PropTypes.number,
  }),
  idx: PropTypes.number,
};

export default OneStreamTableItem;
