import { useEffect, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Send from "@mui/icons-material/Send";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { useCommentsContext, usePaginationContext } from "../../../hooks/FullscreenAnnotationModal";
import Comment from "../subcomponents/Comment";

const CommentsPanel = ({ product }) => {
  const intl = useIntl();

  const { add: addComment, comments, pageComments, pageCommentCount } = useCommentsContext();
  const { changePage, pageNo: currentPage, pageCount } = usePaginationContext();

  const [newComment, setNewComment] = useState("");

  const handleAddComment = (event) => {
    if (!currentPage) {
      return;
    }
    if (event.key === 'Enter' || event.type === 'click') {
      if (newComment.trim()) {
        const foo = Math.random() > 0.5;
        const commentNumber = pageComments(currentPage).length + 1;
       /* const comment = {
          id: Date.now(),
          user: foo ? 'Lana Lönn' : 'Anna Asp',
          avatar: foo ? 'https://uq.unq.se/webapi/api/UserAvatar/33?_=164edd5c0' : 'https://cdn.prod.website-files.com/6365d860c7b7a7191055eb8a/65a74b82fec11d8c4c984a2a_Aliah%20Lane-p-500.png',
          text: newComment,
          timestamp: new Date().toISOString(),
          number: commentNumber,          
        };*/
        addComment({ comment: newComment, pageNo: currentPage, resourceVersionId: product.versionId });
        setNewComment("");
      }
    }
  };

  const generateMenuItems = useCallback(() => {
    return (
      [...Array(pageCount).keys().map(n => n + 1)]
        .filter(pageNo => (pageNo === currentPage || pageCommentCount(pageNo) > 0))
        .map((pageNo, _) => (
          <MenuItem
            key={pageNo}
            value={pageNo}
          >
            page {pageNo}
          </MenuItem>
        ))
    );
  }, [comments, currentPage, pageCount]);

  useEffect(() => {
    setNewComment("");
  }, [comments]);

  return (
    <>
      <Typography variant="div"></Typography>
      <Box display="flex" justifyContent="flex-start" alignItems="flex-end">
        <Typography variant="h6">
          <FormattedMessage id="context.comments" />
        </Typography>
        {(pageCount > 1) && (
          <Box>
            <Typography variant="span" p="0 0.2rem 0 0.3rem">for</Typography>
            <Select
              disableUnderline
              onChange={(e) => changePage(e.target.value)}
              size="small"
              sx={{ boxShadow: 'none', '.MuiInput-notchedOutline': { border: 0 } }}
              value={(currentPage || "").toString()}
              variant="standard"
            >
              {generateMenuItems()}
            </Select>
          </Box>
        )}
      </Box>
      <List sx={{ paddingBottom: "0px" }}>
        {pageComments(currentPage).map((comment, index) => (
          <Comment
            comment={comment}
            currentPage={currentPage}
            index={index}
            key={"comment_" + currentPage + "_" + index}
          />
        ))}
      </List>
      <Box sx={{ width: '100%' }}>
        <FormControl fullWidth>
          <OutlinedInput
            disabled={!pageCount}
            fullWidth
            id="add-comment"
            onChange={(e) => setNewComment(e.target.value)}
            onKeyPress={handleAddComment}
            placeholder={`${intl.formatMessage({ id: "siteUsers.addAComment" })}...`}
            sx={{ background: "#ffffff" }}
            value={newComment}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="send-comment"
                  disabled={!pageCount}
                  edge="end"
                  onClick={handleAddComment}
                >
                  <Send />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
    </>
  );
};

export default CommentsPanel;
