import { useState } from "react";
import { ListItem, Divider, List, ListItemText, ListItemButton } from "@mui/material";
import WorkflowSelector from "./WorkflowSelector";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

const WorkflowPanel = ({ open, onSelectWorkflow }) => {

  const [selectedWorkFlowId, setSelectedWorkFlowId] = useState();

  return (
    <>
      <List>
        {[
          { text: "Startsida", icon: <HomeOutlinedIcon /> },
          { text: "Flöden", icon: <NotificationsNoneOutlinedIcon /> }].map(({ text, icon: Icon }, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block", marginTop: "0px", marginBottom: "0px", padding: 0 }}>
              <ListItemButton
                sx={{
                  minHeight: 34,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    marginRight: "8px",
                  }}
                >
                  {Icon}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ marginTop: "0px", marginBottom: "0px" }} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      {open && (
          <Divider sx={{ marginTop: "8px", marginBottom: "22px" }} />
      )}
      <WorkflowSelector
        onSelect={(n) => {
          setSelectedWorkFlowId(n)
          onSelectWorkflow?.(n);
        }}
        selectedWorkflowId={selectedWorkFlowId || ""}
        size="small"
        sx={{ display: "inherit", marginLeft: "20px", marginRight: "20px" }}
      />
    </>
  )
};

export default WorkflowPanel;
