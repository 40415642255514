import { useMutation } from "@apollo/client";
import { CONFIRM_MESSAGE_READ } from "../../graphql/mutations/SiteChannelContext";

const extractResult = (data) => data?.siteChannelContext?.confirmMessageRead;

const useSiteChannelMessageReadConfirmation = ({ onCompleted, onError } = {}) => {

  const [confirmMessageReadMutation, { data, error, loading }] = useMutation(
    CONFIRM_MESSAGE_READ,
    {
      errorPolicy: "none",
      onCompleted: (data) => {
        const result = extractResult(data);
        console?.info("** useSiteChannelMessageReadConfirmation - completed", { result });
        onCompleted?.(result);
      },
      onError: (error) => {
        console?.error("** useSiteChannelMessageReadConfirmation - error", { error });
        onError?.(error);
      },
    }
  );

  const executeConfirmMessageReadMutation = async (messageId) => (
    await confirmMessageReadMutation({
      variables: {
        messageId
      }
    })
  );

  return [
    executeConfirmMessageReadMutation,
    {
      data: extractResult(data),
      error,
      loading,
    }
  ];

};

export default useSiteChannelMessageReadConfirmation;
