import { debounce } from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLazyQuery } from "@apollo/client";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import SearchAutoComplete from "../../common/SearchAutoComplete";
import { COMPANY_RESPONSIBLE_LOOK_UP } from "../../../graphql/queries";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    marginTop: 20,
  },
  modalFooter: {
    marginTop: 20,
    paddingTop: 10,
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& .MuiFormControl-root": {
      marginTop: 0,
      marginRight: 10,
      width: 240,
    },
    "& input": {
      paddingTop: 8,
      paddingBottom: 9,
    },
  },
  listBoxCont: {
    margin: "4px 0",
    overflow: "hidden",
    fontSize: 14,
    fontFamily: "Montserrat",
    fontWeight: 400,
    lineHeight: 1.5,
    width: "250px",
    "& li:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  btnSave: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
}));

const StartConversationModal = ({ handleClose, onAddConversation, open }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [user, setUser] = useState();
  const [users, setUsers] = useState([]);
  const [autoCompleteOpen, setShowAutoComplete] = useState(false);

  const showAutoComplete = () => {
    setShowAutoComplete(true);
  };

  const hideAutoComplete = () => {
    setShowAutoComplete(false);
  };

  const [
    searchCompanyResponsible,
    { data: reponsibleData, loading: responsibleLoading },
  ] = useLazyQuery(COMPANY_RESPONSIBLE_LOOK_UP);

  const debounceFetchUsersList = useCallback(
    debounce(async searchTerm => {
      searchCompanyResponsible({
        variables: { searchTerm },
      });
    }, 500),
    []
  );

  useEffect(() => {
    if (
      reponsibleData &&
      reponsibleData.productEdit &&
      Array.isArray(reponsibleData.productEdit.companyAnsvarigLookup) &&
      reponsibleData.productEdit.companyAnsvarigLookup.length > 0
    ) {
      const tempUsers = reponsibleData.productEdit.companyAnsvarigLookup.map(
        x => {
          return { label: x.user, value: x.userId };
        }
      );
      setUsers(tempUsers);
    }
  }, [reponsibleData]);

  const handleOnChange = async text => {
    if (text && text.length > 2) {
      debounceFetchUsersList(text);
    }
  };

  const handleSelectUser = () => {
    onAddConversation(user.value);
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={intl.formatMessage({ id: "messaging.directmessage" })}
      />
      <DraggableModalBody customClass={classes.modalBody}>
        <SearchAutoComplete
          open={autoCompleteOpen}
          setOpen={showAutoComplete}
          setClose={hideAutoComplete}
          onChange={e => handleOnChange(e)}
          options={users}
          size="small"
          loading={responsibleLoading}
          setValue={setUser}
          value={user}
          label={<FormattedMessage id="settings.email" />}
          customClass={classes.searchField}
          customClasses={{ listbox: classes.listbox }}
        />

        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btnSave} onClick={handleSelectUser}>
            <FormattedMessage id="btn.ok" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

StartConversationModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onAddConversation: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default StartConversationModal;
