import parse, { domToReact } from "html-react-parser";
import EmojiPicker from 'emoji-picker-react';
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Divider, Grid, Popper, Typography } from "@mui/material";
import {
  ADD_NEWS_COMMENT,
  CREATE_NEWSPOST,
  UPDATE_NEWSPOST_LIKE,
  VOTE_NEWSPOST,
} from "../../../graphql/mutations/NewsContext";
import {
  GET_NEWS_FOR_SITE,
  GET_NEWS_FOR_USER,
} from "../../../graphql/queries/NewsContext";
import Avatar from "../../common/Avatar";
import DashboardWidget from "../../widgets/ContainerWidget/DashboardWidget"
import DashboardWidgetBody from "../../widgets/ContainerWidget/DashboardWidgetBody"
import Comments from "./Comments";
import EmoticonDisplay from "./EmoticonDisplay";
import ImageGallery from "./ImageGallery";
import Mention from "./Mention";
import MentionEditor from "./MentionEditor";
import NewsFlowPostModal from "./NewsFlowPostModal";
import PollAnswer from "./PollAnswer";
import PollResult from "./PollResult";
import ReactionButton from "./ReactionButton";
//import TestImageGallery from "./TestImageGallery";

const NewsFlowWidget = ({ layout, siteId, projectId }) => {

  const [newsMain, setNews] = useState([]);
  const user = useSelector(state => state.api.currentViewer.viewer);
  const intl = useIntl();

  const siteRoleRights = useSelector(
    state => state.ui.toggleWorkspaces.workspace.siteRoleRights
  );
  const { canEditNews } = siteRoleRights || {};

  const { newsFilterSetting } =
    layout || {};

  const [getNewsForSite, { data, error, loading }] = useLazyQuery(GET_NEWS_FOR_SITE);
  const [getNewsForUser, { data: companyNewsData, error: companyNewsError, loading: companyNewsLoading }] = useLazyQuery(GET_NEWS_FOR_USER);
  const [createNewsPost, { loading: savingNewsPost }] = useMutation(CREATE_NEWSPOST);
  const [updateNewsPostLike] = useMutation(UPDATE_NEWSPOST_LIKE)

  const [addComment] = useMutation(ADD_NEWS_COMMENT);

  const [castVote] = useMutation(VOTE_NEWSPOST);

  const [modalState, setModalState] = useState({ open: false, content: "", poll: null, imageAttachments: null });
  const [showEmoticonPopper, setShowEmoticonPopper] = useState(false);
  const [emoticonAnchorEl, setEmoticonAnchorEl] = useState(null);
  const [activePostId, setActivePostId] = useState(null); // Track which news post is active

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);



  const commentRefs = useRef({});
  const [commentValues, setCommentValues] = useState({}); // Add this to track values

  const handleSendMessageButtonClick = (e, siteNewsId) => {

    setEmoticonAnchorEl(null);
    setShowEmoticonPopper(false);
    const text = commentValues[siteNewsId] || '';
    handleAddComment(siteNewsId, text);
  }

  const handleKeyDown = (e, siteNewsId) => {
    if (e.key === "Enter") {
      if (!e.shiftKey) {
        e.preventDefault();
        const text = commentValues[siteNewsId] || '';
        if (text.trim()) {
          setEmoticonAnchorEl(null);
          setShowEmoticonPopper(false);
          handleAddComment(siteNewsId, text);
        }
      }
    }
  };

  const handleEmoticonOpen = (event, siteNewsId) => {

    setActivePostId(siteNewsId); // Set the active post ID
    if (!showEmoticonPopper || emoticonAnchorEl !== event.currentTarget) {
      setEmoticonAnchorEl(event.currentTarget);
      setShowEmoticonPopper(true);
    } else {
      setEmoticonAnchorEl(null);
      setShowEmoticonPopper(false);
    }
  };

  const handleEmojiClick = (emoji) => {
    if (activePostId) {

      commentRefs.current[activePostId]?.insertEmoticon(emoji.emoji);

    }
  };

  const handleCommentChange = (siteNewsId, value) => {

    setCommentValues(prev => ({
      ...prev,
      [siteNewsId]: value
    }));
  };

  const handleAddComment = async (siteNewsId, commentText) => {

    if ((commentText || "").trim() === "") {
      return;
    }

    try {
      const response = await addComment({
        variables: {
          siteNewsPostid: siteNewsId,
          comment: commentText
        }
      });

      const newComment = response.data.newsContext.addNewsComment;

      // Clear the input after successful comment
      setCommentValues(prev => ({
        ...prev,
        [siteNewsId]: ''
      }));

      commentRefs.current[siteNewsId]?.clear();

      setNews((prevNews) =>
        prevNews.map((item) =>
          item.id === siteNewsId ?
            { ...item, comments: [...item.comments, newComment] } : item));
    }
    catch (error) {
      console.error('Error adding comment:', error);
    }
  }

  const handleLikeNewsPost = async (siteNewsId, emoticon) => {
    try {
      const { data } = await updateNewsPostLike({
        variables: {
          siteNewsId,
          emoticon,
        },
      });

      const newReaction = data.newsContext.updatePostLikes;

      setNews((prevNews) =>
        prevNews.map((item) =>
          item.id === siteNewsId
            ? {
              ...item,
              likes: newReaction.emoticon === 0
                ? item.likes.filter((like) => like.userId !== newReaction.userId)
                : [
                  ...item.likes.filter((like) => like.userId !== newReaction.userId),
                  newReaction,
                ],
            }
            : item
        )
      );
    } catch (error) {
      console.error("Error updating reaction:", error);
    }
  };


  const handleLoadCompleted = (news) => {
    if (news) {
      setNews((prevNews) => [...prevNews, ...news]);
    }
  };

  const loadMore = () => {
    setPage((prevPage) => prevPage + 10);
  }

  useEffect(() => {
    if (data?.newsContext?.getNewsForSite) {

      handleLoadCompleted(data.newsContext.getNewsForSite)
    }
  }, [data, error, loading])

  useEffect(() => {
    if (companyNewsData?.newsContext?.getNewsForUser) {
      handleLoadCompleted(companyNewsData.newsContext.getNewsForUser)
    }
  }, [companyNewsData, companyNewsError, companyNewsLoading])

  const fetchNews = () => {

    if (Number.isFinite(siteId)) {

      getNewsForSite({
        variables: {
          offset: page,
          pageSize: perPage,
          siteId,
          projectId: (newsFilterSetting || 0) == 0 ? null : projectId
        }
      }

      );
    } else {
      getNewsForUser({
        variables: {
          offset: page,
          pageSize: perPage,
          projectId: (newsFilterSetting || 0) == 0 ? null : projectId
        }
      }
      );
    }
  };



  useEffect(() => {
    fetchNews();
  }, [siteId, projectId, page]);


  const handleVoteChoice = (siteNewsId, choices) => {

    setNews((prevNews) =>
      prevNews.map((item) => {

        if (item.id === siteNewsId) {
          return {
            ...item,
            poll: {
              ...item.poll,
              choices: choices,
            },
          };
        }

        return item;
      })
    );
  };

  const handleVote = async (siteNewsId, choices) => {

    const selectedChoiceIds = choices
      .filter(choice => choice.selected)
      .map(choice => choice.id);

    try {

      const response = await castVote({
        variables: {
          input: {
            siteNewsId: siteNewsId,
            choices: selectedChoiceIds
          }
        }
      });

      const updatedPoll = response.data.newsContext.vote;

      // Update the news collection with the new poll data
      setNews((prevNews) =>
        prevNews.map((item) =>
          item.id === siteNewsId
            ? { ...item, poll: updatedPoll }
            : item
        )
      );

    } catch (error) {
      console.error("Error casting vote:", error);
    }
  };

  const handleSaveNewsPost = async () => {
    try {
      const input = {
        //postImage: uploadedAsset ? uploadedAsset : null,
        thumbnailImage: null,//uploadedAsset,
        allowComments: false,
        category: "",
        leadText: modalState.content,// formValues.preamble,
        siteId,
        templateId: null,//selectedTemplateId,
        title: "",
        newsPost: "",
        projectId,
        poll: modalState.poll,
        imageAttachments: modalState.imageAttachments,
      };

      const res = await createNewsPost({ variables: { input } });

      if (
        res &&
        res.data &&
        res.data.newsContext &&
        res.data.newsContext.createNewsPost
      ) {
        const { success, result } = res.data.newsContext.createNewsPost;
        if (success) {
          //alert.success(<FormattedMessage id="common.genericSuccess" />);
          let temp = [result, ...newsMain];
          setNews(temp);
          setModalState({ open: false, content: "", poll: null, imageAttachments: null });
        } else {
          /*snackbar.workspaceError(
            <FormattedMessage id="common.genericErrorMessage" />
          );*/
        }

      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const renderLeadTextWithMentions = (leadText, mentions) => {
    const mentionRegex = /<@m(\d+)>/g;

    const replaceMentions = (node) => {
      if (node.type === "text") {
        const text = node.data;
        const parts = [];
        let lastIndex = 0;
        let match;

        while ((match = mentionRegex.exec(text)) !== null) {
          const mentionId = parseInt(match[1], 10);
          const mentionData = mentions.find((m) => m.mentionId === mentionId);

          // Add preceding text
          if (match.index > lastIndex) {
            parts.push(text.substring(lastIndex, match.index));
          }

          // Add Mention component or fallback text
          if (mentionData) {
            parts.push(
              <Mention
                key={mentionId}
                mentionId={mentionId}
                mentions={mentionData}
              />
            );
          } else {
            parts.push(match[0]); // Raw text if no matching mention
          }

          lastIndex = mentionRegex.lastIndex;
        }

        // Add remaining text
        if (lastIndex < text.length) {
          parts.push(text.substring(lastIndex));
        }

        // Wrap in a single parent element
        return <>{parts}</>;
      }

      // Recursively process child nodes for nested HTML
      if (node.children) {
        return {
          ...node,
          children: domToReact(node.children, { replace: replaceMentions }),
        };
      }

      return node;
    };

    return parse(leadText, { replace: replaceMentions });
  };


  const getSuggestionList = (post) => {
    const suggestionMap = new Map();

    // Add the post author to the suggestions

    suggestionMap.set(post.authorId, {
      name: post.author,
      avatar: post.author.avatarUrl,
      userId: post.authorId,
    });

    // Add the users who commented on the post
    (post.comments || []).forEach(({ author, authorId, avatarUrl }) => {
      suggestionMap.set(authorId, {
        name: author,
        avatar: avatarUrl,
        userId: authorId,
      });
    });

    //Add unique mentions from the post
    (post.mentions || []).forEach(({ userId, name, avatarUrl }) => {
      suggestionMap.set(userId, {
        name: name,
        avatar: avatarUrl,
        userId: userId,
      });
    });

    // Add unique mentions from the comments
    (post.comments || []).forEach(comment => {
      (comment.mentions || []).forEach(({ userId, name, avatarUrl }) => {
        suggestionMap.set(userId, {
          name: name,
          avatar: avatarUrl,
          userId: userId,
        });
      });
    });

    // Convert map values into an array and return
    return Array.from(suggestionMap.values());
  };

  return <DashboardWidget>
    <DashboardWidgetBody>
      <Box sx={{ maxWidth: "500px", margin: "auto" }}>
        {(canEditNews && (siteId || projectId)) && (
          <Box sx={{
            display: "flex",
            alignItems: "center",
            padding: 1,
            borderRadius: 2,
            border: "1px solid #ddd",
            boxShadow: 1,
            backgroundColor: "#fff",
            marginBottom: 3
          }}>
            {user && (
              <Grid container>
                <Grid item xs={2}><Avatar url={user.avatar} author={user.firstname + " " + user.lastname} /></Grid>
                <Grid item xs={10}>{user.firstname} {user.lastname}</Grid>
                <Grid item xs={12}><Box sx={{ backgroundColor: "#ddd", borderRadius: 2, padding: 2, marginTop: 1 }} onClick={() => setModalState({ ...modalState, open: true })}>Skriv</Box></Grid>
              </Grid>
            )}
          </Box>)}


        {newsMain && newsMain.map((n, index) => {

          const suggestionList = getSuggestionList(n);

          return (
            <Box key={n.id + "_" + index} sx={{ maxWidth: "500px", margin: "auto", padding: 2, borderRadius: 2, border: "1px solid #ddd", backgroundColor: "#fff", marginBottom: 2 }}>

              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Avatar author={n.author} url={n.avatarUrl} />
                <Box>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500, color: "#333" }}>{n.author}</Typography>
                  <Typography sx={{
                    fontSize: "13px", lineHeight: 1.2, fontWeight: 400, color: "#999" }}>
                    {moment(n.createdDate)
                      .format("L")
                      .toString()}
                  </Typography></Box>
              </Box>

              {n.thumbnail && (
                <Box sx={{
                  width: "100%",
                  marginTop: 2,
                  borderRadius: 1,
                  overflow: "hidden",
                  height: "300px",
                  backgroundColor: "#f0f2f5",
                  backgroundImage: `url(${n.thumbnail})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  lineHeight: "300px",
                  fontSize: 32,
                  textAlign: "center"
                }}>{n.title}</Box>)}

              {!n.thumbnail && (<Typography>{n.title}</Typography>)}




              {
                n.leadText && (
                  <Box sx={{ marginTop: 1.5, fontSize: "0.95rem", color: "#333" }}>
                    {renderLeadTextWithMentions(n.leadText, n.mentions)}
                  </Box>
                )
              }

              <Box sx={{ marginTop: 1 }}>
                <ImageGallery
                  imageUrls={(n.images || []).map(x => x.imageUrl)}
                //onClick={(url) => { }}
                />

                {/*{(index === 0) && <TestImageGallery />}*/}
              </Box>

              {n.poll && !n.poll.hasVoted && (<PollAnswer siteNewsId={n.id} poll={n.poll} onUpdateVote={handleVoteChoice} onVote={handleVote} />)}
              {n.poll && n.poll.hasVoted && <PollResult siteNewsId={n.id} poll={n.poll} />}

              <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
              <Grid container>
                <Grid item xs={1}>
                  <EmoticonDisplay likes={n.likes} />
                </Grid>
                <Grid item>
                  <ReactionButton postId={n.id} onLikePost={handleLikeNewsPost} />
                </Grid>
              </Grid>
              <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

              <Comments comments={n.comments} setNews={setNews} onAddComments={() => { console.log("comment added"); }} />
              {true && (
                <Box sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}>

                  <MentionEditor

                    disableToolbar={true} ref={(editor) => {
                      if (editor) {
                        // Dynamically assign ref
                        commentRefs.current[n.id] = editor;
                      }
                    }}
                    fillArea={true}
                    onEnterPressed={(commentText) => handleAddComment(n.id, commentText)}
                    initialSuggestion={suggestionList}
                    onBlur={(newContent) => handleCommentChange(n.id, newContent)} />

                  <Button

                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 0.5,
                      minWidth: 'auto',
                      backgroundColor: 'transparent',
                      cursor: 'pointer'
                    }}

                    onClick={(e) => handleEmoticonOpen(e, n.id)}>
                    <InsertEmoticonIcon />
                  </Button>

                  <Button
                    title={intl.formatMessage({
                      id: "messaging.sendMessageButton.title",
                    })}
                    onClick={(e) => handleSendMessageButtonClick(e, n.id)}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 0.5,
                      minWidth: 'auto',
                      backgroundColor: 'transparent',
                      cursor: 'pointer'
                    }}
                  >
                    <SendIcon />
                  </Button>
                </Box>)}

            </Box>)
        }
        )}
        <Button onClick={loadMore}>Visa mer</Button>
      </Box>
      <NewsFlowPostModal modalState={modalState} user={user} setModalState={setModalState} onSave={handleSaveNewsPost} isSaving={savingNewsPost} />
      <Popper
        open={showEmoticonPopper}
        anchorEl={emoticonAnchorEl}
        placement="bottom-start"
        style={{ position: 'absolute', zIndex: 1300 }}
      >
        <EmojiPicker
          onEmojiClick={(emoji) => handleEmojiClick(emoji)}
          searchDisabled={true}
          previewConfig={{ showPreview: false }}
          emojiStyle="native"
          emojiVersion="4.0" height={300} width={300}
          style={{
            '--epr-emoji-size': '20px',  // This should apply the size
            '--epr-emoji-gap': '4px',    // This controls the gap between emojis
          }} />
      </Popper>
    </DashboardWidgetBody>
  </DashboardWidget>
}

export default NewsFlowWidget;