import { handleActions } from "redux-actions";
import { setLocale } from "../../../actions";

const defaultState = {
  culture: (function() {
    return "sv-SE";
  })(),
};

export default handleActions(
  {
    [setLocale]: (state, { payload }) => {
      const { culture } = payload;
      return {
        ...state,
        culture: culture,
      };
    },
  },
  defaultState
);
