import React from 'react';
import { Group, Path, Rect } from 'react-konva';

const FolderZIPIcon = ({ x = 0, y = 0, width = 24, height = 24, fill = 'black', stroke = 'none', strokeWidth = 0, scale = { x: 1, y: 1 }, opacity = 1, listening = false, offsetX = 0, offsetY = 0, renderAsSVG = false }) => {
  return renderAsSVG ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      opacity={opacity}
    >
      <g>
        <path d="M21.412,6.587C21.021,6.196,20.55,6,20,6h-8l-2-2H4C3.45,4,2.979,4.196,2.587,4.587S2,5.45,2,6v12&#10;&#9;&#9;c0,0.551,0.196,1.021,0.587,1.412C2.979,19.805,3.45,20,4,20h16c0.55,0,1.021-0.195,1.412-0.588C21.805,19.021,22,18.551,22,18V8&#10;&#9;&#9;C22,7.45,21.805,6.979,21.412,6.587z M20,18H4V6h5.175l2,2H20V18z" />
        <path d="M4,18V6V18z" />
        <rect x="14" y="12" width="2" height="2" />
        <rect x="16" y="14" width="2" height="2" />
        <rect x="16" y="10" width="2" height="2" />
        <rect x="14" y="8" width="2" height="2" />
        <rect x="14" y="16" width="2" height="2" />
      </g>
    </svg>




    


  ) : (
    <Group
      x={x}
      y={y}
      width={width}
      height={height}
      scale={scale}
      listening={listening}
      offsetX={offsetX}
      offsetY={offsetY}
      opacity={opacity}
    >
        <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} data="M21.412,6.587C21.021,6.196,20.55,6,20,6h-8l-2-2H4C3.45,4,2.979,4.196,2.587,4.587S2,5.45,2,6v12&#10;&#9;&#9;c0,0.551,0.196,1.021,0.587,1.412C2.979,19.805,3.45,20,4,20h16c0.55,0,1.021-0.195,1.412-0.588C21.805,19.021,22,18.551,22,18V8&#10;&#9;&#9;C22,7.45,21.805,6.979,21.412,6.587z M20,18H4V6h5.175l2,2H20V18z" />
        <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} data="M4,18V6V18z" />
        <Rect fill={fill} stroke={stroke} strokeWidth={strokeWidth} x="14" y="12" width="2" height="2" />
        <Rect fill={fill} stroke={stroke} strokeWidth={strokeWidth} x="16" y="14" width="2" height="2" />
        <Rect fill={fill} stroke={stroke} strokeWidth={strokeWidth} x="16" y="10" width="2" height="2" />
        <Rect fill={fill} stroke={stroke} strokeWidth={strokeWidth} x="14" y="8" width="2" height="2" />
        <Rect fill={fill} stroke={stroke} strokeWidth={strokeWidth} x="14" y="16" width="2" height="2" />
    </Group>
  );
};

export default FolderZIPIcon;