import { useEffect, useRef } from "react";
import DropzoneComponent from "react-dropzone-component";
import "../../../../../../node_modules/react-dropzone-component/styles/filepicker.css";
import "../../../../../../node_modules/dropzone/dist/min/dropzone.min.css";

const FileUploadSelector = ({ children, clickable, disabled, onAcceptedFile, onRejectedFile }) => {
  const dzRef = useRef();

  const disableDropzone = () => dzRef?.current?.dropzone.disable();
  const enableDropzone = () => dzRef?.current?.dropzone.enable();
  const removeFile = (file) => dzRef?.current?.dropzone.removeFile(file);

  const componentConfig = {
    postUrl: "-",
  };

  const djsConfig = {
    accept: async (file, done) => {
      await onAcceptedFile?.(file);
      done();
      removeFile(file);
    },
    acceptedFiles: "image/gif,image/jpeg,image/png",
    autoProcessQueue: false,
    autoQueue: false,
    capture: "image/*",
    clickable: clickable || false,
    createImageThumbnails: false,
    disablePreviews: true,
    maxFilesize: 4000, // !!!
    previewsContainer: false,
    uploadMultiple: false,
  };

  const eventHandlers = {
    error: async (file) => {
      await onRejectedFile?.(file);
      removeFile(file);
    },
  };

  useEffect(() => {
    if (disabled) {
      disableDropzone();
    }
    else {
      enableDropzone();
    }
  }, [disabled]);

  return (
    <DropzoneComponent
      ref={dzRef}
      config={componentConfig}
      djsConfig={djsConfig}
      eventHandlers={eventHandlers}
    >
      {children}
    </DropzoneComponent>
  );
};

export default FileUploadSelector;
