import { gql } from '@apollo/client';

/**
 *  @deprecated Will be replaced by @see {@link GET_ALL_CONVERSATIONS}.
 */
const GET_CONVERSATIONS = gql`
  query getConversations {
    directMessages {
      conversations {
        directMessageConversationId
        latestMessageId
        latestReadMessageId
        latestReadMessageTime
        members {
          name
          userId
          username
          avatarUrl
        }
        unreadMessageCount
      }
    }
  }
`;

export default GET_CONVERSATIONS;
