import { gql } from '@apollo/client';

const getOneStreamJob = gql`
  query GetOneStreamJob(
    $siteId: Int!
    $sortField: String
    $ascending: Boolean!
    $offset: Int!
    $pageSize: Int
  ) {
    oneStream {
      oneStreamJobs(
        siteId: $siteId
        sortField: $sortField
        ascending: $ascending
        offset: $offset
        pageSize: $pageSize
      ) {
        result {
          errorFiles
          oKFiles
          oneStreamJobId
          jobName
          jobStatus
          latestStatus
          latestMessage
          totalFiles
          warningFiles
          username
          uploadDate
          jobType
          costPlace
        }
        totalItems
      }
    }
  }
`;

export default getOneStreamJob;
