import { ModalCompact, ModalCompactBody } from ".."
import ModalCompactActions from "./ModalCompactActions";
import ModalCompactHead from "./ModalCompactHead"

const SimpleModalCompact = ({ children, draggable, footer, fullScreen, onClose, open, title }) => {

  return (
    <>
      {open && (
        <ModalCompact draggable={draggable} fullScreen={fullScreen} open={open}>
          <ModalCompactHead handleClose={onClose} title={title} />
          <ModalCompactBody>{children}</ModalCompactBody>
          {footer && (
            <ModalCompactActions>{footer}</ModalCompactActions>
          )}
        </ModalCompact>
      )}
    </>
  );
}

export default SimpleModalCompact;
