import { useMutation, useLazyQuery } from "@apollo/client";
import { createContext, useEffect, useState } from "react";
import { ADD_COMMENT, ADD_FIGURE } from "../../graphql/mutations/ResourceVersionCommentContext";
import getCommentsAndFigures from "../../graphql/queries/getCommentsAndFigures";

export const commentsContext = createContext();

const { Provider } = commentsContext;

const _initialState = {};

const CommentsProvider = ({ children }) => {
  const [comments, setComments] = useState(_initialState);
  const [annotations, setAnnotations] = useState([]);

  const [addComment, { data, loading, error }] = useMutation(ADD_COMMENT);
  const [addFigure, { data: figureData, loading: figureLoading, error: figureError }] = useMutation(ADD_FIGURE);

  const [fetchComments, { data: commentData, loading: commentLoading, error: commentError }] = useLazyQuery(getCommentsAndFigures)

  const add = ({ comment, pageNo, resourceVersionId }) => {

    addComment({
      variables: {
        versionId: resourceVersionId,
        pageNo: pageNo,
        comment: comment
      }
    });
  };

  // Fetch initial comments for a product
  const loadComments = (productId) => {
    if (!productId) return;
    fetchComments({
      variables: { productId },
    });
  };

  useEffect(() => {
    if (commentData) {
      const productComments = commentData.productComments || [];

      // Flatten all comments across product versions and group by pageNo
      const commentsByPage = productComments.reduce((acc, productComment) => {
        const { comments = [], } = productComment;

        comments.forEach(({ pageNo, ...commentDetails }) => {
          if (!acc[pageNo]) acc[pageNo] = [];
          acc[pageNo].push({ pageNo, ...commentDetails });
        });       



        return acc;
      }, {});
      
      const annotationsData = productComments.flatMap((productComment) =>
        productComment.figures?.map((figure) => JSON.parse(figure.figureData)) || []
      );

      setComments((prevComments) => ({
        ...prevComments,
        ...commentsByPage,
      }));

      setAnnotations(annotationsData);

      
    }
  }, [commentData]);

  const addAnnotation = (resourceVersionId, pageNo, annotation) => {


    addFigure({
      variables: {
        versionId: resourceVersionId,
        pageNo: pageNo,
        figureData: JSON.stringify(annotation)
      }

    });

    setAnnotations([...annotations, annotation]);
  }

  const updateAnnotation = (id, update) => {
    debugger;
  }

  useEffect(() => {
    if (data) {
      debugger;
      const { pageNo } = data.resourceComments.addComment;
      setComments(prevComments => ({
        ...prevComments,
        [pageNo]: [...pageComments(pageNo), data.resourceComments.addComment],
      }));
    }
  }, [data])

  

  const pageComments = (pageNo) => (comments[pageNo] || []);

  return (
    <Provider value={{
      add,
      clear: () => setComments(_initialState),
      comments,
      pageCommentCount: (pageNo) => pageComments(pageNo).length,
      pageComments,
      loadComments, 
      commentLoading,
      commentError,
      annotations,
      addAnnotation,
      updateAnnotation
    }}>
      {children}
    </Provider>
  );
};

export default CommentsProvider;
