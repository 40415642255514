import { gql } from '@apollo/client';
import Poll from "../../fragments/Poll";

const GET_NEWS_FOR_USER = gql`
query GetNewsForUser($offset: Int!, $pageSize: Int!) {
	newsContext {
		getNewsForUser(offset: $offset, pageSize: $pageSize) {
			author
			authorId
			avatarUrl
			category
			createdDate
			id
			image
			itemType
			leadText
			site
			thumbnail
			title
			viewCount
			newsPost
			poll {
				...PollFragment
			}
			mentions {
				avatarUrl
				mentionId
				name
				username
				userId
			}
			likes {
				userId
				name
				emoticon
				isCurrentUser
			}
			comments {
				commentDate
				text
				username
				author
				authorId
				avatarUrl
				commentId
				likes {
					userId
					name
					emoticon
					isCurrentUser
				}
				mentions {
					avatarUrl
					mentionId
					name
					username
					userId
				}
			}
      images {
        imageUrl
      }
		}
	}
}
${Poll}
`;

export default GET_NEWS_FOR_USER;
