import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box } from "@mui/material"

const OrientationType = Object.freeze({
  LANDSCAPE: "landscape",
  PORTRAIT: "portrait",
  SQUARE: "square",
  UNKNOWN: "unknown",
});

const FlexWrapper = ({ aspectRatio, children, flexDirection = "row", width }) => (
  <Box sx={{
    aspectRatio,
    display: "flex",
    flexDirection,
    flexWrap: "wrap",
    gap: "2px",
    justifyContent: "space-between",
    width,
  }}>
    {children}
  </Box>
);

const ImageList = ({ aspectRatio, height, images, lastImageOverlay, onClick, width }) => (
  (images || []).map(
    (image, index) => (
      <ImageListItem
        aspectRatio={aspectRatio}
        height={height}
        image={image}
        imageOverlay={lastImageOverlay}
        onClick={(!!lastImageOverlay && index === images.length - 1) ? undefined : onClick}
        width={width}
      >
        {(index === images.length - 1) && lastImageOverlay}
      </ImageListItem>
    )
  )
);

const ImageListItem = ({ aspectRatio, children, image, height, onClick, width }) => (
  <Box
    onClick={() => onClick?.(image.url)}
    sx={{
      aspectRatio,
      backgroundImage: `url(${image.url})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      height,
      width,
      "&:hover": !!onClick && {
        cursor: "pointer",
        opacity: 0.9,
      },
    }}
  >
    {children}
  </Box>
);

const MoreImagesOverlay = ({ imageCount, onClick }) => (
  <Box
    onClick={() => onClick?.()}
    sx={{
      alignContent: "center",
      background: "#00000080",
      display: "flex",
      flexWrap: "wrap",
      height: "100%",
      justifyContent: "center",
      pointerEvents: "all",
      "&:hover": !!onClick && {
        cursor: "pointer",
        opacity: 0.9,
      },
    }}
  >
    <Box
      sx={{
        color: "#fff",
        fontSize: "xx-large",
        pointerEvents: "none",
      }}
    >
      +{imageCount}
    </Box>
  </Box>
);

const LandscapeLayout = ({ images, onClick, totalImageCount }) => {

  const length = (images || []).length;

  if (length === 0) {
    return;
  }

  const renderImages = () => {

    if (length == 1) {
      return (
        <FlexWrapper>
          <ImageList images={images} width="100%" aspectRatio={466 / 261} onClick={onClick} />
        </FlexWrapper>
      )
    }
    else if (length == 2) {
      //If the first two images differs in orientation, show them both as squares.
      return (
        <FlexWrapper>
          {(images[0].orientation === images[1].orientation)
            ? <ImageList images={images} width="100%" aspectRatio={466 / 232} onClick={onClick} />
            : <ImageList images={images} width="calc(50% - 1px)" aspectRatio={1} onClick={onClick} />}
        </FlexWrapper>
      );
    }
    else if (length == 3) {
      return (
        <FlexWrapper>
          <FlexWrapper width="100%">
            <ImageListItem image={images[0]} width="100%" aspectRatio={492 / 327} onClick={onClick} />
          </FlexWrapper>
          <FlexWrapper width="100%">
            <ImageList images={images.slice(1)} width="calc(50% - 1px)" aspectRatio={245 / 163} onClick={onClick} />
          </FlexWrapper>
        </FlexWrapper>
      )
    }
    else if (length == 4) {
      return (
        <FlexWrapper>
          <FlexWrapper width="100%">
            <ImageListItem image={images[0]} width="100%" aspectRatio={492 / 327} onClick={onClick} />
          </FlexWrapper>
          <FlexWrapper width="100%">
            <ImageList images={images.slice(1)} width="calc(100% / 3 - 2px)" aspectRatio={1} onClick={onClick} />
          </FlexWrapper>
        </FlexWrapper>
      )
    }
    else if (length >= 5) {
      const moreImagesCount = totalImageCount - 5;
      return (
        <FlexWrapper>
          <FlexWrapper flexDirection="column" width="calc(100% * (245 / 408) - 1px)" aspectRatio={0.5}>
            <ImageList images={images.slice(0, 2)} height="calc(50% - 1px)" onClick={onClick} />
          </FlexWrapper>
          <FlexWrapper flexDirection="column" width="calc(100% * (163 / 408) - 1px)">
            <ImageList
              images={images.slice(2, 5)} height="calc(100% / 3 - 2px)" onClick={onClick}
              lastImageOverlay={
                (moreImagesCount > 0) && <MoreImagesOverlay imageCount={moreImagesCount} onClick={onClick} />
              }
            />
          </FlexWrapper>
        </FlexWrapper>
      )
    }
  }

  return renderImages();
}

const PortraitLayout = ({ images, onClick, totalImageCount }) => {

  const length = (images || []).length;

  if (length === 0) {
    return;
  }

  const renderImages = () => {

    if (length == 1) {
      return (
        <FlexWrapper>
          <ImageList images={images} width="100%" aspectRatio={492 / 738} onClick={onClick} />
        </FlexWrapper>
      )
    }
    else if (length == 2) {
      //If the first two images differs in orientation, show them both as squares.
      return (
        <FlexWrapper>
          {(images[0].orientation === images[1].orientation)
            ? <ImageList images={images} width="calc(50% - 1px)" aspectRatio={245 / 492} onClick={onClick} />
            : <ImageList images={images} width="calc(50% - 1px)" aspectRatio={1} onClick={onClick} />}
        </FlexWrapper>
      );
    }
    else if (length == 3) {
      return (
        <FlexWrapper>
          <FlexWrapper width="calc(100% * (327 / 490) - 1px)" aspectRatio={327 / 490}>
            <ImageListItem image={images[0]} width="100%" onClick={onClick} />
          </FlexWrapper>
          <FlexWrapper width="calc(100% * (163 / 490) - 1px)" aspectRatio={163 / 245}>
            <ImageList images={images.slice(1)} width="100%" onClick={onClick} />
          </FlexWrapper>
        </FlexWrapper>
      )
    }
    else if (length == 4) {
      return (
        <FlexWrapper>
          <FlexWrapper width="calc(100% * (327 / 490) - 1px)" aspectRatio={327 / 490}>
            <ImageListItem image={images[0]} width="100%" onClick={onClick} />
          </FlexWrapper>
          <FlexWrapper width="calc(100% * (163 / 490) - 1px)" aspectRatio={163 / 245}>
            <ImageList images={images.slice(1)} width="100%" onClick={onClick} />
          </FlexWrapper>
        </FlexWrapper>
      )
    }
    else if (length >= 5) {
      const moreImagesCount = totalImageCount - 5;
      return (
        <FlexWrapper>
          <FlexWrapper width="100%" height="calc(100% * (245 / 490) - 1px)">
            <ImageList images={images.slice(0, 2)} width="calc(100% / 2 - 1px)" aspectRatio={1} onClick={onClick} />
          </FlexWrapper>
          <FlexWrapper width="100%" height="calc(100% * (194 / 466) - 1px)">
            <ImageList
              images={images.slice(2, 5)} width="calc(100% / 3 - 2px)" aspectRatio={1} onClick={onClick}
              lastImageOverlay={
                (moreImagesCount > 0) && <MoreImagesOverlay imageCount={moreImagesCount} onClick={onClick} />
              }
            />
          </FlexWrapper>
        </FlexWrapper>
      )
    }
  }

  return renderImages();
}

const SquareLayout = ({ images, onClick, totalImageCount }) => {

  const length = (images || []).length;

  if (length === 0) {
    return;
  }

  const renderImages = () => {

    if (length == 1) {
      return (
        <FlexWrapper>
          <ImageList images={images} width="100%" aspectRatio={1} onClick={onClick} />
        </FlexWrapper>
      )
    }
    else if (length == 2) {
      return (
        <FlexWrapper>
          <ImageList images={images} width="calc(50% - 1px)" aspectRatio={1} onClick={onClick} />
        </FlexWrapper>
      );
    }
    else if (length == 3) {
      return (
        <FlexWrapper>
          <FlexWrapper width="100%">
            <ImageListItem image={images[0]} width="100%" aspectRatio={492 / 245} onClick={onClick} />
          </FlexWrapper>
          <FlexWrapper width="100%">
            <ImageList images={images.slice(1)} width="calc(50% - 1px)" aspectRatio={1} onClick={onClick} />
          </FlexWrapper>
        </FlexWrapper>
      )
    }
    else if (length == 4) {
      return (
        <FlexWrapper>
          <FlexWrapper width="100%">
            <ImageList images={images.slice(0, 2)} width="calc(50% - 1px)" aspectRatio={1} onClick={onClick} />
          </FlexWrapper>
          <FlexWrapper width="100%">
            <ImageList images={images.slice(2, 4)} width="calc(50% - 1px)" aspectRatio={1} onClick={onClick} />
          </FlexWrapper>
        </FlexWrapper>
      )
    }
    else if (length >= 5) {
      const moreImagesCount = totalImageCount - 5;
      return (
        <FlexWrapper>
          <FlexWrapper width="100%">
            <ImageList images={images.slice(0, 2)} width="calc(50% - 1px)" aspectRatio={1} onClick={onClick} />
          </FlexWrapper>
          <FlexWrapper width="100%">
            <ImageList
              images={images.slice(2, 5)} width="calc(100% / 3 - 2px)" aspectRatio={1} onClick={onClick}
              lastImageOverlay={
                (moreImagesCount > 0) && <MoreImagesOverlay imageCount={moreImagesCount} onClick={onClick} />
              }
            />
          </FlexWrapper>
        </FlexWrapper>
      )
    }
  }

  return renderImages();
}

const ImageGallery = ({ disabled, imageUrls, loadingMessage, onClick }) => {
  const [imageData, setImageData] = useState([]);
  const [loading, setLoading] = useState(true);

  const determineImageOrientation = (aspectRatio) => {
    if (Math.abs(aspectRatio - 1) < 0.2) return OrientationType.SQUARE;
    if (aspectRatio > 1) return OrientationType.LANDSCAPE;
    return OrientationType.PORTRAIT;
  }

  const determineLayout = (images) => images[0]?.orientation ?? OrientationType.UNKNOWN;

  useEffect(() => {
    if (!imageUrls) {
      return;
    }

    const fetchImages = async () => {

      const limitedUrls = imageUrls.slice(0, 5);
      const promises = limitedUrls.map(
        (url) =>
          new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => {

              const aspectRatio = img.naturalWidth / img.naturalHeight;
              const image = {
                url,
                width: img.naturalWidth,
                height: img.naturalHeight,
                aspectRatio,
                orientation: determineImageOrientation(aspectRatio)
              }

              resolve(image);
            };
            img.onerror = () => resolve(null); // Handle broken links gracefully
          })
      );

      const results = (await Promise.all(promises)).filter(Boolean);

      setImageData(results);
      setLoading(false);
    };

    fetchImages();
  }, [imageUrls]);

  if (loading) {
    let message;

    if (typeof loadingMessage === "undefined") {
      message = <><FormattedMessage id="newsFlowWidget.images.loading" />...</>;
    }
    else {
      message = loadingMessage;
    }

    return (<Box>{message}</Box>);
  }

  const layout = determineLayout(imageData);

  switch (layout) {
    case OrientationType.LANDSCAPE:
      return (
        <LandscapeLayout images={imageData} onClick={!disabled && onClick} totalImageCount={imageUrls.length} />
      );
    case OrientationType.PORTRAIT:
      return (
        <PortraitLayout images={imageData} onClick={!disabled && onClick} totalImageCount={imageUrls.length} />
      );
    case OrientationType.SQUARE:
      return (
        <SquareLayout images={imageData} onClick={!disabled && onClick} totalImageCount={imageUrls.length} />
      );
  }

  return null;
};

export default ImageGallery;
