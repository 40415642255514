import {
  ContainerIcon,
  FolderIcon,
  FolderUploadIcon,
  FolderZIPIcon,
  PreflightIcon,
  TemplateIcon,
  XMLIcon,
} from '../../icons';
import { STEP_TYPE } from "../../utils/types";

const StepTypeIcon = ({ type, x, y, fill, scale, opacity, listening }) => {
  const props = { type, x, y, fill, scale, opacity, listening };
  switch (type) {
    case STEP_TYPE.CONTAINER: return <ContainerIcon {...props} />
    case STEP_TYPE.FOLDER: return <FolderIcon {...props} />
    case STEP_TYPE.FOLDER_UPLOAD: return <FolderUploadIcon {...props} />
    case STEP_TYPE.FOLDER_ZIP: return <FolderZIPIcon {...props} />
    case STEP_TYPE.PREFLIGHT: return <PreflightIcon {...props} />
    case STEP_TYPE.TEMPLATES: return <TemplateIcon {...props} />
    case STEP_TYPE.XML_LOADING: return <XMLIcon {...props} />
    default: return <ContainerIcon {...props} />
  }
};

export default StepTypeIcon;
