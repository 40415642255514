import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
    "&.MuiGrid-item": {
      padding: 20,
    },
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 10,
  },
  body: {
    padding: 20,
  },
  card: {
    //padding: 15,
    //borderRadius: 4,
    //border: "1px solid #F2F2F2",
    //marginBottom: 15,
    padding: "0 15px",
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
    marginTop: "1em",
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
  },
  textItalic: {
    fontSize: 12,
    fontStyle: "italic",
    marginBottom: 10,
  },
}));

const lastUpdatedDate = new Date(2020, 9, 5);

const CookiesSection = ({ lang }) => {
  const classes = useStyles();

  const Card = ({ children, hidden = false }) => {
    return !hidden && <Box className={classes.card}>{children}</Box>;
  };

  const Heading = ({ children, hidden = false, ...rest }) => {
    return (
      !hidden && (
        <Typography className={classes.heading} {...rest}>
          {children}
        </Typography>
      )
    );
  };

  const Label = ({ children, hidden = false }) => {
    return (
      !hidden && <Typography className={classes.label}>{children}</Typography>
    );
  };

  const Section = ({ children, hidden = false }) => {
    return !hidden && <>{children}</>;
  };

  const Text = ({ children, hidden = false }) => {
    return (
      !hidden && (
        <Typography className={classes.text} variant="body1">
          {children}
        </Typography>
      )
    );
  };

  const LastUpdated = () => {
    const lastUpdated = moment(lastUpdatedDate)
      .locale(lang)
      .format("LL");
    return (
      <>
        <FormattedMessage id="common.lastUpdated" />: {lastUpdated}
      </>
    );
  };

  const ContentInSwedish = () => {
    return (
      <>
        <Typography className={classes.headTitle} variant="h1">
          Kakor på uniqueue.se
        </Typography>
        <Text>
          På uniqueue.se använder vi kakor. En kaka (eller cookie) är en liten
          textfil som lagras på din dator och som innehåller information.
          Huvudsyftet med kakor är att underlätta för besökaren på webbplatsen.
        </Text>

        <Section>
          <Heading variant="h2">Övrigt</Heading>
          <Text>
            Förutom kakor används även en liknande teknik som kallas
            localStorage på UniQueues webbplats. Det används till exempel för
            att minnas besökarens val av arbetsyta. Inget av detta överför någon
            information till tredje part eller används för att samla uppgifter
            om dig.
          </Text>
        </Section>

        <Section>
          <Heading variant="h2">Undvika kakor</Heading>
          <Text>
            Vill du inte acceptera kakor kan din webbläsare ställas in så att du
            automatiskt nekar till lagring av kakor eller informeras varje gång
            en webbplats begär att få lagra en kaka. Då kommer du för varje
            sidhämtning att få upp den kortfattade informationen om kakor.
            Klicka OK så stängs detta meddelande och du kan ta del av allt
            innehåll som inte kräver kakor.
          </Text>
          <Text>
            Genom webbläsaren kan också tidigare lagrade kakor raderas. Se
            webbläsarens hjälpsidor för mer information.
          </Text>
        </Section>
      </>
    );
  };

  const ContentInEnglish = () => {
    return (
      <>
        <Typography className={classes.headTitle} variant="h1">
          Cookies at uniqueue.se
        </Typography>
        <Text>
          At uniqueue.se we use cookies. A cookie is a small text file stored on
          your computer that contains information. The main purpose of cookies
          is to make it easier for the visitor to the website.
        </Text>

        <Section>
          <Heading variant="h2">Miscellaneous</Heading>
          <Text>
            In addition to cookies, a similar technology called localStorage is
            also used on the UniQueue website. It is used, for example, to
            remember the visitor's choice of workspace. None of this transfers
            any information to third parties or is used to collect data about
            you.
          </Text>
        </Section>

        <Section>
          <Heading variant="h2">Avoid cookies</Heading>
          <Text>
            If you do not want to accept cookies, your browser can be set so
            that you automatically refuse the storage of cookies or be informed
            every time a website requests to store a cookie. Then for each page
            download you will get the brief information about cookies. Click OK
            and this message will close and you can enjoy all content that does
            not require cookies.
          </Text>
          <Text>
            Through the browser, previously stored cookies can also be deleted.
            See your browser's help pages for more information.
          </Text>
        </Section>
      </>
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6} className={classes.root}>
        {lang === "sv-SE" && <ContentInSwedish />}
        {lang !== "sv-SE" && <ContentInEnglish />}
        <Typography
          className={classes.textItalic}
          style={{ marginBottom: 0, textAlign: "right" }}
        >
          <LastUpdated />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CookiesSection;
