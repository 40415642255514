import { useLazyQuery } from "@apollo/client";
import { GET_CONVERSATION } from "../../graphql/queries/DirectMessages";

const extractChannelData = (data) => data?.directMessages?.conversation;

const useConversationData = ({ onCompleted, onError } = {}) => {

  const [getConversationQuery, { data, error, loading }] = useLazyQuery(
    GET_CONVERSATION,
    {
      errorPolicy: "none",
      onCompleted: (data) => {
        const conversation = extractChannelData(data);
        console?.info("** Completed query: Conversation", { conversation });
        onCompleted?.(conversation);
      },
      onError: (error) => {
        console?.error("** Query error: Conversation", { error });
        onError?.(error);
      },
    }
  );

  const executeGetConversationQuery = async (directMessageConversationId, last = 50) => (
    await getConversationQuery({
      variables: {
        directMessageConversationId,
        last,
      }
    })
  );

  return [
    executeGetConversationQuery,
    {
      data: extractChannelData(data),
      error,
      loading,
    }
  ];

};

export default useConversationData;
