import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTermsOfUseContext } from "../../../hooks/useTermsOfUseContext";
import { Default } from "../index";

const useStyles = theme => ({
  root: {
    backgroundColor: theme.palette.component.pageBackground,
    height: "100%",
    maxHeight: "100%",
    position: "relative",
    paddingTop: 2,
    "& > :nth-child(1)": {
      minHeight: "calc(100vh - 96px)",
      "@supports (height: 100dvh)": {
        minHeight: "calc(100dvh - 96px)", // Override if `dvh` is supported
      },
    },
    //[theme.breakpoints.between("sm", "md")]: {
    //  paddingTop: 55,
    //},
    [theme.breakpoints.up("md")]: {
      paddingTop: 36,
    },
  },
});

/**
 * Standard view for displaying content for logged in users.
 * Checks if the user has accepted the "Terms of Use", and if not,
 * presents a dialog where the user can view and accept the terms.
 *
 * @param {object} param0
 * @param {any} param0.children
 * @param {any} param0.classes
 * @param {boolean} param0.noConsentCheck Bypasses the "Terms of Use" check if true.
 */
const DefaultView = ({ children, classes, noConsentCheck = false }) => {
  const termsOfUseContext = useTermsOfUseContext();
  const { userMustAcceptTermsOfUse, userMustSetupMfa } = termsOfUseContext;
  const consentNeeded = userMustAcceptTermsOfUse && !noConsentCheck;
  
  useEffect(() => {
    
    if (consentNeeded) {
      termsOfUseContext.openDialog();
    }
    else if (!userMustAcceptTermsOfUse && userMustSetupMfa) {
      termsOfUseContext.openMfaDialog();
    }

  }, [consentNeeded, userMustSetupMfa, userMustAcceptTermsOfUse]);

  return (
    !consentNeeded && !userMustSetupMfa && (
      <>
        <Default
          history={
            children.props && children.props.history
              ? children.props.history
              : null
          }
        />
        <Box className={classes.root}>{children}</Box>
      </>
    )
  );
};

export default withStyles(useStyles)(DefaultView);
