import { useLazyQuery, useMutation } from "@apollo/client";

import { GET_FOLDER, GET_RESOURCES } from "../../graphql/queries";
import { TreeNodeContainer } from "../ProductsMainView";
import FolderList from "../ProductsMainView/FolderList"



import { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { Box, Card, CardMedia, CardContent, DialogContent } from '@mui/material';

import { pdfjs, Document, Page, Outline, Thumbnail } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { INITIALIZE_RESOURCE_DOWNLOAD } from "../../graphql/mutations";
import ShowContenDialog from "./ImageList/ShowContentDialog";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


const FileViewer = ({ folderId }) => {

  const [folders, setFolders] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState();
  const [openedNodes, setOpenedNodes] = useState([]);

  const [resources, setResources] = useState([])
  const [getFolder, { loading, error, data }] = useLazyQuery(GET_FOLDER);
  const [getResources, { loading: resourceLoding, error: resourceError, data: resourceData }] = useLazyQuery(GET_RESOURCES);
  const [currentResource, setCurrentResource] = useState();
  const [selectedPdf, setSelectedPdf] = useState();

  useEffect(() => {

    if (folderId) {
      getFolder({ variables: { folderId } });
    }

  }, [folderId]);


  useEffect(() => {

    if (data) {

      let siteFolders = [data.folder];
      setFolders(siteFolders);
    }

  }, [data]);


  useEffect(() => {
    if (currentFolderId) {
      getResources({ variables: { folderId: currentFolderId, offset: 0, pageSize: 10 } });
    }
  }, [currentFolderId]);


  useEffect(() => {

    if (resourceData) {

      let resources = resourceData?.resources?.items?.filter(n => n.fileExtension === ".pdf") || [];
      setResources(resources);

      if (resources.length > 0) {
        setCurrentResource(resources[0]);
      }

      if (resources.length === 1) {
        handleCardClick(resources[0]);
      }
    }


  }, [resourceData]);

  useEffect(() => {

    if (currentResource) {
      const foo = getDownloadUrl(currentResource.id);
    }

  }, [currentResource])


  const [numPages, setNumPages] = useState();
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState();

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  const [initDownloadResource] = useMutation(INITIALIZE_RESOURCE_DOWNLOAD);

  const getDownloadUrl = async item => {
    // versionId set to hardcode since we cannot fetch versionId from the resource at the moment

    try {
      const res = await initDownloadResource({
        variables: { resourceId: item, versionId: 0 },
      });
      if (res && res.data && res.data.initResourceDownload) {
        return res.data.initResourceDownload;
        //setPdfUrl(downloadUri);
      } else {
        console.log("Download Failure");
      }
    } catch (e) {
      console.log(`Error downloading product ${e}`);
    }
  };

  const handleOpenNode = useCallback((id) => {
    setOpenedNodes((prev) => {
      const nodeIndex = prev.indexOf(id);
      return nodeIndex === -1 ? [...prev, id] : prev.filter((x) => x !== id);
    });
  }, []);

  const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
  };

  const [pdfUrl, setPdfUrl] = useState();

  const handleCardClick = async (pdf) => {
    setSelectedPdf(pdf);
    let url = { pdfUrl: await getDownloadUrl(pdf.id) };
    setPdfUrl(url);
  };

  const handleClose = () => {
    setSelectedPdf(null);
    setPdfUrl(null);
  };


  return <Box sx={{ display: "flex", justifyContent: "space-between" }}>
    {pdfUrl && <ShowContenDialog onClose={handleClose} selectedValue={pdfUrl} open={pdfUrl} />}
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
      {resources.map((resource) => (
        <Card key={resource.id} sx={{ maxWidth: 200 }} onClick={() => handleCardClick(resource)}>
          <CardMedia
            component="img"
            height="140"
            image={resource.thumbnailImageUri || "/default-thumbnail.jpg"}
            alt={resource.name}
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {resource.name}
            </Typography>
          </CardContent>
        </Card>
      ))}


    </Box>

    <Box>

      {folders?.length > 0 && (
        folders.map((folder) => (
          <TreeNodeContainer
            key={folder.folderId}
            data={folder}
            openedNodes={openedNodes}
            handleOpenNode={handleOpenNode}
            selectedFolderId={currentFolderId}
            setFolderId={setCurrentFolderId}
          />
        ))
      )}
    </Box>
  </Box>
}

export default FileViewer;