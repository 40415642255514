import { gql } from '@apollo/client';


const UPDATE_CULTURE = gql`
 mutation updateAuthenticatorSecret($culture:String!){
	userAccountContext{
		updateUserCulture(culture:$culture)
	}
}`;

export default UPDATE_CULTURE;
