import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { Loading } from "../../common";
import SimpleModalCompact from "../../../components/common/ModalCompact/SimpleModalCompact";
import { DYNAMIC_PRODUCT_TYPE } from "../../../constant/types";
import { useDeepLinkContext } from "../../../hooks";
import DynamicProduct from "./DynamicProduct";
import NonDynamicProduct from "./NonDynamicProduct";

import { CommentsProvider, PaginationProvider } from "../../../context/FullscreenAnnotationModal";

const ProductModal = ({
  customMetadata,
  fromFileView,
  handleAddImageBasket,
  handleClose,
  isImage,
  loading,
  open,
  product,
  quantity,
  setQuantity,
}) => {
  const { clearData: clearDeepLinkData, deepLink } = useDeepLinkContext();

  const _handleClose = () => {
    if (deepLink) {
      clearDeepLinkData();
    }
    handleClose?.();
  };

  const renderBody = () => (
    (product.productType === DYNAMIC_PRODUCT_TYPE) ? (
      <DynamicProduct
        handleClose={_handleClose}
        product={product}
        quantity={quantity}
        setQuantity={setQuantity}
        customMetadata={customMetadata}
      />
    ) : (
      <PaginationProvider>
        <CommentsProvider>
          <NonDynamicProduct
            handleClose={_handleClose}
            product={product}
            quantity={quantity}
            setQuantity={setQuantity}
            isImage={isImage}
            handleAddImageBasket={handleAddImageBasket}
            customMetadata={customMetadata}
            fromFileView={fromFileView}
          />
        </CommentsProvider>
      </PaginationProvider>
    ));

  const renderLoading = () => (
    <Box sx={{
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
    }}>
      <Loading />
    </Box>
  );

  return (
    !deepLink &&
    <SimpleModalCompact fullScreen
      onClose={_handleClose}
      open={open}
      title={product.name}
    >
      {loading ? renderLoading() : renderBody()}
    </SimpleModalCompact>
  );
};

ProductModal.defaultProps = {
  customMetadata: null,
  fromFileView: false,
  loading: false,
  quantity: null,
};

ProductModal.propTypes = {
  customMetadata: PropTypes.arrayOf(PropTypes.shape({})),
  handleAddImageBasket: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isImage: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    minimumPrice: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    quantities: PropTypes.shape({
      selectionType: PropTypes.string.isRequired,
      valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    quantity: PropTypes.number,
  }).isRequired,
  quantity: PropTypes.number,
  setQuantity: PropTypes.func.isRequired,
};

export default ProductModal;
