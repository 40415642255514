import { useState } from "react";
import { Tab, Tabs, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TabPanel from "../TabPanel";
import InstructionsTab from "./InstructionsTab";
import SettingsTab from "./SettingsTab";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 12,
    //fontWeight: 500,
    marginBottom: 1,
    marginTop: "1em",
  },
}));

const StepDetailsTabs = ({ initialTabIndex = 0, onChange, step }) => {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(initialTabIndex);

  const onChangeTab = (event, newTabIndex) => {
    setSelectedTab(newTabIndex);
  };

  return (
    <>
      <Toolbar variant="dense" sx={{ marginTop: "-1em" }}>
        <Tabs
          onChange={onChangeTab}
          scrollButtons="auto"
          sx={{
            marginBottom: "1em",
            "& .MuiButtonBase-root": {
              fontSize: "small",
              padding: "0.5em",
            },
          }}
          value={selectedTab}
          variant="scrollable"
        >
          <Tab label="Inställningar" {...a11yProps(0)} />
          <Tab label="Instruktioner" {...a11yProps(1)} />
        </Tabs>
      </Toolbar>

      <TabPanel value={selectedTab} index={0}>
        <SettingsTab classes={classes} onChange={onChange} step={step} />
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <InstructionsTab classes={classes} onChange={onChange} step={step} />
      </TabPanel>
    </>
  );
};

export default StepDetailsTabs;
