function Point(x, y) {
  this.x = x;
  this.y = y;
}

function get(obj, prop, fallback) {
  if (obj.hasOwnProperty(prop)) return obj[prop];
  return fallback;
}

function intersect(p, q, r) {
  var dx = q.x - p.x;
  var dy = q.y - p.y;

  var len = Math.sqrt(dx * dx + dy * dy);
  var a = 0.5 * len / r;

  if (a < -1) a = -1;
  if (a > 1) a = 1;

  var phi = Math.atan2(dy, dx);
  var gamma = Math.acos(a);

  return [phi - gamma, Math.PI + phi + gamma];
}

export const calculateCloud = (poly, options) => {
  const radius = get(options, "radius", 20);
  const overlap = get(options, "overlap", 5 / 6);
  const stretch = get(options, "stretch", true);
  let circle = [];
  const delta = 2 * radius * overlap;
  let prev = poly[poly.length - 1];
  for (let i = 0; i < poly.length; i++) {
    let curr = poly[i];
    let dx = curr.x - prev.x;
    let dy = curr.y - prev.y;
    let len = Math.sqrt(dx * dx + dy * dy);
    dx = dx / len;
    dy = dy / len;
    let d = delta;
    if (stretch) {
      let n = Math.floor(len / delta + 0.5);
      if (n < 1) n = 1;
      d = len / n;
    }
    for (let a = 0; a + 0.1 * d < len; a += d) {
      circle.push({
        x: prev.x + a * dx,
        y: prev.y + a * dy,
      });
    }
    prev = curr;
  }
  let prevCircle = circle[circle.length - 1];
  for (let i = 0; i < circle.length; i++) {
    let currCircle = circle[i];
    let angle = intersect(prevCircle, currCircle, radius);
    prevCircle.end = angle[0];
    currCircle.begin = angle[1];
    prevCircle = currCircle;
  }
  return circle;
};

// Function to draw the calculated cloud shape on a canvas
export const drawCloud = (context, shape, options) => {

  const offset = options?.radius ?? 20; 

  const poly = [
    { x: offset, y: offset },
    { x: shape.width, y: offset },
    { x: shape.width, y: shape.height },
    { x: offset, y: shape.height },
  ];

  const cloud = calculateCloud(poly, options);

  if (!cloud || !Array.isArray(cloud) || cloud.length === 0) {
    console.error('calculateCloud did not return a valid array or returned an empty array');
    context.beginPath();
    context.rect(0, 0, shape.width, shape.height);
    if (options.fill) {
      context.fillStyle = options.fill;
      context.fill();
    }
    if (options.outline) {
      context.strokeStyle = options.outline;
      context.lineWidth = get(options, 'width', 1.0);
      context.stroke();
    }
    return;
  }

  const radius = get(options, 'radius', 20);

  context.beginPath();
  if (get(options, 'fill', false)) {
    context.fillStyle = options.fill;
    for (let i = 0; i < cloud.length; i++) {
      const curr = cloud[i];
      if (curr.begin !== undefined && curr.end !== undefined) {
        context.arc(curr.x, curr.y, radius, curr.begin, curr.end);
      }
    }
    context.fill();
  }

  if (get(options, 'outline', false)) {
    context.strokeStyle = options.outline;
    context.lineWidth = get(options, 'width', 1.0);

    const incise = Math.PI * get(options, 'incise', 15) / 180;

    for (let i = 0; i < cloud.length; i++) {
      const curr = cloud[i];
      if (curr.begin !== undefined && curr.end !== undefined) {
        context.beginPath();
        context.arc(curr.x, curr.y, radius, curr.begin, curr.end + incise);
        context.stroke();
      }
    }
  }
};