import { clamp } from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { Avatar, Badge, Box } from "@mui/material";
import { CloseButton as RemoveButton } from "./common/Buttons";

const EditImagesPanel = ({ disabled, images, onRemoveImage }) => {

  const bottomRef = useRef(null);
  const prevCount = useRef(images.length);

  const maxCols = clamp(images.length, 1, 4);

  const columnWidths = useMemo(
    () => ({
      xs: `calc(100% / ${Math.min(2, maxCols)} - 10px)`,
      sm: `calc(100% / ${Math.min(3, maxCols)} - 10px)`,
      md: `calc(100% / ${Math.min(4, maxCols)} - 10px)`,
    }), [maxCols]
  );

  useEffect(() => {
    const newCount = images.length;
    if (bottomRef.current && newCount > prevCount.current) {
      bottomRef.current.scrollIntoView();
    }
    prevCount.current = newCount;
  }, [images]);

  const ImageItem = ({ children, disabled, onClick, onRemove, src }) => (
    <Badge
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      badgeContent={
        <RemoveButton disabled={disabled} onClick={onRemove} />
      }
      slotProps={{
        badge: {
          sx: {
            margin: 2.5,
          },
        },
        root: {
          sx: {
            aspectRatio: 1,
            border: "1px solid #ddd",
            cursor: disabled ? undefined : "pointer",
            margin: 0.5,
            position: "relative",
            minWidth: {
              md: "190px",
            },
            width: columnWidths
          },
        },
      }}
      slots={{
        root: Box,
      }}
    >
      <Avatar
        variant="rounded"
        onClick={onClick}
        src={src}
        sx={{
          height: "100%",
          width: "100%",
          "& :hover": {
            backgroundColor: disabled ? undefined : "#000",
            transition: disabled ? undefined : "background-color 0.25s ease",
          },
          "& img": {
            objectFit: "contain"
          },
        }}
      >
        {children}
      </Avatar>
    </Badge>
  );

  return (
    <Box sx={{ border: 1, borderRadius: 2, padding: 0.5 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "2px", maxWidth: "900px", overflow: "auto" }}>
        {(images.length > 0) && (
          images.map((image, _) => (
            <ImageItem
              disabled={disabled}
              key={image.key}
              src={image.downloadUrl}
              onRemove={() => onRemoveImage?.(image.key)}
            />
          ))
        )}
        <Box ref={bottomRef} />
      </Box>
    </Box>
  );
}

export default EditImagesPanel;
