import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { useMessagingContext } from "../../hooks/Messaging";
import DesktopView from "./DesktopView";
import MobileView from "./MobileView";

const Messaging = ({
  channels,
  conversations,
  setSiteId,
}) => {
  const theme = useTheme();

  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

  const {
    activeChannel,
    activeChannelId,
    activeChannelLoading,
    activeConversation,
    activeConversationId,
    activeConversationLoading,
    addConversation,
    channelsLoading,
    conversationsLoading,
    selectChannel,
    selectConversation,
  } = useMessagingContext();

  const handleOnConversationAdded = async ({ conversation }) => {
    if (addConversation(conversation)) {
      const { directMessageConversationId } = conversation;
      selectConversation(directMessageConversationId);
    }
  };

  return (
    <Box
      sx={{
        flex: 1,
        height: {
          xs: "calc(100vh - 98px)", // Fallback for unsupported browsers
          "@supports (height: 100dvh)": "calc(100dvh - 98px)", // Modern browsers
        },
      }}
    >
      {isMobileView ? (
        <MobileView
          activeChannelId={activeChannelId}
          activeConversationId={activeConversationId}
          channel={activeChannel}
          channelLoading={activeChannelLoading}
          channels={channels}
          channelsLoading={channelsLoading}
          conversation={activeConversation}
          conversationLoading={activeConversationLoading}
          conversations={conversations}
          conversationsLoading={conversationsLoading}
          onChannelSelected={selectChannel}
          onConversationAdded={handleOnConversationAdded}
          onConversationSelected={selectConversation}
          setSiteId={setSiteId}
        />
      ) : (
        <DesktopView
          activeChannelId={activeChannelId}
          activeConversationId={activeConversationId}
          channel={activeChannel}
          channelLoading={activeChannelLoading}
          channels={channels}
          channelsLoading={channelsLoading}
          conversation={activeConversation}
          conversationLoading={activeConversationLoading}
          conversations={conversations}
          conversationsLoading={conversationsLoading}
          onChannelSelected={selectChannel}
          onConversationAdded={handleOnConversationAdded}
          onConversationSelected={selectConversation}
          setSiteId={setSiteId}
        />
      )}
    </Box>
  );
};

export default Messaging;
