import React, { useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import axios from "axios";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Avatar, Button, Box, CircularProgress, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { commonErrors } from "../../../constant/errorCodes";
import { INIT_SITE_NEWS_ASSET_UPLOAD } from "../../../graphql/mutations";
import { UPLOAD_NEWS_POST_THUMBNAIL } from "../../../graphql/mutations/NewsContext";
import { getSiteId } from "../../../helpers/selectors";
import { useSnackbar } from "../../../hooks";
import AvatarEditorModal from "../AvatarEditorModal";
import ButtonPrimaryAltSm from "../ButtonPrimaryAltSm";
import Chip from "../Chip";
import TextAreaFull from "../FormControl/TextAreaFull";
import TemplatesList from "../TemplatesList";
import TextField from "../TextField";
import { ErrorText } from "..";
import Label from "./Label";

const { NOT_AUTHORIZED } = commonErrors;

const useStyles = makeStyles(theme => ({
  modalBody: {
    padding: 20,
    paddingBottom: 15,
    backgroundColor: theme.palette.common.white,
    "& .MuiGrid-container:last-of-type": {
      marginBottom: 0,
    },
  },
  formControl: {
    marginBottom: 15,
    "& .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3",
    },
    "& .Mui-disabled:hover": {
      cursor: "not-allowed",
    },
  },
  labelSelect: {
    marginBottom: 8,
  },
  templates: {
    marginTop: 8,
    marginBottom: 10,
  },
  uploadBox: {
    marginTop: 8,
    padding: 5,
    borderRadius: 4,
    border: "1px solid #d7d7d7",
  },
  input: {
    display: "none",
  },
  chipRoot: {
    width: "100%",
    marginTop: 8,
    "& .MuiInputBase-root": {
      paddingTop: 5,
      fontSize: 12,
    },
  },
  chip: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.common.white,
    fontSize: 10,
    textTransform: "uppercase",
    height: 24,
    "& .MuiChip-deleteIcon": {
      width: "16px !important",
      color: theme.palette.common.white,
    },
  },
  modalFooter: {
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 10,
    "& .MuiFormControl-root": {
      marginTop: 0,
      marginRight: 10,
      width: 240,
    },
    "& input": {
      paddingTop: 8,
      paddingBottom: 9,
    },
  },

  btnContinue: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    marginLeft: 7,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnAdd: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: "#b8b8b8",
    marginLeft: 7,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
  media: {
    height: "150px",
    width: "auto",
  },
}));

//const initialFormValues = {
//  allowComments: false,
//  featuredImage: "",
//  preamble: "",
//  title: "",
//};

const CreateNewsModalBody = ({
  siteId,
  handleClose,
  formValues,
  setFormValues,
  category,
  setCategory,
  uploadedAsset,
  setUploadedAsset,
  setNewsTypeModal,
  handleSaveNewsPost,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  // const [formValues, setFormValues] = useState(initialFormValues);
  // const [category, setCategory] = useState("");
  // const [uploadedAsset, setUploadedAsset] = useState(null);
  // const [createNewsPost, { loading: loadingCreateNewsPost }] = useMutation(
  //   CREATE_NEWS_POST
  // );
  const [initSiteNewsAssetUpload] = useMutation(INIT_SITE_NEWS_ASSET_UPLOAD);
  const [uploadThumbnail] = useMutation(UPLOAD_NEWS_POST_THUMBNAIL);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadedImage, setUploadedImage] = useState("");
  const [avatarEditorModal, setAvatarEditorModal] = useState(false);
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState(1);

  // const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const alert = useAlert();
  const snackbar = useSnackbar();
  // const history = useHistory();

  const handleError = errorResult => {
    const { errorCode, errorMessage } = errorResult;

    if (errorCode === NOT_AUTHORIZED) {
      snackbar.workspaceError(<FormattedMessage id="common.notAuthorized" />);
      console.error("error >", errorMessage);
      return;
    }
  };

  const handleScale = (event, newValue) => {
    setScale(newValue);
  };

  const setEditorRef = edit => {
    setEditor(edit);
  };

  const handleNext = () => {
    setNewsTypeModal(true);
    handleClose();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCategoryChange = e => {
    setCategory(e.target.value);
  };

  const handleUploadThumbnailImage = async () => {
    try {
      const canvasScaled = editor.getImageScaledToCanvas();
      const imageData = canvasScaled.toDataURL();
      const res = await uploadThumbnail({
        variables: { siteId, imageData, imageName: imageName || "" },
      });

      if (
        res &&
        res.data &&
        res.data.newsContext &&
        res.data.newsContext.uploadNewsPostThumbnail
      ) {
        const {
          success,
          errorResult,
          thumbnailFileId
        } = res.data.newsContext.uploadNewsPostThumbnail;
        if (success) {         
          
          alert.success(<FormattedMessage id="common.genericSuccess" />);
          setAvatarEditorModal(false);
          setUploadSuccess(true);
          setUploadedImage(image);
          setUploadedAsset(thumbnailFileId);
        } else {
          handleError(errorResult);
        }
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const showUploadFailAlert = () => {
    snackbar.workspaceError(<FormattedMessage id="fileupload.fail" />);
  };

  const showUploadSuccessAlert = () => {
    alert.success(<FormattedMessage id="fileupload.success" />);
  };

  const handleOnSelectUploadFile = async event => {
    setLoadingUpload(true);
    const { target } = event || {};
    const { files } = target || {};
    try {
      const res = await initSiteNewsAssetUpload({ variables: { siteId } });
      if (res && res.data && res.data.initSiteNewsAssetUpload) {
        const uploadUrl = res.data.initSiteNewsAssetUpload;

        if (files && files[0]) {
          try {
            const formData = new FormData();
            formData.append("file", files[0]);

            const response = await axios.post(`${uploadUrl}`, formData);
            if (response && response.data && response.status === 200) {
              const { Id: assetId, Name: name } = response.data;
              setUploadedAsset({ assetId, assetFileName: name });
              showUploadSuccessAlert();
            } else {
              showUploadFailAlert();
            }
          } catch (uploadException) {
            showUploadFailAlert();
          }
        } else {
          showUploadFailAlert();
        }
      } else {
        showUploadFailAlert();
      }
      setLoadingUpload(false);
    } catch (error) {
      setLoadingUpload(false);
      showUploadFailAlert();
    }
  };

  const preambleTooLong = (formValues.preamble.length > 1000);
  const disableSave = (formValues.title.length == 0) || (formValues.preamble.length == 0) || preambleTooLong;

  const handleSave = () => {
    //No post body, just a simple post.
    handleSaveNewsPost("");
  }

  const renderUploadBoxContent = () => {
    return (
      <>
        {uploadSuccess && (
          <img className={classes.media} src={uploadedImage} alt={imageName} />
        )}
        <ButtonPrimaryAltSm
          onClick={() => setAvatarEditorModal(true)}
          component="span"
          label={intl.formatMessage({
            id: "common.chooseImage",
          })}
        />
      </>
    );
  };

  return (
    <>
      <Box className={classes.templates}>

        <Box className={classes.formControl}>
          <Label
            title={intl.formatMessage({
              id: "common.title",
            }) + " *"}
          />
          <TextField
            value={formValues.title}
            name="title"
            onChange={handleChange}
          />
        </Box>
        <Box className={classes.formControl}>
          <Grid container>
            <Grid xs={12} md={6}>
              <Label
                title={intl.formatMessage({
                  id: "newsPost.preamble",
                }) + " *"}
              />
            </Grid>
            <Grid xs={12} md={6} align="right">
              {preambleTooLong && (
                <ErrorText
                  message={intl.formatMessage({ id: "newsPost.textTooLong" })}
                  fontSize={11}
                />
              )}
            </Grid>
          </Grid>

          <TextAreaFull
            rows="5"
            rowsMax="5"
            value={formValues.preamble}
            name="preamble"
            onChange={handleChange}
          />
        </Box>
        <Box className={classes.formControl}>
          <Label
            title={intl.formatMessage({
              id: "newsPost.featuredImage",
            })}
          />
          <Box className={classes.uploadBox}>{renderUploadBoxContent()}</Box>
        </Box>
      </Box>
      <Box className={classes.formControl}>
        <Label
          title={intl.formatMessage({
            id: "common.category",
          })}
        />
        <TextField
          value={category}
          name="category"
          onChange={handleCategoryChange}
        />
      </Box>
      <Box className={classes.modalFooter} align="right">
        <Button className={classes.btnAdd} onClick={handleNext}>
          {intl.formatMessage({
            id: "newsPost.addLinkedNewsPage",
          })}
        </Button>
        <Button className={classes.btnContinue} disabled={disableSave} onClick={handleSave}>
          {intl.formatMessage({
            id: "btn.save",
          })}
        </Button>
      </Box>
      {avatarEditorModal && (
        <AvatarEditorModal
          open={avatarEditorModal}
          handleClose={() => setAvatarEditorModal(false)}
          image={image}
          setImage={setImage}
          height={500}
          width={500}
          scale={scale}
          handleScale={handleScale}
          setEditorRef={setEditorRef}
          onClick={handleUploadThumbnailImage}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    siteId: getSiteId(state),
  };
};

CreateNewsModalBody.defaultProps = {
  category: "",
  formValues: {},
  siteId: null,
  uploadedAsset: null,
};

CreateNewsModalBody.propTypes = {
  category: PropTypes.string,
  formValues: PropTypes.shape({}),
  handleClose: PropTypes.func.isRequired,
  setCategory: PropTypes.func.isRequired,
  setFormValues: PropTypes.func.isRequired,
  setNewsTypeModal: PropTypes.func.isRequired,
  setUploadedAsset: PropTypes.func.isRequired,
  siteId: PropTypes.number,
  uploadedAsset: PropTypes.shape({}),
};

export default connect(mapStateToProps)(CreateNewsModalBody);
