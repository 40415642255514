import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import WorkspaceSelector from "../../common/WorkspaceSelector";
import ChannelRow from "./ChannelRow";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    width: "100%",
  },
  formRoot: {
    marginRight: 0,
  },
  switchText: {
    fontSize: 11,
    paddingLeft: 5,
    width: 135,
  },
}));

const ShowChannelAndWorkspaces = ({
  workspaces,
  workspaceName,
  onClick,
  handleToggle,
  open,
  setOpen,
}) => {
  const classes = useStyles();

  return (
    <ChannelRow>
      <Box className={classes.root}>
        <WorkspaceSelector
          workspaces={workspaces}
          workspaceName={workspaceName}
          onClick={onClick}
          handleToggle={handleToggle}
          open={open}
          searchable
          setOpen={setOpen}
          searchAll
        />
      </Box>
    </ChannelRow>
  );
};

export default ShowChannelAndWorkspaces;
