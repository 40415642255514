import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PageRoute from "../../../constant/pageRouteDictionary.json";
import ContactsPopup from "../../Messaging/ContactsPopup";
import {
  ImageBasketNotifyIcon,
  MessageNotifyIcon,
  ShoppingCartNotifyIcon,
} from "../Header/NotifyIcons";

const useStyles = makeStyles(theme => ({
  notification: {
    display: "flex",
    marginLeft: "auto",
  },
  sectionMobile: {
    display: "flex",
    marginTop: 10,
    marginBottom: -10,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const MobileMenu = ({ totalCartItems, totalImageItems, unreadMessagesCount }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.sectionMobile}>
      <Box className={classes.notification}>
        <ContactsPopup>
          <MessageNotifyIcon counter={unreadMessagesCount} />
        </ContactsPopup>
        <ImageBasketNotifyIcon
          counter={totalImageItems}
          onClick={() => history.push(PageRoute.IMAGE_BASKET)}
        />
        <ShoppingCartNotifyIcon
          counter={totalCartItems}
          onClick={() => history.push(PageRoute.CHECKOUT)}
        />
      </Box>
    </Box>
  );
};

MobileMenu.propTypes = {
  totalCartItems: PropTypes.number.isRequired,
  totalImageItems: PropTypes.number.isRequired,
  unreadMessagesCount: PropTypes.number.isRequired,
};

export default MobileMenu;
