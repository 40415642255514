import { useMemo } from 'react';
import { Menu } from '@mui/material';
import { ANNOTATION_TYPE, TOOL_TYPE } from "../../utils/types";
import AnnotationTypeMenuIcon from "./AnnotationTypeMenuIcon";
import AnnotationTypeMenuItem from "./AnnotationTypeMenuItem";

const AnnotationTypeMenu = ({
  activeAnnotationType,
  anchorEl,
  onClose,
  onSelect,
  sx,
}) => {
  const annotationTypes = useMemo(() => {
    return [
      ANNOTATION_TYPE.ARROW,
      ANNOTATION_TYPE.CLOUD,
      ANNOTATION_TYPE.ELLIPSE,
      ANNOTATION_TYPE.RECTANGLE,
    ];
  }, []);

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      sx={sx}
      variant="menu"
    >
      {annotationTypes
        .map(
          type =>
            <AnnotationTypeMenuItem
              key={type}
              icon={<AnnotationTypeMenuIcon type={type} />}
              onSelect={(e) => onSelect?.(e, TOOL_TYPE.ANNOTATION, type)}
              selected={activeAnnotationType === type}
            />
        )
      }
    </Menu>
  );
};

export default AnnotationTypeMenu;
