import { NODEOFFSET } from "./config";

const calcStepPosFromNode = (node, nodePosition, nodeWidth, nodeHeight) => {
  const { x, y } = node; // Destructure x and y from node

  let newX = x; // Initialize newX
  let newY = y; // Initialize newY

  switch (nodePosition) {
    case 'top':
      newX = x - NODEOFFSET - nodeWidth / 2;
      newY = y;
      break;
    case 'bottom':
      newX = x - NODEOFFSET - nodeWidth / 2;
      newY = y - NODEOFFSET * 2 - nodeHeight;
      break;
    case 'left':
      newX = x;
      newY = y - NODEOFFSET - nodeHeight / 2;
      break;
    case 'right':
      newX = x - NODEOFFSET * 2 - nodeWidth;
      newY = y - NODEOFFSET - nodeHeight / 2;
      break;
    default:
      newX = x + NODEOFFSET;
      newY = y - nodeHeight / 2;
      break;
  }

  return {
    left: newX,
    top: newY,
    width: nodeWidth + NODEOFFSET * 2,
    height: nodeHeight + NODEOFFSET * 2,
  };
};

export default calcStepPosFromNode;