import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Divider, List } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChannelsSkeleton from "../../Skeleton/ChannelsSkeleton";
import ChannelHead from "./ChannelHead";
import ChannelListItem from "./ChannelListItem";
import CreateChannelModal from "./CreateChannelModal";
import ShowChannelAndWorkspaces from "./ShowChannelAndWorkspaces";

const useStyles = makeStyles(theme => ({
  listRoot: {
    padding: 0,
  },
  divider: {
    margin: "15px 0 0 0",
  },
  headDivider: {
    margin: "7px 0 0 0",
  },
}));

const ChannelsContainer = ({
  activeChannelId,
  channels,
  channelsLoading,
  onChannelSelected,
  setSiteId,
}) => {
  const classes = useStyles();

  const initialWorkspaceName = useSelector(
    state => state.ui.toggleWorkspaces.workspace.name
  );
  const workspaces = useSelector(
    state => state.api.currentViewer.viewer.structure.items
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [openCreateChannel, setOpenCreateChannel] = useState(false);
  const [workspaceName, setWorkspaceName] = useState(initialWorkspaceName);
  const [workspaceSelector, setWorkspaceSelector] = useState(false);

  const open = Boolean(anchorEl);
  const settingPopoverId = open ? "settings-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenCreateChannel = () => {
    setOpenCreateChannel(true);
  };
  const handleCloseCreateChannel = () => {
    setOpenCreateChannel(false);
  };

  const handleToggle = () => {
    setWorkspaceSelector(prevState => !prevState);
  };

  const handleClickSetting = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClickSite = site => {
    let found = {};
    if (site.siteId !== 0) {
      found = sites.find(x => x.id === site.siteId);
    } else {
      found = { ...site, id: 0 };
    }

    const { id, name } = found;

    setWorkspaceName(name);
    setWorkspaceSelector(false);

    setSiteId(id);
  };

  const sites = useSelector(state => state.api.currentViewer.viewer.sites);

  return (
    <>
      {false && (
        <Box>
          <ChannelHead
            handleClose={handleClose}
            anchorEl={anchorEl}
            open={open}
            id={settingPopoverId}
            handleOpenCreateChannel={handleOpenCreateChannel}
            handleClickSetting={handleClickSetting}
          />
        </Box>
      )}
      <Box className={classes.headDivider} />
      <ShowChannelAndWorkspaces
        workspaces={workspaces}
        workspaceName={workspaceName}
        onClick={handleOnClickSite}
        handleToggle={handleToggle}
        open={workspaceSelector}
        setOpen={setWorkspaceSelector}
      />
      <Divider className={classes.divider} />

      {channelsLoading ? (
        <ChannelsSkeleton />
      ) : (
        <List className={classes.listRoot}>
          {channels.map(x => (
            <ChannelListItem
              key={x.siteChannelId}
              activeChannelId={activeChannelId}
              channel={x}
              onChannelSelected={onChannelSelected}
            />
          ))}
        </List>
      )}

      <CreateChannelModal
        open={openCreateChannel}
        handleClose={handleCloseCreateChannel}
      />
    </>
  );
};

export default ChannelsContainer;
