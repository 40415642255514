import { createActions } from 'redux-actions';

export const {
  setConversations,
  setConversationsLoading,
  setConversationsError,
} = createActions({
  SET_CONVERSATIONS: ({ items }) => ({ items }),
  SET_CONVERSATIONS_LOADING: ({ loading }) => ({ loading }),
  SET_CONVERSATIONS_ERROR: ({ error }) => ({ error }),
});