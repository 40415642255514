import { createContext, useState } from "react";

export const paginationContext = createContext();

const { Provider } = paginationContext;

const _initialState = {
  pageCount: 0,
  pageNo: 0,
};

const clamp = (number, min, max) => Math.max(min, Math.min(number, max));

const PaginationProvider = ({ children }) => {
  const [state, setState] = useState(_initialState);

  const initialize = ({ pageCount, pageNo } = {}) => {
    setState({
      ..._initialState,
      pageCount,
      pageNo,
    });
  };

  const changePage = (pageNo) => {
    setState(prev => ({
      ...prev,
      pageNo: clamp(pageNo, 1, prev.pageCount),
    }));
  };

  const nextPage = () => {
    setState(prev => ({
      ...prev,
      pageNo: clamp(prev.pageNo + 1, 1, prev.pageCount),
    }));
  };

  const previousPage = () => {
    setState(prev => ({
      ...prev,
      pageNo: clamp(prev.pageNo - 1, 1, prev.pageCount),
    }));
  };

  return (
    <Provider value={{
      ...state,
      changePage,
      initialize,
      nextPage,
      previousPage,
    }}>
      {children}
    </Provider>
  );
};

export default PaginationProvider;
