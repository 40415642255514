import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  CommentOutlined as CommentsPanelIcon,
  LocalOfferOutlined as MetadataPanelIcon,
} from "@mui/icons-material";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import MetadataPanel from "./MetadataPanel";

import { useCommentsContext, usePaginationContext } from "../../../../hooks/FullscreenAnnotationModal";
import CommentsPanel from "../../../../pages/OneStream/subcomponents/CommentsPanel"; // TODO: Relocate CommentsPanel.
//import MetadataPanel from "../../../../pages/OneStream/subcomponents/MetadataPanel";
import DrawingEditor from '../../../../pages/OneStream/pages/DrawingEditor'; // TODO: Relocate DrawingEditor.

import SidePanel from "./SidePanel";

const _initialPanels = {
  comments: false,
  metadata: true,
  stepinfo: undefined,
  workflow: undefined,
};

const _initialViewportTransform = {
  x: 50,
  y: 1,
  scale: 1,
};

const NonDynamicProduct = ({
  customMetadata,
  editMode,
  fromFileView,
  handleAddImageBasket,
  handleClose,
  isImage,
  product,
  quantity,
  setQuantity,
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  
  const { clear: resetComments, loadComments } = useCommentsContext();
  const { initialize: resetPagination } = usePaginationContext();

  const [activePanels, setActivePanels] = useState(_initialPanels); // CommentsPanel, MetadataPanel, ... (TODO)
  


  const [rating, setRating] = useState(0); // MetadataPanel
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 }); // DrawingEditor
  const [viewportTransform, setViewportTransform] = useState(_initialViewportTransform); // DrawingEditor

  const havePanel = (panelName) => (activePanels[panelName] !== undefined);
  const isOpenPanel = (panelName) => (Boolean(activePanels[panelName]));

  const [containerRef, setContainerRef] = useState();
  const drawingEditorRef = useRef(null); // DrawingEditor
  const stageRef = useRef(null); // DrawingEditor

  const handleCloseWorkflowDrawer = () => {
    setActivePanels(prev => ({ ...prev, workflow: false, }));
  };

  const resetViewportTransform = useCallback(() => {
    setViewportTransform(_initialViewportTransform);
  }, [setViewportTransform]);

  const initialize = useCallback(() => {
    setActivePanels(_initialPanels);
    
    resetComments();
    resetPagination();
    resetViewportTransform();
  }, [resetComments, resetPagination, resetViewportTransform]);

  const handleFileOpened = ({ id, numPages, pageNumber }) => {
    if (numPages && numPages > 0) {
      resetComments();
      resetPagination({ pageCount: numPages, pageNo: pageNumber });
      resetViewportTransform();
    }
    else {
      initialize();
    }
  }

  const handleResize = useCallback(() => {
    if (drawingEditorRef.current) {
      const { width, height } = drawingEditorRef.current.getBoundingClientRect();
      setStageSize({ width, height });
    }
  }, []);

  const handleWorkflowLoaded = useCallback(({ id }) => {
    if (!!id) {
      
      resetComments();
      resetPagination({ pageCount: 1, pageNo: 1 });
      resetViewportTransform();
    } else {
      initialize();
    }
  }, [resetComments, resetPagination, resetViewportTransform, initialize]);


  useEffect(() => {

    if (product) {
      loadComments(product.id);
    }

    if (!quantity && product) {
      var { minimumQuantity } = product;
      setQuantity(minimumQuantity);
    }
  }, [product, quantity]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // initial call
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  // Trigger resize when activePanels change
  useEffect(() => {
    handleResize();
  }, [activePanels, handleResize]);

  const togglePanel = (panel) => {
    setActivePanels((prev) => {
      let updated = { ...prev };
      if (prev[panel]) {
        updated[panel] = false;
      }
      else {
        //if (["metadata", "workflow"].includes(panel)) {
        //  updated.metadata = false;
        //  updated.workflow = false;
        //}
        //else if (["comments", "stepinfo"].includes(panel)) {
        //  updated.comments = false;
        //  updated.stepinfo = false;
        //}
        updated[panel] = true;
      }
      return updated;
    });
  };

  return (
    <Box
      ref={setContainerRef}
      sx={{
        display: 'flex',
        flexDirection: mobileView ? "column" : "row",
        width: '100%',
        height: '100%',
        maxWidth: 'none',
        position: "relative",
      }}
    >

      {/* Metadata Column */}
      {havePanel("metadata") && (
        <SidePanel
          containerRef={containerRef}
          onTabMarkerClick={() => togglePanel("metadata")}
          open={isOpenPanel("metadata")}
          placement="left"
          tabMarkerIcon={<MetadataPanelIcon />}
          tabMarkerTooltip={<FormattedMessage id="metadata.metadata" />}
        >
          <MetadataPanel
            customMetadata={customMetadata}
            editMode={editMode}
            fromFileView={fromFileView}
            isImage={isImage}
            onAddToImageBasket={handleAddImageBasket}
            onClose={handleClose}
            product={product}
            quantity={quantity}
            setQuantity={setQuantity}
          />
        </SidePanel>
      )}

      {/* Workflow Column */}
      {false && activePanels.workflow && (
        <Box
          sx={{
            background: "#f2f2f0",
            maxWidth: '400px',
            minWidth: '250px',
            overflowY: 'auto',
            padding: 2,
            width: '20%',
          }}
        >
          TODO: Workflow panel
        </Box>
      )}

      {/* DrawingEditor Column */}
      <Box
        ref={drawingEditorRef}
        sx={{
          flex: mobileView ? "none" : "auto",
          position: 'relative',
          borderLeft: (mobileView || !activePanels.metadata) ? '1px solid #ccc' : 'none',
          borderRight: (mobileView || !activePanels.comments) ? '1px solid #ccc' : 'none',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <DrawingEditor          
          resource={product}
          stageSize={stageSize}
          viewportTransform={viewportTransform}
          setViewportTransform={setViewportTransform}
          stageRef={stageRef}
          onFileOpened={handleFileOpened}
          onStageSizeChange={handleResize}
          onWorkflowLoaded={handleWorkflowLoaded}
          openWorkflowDrawer={activePanels.workflow}
          onCloseWorkflowDrawer={handleCloseWorkflowDrawer}        
        />
      </Box>

      {/* Stepinfo Column */}
      {activePanels.stepinfo && (
        <Box sx={{
          background: "#f2f2f0",
          maxWidth: '500px',
          minWidth: '300px',
          overflowY: 'auto',
          padding: 2,
          width: '25%',
        }}>
          TODO: Stepinfo panel
        </Box>
      )}

      {/* Comments Column */}
      {havePanel("comments") && (
        <SidePanel
          containerRef={containerRef}
          onTabMarkerClick={() => togglePanel("comments")}
          open={isOpenPanel("comments")}
          placement="right"
          tabMarkerIcon={<CommentsPanelIcon />}
          tabMarkerTooltip={<FormattedMessage id="context.comments" />}
        >
          <CommentsPanel product={product} />
        </SidePanel>
      )}

    </Box>
  );
};

NonDynamicProduct.defaultProps = {
  editMode: false,
  customMetadata: null,
};

NonDynamicProduct.propTypes = {
  customMetadata: PropTypes.arrayOf(PropTypes.shape({})),
  editMode: PropTypes.bool,
  handleAddImageBasket: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isImage: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    articleNo: PropTypes.string.isRequired,
    imageMetadata: PropTypes.shape({
      contact: PropTypes.string,
      date: PropTypes.string,
      model: PropTypes.string,
      location: PropTypes.string,
      occupation: PropTypes.string,
      photographer: PropTypes.string,
    }),
    unit: PropTypes.string,
    weight: PropTypes.number,
    material: PropTypes.string,
    numPages: PropTypes.string.isRequired,
    detailedDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    minimumPrice: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    productImageUri: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    quantities: PropTypes.shape({
      selectionType: PropTypes.string.isRequired,
      valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    statistics: PropTypes.shape({
      downloadCount: PropTypes.number,
      shareCount: PropTypes.number,
      viewCount: PropTypes.number,
    }),
    stock: PropTypes.shape({
      stockBalance: PropTypes.number.isRequired,
      temporaryOutOfStock: PropTypes.bool.isRequired,
    }),
    productDisplaySettings: PropTypes.shape({
      showFormat: PropTypes.bool,
      showNumPages: PropTypes.bool,
      showPrice: PropTypes.bool,
      showStockBalance: PropTypes.bool,
    }),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        tagId: PropTypes.number.isRequired,
        tagName: PropTypes.string.isRequired,
      })
    ).isRequired,
    userPermissions: PropTypes.shape({
      order: PropTypes.bool,
      download: PropTypes.bool,
    }),
    thumbnailImageUri: PropTypes.string,
  }).isRequired,
  quantity: PropTypes.number.isRequired,
  setQuantity: PropTypes.func.isRequired,
};

export default NonDynamicProduct;
