import { createActions } from "redux-actions";

const {
  cartAddItems,
  cartAddItem,
  cartInitialize,
  cartRemoveItem,
  cartUpdateItem,
  setCurrentUser,
  currentViewer,
  currentWorkspace,
  expandItem,
  login,
  logout,
  removeWidget,
  selectWorkspace,
  setFolderData,
  setFolderId,
  setLocale,
  setMonitoringOrderRecipientTabSettings,
  setProductView,
  setTotalProducts,
  toggleCart,
  toggleDetails,
  imageBasketAddItem,
  imageBasketInitialize,
  setSearchResults,
  emptySearchResults,
  setSearchQuery,
  setDoDetailedSearch,
  setTheme,
  searchBeataWidget,
  setChannels,
  setChannelsLoading,
  setChannelsError,
  setActiveChannelId,
  addNewMessage,
  updateWorkspaceRoleRights,
} = createActions({
  CART_ADD_ITEMS: ({ items }) => ({ items }),
  CART_ADD_ITEM: ({ item }) => ({ item }),
  CART_INITIALIZE: ({ items, fileGroups, oneStreamGroups }) => ({
    items,
    fileGroups,
    oneStreamGroups,
  }),
  CART_REMOVE_ITEM: ({ index }) => ({ index }),
  CART_UPDATE_ITEM: ({ item, index }) => ({ item, index }),
  SET_CURRENT_USER: ({ user }) => ({ user }),
  CURRENT_VIEWER: ({ viewer }) => ({ viewer }),
  CURRENT_WORKSPACE: ({ workspace }) => ({ workspace }),
  EXPAND_ITEM: ({ id }) => ({ id }),
  LOGIN: ({ accessToken }) => ({ accessToken }),
  LOGOUT: () => {},
  REMOVE_WIDGET: ({ id }) => ({ id }),
  SELECT_WORKSPACE: ({
    id,
    name,
    siteRoleRights,
    defaultViewType,
    siteRoleId,
  }) => ({
    id,
    name,
    siteRoleRights,
    defaultViewType,
    siteRoleId,
  }),
  SET_FOLDER_DATA: ({ folder }) => ({ folder }),
  SET_FOLDER_ID: ({ id }) => ({ id }),
  SET_LOCALE: ({ culture }) => ({ culture }),
  SET_MONITORING_ORDER_RECIPIENT_TAB_SETTINGS: ({ settings }) => ({ settings }),
  SET_PRODUCT_VIEW: ({ productView }) => ({ productView }),
  SET_TOTAL_PRODUCTS: ({ total }) => ({ total }),
  TOGGLE_CART: ({ name }) => ({ name }),
  TOGGLE_DETAILS: ({ name }) => ({ name }),
  IMAGE_BASKET_ADD_ITEM: ({ item }) => ({ item }),
  IMAGE_BASKET_INITIALIZE: ({ items }) => ({ items }),
  SET_SEARCH_RESULTS: ({ items }) => ({ items }),
  EMPTY_SEARCH_RESULTS: ({ items }) => ({ items }),
  SET_SEARCH_QUERY: ({ searchQuery, productOwner }) => ({
    searchQuery,
    productOwner,
  }),
  SET_DO_DETAILED_SEARCH: ({ doDetailedSearch }) => ({ doDetailedSearch }),
  SET_THEME: ({ theme }) => ({ theme }),
  SEARCH_BEATA_WIDGET: ({ beataWidget }) => ({ beataWidget }),
  SET_CHANNELS: ({ channels }) => ({ channels }),
  SET_CHANNELS_LOADING: ({ loading }) => ({ loading }),
  SET_CHANNELS_ERROR: ({ error }) => ({ error }),
  SET_ACTIVE_CHANNEL_ID: ({ channelId }) => ({ channelId }),
  ADD_NEW_MESSAGE: ({ message, siteChannelId }) => ({ message, siteChannelId }),
  UPDATE_WORKSPACE_ROLE_RIGHTS: ({ items }) => ({ items }),
});

export {
  cartAddItems,
  cartAddItem,
  cartInitialize,
  cartRemoveItem,
  cartUpdateItem,
  setCurrentUser,
  currentViewer,
  currentWorkspace,
  expandItem,
  login,
  logout,
  removeWidget,
  selectWorkspace,
  setFolderData,
  setFolderId,
  setLocale,
  setMonitoringOrderRecipientTabSettings,
  setProductView,
  setTotalProducts,
  toggleCart,
  toggleDetails,
  imageBasketAddItem,
  imageBasketInitialize,
  setSearchResults,
  emptySearchResults,
  setSearchQuery,
  setDoDetailedSearch,
  setTheme,
  searchBeataWidget,
  setActiveChannelId,
  addNewMessage,
  updateWorkspaceRoleRights,
};

export * from "./fileView";
export * from "./suppliers";
export * from "./companySupplierSettings";
export * from "./siteFolders";
export * from "./siteUsers";
export * from "./oneStreamJob";
export * from "./search";
export * from "./userDetails";
export * from "./CompanyAdmin/companyRoles";
export * from "./CompanyAdmin/adminSidebar";
export * from "./CompanyAdmin/availableCompany";
export * from "./CompanyAdmin/groups";
export * from "./CompanyAdmin/workspaceOrderGroupCandidates";
export * from "./customMetadata";
export * from "./channelActions";
export * from "./conversationsActions";
