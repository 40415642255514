import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ConfirmCloseModal from "../ConfirmCloseModal";

/**
 * See: https://stackoverflow.com/questions/71035091/custom-react-router-prompt-a-history-supports-only-one-prompt-at-a-time#answer-71105897
 */
const ConfirmCloseModalRouterPrompt = ({ when }) => {
  const history = useHistory();

  const [currentPath, setCurrentPath] = useState("");
  const [showPrompt, setShowPrompt] = useState(false);

  const unblockRef = useRef();

  const handleCancel = () => {
    setShowPrompt(false);
  }

  const handleOK = () => {
    if (unblockRef) {
      unblockRef.current();
    }
    setShowPrompt(false);
    history.push(currentPath);
  }

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (when) {
        setCurrentPath(location.pathname);
        setShowPrompt(true);
      }
      return !when;
    });
    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [when]);

  return showPrompt && (
    <ConfirmCloseModal
      onCancel={handleCancel}
      onConfirmCloseModal={handleOK}
      open={showPrompt}
      setOpen={setShowPrompt}
    />
  );
}

ConfirmCloseModalRouterPrompt.defaultProps = {
  when: false,
};

ConfirmCloseModalRouterPrompt.propTypes = {
  when: PropTypes.bool,
};

export default ConfirmCloseModalRouterPrompt;
