import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useAlert } from "react-alert";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import SimpleModalCompact from "../../../common/ModalCompact/SimpleModalCompact";
import { CloseButton, EditImagesButton, FileInputButton } from "./common/Buttons";
import FileUpload from "./common/FileUpload";
import FileUploadMessage from "./common/FileUploadMessage";
import EditImagesPanel from "./EditImagesPanel";
import ImageGallery from "../ImageGallery";

import { useNewsPostFileUploadSession } from "../../../../hooks/News";

const SpinnerOverlay = ({ show }) => {
  if (!show) {
    return
  }

  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
        background: "#00000040",
        color: "#000",
      }}
    >
      <CircularProgress color="inherit" />
    </Box>
  );
};

const ImagesPanel = ({ disabled, images, newsPostFileUploadSession, onClose, setImages }) => {

  // images: [
  //   {
  //     key: 1,
  //     fileId: "e56aff6e1ea441fba90b1c8a8b61ed5a"
  //     downloadUrl: "http://localhost:5001/api/NewsPostFile?session=TmV3c1Bvc3RGaWxlVXBsb2FkU2Vzc2lvbklkZW50aWZpZXI6OTo2NmYzNzMwMmJjOTY0NmIwYjFlODVlZjZjMzgwMjYxOA==&fileId=e56aff6e1ea441fba90b1c8a8b61ed5a"
  //     deleteUrl: "http://localhost:5001/api/NewsPostFile/Delete?session=TmV3c1Bvc3RGaWxlVXBsb2FkU2Vzc2lvbklkZW50aWZpZXI6OTo2NmYzNzMwMmJjOTY0NmIwYjFlODVlZjZjMzgwMjYxOA==&fileId=e56aff6e1ea441fba90b1c8a8b61ed5a"
  //   }
  // ]

  const alert = useAlert();

  const [initNewsPostFileUpload, { loading: preparingUpload }] = useNewsPostFileUploadSession();

  const concurrentUploadsCountRef = useRef(0);
  const imageKeyRef = useRef(0);
  const imagesRef = useRef(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [uploading, setUploading] = useState(false);

  const uploadFile = async ({ file }) => {
    const session = newsPostFileUploadSession;

    if (!session) {
      console.log("** no upload session");
      return;
    }

    try {
      const formData = new FormData();

      formData.append("file", file);

      await initNewsPostFileUpload({
        session,
        onError: () => {
          handleRejectedFile(file);
        },
        onSuccess: async ({ uploadUrl }) => {
          concurrentUploadsCountRef.current++;
          setUploading(true);

          await axios
            .post(`${uploadUrl}`, formData)
            .then(response => {
              const { success, message } = response?.data;
              if (success) {
                const { fileId, downloadUrl, deleteUrl } = response.data;
                concurrentUploadsCountRef.current--;
                const image = {
                  fileId,
                  downloadUrl,
                  deleteUrl,
                };
                addImage(image);
              }
              else {
                concurrentUploadsCountRef.current--;
                handleRejectedFile(file);
              }
            })
            .catch(err => {
              concurrentUploadsCountRef.current--;
              handleRejectedFile(file);
            });

          if (concurrentUploadsCountRef.current === 0) {
            setUploading(false);
          }
        },
      })
    }
    catch (error) {
      handleRejectedFile(file);
      if (concurrentUploadsCountRef.current === 0) {
        setUploading(false);
      }
    }
  };

  const deleteFile = async (image) => {
    try {
      const { deleteUrl, key } = image;

      return await axios
        .post(`${deleteUrl}`)
        .then(response => {
          const { success, message } = response?.data;
          if (success) {
            removeImage(key);
          }
          else {
            console.log("** delete failed", { image, message });
            removeImage(key);
          }
        })
        .catch(err => {
          console.log("** delete failed", { image, err });
          removeImage(image?.key);
        });
    }
    catch (error) {
      console.log("** delete failed", { image, error });
      removeImage(image?.key);
    }
  };

  const addImage = (image) => {
    const key = imageKeyRef.current;
    imageKeyRef.current = key + 1;
    setImages(prev => {
      const newState = [...prev, { key, ...image }];
      //console.log("**", { prev, newState });
      return newState;
    });
  };

  const getImageByKey = (key) => {
    const image = images.find(n => n.key === key);
    //console.log("** [ImagesPanel] getImageByKey", { key, image });
    return image;
  };

  const removeAllImages = () => {
    setImages(prev => {
      const newState = [];
      //console.log("**", { prev, newState });
      return newState;
    });
  };

  const removeImage = (key) => {
    setImages(prev => {
      const newState = prev.filter((image, _) => image.key !== key);
      //console.log("**", { prev, newState });
      return newState;
    });
  };

  const handleAcceptedImage = async (file) => {
    await uploadFile({ file });
  };

  const getRejectedFileMessage = (filename) => (
    <Box component="span">
      <FormattedMessage id="newsFlowWidget.images.uploadFailed" /><br />
      <Typography sx={{ fontSize: "smaller", fontStyle: "italic" }}>
        {filename}
      </Typography>
    </Box>
  );

  const handleRejectedFile = (file) => {
    const message = getRejectedFileMessage(file?.name);
    const options = { offset: 5 };
    alert.error(message, options);
  };

  const handleRemoveImage = async (key) => {
    const image = getImageByKey(key);
    await deleteFile(image);
  };

  const handleCloseClick = () => {
    removeAllImages();

    onClose?.();
  };

  useEffect(() => {
    if (showEditModal && images.length == 0) {
      setShowEditModal(false);
      onClose?.();
    }
  }, [images]);

  return (
    <>
      <Box sx={{ position: "relative", display: "flex", flexDirection: "column", marginTop: 2, border: 1, borderRadius: 2, padding: 1 }}>
        {(images.length === 0) && (
          <>
            <CloseButton disabled={disabled} onClick={handleCloseClick} sx={{ position: "absolute", top: 0, right: 0, margin: 2, zIndex: 1 }} />
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, minHeight: "inherit", position: "relative" }}>
              <SpinnerOverlay show={uploading} />
              <FileUpload
                clickable
                disabled={disabled}
                message={<FileUploadMessage />}
                onAcceptedFile={handleAcceptedImage}
                onRejectedFile={handleRejectedFile}
              />
            </Box>
          </>
        )}

        {(images.length > 0) && (
          <>
            <Box sx={{
              display: "flex", alignItems: "center", gap: 1, "& button": { backgroundColor: "#ffffffc0" }, marginBottom: 1, marginRight: 3,
              position: "absolute", margin: 1, "& button:hover": { background: "#fff" },
              "& label": { backgroundColor: "#ffffffc0" }, "& label:hover": { background: "#fff" },
              top: 0, left: 0, right: 0, padding: 1, zIndex: 1,
            }}>
              <EditImagesButton
                disabled={disabled}
                imageCount={images.length}
                onClick={() => { setShowEditModal(true); }}
              />
              <FileInputButton
                disabled={disabled}
                onAcceptedFile={handleAcceptedImage}
                onRejectedFile={handleRejectedFile}
              />
              <CloseButton
                disabled={disabled}
                onClick={handleCloseClick}
                sx={{ margin: "0 0 0 auto" }}
              />
            </Box>

            <FileUpload
              disabled={disabled}
              onAcceptedFile={handleAcceptedImage}
              onRejectedFile={handleRejectedFile}
            >
              <SpinnerOverlay show={uploading} />
              <Box ref={imagesRef} sx={{ display: "block", overflow: "auto" }}>
                <ImageGallery imageUrls={images.map(n => n.downloadUrl)} loadingMessage="" />
              </Box>
            </FileUpload>
          </>
        )}
      </Box>
      <SimpleModalCompact
        footer={
          <Box sx={{ display: "flex", flexDirection: "row-reverse", gap: "8px", marginTop: 1 }}>
            <Button
              variant="contained"
              disabled={disabled}
              onClick={() => setShowEditModal(false)}
            >
              <FormattedMessage id="newsFlowWidget.images.buttons.done" />
            </Button>
            <FileInputButton
              disabled={disabled}
              onAcceptedFile={handleAcceptedImage}
              onRejectedFile={handleRejectedFile}
            />
          </Box>
        }
        open={showEditModal}
        title={<FormattedMessage id="newsFlowWidget.images.editModalTitle" />}
      >
        <Box sx={{ margin: 0.5, marginTop: 0 }}>
          <EditImagesPanel
            disabled={disabled}
            images={images}
            onAddImage={handleAcceptedImage}
            onRemoveImage={handleRemoveImage}
          />
        </Box>
      </SimpleModalCompact>
    </>
  );
}

export default ImagesPanel;
