import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button } from "@mui/material";
import SimpleModalCompact from "../../../components/common/ModalCompact/SimpleModalCompact";
import { useCommentsContext, usePaginationContext } from "../../../hooks/FullscreenAnnotationModal";
import CommentsPanel from "../subcomponents/CommentsPanel";
import MetadataPanel from "../subcomponents/MetadataPanel";
import DrawingEditor from './DrawingEditor';

const fileMetadata = {
  'Filtyp': 'PDF',
  'Filstorlek': '2.5 MB',
  'Format': 'A4',
  'Sidomfång': '24',
  'Date Created': '2023-08-26',
  'Artikelnr': '222-1545',
  'Beställningsenhet': 'st',
  'Vikt': '111',
  'Ansvarig': 'Robert Lönn',
  'Nedladdningar': '121',
  'Delningar': '12',
  'Visningar': '145',
};


const resource = {
  "articleNo": "222-1674",
  "breadcrumb": null,
  "changedBy": "Förbundet -  Stefan Björn (Service)",
  "changedDate": "2017-12-05T09:41:23.983",
  "consented": "NOT_SENT",
  "customMetadata": [
    {
      "display": true,
      "label": "Kan användas",
      "value": "Utbildning, Samtal, div"
    },
    {
      "display": false,
      "label": "Etikett (ej synlig)",
      "value": "Värde (ej synligt)"
    },
    {
      "display": false,
      "label": "Label (visible)",
      "value": "Value (visible)"
    },
    {
      "display": false,
      "label": "Label (not visible)",
      "value": "Value (not visible)"
    }
  ],
  "description": "Branschavtalet gäller för samtliga arbetare inom berörda LO-förbunds organisationsområde anställda vid företag anslutna till Almega Samhallförbundet. Giltighetstid: 2017-10-01–2020-09-30",
  "detailedDescription": "Branschavtalet gäller för samtliga arbetare inom berörda LO-förbunds organisationsområde anställda vid företag anslutna till Almega Samhallförbundet. Giltighetstid: 2017-10-01–2020-09-30",
  "downloadedBy": "",
  "downloadedDate": null,
  "fileExtension": ".pdf",
  "format": "A5",
  "id": "UHJvZHVjdDozOTQ4OQ==",
  "imageMetadata": {
    "contact": "",
    "date": null,
    "imageNo": "",
    "isFreeToUser": false,
    "location": "",
    "model": "",
    "occupation": "",
    "photographer": ""
  },
  "isEditableFromSearchResult": false,
  "locked": true,
  "material": "",
  "minimumPrice": 0,
  "minimumQuantity": 1,
  "name": "Almega Samhall 2017-2020",
  "numPages": "48",
  "pricesInclusiveFreight": false,
  "productDisplaySettings": {
    "showFormat": true,
    "showNumPages": true,
    "showPrice": true,
    "showStockBalance": true
  },
  "productImageUri": "https://uq.unq.se/UniQueue3/Content/ColorBoxImage.ashx?resourceId=39489",
  "productManager": null,
  "productResponsible": "Devops Utvecklare",
  "productType": "STOCK_PRODUCT",
  "quantities": {
    "selectionType": "UNRESTRICTED",
    "valuesList": [
      1
    ]
  },
  "size": 750519,
  "statistics": {
    "downloadCount": 5,
    "shareCount": 0,
    "viewCount": 0
  },
  "stock": {
    "refillThreshold": 200,
    "stockBalance": 589,
    "temporaryOutOfStock": false
  },
  "tags": [],
  "thumbnailImageUri": "https://uq.unq.se/UniQueue3/Content/IconHandler.ashx?resourceId=39489",
  "unit": "st.",
  "uploadedBy": "Förbundet -  Stefan Björn (Service)",
  "uploadedDate": "2017-01-01T00:00:00",
  "userPermissions": {
    "addToImageCart": false,
    "download": true,
    "order": true,
    "share": false
  },
  "vatPercentage": 25,
  "weight": 65,
  "versionNo": 1
};

const text = "Friskolorna inom Almega Tjänsteföretagen och Kommunal har tillsammans tagit fram den här skriften för att uppmuntra lokala parter att utveckla löneprocessen och lönesystem så att ökade arbetsinsatser och yrkesutveckling premieras. Lönebildningen och lönesättningen blir därmed en del av en produktivitetsskapande process.";

const _initialPanels = {
  comments: true,
  metadata: true,
  stepinfo: false,
  workflow: false,
};

const _initialViewportTransform = {
  x: 50,
  y: 1,
  scale: 1,
};

const FullscreenAnnotationModal = ({ onClose, open }) => {

  const { clear: resetComments } = useCommentsContext();
  const { initialize: resetPagination } = usePaginationContext();

  const [activePanels, setActivePanels] = useState(_initialPanels); // CommentsPanel, MetadataPanel, ... (TODO)
  const [annotations, setAnnotations] = useState([]); // CommentsPanel
  const [rating, setRating] = useState(0); // MetadataPanel
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 }); // DrawingEditor
  const [viewportTransform, setViewportTransform] = useState(_initialViewportTransform); // DrawingEditor

  const containerRef = useRef(null); // FullscreenAnnotationModal
  const drawingEditorRef = useRef(null); // DrawingEditor
  const stageRef = useRef(null); // DrawingEditor

  const handleCloseWorkflowDrawer = () => {
    setActivePanels(prev => ({ ...prev, workflow: false, }));
  };

  const resetViewportTransform = useCallback(() => {
    setViewportTransform(_initialViewportTransform);
  }, [setViewportTransform]);

  const initialize = useCallback(() => {
    setActivePanels(_initialPanels);
    setAnnotations([]);
    resetComments();
    resetPagination();
    resetViewportTransform();
  }, [resetComments, resetPagination, resetViewportTransform]);

  const handleFileOpened = ({ id, numPages, pageNumber }) => {
    debugger;
    if (numPages && numPages > 0) {
      resetComments();
      resetPagination({ pageCount: numPages, pageNo: pageNumber });
      resetViewportTransform();
    }
    else {
      initialize();
    }
  }

  const handleResize = useCallback(() => {
    if (drawingEditorRef.current) {
      const { width, height } = drawingEditorRef.current.getBoundingClientRect();
      setStageSize({ width, height });
    }
  }, []);

  const handleWorkflowLoaded = useCallback(({ id }) => {
    if (!!id) {
      setAnnotations([]);
      resetComments();
      resetPagination({ pageCount: 1, pageNo: 1 });
      resetViewportTransform();
    } else {
      initialize();
    }
  }, [setAnnotations, resetComments, resetPagination, resetViewportTransform, initialize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // initial call
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  // Trigger resize when activePanels change
  useEffect(() => {
    handleResize();
  }, [activePanels, handleResize]);

  useEffect(() => {
    initialize();
  }, [open]);

  const togglePanel = (panel) => {
    setActivePanels((prev) => {
      let updated = { ...prev };
      if (prev[panel]) {
        updated[panel] = false;
      }
      else {
        //if (["metadata", "workflow"].includes(panel)) {
        //  updated.metadata = false;
        //  updated.workflow = false;
        //}
        //else if (["comments", "stepinfo"].includes(panel)) {
        //  updated.comments = false;
        //  updated.stepinfo = false;
        //}
        updated[panel] = true;
      }
      return updated;
    });
  };

  return (
    <SimpleModalCompact fullScreen
      onClose={onClose}
      open={open}
      title="Annotations"
    >
      <Box sx={{ position: "absolute", top: 0, left: 0, right: 0, margin: "auto", textTransform: "none", width: "fit-content" }}>
        <Button sx={{ background: activePanels["metadata"] ? "beige" : "" }} onClick={() => togglePanel("metadata")}>Metadata</Button>
        <Button sx={{ background: activePanels["workflow"] ? "beige" : "" }} onClick={() => togglePanel("workflow")}>Workflow</Button>
        {/*<Button sx={{ background: activePanels["stepinfo"] ? "beige" : "" }} onClick={() => togglePanel("stepinfo")}>Stepinfo</Button>*/}
        <Button sx={{ background: activePanels["comments"] ? "beige" : "" }} onClick={() => togglePanel("comments")}>Comments</Button>
      </Box>
      <Box ref={containerRef}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          width: '100%',
          height: '100%',
          maxWidth: 'none',
        }}
      >

        {/* Metadata Column */}
        {activePanels.metadata && (
          <Box
            sx={{
              background: "#f2f2f0",
              maxWidth: '400px',
              minWidth: '250px',
              overflowY: 'auto',
              padding: 2,
              width: '20%',
            }}
          >
            <MetadataPanel
              fileMetadata={fileMetadata}
              rating={rating}
              setRating={setRating}
              text={text}
            />
          </Box>
        )}

        {/* Workflow Column */}
        {false && activePanels.workflow && (
          <Box
            sx={{
              background: "#f2f2f0",
              maxWidth: '400px',
              minWidth: '250px',
              overflowY: 'auto',
              padding: 2,
              width: '20%',
            }}
          >
            TODO: Workflow panel
          </Box>
        )}

        {/* DrawingEditor Column */}
        <Box
          ref={drawingEditorRef}
          sx={{
            flexGrow: 1,
            position: 'relative',
            borderLeft: activePanels.metadata ? '1px solid #ccc' : 'none',
            borderRight: activePanels.comments ? '1px solid #ccc' : 'none',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <DrawingEditor
            resource={resource}
            stageSize={stageSize}
            viewportTransform={viewportTransform}
            setViewportTransform={setViewportTransform}
            stageRef={stageRef}
            onFileOpened={handleFileOpened}
            onStageSizeChange={handleResize}
            onWorkflowLoaded={handleWorkflowLoaded}
            annotations={annotations}
            setAnnotations={setAnnotations}
            openWorkflowDrawer={activePanels.workflow}
            onCloseWorkflowDrawer={handleCloseWorkflowDrawer}
          />
        </Box>

        {/* Stepinfo Column */}
        {activePanels.stepinfo && (
          <Box sx={{
            background: "#f2f2f0",
            maxWidth: '500px',
            minWidth: '300px',
            overflowY: 'auto',
            padding: 2,
            width: '25%',
          }}>
            TODO: Stepinfo panel
          </Box>
        )}

        {/* Comments Column */}
        {activePanels.comments && (
          <Box sx={{
            background: "#f2f2f0",
            maxWidth: '500px',
            minWidth: '300px',
            overflowY: 'auto',
            padding: 2,
            width: '25%',
          }}>
            <CommentsPanel />
          </Box>
        )}

      </Box>
    </SimpleModalCompact>
  );
};

export default FullscreenAnnotationModal;
