import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

const StepTypeMenuItem = ({ icon, onSelect, selected, text }) => (
  <MenuItem
    dense
    onClick={onSelect}
    selected={selected}
  >
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={text} />
  </MenuItem>
);

export default StepTypeMenuItem;
