import { isArray } from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { setChannels } from '../../actions';
import { getChannels as channelsSelector } from "../../helpers/messagingSelectors";

const useUnreadSiteChannelMessagesCounter = () => {
  const dispatch = useDispatch();

  const channels = useSelector(channelsSelector);

  const updateCounter = (action, channelId) => {
    if (!isArray(channels)) {
      return;
    }

    var index = channels.findIndex(n => n.siteChannelId === channelId);
    if (index < 0) {
      return;
    }

    var tempChannels = [...channels];
    var tempChannel = { ...tempChannels[index] };

    switch (action) {
      case "increase":
        tempChannel.unreadMessageCount++;
        break;
      case "reset":
        tempChannel.unreadMessageCount = 0;
        break;
      default:
        console.warn("** Unhandled action", { action, channelId });
        return;
    }

    tempChannels.splice(index, 1, tempChannel);
    dispatch(setChannels({ items: tempChannels }));
  };

  return {
    increase: (channelId) => updateCounter("increase", channelId),
    reset: (channelId) => updateCounter("reset", channelId),
  };
};

export default useUnreadSiteChannelMessagesCounter;
