import { SHAPESIZE } from "./config";
import { STEP_TYPE } from "./types";

const WIDTH = SHAPESIZE;
const HEIGHT = SHAPESIZE;

const useGetWorkflow = () => {
  const getWorkflow = (id) => WORKFLOWS.find(workflow => workflow.id === id) || EMPTY_WORKFLOW;
  const listWorkflows = () => WORKFLOWS.map(workflow => ({ id: workflow.id, name: workflow.name }));

  return {
    getWorkflow,
    listWorkflows,
  };
};

const EMPTY_WORKFLOW = {
  id: undefined,
  name: "New workflow",
  connections: [],
  steps: {}
};

const WORKFLOW_001 = {
  id: "workflow-001",
  name: "Siemens Workflow",
  connections: [
    { from: { id: "upload", position: "right" }, to: { id: "step1", position: "left" } },
    { from: { id: "step1", position: "right" }, to: { id: "step2", position: "left" } },
    { from: { id: "step2", position: "right" }, to: { id: "step3", position: "left" } },
    //{ from: { id: "step3", position: "right" }, to: { id: "step4", position: "left" } },

    { from: { id: "step3", position: "right" }, to: { id: "step4_1", position: "left" } },
    { from: { id: "step4_1", position: "bottom" }, to: { id: "step4_2", position: "top" } },
    { from: { id: "step4_2", position: "bottom" }, to: { id: "step4_3", position: "top" } },
    { from: { id: "step4_1", position: "right" }, to: { id: "step5", position: "left" } },

    //{ from: { id: "step4", position: "right" }, to: { id: "step5", position: "left" } },

    { from: { id: "step5", position: "right" }, to: { id: "step6", position: "left" } },
    { from: { id: "step4_1", position: "right" }, to: { id: "digital", position: "left" } },
    { from: { id: "step6", position: "right" }, to: { id: "step7", position: "left" } },
    { from: { id: "step2", position: "bottom" }, to: { id: "error", position: "top" } },
  ],
  steps: {
    upload: {
      id: "upload",
      x: 50,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#ecdc02",
      name: "Filuppladdning",
      type: STEP_TYPE.FOLDER_UPLOAD,
    },
    step1: {
      id: "step1",
      x: 200,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#990",
      name: "XML-inläsning",
      type: STEP_TYPE.XML_LOADING,
    },
    step2: {
      id: "step2",
      x: 350,
      y: 70,
      width: WIDTH,
      height: HEIGHT,
      colour: "#f57f20",
      name: "Preflight",
      type: STEP_TYPE.PREFLIGHT,
      preflightProfileId: "preflight-profile-001",
    },
    step3: {
      id: "step3",
      x: 500,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#00aeef",
      name: "Pärmuppdelning",
      type: STEP_TYPE.CONTAINER,
    },
    step4_1: {
      id: "step4_1",
      x: 700,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#28f",
      name: "Mall 1",
      type: STEP_TYPE.TEMPLATES,
      templateId: "template-001",
    },
    step4_2: {
      id: "step4_2",
      x: 700,
      y: 175,
      width: WIDTH,
      height: HEIGHT,
      colour: "#28f",
      name: "Mall 2",
      type: STEP_TYPE.TEMPLATES,
      templateId: "template-002",
    },
    step4_3: {
      id: "step4_3",
      x: 700,
      y: 350,
      width: WIDTH,
      height: HEIGHT,
      colour: "#28f",
      name: "Mall 3",
      type: STEP_TYPE.TEMPLATES,
      templateId: "template-003",
    },
    step5: {
      id: "step5",
      x: 840,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#00aeef",
      name: "Pdf merge",
      type: STEP_TYPE.CONTAINER,
    },
    step6: {
      id: "step6",
      x: 1000,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#93ca30",
      name: "Utskriftsinstruktioner",
      type: STEP_TYPE.FOLDER_ZIP,
    },
    step7: {
      id: "step7",
      x: 1120,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#93ca30",
      name: "Utskrift",
      type: STEP_TYPE.PRINT,
    },
    digital: {
      id: "digital",
      x: 1120,
      y: 220,
      width: WIDTH,
      height: HEIGHT,
      colour: "#93ca30",
      name: "Digital publikation",
      type: STEP_TYPE.PRINT,
    },
    error: {
      id: "error",
      x: 380,
      y: 220,
      width: WIDTH,
      height: HEIGHT,
      colour: "#f05",
      name: "Preflightfel",
      type: STEP_TYPE.CONTAINER,
      notifications: ["Fel i pdf: E1B16455443.pdf"]
    },
  }
};

const WORKFLOW_002 = {
  id: "workflow-002",
  name: "Second workflow",
  connections: [
    { from: { id: "upload", position: "right" }, to: { id: "step1", position: "left" } },
    { from: { id: "step1", position: "right" }, to: { id: "step2", position: "left" } },
    { from: { id: "step2", position: "right" }, to: { id: "step3", position: "left" } },
    //{ from: { id: "step3", position: "right" }, to: { id: "step4", position: "left" } },

    { from: { id: "step3", position: "right" }, to: { id: "step4_1", position: "left" } },
    { from: { id: "step4_1", position: "bottom" }, to: { id: "step4_2", position: "top" } },
    { from: { id: "step4_2", position: "bottom" }, to: { id: "step4_3", position: "top" } },
  ],
  steps: {
    upload: {
      id: "upload",
      x: 50,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#ecdc02",
      name: "Filuppladdning",
      type: STEP_TYPE.FOLDER_UPLOAD,
    },
    step1: {
      id: "step1",
      x: 200,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#990",
      name: "XML-inläsning",
      type: STEP_TYPE.XML_LOADING,
    },
    step2: {
      id: "step2",
      x: 350,
      y: 70,
      width: WIDTH,
      height: HEIGHT,
      colour: "#f57f20",
      name: "Preflight",
      type: STEP_TYPE.PREFLIGHT,
      preflightProfileId: "preflight-profile-001",
    },
    step3: {
      id: "step3",
      x: 500,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#00aeef",
      name: "Pärmuppdelning",
      type: STEP_TYPE.CONTAINER,
    },
    step4_1: {
      id: "step4_1",
      x: 700,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#28f",
      name: "Mall 1",
      type: STEP_TYPE.TEMPLATES,
      templateId: "template-001",
    },
    step4_2: {
      id: "step4_2",
      x: 700,
      y: 175,
      width: WIDTH,
      height: HEIGHT,
      colour: "#28f",
      name: "Mall 2",
      type: STEP_TYPE.TEMPLATES,
      templateId: "template-002",
    },
    step4_3: {
      id: "step4_3",
      x: 700,
      y: 350,
      width: WIDTH,
      height: HEIGHT,
      colour: "#28f",
      name: "Mall 3",
      type: STEP_TYPE.TEMPLATES,
      templateId: "template-003",
    },
  }
};

const WORKFLOW_003 = {
  id: "workflow-003",
  name: "Third workflow",
  connections: [
    { from: { id: "upload", position: "right" }, to: { id: "step1", position: "left" } },
    { from: { id: "step1", position: "right" }, to: { id: "step2", position: "left" } },
    { from: { id: "step2", position: "right" }, to: { id: "step3", position: "left" } },
  ],
  steps: {
    upload: {
      id: "upload",
      x: 50,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#ecdc02",
      name: "Filuppladdning",
      type: STEP_TYPE.FOLDER_UPLOAD,
    },
    step1: {
      id: "step1",
      x: 200,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#990",
      name: "XML-inläsning",
      type: STEP_TYPE.XML_LOADING,
    },
    step2: {
      id: "step2",
      x: 350,
      y: 70,
      width: WIDTH,
      height: HEIGHT,
      colour: "#f57f20",
      name: "Preflight",
      type: STEP_TYPE.PREFLIGHT,
      preflightProfileId: "preflight-profile-001",
    },
    step3: {
      id: "step3",
      x: 500,
      y: 50,
      width: WIDTH,
      height: HEIGHT,
      colour: "#00aeef",
      name: "Pärmuppdelning",
      type: STEP_TYPE.CONTAINER,
    },
  }
};

const WORKFLOWS = [
  WORKFLOW_001,
  WORKFLOW_002,
  WORKFLOW_003,
];

export default useGetWorkflow;
