import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Button, IconButton } from "@mui/material";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos'
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

const visuallyHiddenStyle = {
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
};

const BaseButton = ({ children, component, disabled, onClick, role, startIcon, tabIndex, variant = "outlined", sx }) => {
  return (
    <Button
      component={component}
      disabled={disabled}
      onClick={onClick}
      role={role}
      size="small"
      startIcon={startIcon}
      style={{
        borderRadius: "8px",
        color: "inherit",
      }}
      tabIndex={tabIndex}
      variant={variant}
      sx={{
        textTransform: "none",
        ...sx
      }}
    >
      {children}
    </Button>
  )
};

export const CloseButton = ({ disabled, onClick, sx }) => (
  <IconButton
    color="inherit"
    disabled={disabled}
    onClick={onClick}
    size="small"
    sx={{
      backgroundColor: "#f0f0f080",
      "&:hover": {
        backgroundColor: "#f0f0f0ff",
      },
      ...sx
    }}
  >
    <CloseIcon fontSize="inherit" />
  </IconButton>
);

export const EditImagesButton = ({ disabled, imageCount, onClick, sx }) => (
  <BaseButton
    disabled={disabled}
    onClick={onClick}
    startIcon={<EditIcon />}
    sx={sx}
  >
    {imageCount > 1
      ? <FormattedMessage id="newsFlowWidget.images.buttons.editAll" />
      : <FormattedMessage id="newsFlowWidget.images.buttons.edit" />
    }
  </BaseButton>
);

export const FileInputButton = ({ disabled, onAcceptedFile, onRejectedFile, sx, variant }) => {

  const inputRef = useRef(null);

  const handleChange = async event => {
    const files = event?.target?.files || [];

    const fileCount = files.length;

    if (fileCount !== 1) {
      if (fileCount > 1) {
        console.log(`** expected one file, got ${fileCount}`);
      }
      return;
    }

    const file = files[0];
    const isAccepted = ["image/gif", "image/jpeg", "image/png"].includes(file.type);

    if (isAccepted) {
      await onAcceptedFile?.(file);
    }
    else {
      await onRejectedFile?.(file);
    }

    inputRef.current.value = "";
  }

  return (
    <BaseButton
      component="label"
      disabled={disabled}
      role={undefined}
      startIcon={<AddToPhotosIcon />}
      tabIndex={-1}
      variant={variant}
      sx={sx}
    >
      <FormattedMessage id="newsFlowWidget.images.buttons.addImage" />
      <input
        ref={inputRef}
        type="file"
        accept="image/gif,image/jpeg,image/png"
        onChange={handleChange}
        style={visuallyHiddenStyle}
      />
    </BaseButton>
  );
};
