import clsx from "clsx";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import {
  Box,
  Icon,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CREATE_CONVERSATION as CREATE_DIRECT_MESSAGE_CONVERSATION } from "../../graphql/mutations/DirectMessageContext";
import { useMessagingContext } from "../../hooks/Messaging";
import ChannelsContainer from "./Channels/ChannelsContainer";
import ConversationContainer from "./Channels/ConversationContainer";
import MobileMessageDialog from "./MobileMessageDialog";
import StartConversationModal from "./StartConversationModal";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: 0,
    backgroundColor: theme.palette.common.white,
    height: "100%",
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 16,
  },
  unreadMessageCount: {
    backgroundColor: theme.palette.component.badgeBackground,
    borderRadius: "50%",
    color: theme.palette.component.badgeColor,
    fontSize: 10,
    fontWeight: 700,
    margin: "auto",
    marginLeft: "1em",
    padding: "3px 7px",
    textAlign: "center",
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const TabLabel = ({ classes, counter, messageId }) => (
  <Box>
    <FormattedMessage id={messageId} />
    {(counter > 0) && (
      <Box component="span" className={classes.unreadMessageCount}>
        {counter}
      </Box>
    )}
  </Box>
);

const MobileView = ({
  activeChannelId,
  activeConversationId,
  channel,
  channelLoading,
  channels,
  channelsLoading,
  conversation,
  conversationLoading,
  conversations,
  conversationsLoading,
  onChannelSelected,
  onConversationAdded,
  onConversationSelected,
  setSiteId,
}) => {
  const classes = useStyles();

  const {
    haveChannels,
    unreadChannelMessagesCount,
    unreadConversationMessagesCount,
  } = useMessagingContext();

  const [createConversation] = useMutation(CREATE_DIRECT_MESSAGE_CONVERSATION);

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showMessages, setShowMessages] = useState(false);
  const [startConversationVisible, setStartConversationVisible] = useState(false);

  const handleChannelSelected = async (channelId) => {
    await onChannelSelected(channelId);
    setShowMessages(true);
  };

  const handleConversationSelected = async (conversationId) => {
    await onConversationSelected(conversationId);
    setShowMessages(true);
  };

  const handleAddConversation = async userIds => {
    try {
      const res = await createConversation({
        variables: { userIds },
      });
      if (
        res &&
        res.data &&
        res.data.directMessageContext &&
        res.data.directMessageContext.createConversation
      ) {
        onConversationAdded({
          conversation: res.data.directMessageContext.createConversation,
        });
      }
    } catch (err) {
      console.error("error >", err);
    }

    setStartConversationVisible(false);
  };

  useEffect(() => {
    if (activeConversationId !== null && !showMessages) {
      setShowMessages(true);
    }
  }, [activeConversationId]);

  return (
    <div className={classes.root}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          aria-label="Channels and Users"
          onChange={(_, newIndex) => setActiveTabIndex(newIndex)}
          value={activeTabIndex}
        >
          <Tab
            label={
              <TabLabel
                classes={classes}
                counter={unreadConversationMessagesCount}
                messageId="messaging.users"
              />
            }
            {...a11yProps(0)}
          />
          {haveChannels && (
            <Tab
              label={
                <TabLabel
                  classes={classes}
                  counter={unreadChannelMessagesCount}
                  messageId="messaging.channels"
                />
              }
              {...a11yProps(1)}
            />
          )}
        </Tabs>
      </Box>

      <TabPanel value={activeTabIndex} index={0}>
        <IconButton
          onClick={() => setStartConversationVisible(true)}
        >
          <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
        </IconButton>
        <ConversationContainer
          activeConversationId={activeConversationId}
          conversations={conversations}
          loading={conversationsLoading}
          onAddConversation={handleAddConversation}
          onConversationSelected={handleConversationSelected}
        />
        <StartConversationModal
          handleClose={() => setStartConversationVisible(false)}
          onAddConversation={handleAddConversation}
          open={startConversationVisible}
        />
      </TabPanel>

      <TabPanel value={activeTabIndex} index={1}>
        <ChannelsContainer
          activeChannelId={activeChannelId}
          channelsLoading={channelsLoading}
          channels={channels}
          onChannelSelected={handleChannelSelected}
          setSiteId={setSiteId}
        />
      </TabPanel>

      <MobileMessageDialog
        activeChannelId={activeChannelId}
        activeConversationId={activeConversationId}
        channel={channel}
        channelLoading={channelLoading}
        conversation={conversation}
        conversationLoading={conversationLoading}
        onClose={() => setShowMessages(false)}
        open={showMessages}
      />
    </div>
  );
};

export default MobileView;
