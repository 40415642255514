import { useContext } from "react";
import { commentsContext } from "../../context/FullscreenAnnotationModal/CommentsContext";

export const useCommentsContext = () => {
  const context = useContext(commentsContext);

  if (context === undefined) {
    throw new Error(
      "Hook useCommentsContext is used outside of its Provider."
    );
  }

  return context;
};
