import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, ListItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: 5,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 2,
    justifyContent: "space-between",
    minHeight: 36,
    fontSize: 12,
    "& > div > button": {
      display: "none",
    },
    "&:hover > div > button": {
      display: "block",
    },
  },
  listItemActive: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  listItemUnread: {
    fontWeight: 600,
  },
  profileImg: {
    width: 30,
    height: 30,
    objectFit: "cover",
    borderRadius: "50%",
    marginRight: 8,
  },
  statusIndicator: {
    width: 5,
    height: 5,
    backgroundColor: "inherit",
    borderRadius: "50%",
    marginRight: 8,
    display: "inline-block",
    verticalAlign: "middle",
  },
  statusOnline: {
    backgroundColor: "#3FA844",
  },
  statusOffline: {
    backgroundColor: "#a7a7a7",
  },
  deleteIconContainer: {
    minWidth: 26,
    paddingRight: 5,
  },
  deleteIcon: {},
  unreadMessageCount: {
    backgroundColor: theme.palette.component.badgeBackground,
    borderRadius: "50%",
    color: theme.palette.component.badgeColor,
    fontSize: 10,
    fontWeight: 700,
    margin: "auto",
    marginLeft: "1em",
    padding: "2px 7px",
    textAlign: "center",
  },
}));

const ConversationListItem = ({
  activeConversationId,
  conversation,
  onAddConversation,
  onConversationSelected,
}) => {
  const classes = useStyles();

  const {
    directMessageConversationId,
    latestMessageId,
    members,
    unreadMessageCount,
  } = conversation;

  const isActive = directMessageConversationId === activeConversationId;
  const isUnread = unreadMessageCount > 0;
  const channelName = members.map(n => n.name).join(", ");

  return (
    <ListItem
      button
      disableRipple
      className={clsx([
        classes.listItem,
        isActive && classes.listItemActive,
        isUnread && classes.listItemUnread,
      ])}
      onClick={() => {
        if (directMessageConversationId) {
          onConversationSelected(directMessageConversationId);
        }
        else {
          onAddConversation(members[0].userId);
        }
      }}
    >
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <span className={clsx([classes.statusIndicator])} />
          {members.map(n => {
            return (
              <img
                key={n.userId}
                src={n.avatarUrl}
                alt="Profile"
                className={classes.profileImg}
              />
            );
          })}

          <Typography variant="inherit">{channelName}</Typography>
          {isUnread && !isActive && (
            <span className={classes.unreadMessageCount}>
              {unreadMessageCount}
            </span>
          )}
        </Box>
      </Box>
    </ListItem>
  );
};

ConversationListItem.defaultProps = {
  activeConversationId: null,
};

ConversationListItem.propTypes = {
  activeConversationId: PropTypes.number,
  conversation: PropTypes.shape({}).isRequired,
  onAddConversation: PropTypes.func.isRequired,
  onConversationSelected: PropTypes.func.isRequired,
};

export default ConversationListItem;
