import { useMutation } from "@apollo/client";
import { INIT_NEWS_POST_FILE_UPLOAD } from "../../graphql/mutations/NewsContext";

const extractResult = (data) => data?.newsContext?.initNewsPostFileUpload;

const useNewsPostFileUploadSession = () => {

  const [initNewsPostFileUploadMutation, { data, error, loading }] = useMutation(
    INIT_NEWS_POST_FILE_UPLOAD,
    {
      errorPolicy: "none",
    }
  );

  const executeInitNewsPostFileUploadMutation = async ({ session, onError, onSuccess }) => {
    try {
      const res = await initNewsPostFileUploadMutation({
        variables: {
          session
        },
      });

      const result = extractResult(res.data);

      if (result?.success) {
        const { session, uploadUrl } = result;
        console?.info("** useNewsPostFileUploadSession - success", { session, result });
        onSuccess?.({ session, uploadUrl });
      }
      else {
        const { session, errorResult } = result;
        console?.error("** useNewsPostFileUploadSession - error", { session, errorResult });
        onError?.({ session });
      }
    } catch (error) {
      console?.error("** useNewsPostFileUploadSession - error", { session, error });
      onError?.({ session });
    }
  };

  return [
    executeInitNewsPostFileUploadMutation,
    {
      data: extractResult(data),
      error,
      loading,
    }
  ];

};

export default useNewsPostFileUploadSession;
