import * as WidgetIcons from "../assets/img/widgets/widgetIcons.js";
import { WIDGET_PANELS } from "../components/WorkspaceDashboard/WidgetsDrawer/index.jsx";

const basicWidgetValues = {
  minW: undefined,
  minH: undefined,
  maxW: undefined,
  maxH: undefined,
  borderColor: "#7a0625ff",
  bgColor: "#fff",
  fontColor: "#662336",
  actionBarBG: "#91657185",
  showTitleBar: true,
  borderRadiusTopLeft: 6,
  borderRadiusTopRight: 6,
  borderRadiusBottomLeft: 6,
  borderRadiusBottomRight: 6,
  borderWidth: 0,
  contentBG: "#fff",
  dividerColor: "#dee2e6",
  titleColor: "#212529",
};

export const WIDGET_TYPES = Object.freeze({
  //typos shall not be fixed!
  //will break existing saved dashboards.
  RECENTLY_MODIFIED_FILES: "recently-modefied-files", //keep the misspelled name
  YOUTUBE: "video",
  NEWS_BIG: "news",
  NEWS_COLUMN: "news-post",
  WORKSPACE: "workspace",
  WORKSPACES: "workspaces",
  TEXTEDITOR: "informationmessage",
  LINK: "link",
  IMAGE_GALLERY: "imageGallery",
  CONTACTS: "contacts-Widget",
});

export const widgetList = [
  {
    ...basicWidgetValues,
    i: "a",
    x: 0,
    y: 0,
    w: 2,
    h: 4,
    image: WidgetIcons.YouTube,
    title: "widget.youtube",
    desc: "widget.youtubeDesc",
    type: WIDGET_TYPES.YOUTUBE,
    videoId: null,
    isConfigured: false,
  },
  {
    ...basicWidgetValues,
    i: "b",
    x: 0,
    y: 0,
    w: 2,
    h: 20,
    image: WidgetIcons.RecentlyModifiedFiles,
    title: "widget.recentlyModified",
    desc: "widget.recentlyModifiedDesc",
    type: WIDGET_TYPES.RECENTLY_MODIFIED_FILES,
    isConfigured: true,
  },
  {
    ...basicWidgetValues,
    i: "c",
    x: 0,
    y: 0,
    w: 8,
    h: 17,
    image: WidgetIcons.NewsWidgetWide,
    title: "widget.news",
    desc: "widget.newsDesc",
    type: WIDGET_TYPES.NEWS_BIG,
    newsTemplate: 1,
    isConfigured: true,
  },
  {
    ...basicWidgetValues,
    i: "c",
    x: 0,
    y: 0,
    w: 8,
    h: 17,
    image: WidgetIcons.NewsWidgetWide,
    title: "widget.news",
    desc: "widget.newsDesc",
    type: WIDGET_TYPES.NEWS_FLOW,
    newsTemplate: 1,
    isConfigured: true,
  },
  {
    ...basicWidgetValues,
    i: "d",
    x: 0,
    y: 0,
    w: 2,
    h: 7,
    minW: 2,
    minH: 7,
    image: WidgetIcons.Workspace,
    title: "widget.siteWidget",
    desc: "widget.siteWidgetDesc",
    type: WIDGET_TYPES.WORKSPACE,
    isConfigured: false,
  },
  //{
  //  ...basicWidgetValues,
  //  i: "e",
  //  x: 0,
  //  y: 0,
  //  w: 2,
  //  h: 7,
  //  minW: 2,
  //  minH: 7,
  //  image: WidgetIcons.SiteLayers,
  //  title: "widget.pageLinkTitle",
  //  desc: "widget.pageLinkTitleDesc",
  //  type: "page-link",
  //  isConfigured: false,
  //},
  {
    ...basicWidgetValues,
    i: "f",
    x: 0,
    y: 0,
    w: 8,
    h: 4,
    image: WidgetIcons.TextEditor,
    title: "widget.information",
    desc: "widget.informationDesc",
    type: WIDGET_TYPES.TEXTEDITOR,
    isConfigured: false,
  },
  {
    ...basicWidgetValues,
    i: "g",
    x: 0,
    y: 0,
    w: 2,
    h: 4,
    image: WidgetIcons.Link,
    title: "widget.linkWidget",
    desc: "widget.linkWidgetDesc",
    type: WIDGET_TYPES.LINK,
    isConfigured: false,
  },
  {
    ...basicWidgetValues,
    i: "h",
    x: 0,
    y: 0,
    w: 2,
    h: 20,
    image: WidgetIcons.NewsWidgetNarrow,
    title: "widget.newsPost",
    desc: "widget.newsPostDesc",
    type: WIDGET_TYPES.NEWS_COLUMN,
    isConfigured: true,
  },
  {
    ...basicWidgetValues,
    i: "i",
    x: 0,
    y: 0,
    w: 2,
    h: 20,
    image: WidgetIcons.Workspaces,
    title: "widget.myWorkspaces",
    desc: "widget.myWorkspaces",
    type: WIDGET_TYPES.WORKSPACES,
    isConfigured: true,
  },
  {
    ...basicWidgetValues,
    i: "j",
    x: 0,
    y: 0,
    w: 8,
    h: 16,
    image: WidgetIcons.Workspaces,
    title: "widget.imageGallery",
    desc: "widget.imageGallery",
    type: WIDGET_TYPES.IMAGE_GALLERY,
    isConfigured: true,
  },
  {
    ...basicWidgetValues,
    i: "k",
    x: 0,
    y: 0,
    w: 2,
    h: 20,
    image: WidgetIcons.ContactsWidget,
    title: "widget.contactsWidget.title",
    desc: "widget.contactsWidget.description",
    type: WIDGET_TYPES.CONTACTS,
    isConfigured: true,
  },
];
