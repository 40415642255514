import { IconButton } from '@mui/material';

const sxSquareButton = {
  borderRadius: 1,
  "&::before,&::after": {
    content: "''",
    position: "absolute",
    bottom: "4px",
    right: "4px",
    borderColor: "transparent",
    borderStyle: "solid"
  },
  "& .MuiTouchRipple-root .MuiTouchRipple-child": {
    borderRadius: 1,
  },
};

const sxMenuButton = {
  "&::before": {
    borderWidth: "4px",
    borderRightColor: "#efefef",
    borderBottomColor: "#efefef"
  },
  "&::after": {
    borderRadius: "1px",
    borderWidth: "3px",
    borderRightColor: "initial",
    borderBottomColor: "initial",
  }
};

const ToolbarIconButton = ({ activeToolType, children, disabled, isMenuButton, onSelect, sx, toolType }) => {
  const backgroundColor =
    (toolType === activeToolType)
      ? 'rgba(0, 0, 0, 0.1)'
      : 'rgba(0, 0, 0, 0.0)';

  return (
    <IconButton
      disabled={disabled}
      onClick={(e) => onSelect?.(e, toolType)}
      sx={{
        backgroundColor,
        ...sxSquareButton,
        ...(isMenuButton ? sxMenuButton : undefined),
        ...sx,
      }}
    >
      {children}
    </IconButton>
  )
};

export default ToolbarIconButton;
