import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { alpha, TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { Paper, Box, Typography, Button, Grid, Chip } from "@mui/material";
import clsx from "clsx";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import axios from "axios";
import OneStreamTable from "../../components/OneStream/OneStreamTable";
import OneStreamDropzone from "../../components/OneStream/OneStreamDropzone";
import NewUploadModal from "../../components/OneStream/NewUploadModal";
import { useGetOneStreamUploadUrl, useGetOneStreamJobs } from "../../hooks";
import Pagination from "../../components/common/Pagination";
import { getSiteId } from "../../helpers/selectors";
//import StatisticsModal from "../../components/OneStream/Statistics/StatisticsModal";
import OneStreamFlowModal from "../../components/OneStream/OneStreamFlow/OnestreamFlowModal";
import SimpleModalCompact from '../../components/common/ModalCompact/SimpleModalCompact';
import { Table, TableCell, TableHead, TableBody } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
  },
  paperHead: {
    padding: theme.spacing(2),
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  title: {
    color: theme.palette.component.productBoxColor,
    fontSize: 18,
    fontWeight: 600,
    marginRight: 10,
  },
  btnAdd: {
    padding: "10px 15px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.common.white,
    fontSize: 12,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.light, 0.8),
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 10,
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
  },
  selectWrap: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    width: 300,
    "& .MuiIconButton-root": {
      padding: 5,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.common.white,
    },
  },
  paperBody: {
    padding: theme.spacing(4),
    paddingTop: 10,
    paddingBottom: 20,
    "& .dropzone": {
      backgroundColor: theme.palette.common.white,
      marginTop: 15,
    },
  },
  paginationWrapper: {
    marginLeft: "auto",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 8,
      marginTop: 8,
      marginLeft: 0,
    },
  },
}));

const OneStream2 = ({ }) => {

  const classes = useStyles();

  const [formValues, setFormValues] = useState({ preset: 1, email: "" });
  const [showErrorReport, setShowErrorReport] = useState(false);

  const data = [{
    id: 1,
    project: "AAA-001",
    costPlace: "4454-4545",
    binderType: "Generic",
    uploadedBy: "john.doe@siemens.com",
    uploadDate: "2024-09-23 11:24",
    updated: "2024-09-23 11:29",
    status: "Klar",
    statusColor: "#008800",
    progress: "",
    errorReport: "",
    actions: ["Beställ"]
  },

  {
    id: 2,
    project: "AAA-002",
    costPlace: "4454-4565",
    binderType: "Generic",
    uploadedBy: "john.doe@siemens.com",
    uploadDate: "2024-09-24 10:18",
    updated: "2024-09-24 10:28",
    status: "Fel",
    statusColor: "#AA0000",
    progress: "",
    errorReport: "Felrapport",
    actions: ["Åtgärda"]
  },

  {
    id: 3,
    project: "AAA-003",
    costPlace: "4454-4567",
    binderType: "Generic",
    uploadedBy: "john.doe@siemens.com",
    uploadDate: "2024-09-25 9:14",
    updated: "2024-09-25 9:22",
    status: "Preflight",
    statusColor: "#0000AA",
    progress: "Preflighting (123 of 256)",
    errorReport: "",
    actions: ["Avbryt"]
  },

  {
    id: 4,
    project: "AAA-005",
    costPlace: "4454-4567",
    binderType: "Generic",
    uploadedBy: "john.doe@siemens.com",
    uploadDate: "2024-09-25 10:30",
    updated: "2024-09-25 10:30",
    status: "Köad",
    statusColor: "#888",
    progress: "",
    errorReport: "",
    actions: ["Avbryt"]
  }

  ]

  const handleActionClick = (row) => {

  }



  return (<div className={classes.root}>

    <SimpleModalCompact open={showErrorReport} onClose={n => setShowErrorReport(false)} title="Felrapport">
      <Grid container justifyContent="center">
        <Grid item>
          <Box>
            <Typography variant="h3" component="h3">Xml</Typography>
            <Table>
              <TableRow><TableCell>Allt ok</TableCell></TableRow>
            </Table>
      
                      <Typography variant="h3" component="h3">Preflight</Typography>
            <Table>
              <TableRow><TableCell>E1B1524428.pdf</TableCell><TableCell>Document contains unreadable pages</TableCell></TableRow>
            </Table>                    
            
            </Box>
       </Grid>
      </Grid>
    </SimpleModalCompact>


    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <Paper className={classes.paper} elevation={0}>
          <Box className={classes.paperHead}>
            <Typography variant="h1" component="h2" className={classes.title}>
              OneStream
              </Typography>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box sx={{ marginLeft: 1, marginRight: 1, marginTop: 1 }}><span style={{ opacity: 0.8 }}> Siemens Binder Flow</span></Box>
              <Button
                className={classes.btnAdd}

              >
                <FormattedMessage id="onestream.newUpload" />
                <Icon
                  className={clsx(["fa fa-plus-circle", classes.btnIcon])}
                />
              </Button>
            </Box>

          </Box>

          {/*    id: 1,
    project: "",
    po: "",
    binderType: "",
    uploadedBy: "",
    uploadDate: "",
    updated: "",
    status: "",
    statusColor: "",
    progress: "",
    errorReport: "",
    actions: [""]*/}

          <Box className={classes.paperBody}>
            <Table>
              <TableHead>
                <TableRow>



                  <TableCell>#</TableCell>
                  <TableCell>Projekt</TableCell>
                  <TableCell>PO-nummer</TableCell>
                  <TableCell>Manualtyp</TableCell>
                  <TableCell>Initierad av</TableCell>
                  <TableCell>Uppladdad</TableCell>
                  <TableCell>Uppdaterad</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Förlopp</TableCell>
                  <TableCell>Rapport</TableCell>
                  <TableCell>Åtgärder</TableCell>
                </TableRow>

              </TableHead>
              <TableBody>

                {data.map(n => (
                  <TableRow>
                    <TableCell>{n.id}</TableCell>
                    <TableCell>{n.project}</TableCell>
                    <TableCell>{n.costPlace}</TableCell>
                    <TableCell>{n.binderType}</TableCell>
                    <TableCell>{n.uploadedBy}</TableCell>
                    <TableCell>{n.uploadDate}</TableCell>
                    <TableCell>2024-08-31 12:25</TableCell>
                    <TableCell><Chip sx={{ backgroundColor: n.statusColor, color: "white" }} label={n.status}></Chip></TableCell>
                    <TableCell>{n.progress}</TableCell>
                    <TableCell>


                      {n.errorReport !== "" && (<Button onClick={n => setShowErrorReport(true)} variant="contained">{n.errorReport}</Button>)}


                    </TableCell>
                    <TableCell>{n.actions.map(label => (<Button variant="contained">{label}</Button>))}</TableCell>
                  </TableRow>))}




              </TableBody>
            </Table>
          </Box>
        </Paper>
      </Grid>
    </Grid>

  </div>
  );
}

export default OneStream2;