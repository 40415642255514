import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { bindActionCreators } from "redux";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import UserDetails from "../../../UserDetails";
import { setLocale, selectWorkspace } from "../../../../actions";

import SearchBar from "./SearchBar";
import NavMenuList from "./NavMenuList";
import LanguageSelecter from "./LanguageSelector";

import {
  setSearchResults,
  setSearchQuery,
  setDoDetailedSearch,
} from "../../../../actions";
import { useMutation } from "@apollo/client";
import { UPDATE_CULTURE } from "../../../../graphql/mutations";

const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
    backgroundColor: theme.palette.primary.dark,
    height: "100%",
  },
  menuButton: {
    color: theme.palette.component.hamburger,
    padding: 0,
    marginRight: 10,
    [theme.breakpoints.down("sm")]: {
      marginRight: 8,
      flex: 1,
    },
  },
  linkWrapper: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: "center",
    backgroundColor: theme.palette.primary.dark,
  },
  divider: {
    marginBottom: 15,
    marginTop: 15,
    backgroundColor: "#6b3b49",
  },
  ProfileMargin: {
    margin: "5px 0 15px 0",
  },
}));

const NavbarMobileDrawer = ({
  companyMenuItems,
  sites,
  workspace,
  currentViewer,
  // eslint-disable-next-line no-shadow
  selectWorkspace,
  // eslint-disable-next-line no-shadow
  setLocale,
  locale,
}) => {
  const classes = useStyles();
  const [menus, setMenus] = useState([]);
  const [updateCulture] = useMutation(UPDATE_CULTURE);

  useEffect(() => {
    if (sites && workspace && workspace.id) {
      const selectedSite = sites.find(x => x.id === workspace.id);
      if (selectedSite) {
        const { menuItems } = selectedSite;
        setMenus(menuItems);
      }
    } else {
      setMenus(companyMenuItems);
    }
  }, [sites, workspace]);

  const [anchorLang, setAnchorLang] = React.useState(null);
  const handleLang = event => {
    setAnchorLang(event.currentTarget);
  };

  const handleCloseLang = async ({ culture }) => {
    
    if (culture) {
      try {
        
        await updateCulture({ variables: { culture } });
      } catch (error) {
        console.error("Failed to update culture:", error);
        
      } finally {
        
        setLocale({ culture });
      }
    }
    setAnchorLang(null);
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = () => (
    <div className={classes.list} role="presentation">
      <Box pl={3} className={classes.linkWrapper}>
        <div className={classes.ProfileMargin}>
          <UserDetails viewer={currentViewer} setSearchQuery={setSearchQuery} />
        </div>
        <SearchBar />
        <Divider className={classes.divider} />
        <NavMenuList selectWorkspace={selectWorkspace} menus={menus} />
        <Divider className={classes.divider} />
        <LanguageSelecter
          handleLang={handleLang}
          anchorLang={anchorLang}
          handleCloseLang={handleCloseLang}
          locale={locale}
        />
      </Box>
    </div>
  );
  return (
    <div>
      <IconButton
        className={classes.menuButton}
        onClick={toggleDrawer("left", true)}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        open={state.left}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
        swipeAreaWidth={5}
      >
        {sideList("left")}
      </SwipeableDrawer>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    locale: state.ui.locale,
    sites: state.api.currentViewer.viewer.sites,
    workspace: state.ui.toggleWorkspaces.workspace,
    companyMenuItems: state.api.currentViewer.viewer.menuItems,
    currentViewer: state.api.currentViewer.viewer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setLocale,
      selectWorkspace,
    },
    dispatch
  );
};

NavbarMobileDrawer.defaultProps = {
  locale: null,
  workspace: null,
  selectWorkspace: null,
  setLocale: null,
};

NavbarMobileDrawer.propTypes = {
  setLocale: PropTypes.func,
  selectWorkspace: PropTypes.func,
  workspace: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  locale: PropTypes.shape({
    culture: PropTypes.string.isRequired,
  }),
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          page: PropTypes.string.isRequired,
          childMenu: PropTypes.array,
        })
      ),
    }).isRequired
  ).isRequired,
  companyMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      page: PropTypes.string.isRequired,
      childMenu: PropTypes.array,
    })
  ).isRequired,
  currentViewer: PropTypes.shape({
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarMobileDrawer);
