import { useEffect, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { MESSAGING_CONTACTS_LOOKUP } from "../../graphql/queries";

const extractLookupData = (data) => data?.productEdit?.companyAnsvarigLookup;

const useSearchContacts = ({ onCompleted, onError } = {}) => {

  const abortController = useRef(new AbortController());

  const abortRequest = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  useEffect(() => {
    return () => {
      abortRequest();
    };
  }, []);

  const searchTermRef = useRef();

  const [getSearchContactsQuery, { data, error, loading }] = useLazyQuery(
    MESSAGING_CONTACTS_LOOKUP,
    {
      errorPolicy: "none",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const lookupData = extractLookupData(data);
        //console?.info("** Completed query: SearchContacts", { lookupData, searchTerm: searchTermRef.current });
        onCompleted?.(lookupData, searchTermRef.current);
      },
      onError: async (error) => {
        //console?.error("** Query error: SearchContacts", { error, searchTerm: searchTermRef.current });
        onError?.(error, searchTermRef.current);
      },
    }
  );

  const getSearchContacts = async (searchTerm) => {
    abortRequest();

    searchTermRef.current = searchTerm;

    return await getSearchContactsQuery({
      context: {
        fetchOptions: {
          signal: abortController.current.signal,
        },
      },
      variables: {
        searchTerm,
      }
    });
  };

  return [
    getSearchContacts,
    {
      data: extractLookupData(data),
      error,
      loading,
    }
  ];

};

export default useSearchContacts;
