import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Avatar as Profile } from "@mui/material";

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: 6,
  },
  avatarLetters: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 13,
    background: theme.palette.primary.light,
    marginRight: 6,
  },
}));

const Avatar = ({ author, url, customClass }) => {
  const classes = useStyles();

  const splitAuthor = author.trim().split(/\s+/); 
  let firstname = "";
  let lastname = "";

  
  if (splitAuthor.length > 0) {
    firstname = splitAuthor[0].charAt(0).toUpperCase();
  }

  if (splitAuthor.length > 1) {
    lastname = splitAuthor[splitAuthor.length - 1].charAt(0).toUpperCase(); // First letter of the last word
  } else {
    lastname = ""; 
  }

  if (url) {
    return (
      <Profile className={clsx([classes.avatar, customClass])} src={url} />
    );
  }

  return (
    <Profile className={clsx([classes.avatarLetters, customClass])}>
      {`${firstname}${lastname}`}
    </Profile>
  );
};

export default Avatar;
