import { createActions } from 'redux-actions';

export const {
  setChannels,
  setChannelsLoading,
  setChannelsError,
} = createActions({
  SET_CHANNELS: ({ items }) => ({ items }),
  SET_CHANNELS_LOADING: ({ loading }) => ({ loading }),
  SET_CHANNELS_ERROR: ({ error }) => ({ error }),
});