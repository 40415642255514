import { useMutation } from "@apollo/client";
import { CLEAR_NEWS_POST_FILE_UPLOAD_SESSION } from "../../graphql/mutations/NewsContext";

const extractResult = (data) => data?.newsContext?.clearNewsPostFileUploadSession;

const useClearNewsPostFileUploadSession = ({ onCleared, onError } = {}) => {

  const [clearNewsPostFileUploadSessionMutation, { data, error, loading }] = useMutation(
    CLEAR_NEWS_POST_FILE_UPLOAD_SESSION,
    {
      errorPolicy: "none",
      onCompleted: (data) => {
        const result = extractResult(data);
        console?.info("** useClearNewsPostFileUploadSession - completed", { result });
        const { success } = result;
        if (success) {
          const { session } = result;
          onCleared?.(session);
        }
        else {
          const { errorResult } = result;
          console?.error("** useClearNewsPostFileUploadSession - error", { errorResult });
          onError?.();
        }
      },
      onError: (error) => {
        console?.error("** useClearNewsPostFileUploadSession - error", { error });
        onError?.(error);
      },
    }
  );

  const executeClearNewsPostFileUploadSessionMutation = async (session) => (
    await clearNewsPostFileUploadSessionMutation({
      variables: {
        session
      }
    })
  );

  return [
    executeClearNewsPostFileUploadSessionMutation,
    {
      data: extractResult(data),
      error,
      loading,
    }
  ];

};

export default useClearNewsPostFileUploadSession;
