import { gql } from '@apollo/client';
import SiteNewsComment from "../../fragments/SiteNewsComment";

const ADD_NEWS_COMMENT = gql`
mutation AddNewsComment($siteNewsPostid: ID!, $comment: String) {
	newsContext {
		addNewsComment(siteNewsPostid: $siteNewsPostid, comment: $comment) {
			commentDate
			text
			username
			authorId
			avatarUrl
			commentId
			mentions {
				avatarUrl
				mentionId
				name
				username
				userId
			}
			likes {
				emoticon
				isCurrentUser
				name
				userId
			}
		}
	}
}  
`;

export default ADD_NEWS_COMMENT;
