import { useSubscription } from "@apollo/client";
import { GET_DIRECT_MESSAGE_CONVERSATION_UPDATE } from "../../graphql/subscription";

const useConversationUpdateSubscription = ({ onError, onUpdate }) => {

  const extractConversation = (data) => data?.data?.directMessageConversationUpdate?.node;

  const { loading } = useSubscription(
    GET_DIRECT_MESSAGE_CONVERSATION_UPDATE,
    {
      onComplete: () => {
        console?.info("** Completed subscription: ConversationUpdateSubscription");
      },
      onData: (options) => {
        const conversation = extractConversation(options?.data);
        console?.info("** Subscription update: ConversationUpdateSubscription", { conversation });
        onUpdate?.(conversation);
      },
      onError: (error) => {
        console?.error("** Subscription error: ConversationUpdateSubscription", { error });
        onError?.(error);
      },
      variables: {
        authToken: localStorage.getItem("access_token"),
      },
    });

  return { loading };

};

export default useConversationUpdateSubscription;
