import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import { EditorState, convertToRaw, Modifier, RichUtils } from "draft-js";
import 'draft-js/dist/Draft.css';
import "@draft-js-plugins/mention/lib/plugin.css";
import "@draft-js-plugins/inline-toolbar/lib/plugin.css";

import Editor from "@draft-js-plugins/editor";

import createMentionPlugin, { defaultSuggestionsFilter } from "@draft-js-plugins/mention";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";
import { Button } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import messagingContactsLookup from "../../../graphql/queries/messagingContactsLookup";

const MentionEditor = React.forwardRef(({ onChange, onBlur, onEnterPressed, disableToolbar, fillArea = false, placeHolder, initialSuggestion }, ref) => {
  const editorRef = useRef(null);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  // Expose methods to parent via ref
  useImperativeHandle(ref, () => ({
    clear: () => setEditorState(EditorState.createEmpty()), // Clear editor content
    focus: () => editorRef.current.focus(),
    insertEmoticon: (emoticon) => insertEmoticonToEditor(emoticon), // Method to insert emoticon
  }));


  const insertEmoticonToEditor = (emoticon) => {

    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    // Insert the emoticon at the current selection
    const newContent = Modifier.insertText(currentContent, selection, emoticon);

    // Create a new editor state with the updated content
    const newEditorState = EditorState.push(editorState, newContent, "insert-characters");

    // Set the updated editor state
    setEditorState(newEditorState);
  };


  const handleChange = (newEditorState) => {

    setEditorState(newEditorState);
    onChange?.(convertMentionsToText(newEditorState));
  }

  // UseMemo for Plugins and Components
  const [plugins, MentionSuggestions, InlineToolbar] = useMemo(() => {
    const mentionPlugin = createMentionPlugin();
    const { MentionSuggestions } = mentionPlugin;

    if (!disableToolbar) {
      const inlineToolbarPlugin = createInlineToolbarPlugin();
      const { InlineToolbar } = inlineToolbarPlugin;
      return [[mentionPlugin, inlineToolbarPlugin], MentionSuggestions, InlineToolbar];
    }

    return [[mentionPlugin], MentionSuggestions, null];
  }, [disableToolbar]);

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);

  const [getContacts, { data, loading, error }] = useLazyQuery(messagingContactsLookup);

  const onSearchChange = useCallback(async ({ value }) => {


    if (value.length < 3 && initialSuggestion?.length > 0) {
      setSuggestions(initialSuggestion);
      return;
    }


    // Wait until we have at least 3 characters
    if (value.length >= 3) {
      // Execute GraphQL query to fetch mentions
      const { data } = await getContacts({
        variables: { searchTerm: value },
      });
      if (data) {
        const users = data.productEdit.companyAnsvarigLookup || [];
        const newSuggestions = users.map(user => ({
          name: `${user.firstname} ${user.lastname}`,
          avatar: user.avatarUrl,
          userId: user.userId,
        }));
        setSuggestions(newSuggestions);
      }
    }
  }, []);

  const handleBlur = () => {
    if (editorState) {
      return onBlur?.(convertMentionsToText(editorState));
    }
  };

  const convertMentionsToText = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const blocks = rawContent.blocks;
    const entityMap = rawContent.entityMap;

    let resultText = "";

    blocks.forEach((block) => {
      let blockText = block.text;

      const sortedEntityRanges = [...block.entityRanges].sort((a, b) => b.offset - a.offset);

      sortedEntityRanges.forEach(({ offset, length, key }) => {
        const entity = entityMap[key];
        if (entity.type === "mention") {
          const mention = entity.data.mention;
          const mentionText = `<@u${mention.userId}>`;
          const before = blockText.slice(0, offset);
          const after = blockText.slice(offset + length);
          blockText = `${before}${mentionText}${after}`;
        }
      });

      resultText += blockText + "\n";
    });

    return resultText.trim();
  };


  const handleKeyCommand = (command, editorState) => {

    // Only handle split-block command (Enter key presses)
    if (command !== 'split-block') {
      return 'not-handled';
    }

    // If no onEnterPressed handler provided, let draft-js handle it normally
    if (!onEnterPressed) {
      return 'not-handled';
    }

    const lastEvent = window.event;
    const isShiftEnter = lastEvent?.shiftKey;

    // Handle Shift + Enter: Insert new line
    if (isShiftEnter) {
      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();
      const newContent = Modifier.splitBlock(contentState, selectionState);
      const newEditorState = EditorState.push(
        editorState,
        newContent,
        'split-block'
      );
      setEditorState(newEditorState);
      return 'handled';
    }

    // Handle regular Enter: Call onEnterPressed callback
    onEnterPressed(convertMentionsToText(editorState));
    return 'handled';
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1, // Make the root div flexible
        border: "1px solid #ccc",
        borderRadius: "4px",
        ...(fillArea ? {} : { maxWidth: "500px", minWidth: "350px" }),
        padding: "8px",
        minHeight: disableToolbar ? "30px" : "150px",
        width: "100%",
        boxSizing: "border-box",
      }}
      onClick={() => {
        editorRef.current?.focus(); // Explicitly focus the editor
      }}
    >
      <Editor
        editorKey="editor"
        editorState={editorState}
        onChange={handleChange}
        plugins={plugins}
        onBlur={handleBlur}
        placeholder={placeHolder}
        handleKeyCommand={handleKeyCommand}
        ref={(element) => {
          editorRef.current = element;
        }}
      />
      {/* Mention Suggestions Dropdown */}
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        onAddMention={(mention) => console.log("Added mention:", mention)}
      />
      {/* Inline Toolbar */}
      {!disableToolbar && InlineToolbar && <InlineToolbar />}
    </div>
  );
});

export default MentionEditor;