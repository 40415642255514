import { memo } from "react";
import { FormattedMessage } from "react-intl";
import PhotoIcon from '@mui/icons-material/Photo';
import PollIcon from '@mui/icons-material/Poll';
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/styles";

const Container = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: "1em",
  border: '1px solid #ddd',
  borderRadius: "4px",
});

const ItemContainer = styled(Box)({
  "& span:not(:first-of-type)": {
    marginLeft: "-0.4em",
  },
  "& span:not(:last-of-type)": {
    marginRight: "-0.4em",
  },
});

const NewsFlowPostToolbar = ({ disabled, onAction }) => {

  const handleItemClick = (action) => {
    if (!!action) {
      onAction?.(action);
    }
  };

  const Item = ({ action, children, disabled, title }) => (
    <Tooltip arrow placement="top" title={title}>
      <Box component="span">
        <IconButton disabled={disabled} onClick={() => handleItemClick(action)}>
          {children}
        </IconButton>
      </Box>
    </Tooltip>
  );

  return (
    <Container>
      <Typography>
        <FormattedMessage id="btn.add" />
      </Typography>

      <ItemContainer>
        <Item
          action="open_images_panel"
          disabled={disabled}
          title={<FormattedMessage id="newsFlowWidget.toolbar.images" />}
        >
          <PhotoIcon htmlColor="green" />
        </Item>
        <Item
          action="add_poll"
          disabled={disabled}
          title={<FormattedMessage id="newsFlowWidget.toolbar.poll" />}
        >
          <PollIcon htmlColor="orange" />
        </Item>
      </ItemContainer>
    </Container>
  );
}

export default memo(NewsFlowPostToolbar);
