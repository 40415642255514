import PropTypes from "prop-types";
import ChannelsSkeleton from "../../Skeleton/ChannelsSkeleton";
import ConversationsList from "./ConversationsList";

const ConversationContainer = ({
  activeConversationId,
  conversations,
  loading,
  onAddConversation,
  onConversationSelected,
}) => {
  if (loading) {
    return <ChannelsSkeleton />;
  }

  return (
    <ConversationsList
      activeConversationId={activeConversationId}
      conversations={conversations}
      onAddConversation={onAddConversation}
      onConversationSelected={onConversationSelected}
    />
  );
};

ConversationsList.defaultProps = {
  activeConversationId: null,
  conversations: [],
  loading: false,
};

ConversationsList.propTypes = {
  activeConversationId: PropTypes.number,
  conversations: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  onAddConversation: PropTypes.func.isRequired,
  onConversationSelected: PropTypes.func.isRequired,
};

export default ConversationContainer;
