/**
 * Updated list of site channels available for the user.
 * 
 * @param {any} state
 */
export const getChannels = state => state.api.channelsReducer.items;

/**
 * Updated list of conversations in which the user participates.
 * 
 * @param {any} state
 */
export const getConversations = state => state.api.conversationReducer.items;
