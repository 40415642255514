import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Icon, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 16,
  },
  iconButtonAdd: {
    "&.Mui-disabled": {
      visibility: "hidden",
    },
  },
}));

const HeaderList = ({ title, iconOnClick, hideButton }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.titleContainer}>
        {title}
      </Box>
      <Box>
        <IconButton
          disabled={hideButton}
          className={classes.iconButtonAdd}
          onClick={event => {
            event.stopPropagation();
            iconOnClick(event);
          }}
        >
          <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
        </IconButton>
      </Box>
    </Box>
  );
};

HeaderList.defaultProps = {
  hideButton: false,
};

HeaderList.propTypes = {
  title: PropTypes.node.isRequired,
  iconOnClick: PropTypes.func.isRequired,
  hideButton: PropTypes.bool,
};

export default HeaderList;
