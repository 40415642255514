import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

const top100Films = [
  { title: 'Bearings', year: 1994 },
  { title: 'Burner', year: 1994 },
  { title: 'Compressor', year: 1972 },
  { title: 'Combustion', year: 1972 },
  { title: 'Exhaust', year: 1974 },
  { title: 'Fuel', year: 2008 },
  { title: 'Inlet', year: 1957 },
  { title: 'Nozzle', year: 1957 },
];

export default function AutocompleteComponent() {
  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={top100Films.map((option) => option.title)}
      defaultValue={[top100Films[1].title]}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="filled"
            sx={{ background: '#8a00e5', color: 'white' }}
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ background: 'white', borderRadius: '6px' }}
          InputLabelProps={{
            style: {
              color: '#000000', // Set the desired label color
              fontWeight: "600",
            },
          }}
          /*label="Select chapter or section"*/
          placeholder="Select..."
        />
      )}
    />
  );
}