import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { Group, Path } from 'react-konva';

const ContainerIcon = ({ x = 0, y = 0, width = 24, height = 24, fill = 'black', stroke = 'none', strokeWidth = 0, scale = { x: 1, y: 1 }, opacity = 1, listening = false, offsetX = 0, offsetY = 0, renderAsSVG = false }) => {
  return renderAsSVG ? (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      opacity={opacity}
    >
      <path d="M23.2,11.15h-3.165V7.042c0-1.958-1.514-3.552-3.374-3.552H7.34c-1.861,0-3.375,1.593-3.375,3.552v4.108H0.8&#10;&#9;c-0.469,0-0.85,0.381-0.85,0.85s0.381,0.85,0.85,0.85h3.165v4.108c0,1.958,1.514,3.552,3.375,3.552h9.321&#10;&#9;c1.86,0,3.374-1.594,3.374-3.552V12.85H23.2c0.47,0,0.85-0.38,0.85-0.85S23.67,11.15,23.2,11.15z M18.215,16.958&#10;&#9;c0,0.955-0.697,1.731-1.554,1.731H7.34c-0.857,0-1.555-0.776-1.555-1.731V7.042c0-0.955,0.698-1.731,1.555-1.731h9.321&#10;&#9;c0.856,0,1.554,0.777,1.554,1.731V16.958z" />
    </SvgIcon>
  ) : (
    <Group
      x={x}
      y={y}
      width={width}
      height={height}
      scale={scale}
      listening={listening}
      offsetX={offsetX}
      offsetY={offsetY}
      opacity={opacity}
    >
      <Path fill={fill} stroke={stroke} strokeWidth={strokeWidth} data="M23.2,11.15h-3.165V7.042c0-1.958-1.514-3.552-3.374-3.552H7.34c-1.861,0-3.375,1.593-3.375,3.552v4.108H0.8&#10;&#9;c-0.469,0-0.85,0.381-0.85,0.85s0.381,0.85,0.85,0.85h3.165v4.108c0,1.958,1.514,3.552,3.375,3.552h9.321&#10;&#9;c1.86,0,3.374-1.594,3.374-3.552V12.85H23.2c0.47,0,0.85-0.38,0.85-0.85S23.67,11.15,23.2,11.15z M18.215,16.958&#10;&#9;c0,0.955-0.697,1.731-1.554,1.731H7.34c-0.857,0-1.555-0.776-1.555-1.731V7.042c0-0.955,0.698-1.731,1.555-1.731h9.321&#10;&#9;c0.856,0,1.554,0.777,1.554,1.731V16.958z" />
    </Group>
  );
};

export default ContainerIcon;