import { gql } from '@apollo/client';

const UPDATE_NEWSPOST_LIKE = gql`
  mutation updatePostLikes($siteNewsId: ID!, $emoticon: Int!) {
    newsContext {
      updatePostLikes(siteNewsId: $siteNewsId, emoticon: $emoticon) {
			emoticon
			isCurrentUser
			name
			userId
      }
    }
  }
`;

export default UPDATE_NEWSPOST_LIKE;
