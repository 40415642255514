//https://stackoverflow.com/questions/34623855/what-is-the-algorithm-behind-the-pdf-cloud-annotation
//https://martin-oehm.de/data/cloud.html
import React, { useEffect, useCallback, useRef, useState } from "react";
import { Box, Container } from '@mui/material';
import { Stage } from 'react-konva';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { usePaginationContext, useCommentsContext } from "../../../hooks/FullscreenAnnotationModal";
import DrawingToolbar from "../subcomponents/DrawingToolbar";
import FileViewer from '../subcomponents/FileViewer';

import ZoomToolbox from '../subcomponents/ZoomToolbox';

import {
  FILE_TYPE as FileType,
  PAGE_MODE_TYPE as PageModeType,
  TOOL_TYPE,
} from "../utils/types";
import { default as useOneStreamSteps } from "../utils/useSteps.js"; // OneStream

import {
  getCursorStyle,
  getRelativePointerPosition,
} from "../utils/drawingEditorCommon.js";

import StepDetailsTabs from "../subcomponents/OneStream/StepDetailsTabs";
import SideDrawer from "./SideDrawer";
import WorkflowPanel from "../subcomponents/OneStream/WorkflowPanel";
import useGetWorkflow from "../utils/useGetWorkflow.js";
import WorkFlowLayer from "./WorkFlowLayer";
import AnnotationLayer from "./AnnotationLayer";

const _initialDimensions = { width: 0, height: 0 };

const _initialToolSettings = {
  tool: TOOL_TYPE.SELECT,
  subType: undefined,
  lineWeight: 1,
  borderColor: 'black',
  fillColor: 'transparent',
};

const DrawingEditor = ({
  resource,
  //annotations,  
  fileMetadata,
  onWorkflowLoaded,
  //setAnnotations,
  setViewportTransform,
  stageRef,
  stageSize,
  onFileOpened,
  onStageSizeChange,
  viewportTransform,
  openWorkflowDrawer,
  onCloseWorkflowDrawer,
}) => {
  const containerRef = useRef(null);
  const imgRef = useRef(null);  // FileViewer
  const maxDimensionsRef = useRef(_initialDimensions);  // FileViewer
  const transformerRef = useRef(null);

  const { pageNo: pdfPageNumber } = usePaginationContext();
  const { annotations, addAnnotation, updateAnnotation } = useCommentsContext();

  const [contentDimensions, setContentDimensions] = useState(_initialDimensions);
  const [file, setFile] = useState(null);  // FileViewer
  const [fileType, setFileType] = useState(null);  // FileViewer, ZoomToolbox
  const [imageDimensions, setImageDimensions] = useState(_initialDimensions); // FileViewer
  const [newAnnotation, setNewAnnotation] = useState(null);
  const [numPages, setNumPages] = useState(null);  // FileViewer, ZoomToolbox
  const [pageMode, setPageMode] = useState(PageModeType.SINGLE);
  const [pdfDimensions, setPdfDimensions] = useState(_initialDimensions);
  const [selectedId, setSelectedId] = useState(null);
  const [toolSettings, setToolSettings] = useState(_initialToolSettings);

  const activeTool = toolSettings?.tool;
  const activeToolSubType = toolSettings?.subType;

  useEffect(() => {
    
    if (resource) {      
      setFileType(FileType.IMAGE);
      setFile(resource.productImageUri);
      setNumPages(parseInt(resource.numPages));
      onFileOpened({ id: 0, numPages: parseInt(resource.numPages), pageNumber : 1 });      
    }

  }, [resource]);

  //----- OneStream --------------------------------------------------
  //
  const osSteps = useOneStreamSteps();
  const osWorkflow = useGetWorkflow();

  const [connections, setConnections] = useState([]);
  const [steps, setSteps] = useState({});

  const [newStepId, setNewStepId] = useState(null);
  const [openSideDrawer, setOpenSideDrawer] = useState(false); //
  const [selectedStep, setSelectedStep] = useState(null);
  
  const [workflowId, setWorkflowId] = useState();

  const updateCursor = useCallback((tool) => {
    const stage = stageRef.current;
    if (stage) {
      stage.container().style.cursor = getCursorStyle(tool);
    }
  }, [stageRef]);

  const handleStageClick = useCallback((e) => {
    if (e.target === e.target.getStage()) {
      setSelectedId(null); // Annotations
      setSelectedStep(null); // OneStream
    }
  }, []);

  const addStep = (step) => {
    const { id } = step;
    setSteps(prev => ({
      ...prev,
      [id]: step,
    }));
  };

  const finishNewStep = () => {
    setNewStepId(null);
  }

  const startNewStep = useCallback((type, pos) => {
    const newStep = osSteps.create(type, pos);
    if (newStep) {
      addStep(newStep);
      setNewStepId(newStep.id);
    }
  }, [osSteps]);

  const handleDragStep = useCallback((e, stepId) => {
    if (stepId) {
      const pos = getRelativePointerPosition(e.target.getStage());
      setSteps(prev => {
        const step = prev[stepId];
        return {
          ...prev,
          [stepId]: {
            ...osSteps.updatePosition(step, pos),
          }
        };
      });
    }
  }, [osSteps]);

  useEffect(() => {
    if (workflowId) {

      const workflow = osWorkflow.getWorkflow(workflowId);
      if (!!workflowId && !workflow.id) {
        console.info("** Could not get workflow.", { workflowId });
      }
      setConnections(workflow.connections);
      setSteps(workflow.steps);

      setFileType();

      onWorkflowLoaded?.({ id: workflow.id });
    }
  }, [workflowId]);

  //
  //-----(OneStream)--------------------------------------------------

  const handleAnnotationDrag = useCallback((e, newAnnotation) => {
    if (newAnnotation) {
      const pos = getRelativePointerPosition(e.target.getStage());
      let adjustedX = pos.x;

      if (pageMode === PageModeType.DOUBLE && newAnnotation.page % 2 === 1) {
        // If we're in "double" mode and on the right page, adjust x by subtracting the left page's width
        adjustedX = pos.x - (pdfDimensions.width / viewportTransform.scale);
      }

      setNewAnnotation({
        ...newAnnotation,
        width: adjustedX - newAnnotation.x,
        height: pos.y - newAnnotation.y,
      });
    }
  }, [pageMode, pdfDimensions, viewportTransform]);

  const handleAnnotationEnd = () => {
    debugger;
    addAnnotation(resource.versionId, newAnnotation.pageNo, newAnnotation);
    //setAnnotations([...annotations, { ...newAnnotation, id: Date.now().toString() }]);
    setNewAnnotation(null);
  }

  const handleAnnotationStart = (e) => {
    const pos = getRelativePointerPosition(e.target.getStage());
    let x = pos.x;

    if (pageMode === PageModeType.DOUBLE) {
      let page = pdfPageNumber;

      // Check if the position is on the left or right page in the spread
      if (x >= (pdfDimensions.width / viewportTransform.scale)) {
        // It's on the right page

        if (page % 2 === 0) {
          //the current displayedpage number is the left one, but we need the right one, so add 1
          page = page + 1;
        }

        x = x - (pdfDimensions.width / viewportTransform.scale);
      } else {
        // It's on the left page
        if (page % 2 === 1) {
          page = page - 1;
        }
        // x remains the same, already relative to the left page
      }

      setNewAnnotation({
        tool: activeTool,
        subType: activeToolSubType, //
        x: x,
        y: pos.y,
        width: 0,
        height: 0,
        pageNo: page,
        lineWeight: toolSettings.lineWeight,
        borderColor: toolSettings.borderColor,
        fillColor: toolSettings.fillColor,
      });
    } else {
      // Single-page mode
      setNewAnnotation({
        tool: activeTool,
        subType: activeToolSubType, //
        x: x,
        y: pos.y,
        width: 0,
        height: 0,
        pageNo: pdfPageNumber,
        lineWeight: toolSettings.lineWeight,
        borderColor: toolSettings.borderColor,
        fillColor: toolSettings.fillColor,
      });
    }
  };

  const updateStageCursor = useCallback((cursor) => {
    const stage = stageRef.current;
    if (stage) {
      stage.container().style.cursor = cursor;
    }
  }, [stageRef]);

  const handleMouseDown = useCallback((e) => {
    switch (activeTool) {
      case TOOL_TYPE.ANNOTATION: // Annotations
        if (!!activeToolSubType) {
          handleAnnotationStart(e);
        }
        break;

      case TOOL_TYPE.PAN:
        updateStageCursor("grabbing");
        break;

      case TOOL_TYPE.STEP: // OneStream
        if (!!activeToolSubType) {
          const pos = getRelativePointerPosition(e.target.getStage());
          startNewStep(activeToolSubType, pos);
        }
        break;

      case TOOL_TYPE.SELECT:
      default:
        break;
    }
  }, [activeTool, activeToolSubType, handleAnnotationStart, startNewStep, updateStageCursor]);

  const handleMouseMove = useCallback((e) => {
    if (activeTool === TOOL_TYPE.PAN) {
      //
    }
    else if (newAnnotation) {
      handleAnnotationDrag(e, newAnnotation);
    }
    else if (newStepId) {
      handleDragStep(e, newStepId);
    }
  }, [activeTool, handleAnnotationDrag, handleDragStep, newAnnotation, newStepId]);

  const handleMouseUp = useCallback((e) => {
    if (activeTool === TOOL_TYPE.PAN) {
      updateStageCursor("grab");
    }
    else if (newAnnotation) {
      handleAnnotationEnd();
    }
    else if (newStepId) {
      finishNewStep();
    }
  }, [activeTool, handleAnnotationEnd, updateStageCursor, newAnnotation, newStepId]);

  const handleStageDragMove = useCallback((e) => {
    if (toolSettings.tool === TOOL_TYPE.PAN) {
      const pos = e.target.position();

      setViewportTransform(prev => ({
        ...prev,
        ...pos,
        scale: prev.scale,
      }));
    }
  }, [toolSettings.tool]);

  const handleToolbarSelect = (e, toolType, subType) => {
    setToolSettings(prev => ({
      ...prev,
      tool: toolType,
      subType: subType,
    }));

    setSelectedId(null);

    setSelectedStep(null); // OneStream
    updateCursor(toolType); // OneStream
  };

  const handleToolSettingChange = (setting, value) => {
    let newToolSettings = { ...toolSettings };
    newToolSettings[setting] = value;
    setToolSettings(newToolSettings);
  };

  useEffect(() => {
    setSelectedId(null);
  }, [pdfPageNumber]);

  const resetDimensions = () => {
    setImageDimensions(_initialDimensions);
    setPdfDimensions(_initialDimensions);
    maxDimensionsRef.current = _initialDimensions;
  };

  const updateSelectedAnnotation = (updates) => {

    updateAnnotation(selectedId, updates);
    /*setAnnotations(prevAnnotations =>
      prevAnnotations.map(ann =>
        ann.id === selectedId ? { ...ann, ...updates } : ann
      )
    );*/
  };

  const updateStageSize = useCallback((contentWidth, contentHeight) => {
    if (containerRef.current) {
      const { width: containerWidth, height: containerHeight } = containerRef.current.getBoundingClientRect();
      const scale = Math.min(containerWidth / contentWidth, containerHeight / contentHeight);
      if (isFinite(scale)) {
        setViewportTransform(prev => ({ ...prev, scale }));
      }
      // If you need to update the stage size in the parent component:
      if (onStageSizeChange) {
        onStageSizeChange({ width: containerWidth, height: containerHeight });
      }
    }
  }, [setViewportTransform, onStageSizeChange]);


  //----- FileView --------------------------------------------------
  //
  const determineFileType = (file) => {
    const type = file?.type;
    return type.includes('image') ? FileType.IMAGE : type.includes('pdf') ? FileType.PDF : null;
  }

  const resetWorkflow = () => {
    const workflow = osWorkflow.getWorkflow();

    setConnections(workflow.connections);
    setSteps(workflow.steps);
  };

  const handleContentRender = ({ width, height }) => {
    setContentDimensions({ width, height });
    updateStageSize(width, height);
  };

  const handleFileChange = useCallback((newFile) => {
    if (newFile) {
      
      const type = determineFileType(newFile);
      const url = URL.createObjectURL(newFile);
      setFile(url);
      setFileType(type);
//      setAnnotations([]);
      resetDimensions();
    }
  },[]);

  const handleFileUpload = (event) => {
    handleFileChange(event.target.files[0]);
  };

  const handleImageLoad = (e) => {
    if (imgRef.current) {
      const { naturalWidth, naturalHeight } = imgRef.current;
      setImageDimensions({
        width: naturalWidth,
        height: naturalHeight
      });
      handleContentRender({
        width: naturalWidth,
        height: naturalHeight
      });
    }
  };

  const handlePageLoaded = (width, height) => {
    setPdfDimensions({ width: width, height: height });
  }

  const handlePageRenderSuccess = (page) => {
    const { width, height } = page.getViewport({ scale: 1 });
    maxDimensionsRef.current.width = Math.max(maxDimensionsRef.current.width, width);
    maxDimensionsRef.current.height = Math.max(maxDimensionsRef.current.height, height);
  };

  const handlePdfLoadSuccess = ({ numPages, width, height }) => {
    
    setNumPages(numPages);
    maxDimensionsRef.current = _initialDimensions;

    resetWorkflow();

    onFileOpened?.({ id: Date.now(), numPages, pageNumber: 1 });
  };

  useEffect(() => {
    handleFileChange(fileMetadata?.file);
  }, [fileMetadata, handleFileChange]);
  //
  //-----(FileView)--------------------------------------------------


  //----- Zoom --------------------------------------------------
  //
  const zoom = useCallback((eOrScale) => {
    const stage = stageRef.current;
    if (!stage) return;

    let newScale;
    let pointerPosition = null;

    // Determine if input is a number (direct scale) or an event (wheel event)
    if (typeof eOrScale === 'number') {
      newScale = eOrScale;
    } else {
      // Wheel zoom
      eOrScale.evt.preventDefault();
      const scaleBy = 1.1;
      const oldScale = viewportTransform.scale;
      newScale = eOrScale.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
    }

    // Set pointer position for zooming centered on mouse pointer
    pointerPosition = stage.getPointerPosition();

    const newTransform = calculateZoom(stage, newScale, viewportTransform, pointerPosition);
    setViewportTransform(newTransform);
  }, [viewportTransform, stageRef, setViewportTransform]);

  const handleZoomIn = useCallback(() => {
    zoom(viewportTransform.scale * 1.1);
  }, [zoom, viewportTransform.scale]);

  const handleZoomOut = useCallback(() => {
    zoom(viewportTransform.scale / 1.1);
  }, [zoom, viewportTransform.scale]);

  const calculateZoom = (stage, scaleFactor, viewportTransform, pointerPosition = null) => {
    if (pointerPosition) {
      // Calculate zoom based on the mouse pointer, from mouse pointer
      const stagePos = stage.getPointerPosition();

      // Get the stage's transform, inverted, to map to stage coordinates
      const invertedTransform = stage.getAbsoluteTransform().copy().invert();
      const pointerCoords = invertedTransform.point(stagePos);

      // Calculate new x, y based on pointer position and scaling
      return {
        x: viewportTransform.x - (pointerCoords.x * (scaleFactor - viewportTransform.scale)),
        y: viewportTransform.y - (pointerCoords.y * (scaleFactor - viewportTransform.scale)),
        scale: scaleFactor
      };
    }

    // Otherwise, when using zoom buttons, zoom from the left top of the stage
    return {
      ...viewportTransform,
      scale: scaleFactor
    };
  };
  //
  //-----(Zoom)--------------------------------------------------


  useEffect(() => {
    const stage = stageRef.current;
    const shape = stage?.findOne('#' + selectedId);
    if (shape) {
      transformerRef.current.nodes([shape]);
      transformerRef.current.getLayer().batchDraw();
    } else {
      transformerRef.current.nodes([]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [selectedId, stageRef]);

  //TODO: Se om dessa två useEffects kan slås ihop?
  useEffect(() => {
    const handleResize = () => {
      updateStageSize(contentDimensions.width, contentDimensions.height);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [contentDimensions, updateStageSize]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        // Instead of setting stage size directly, update it through the parent component
        if (onStageSizeChange) {
          onStageSizeChange({ width, height });
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [onStageSizeChange]);

  return (
    <Box>
      <DrawingToolbar
        onFileUpload={handleFileUpload}
        onSelect={handleToolbarSelect}
        setBorderColor={(borderColor) => {
          handleToolSettingChange("borderColor", borderColor);
          if (selectedId) updateSelectedAnnotation({ borderColor });
        }}
        setFillColor={(fillColor) => {
          handleToolSettingChange("fillColor", fillColor);
          if (selectedId) updateSelectedAnnotation({ fillColor });
        }}
        setLineWeight={(lineWeight) => {
          handleToolSettingChange("lineWeight", lineWeight);
          if (selectedId) updateSelectedAnnotation({ lineWeight });
        }}
        showAnnotationTools
        showWorkflowTools
        toolSettings={toolSettings}
      />
      <ZoomToolbox
        fileType={fileType}
        handleZoom={zoom}
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        pageMode={pageMode}
        setPageMode={setPageMode}
        setViewportTransform={setViewportTransform}
        stage={stageRef.current}
        zoom={Math.round(viewportTransform.scale * 100)}
      />
      <Container ref={containerRef}
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: 'none',
          height: '100vh',
          overflow: 'hidden',
          padding: '0px',
        }}
      >
        <FileViewer
          file={file} //Det är en url. Tidigare så har det varit en blob-url.
          fileType={fileType} // "image" eller "pdf"
          handleImageLoad={handleImageLoad}
          handlePageRenderSuccess={handlePageRenderSuccess}
          handlePdfLoadSuccess={handlePdfLoadSuccess}
          imageDimensions={imageDimensions}
          imgRef={imgRef}
          numPages={numPages}
          onPageLoad={handlePageLoaded}
          pageMode={pageMode}
          pdfPageNumber={pdfPageNumber}
          viewportTransform={viewportTransform}
        />
        <Stage
          draggable={activeTool === TOOL_TYPE.PAN}
          ref={stageRef}
          style={{ position: 'absolute' }}
          onClick={handleStageClick}
          onDragMove={handleStageDragMove}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onWheel={zoom}
          scaleX={viewportTransform.scale}
          scaleY={viewportTransform.scale}
          width={stageSize.width}
          height={stageSize.height}
          x={viewportTransform.x}
          y={viewportTransform.y}
        >
          <WorkFlowLayer
            activeTool={activeTool}
            setOpenSideDrawer={setOpenSideDrawer}
            steps={steps}
            setSteps={setSteps}
            connections={connections}
            setConnections={setConnections}
            selectedStep={selectedStep}
            setSelectedStep={setSelectedStep}
          />

          <AnnotationLayer            
            activeTool={activeTool}
            annotations={annotations}
            //setAnnotations={setAnnotations}
            pdfPageNumber={pdfPageNumber}
            viewportTransform={viewportTransform}
            setToolSettings={setToolSettings}
            toolSettings={toolSettings}
            setSelectedId={setSelectedId}
            pageMode={pageMode}
            pdfDimensions={pdfDimensions}
            newAnnotation={newAnnotation}
            transformerRef={transformerRef }
          />

        </Stage>


        <SideDrawer
          anchor="left"
          onClose={onCloseWorkflowDrawer}
          show={openWorkflowDrawer}
        >
          <WorkflowPanel
            onSelectWorkflow={id => { setWorkflowId(id); }}
          />
        </SideDrawer>


        <SideDrawer
          onClose={() => setOpenSideDrawer(false)}
          show={openSideDrawer}
        >
          <StepDetailsTabs
            onChange={({ key, value }) => {
              if (selectedStep) {
                setSteps(prevSteps => ({
                  ...prevSteps,
                  [selectedStep]: {
                    ...prevSteps[selectedStep],
                    [key]: value,
                  }
                }));
              }
            }}
            step={steps[selectedStep]}
          />
        </SideDrawer>

      </Container>
    </Box>
  );
}

export default DrawingEditor;
