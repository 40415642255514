import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";
import { useGetOneStreamUploadUrl } from "../../hooks";
import axios from "axios";
import FormInput from "./FormInput";
import OneStreamUpload from "./OneStreamUpload";
import OneStreamSubmit from "./OneStreamSubmit";
import OneStreamManualType from "./OneStreamManualType";
import { ManualTypeOptions } from "./helpers";
import { useAlert } from "react-alert";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      maxHeight: "95%",
    },
  },
}));

const NewUploadModal = ({
  open,
  siteId,
  onClose,
  onRefreshTable,
  uploadedFile
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const alert = useAlert();

  const { currentViewer } = useSelector(state => state.api);

  const [formValues, setFormValues] = useState({ preset: "0", email: "", costPlace: "", name: "" });
  const [validatedFields, setValidatedFields] = useState({ name: null, email: null, costPlace: null });

  const [progress, setProgress] = useState(0);
  const [uploadedFileName, setUploadedFileName] = useState("");

  const [fileToUpload, setFileToUpload] = useState(uploadedFile || {}); 

  useEffect(() => {
    if (uploadedFile) {
      handleOnSelectUploadFile(uploadedFile);
    }
  }, [uploadedFile]);

  useEffect(() => {
    if (currentViewer) {
      let email = currentViewer.viewer.username;
      setFormValues({ ...formValues, email: email });

      setValidatedFields({ ...validatedFields, ["email"]: email !== "" });

    }
  }, [currentViewer]);

  const handleRemoveUploadedFile = file => {

    setUploadedFileName("");
    setFileToUpload({});
  };

  const handleClose = () => {    
    onClose();
  };

  const handleCheckField = e => {
    const { value } = e.target;
    const key = e.target.name;
    setFormValues({ ...formValues, [key]: "" + value });
    setValidatedFields({ ...validatedFields, [key]: value !== "" });

  };

  const isAllValid = () => {    
    return validatedFields["name"] && validatedFields["email"] && validatedFields["costPlace"] && uploadedFileName !== "";
  }


  const handleOnSelectUploadFile = async files => {

    if (files && files[0]) {
      const newFile = files[0].name;
      setUploadedFileName(newFile);
      setFileToUpload(files[0]);      
    } else {
      console.warn("An error could be happening in selecting files.");
    }
  };

  const { execute: getUploadUrl } = useGetOneStreamUploadUrl({
    siteid: siteId,
    name: formValues.name,
    email: formValues.email,
    preset: formValues.preset,
    costPlace: formValues.costPlace
  });

  const handleUploadFiles = async () => {
    let uploadUrl = "";
    setProgress(1);
    const config = {
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.round((loaded * 100) / total);
        setProgress(percentCompleted);
      },
    };

    try {

      if (formValues.preset === "0" || formValues.preset === "1" || formValues.preset === "2") {
        console.log("All is well");
      }      

      const res = await getUploadUrl();
      if (res && res.data && res.data.initOneStreamUpload) {
        uploadUrl = res.data.initOneStreamUpload;
      }

      const formData = new FormData();
      formData.append("file", fileToUpload);

      const response = await axios.post(`${uploadUrl}`, formData, config);
      if (
        response &&
        response.data &&
        response.data.success &&
        response.status === 200
      ) {
        console.log(`Success uploading file.`);        
        alert.success(<FormattedMessage id="product.uploaded" />);
        onClose();
        onRefreshTable();
      } else {
        console.error(
          `An error occurred while uploading the file. ${JSON.stringify(
            response
          )}`
        );
      }
    } catch (uploadException) {
      console.error(
        `An error occurred while uploading the file. ${JSON.stringify(
          uploadException
        )}`
      );
    }
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="onestream.newUpload" />}
      />
      <DraggableModalBody>

        <FormInput
          name={"name"}
          label={intl.formatMessage({id: "onestream.project"}) + " *"}
          errorMessage={intl.formatMessage({id: "common.fieldIsRequired"})}
          placeholder={intl.formatMessage({id: "product.name"})}
          onChange={e => handleCheckField(e)}
          formValues={formValues}
          isValid={validatedFields["name"]}
        />

        <FormInput
          name={"email"}
          label={intl.formatMessage({ id: "support.email" }) + " *"}
          errorMessage={intl.formatMessage({id: "common.invalidEmail"})}
          placeholder={intl.formatMessage({id: "orderHistory.email"})}
          onChange={e => handleCheckField(e)}
          formValues={formValues}
          isValid={validatedFields["email"]}
        />

        <FormInput
          name={"costPlace"}
          label={"Purchase order"  + " *"}
          errorMessage={""}
          placeholder={"PO"}
          onChange={e => handleCheckField(e)}
          formValues={formValues}
          isValid={validatedFields["costPlace"]}/>

        <OneStreamManualType
          options={ManualTypeOptions}
          value={formValues.preset}
          name="preset"
          onChange={e => handleCheckField(e)}
        />
        <OneStreamUpload
          uploadedFile={uploadedFileName}
          handleOnSelectUploadFile={handleOnSelectUploadFile}
          handleRemoveUploadedFile={handleRemoveUploadedFile}
        />
        <OneStreamSubmit
          progress={progress}
          handleUploadFiles={handleUploadFiles}
          isValid={isAllValid()}
        />
      </DraggableModalBody>
    </DraggableModal>
  );
};

NewUploadModal.defaultProps = {
  uploadedFile: [],
  formValues: {},
};

NewUploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  setFormValues: PropTypes.func.isRequired,
  handleOnSelectUploadFile: PropTypes.func.isRequired,
  uploadedFile: PropTypes.arrayOf(PropTypes.shape({})),
  setUploadedFile: PropTypes.func.isRequired,
  setRefreshDropzone: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  handleUploadFiles: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
};

export default NewUploadModal;
