import { combineReducers } from "redux";

/**
 * Collections
 */
import cart from "./collections/cart";
import currentViewer from "./collections/viewer";
import fileView from "./collections/fileView";
import folder from "./collections/folder";
import imageBasket from "./collections/image-basket";
import messaging from "./collections/messaging";
import monitoring from "./collections/monitoring";
import productView from "./collections/productView";
import searchResults from "./collections/searchResults";
import siteFolders from "./collections/siteFolders";
import suppliers from "./collections/suppliers";
import supplierSettings from "./collections/company-supplier-settings";
import totalProducts from "./collections/total-product";
import widgets from "./collections/widgets";
import workspaces from "./collections/workspaces";
import siteUsers from "./collections/siteUsers";
import oneStream from "./collections/one-stream";
import search from "./collections/search";
import companyAdmin from "./collections/companyAdmin";
import customMetadata from "./collections/customMetadata";
import conversationReducer from "./collections/conversationReducer";
import channelsReducer from "./collections/channelsReducer";

/**
 * Members
 */
import userDetails from "./members/user-details";

export default combineReducers({
  /**
   * Collections
   */
  cart,
  companyAdmin,
  currentViewer,
  customMetadata,
  fileView,
  folder,
  imageBasket,
  messaging,
  monitoring,
  oneStream,
  productView,
  search,
  searchResults,
  siteFolders,
  siteUsers,
  suppliers,
  supplierSettings,
  totalProducts,
  widgets,
  workspaces,
  /**
   * Members
   */
  userDetails,
  conversationReducer,
  channelsReducer
});
