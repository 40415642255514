import { gql } from '@apollo/client';
import {
  DirectMessageFragment,
  SiteChannelMessageFragment,
} from "../fragments/MessagingFragments";

const MESSAGE_EVENTS_SUBSCRIPTION = gql`
  subscription MessageEventsSubscription($authToken: String!) {
    messageEvents(authToken: $authToken) {
  		messageEventType
  		directMessage {
        ...DirectMessageFragment
  		}
  		siteChannelMessage {
        ...SiteChannelMessageFragment
  		}
    }
  }
  ${DirectMessageFragment}
  ${SiteChannelMessageFragment}
`;

export default MESSAGE_EVENTS_SUBSCRIPTION;
