import React from "react";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles(() => ({
  root: {
  },
}));

function DraggablePaperComponent(props) {
  return (
    <Draggable
      handle=".draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <PaperComponent {...props} />
    </Draggable>
  );
}

const PaperComponent = (props) => (<Paper {...props} />);

const ModalCompact = ({
  open,
  handleClose,
  children,
  customClass,
  draggable,
  fullScreen,
  padding,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={
        draggable
          ? DraggablePaperComponent
          : PaperComponent
      }
      fullScreen={fullScreen}
      {...rest}
      className={classes.root}
      PaperProps={{
        style: {
          padding,
          borderRadius: 6,
          ...(fullScreen
            ? {
              margin: "6px",
              width: `calc(100% - 12px)`,
              maxWidth: `calc(100% - 12px)`,
              height: `calc(100% - 12px)`,
              maxHeight: `calc(100% - 12px)`,
            }
            : isSmallScreen
              ? {
                margin: "0px",
                width: "100%",
                maxWidth: "100%",
                height: "auto",
              }
              : {
                margin: "24px",                
                maxWidth: "unset",
              }),
        },
      }}
    >
      {children}
    </Dialog>
  );
};

export default ModalCompact;

ModalCompact.defaultProps = {
  padding: 6,
  fullScreen: true,
};

export { default as ModalCompactBody } from "./ModalCompactBody";
export { default as ModalCompactHead } from "./ModalCompactHead";
