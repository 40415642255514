import { IconButton, MenuItem } from '@mui/material';

const AnnotationTypeMenuItem = ({ icon, onSelect, selected }) => (
  <MenuItem
    dense
    onClick={onSelect}
    selected={selected}
  >
    <IconButton>{icon}</IconButton>
  </MenuItem>
);

export default AnnotationTypeMenuItem;
