import { gql } from '@apollo/client';
import DirectMessageFragment from "../../fragments/DirectMessage";

const GET_ALL_CONVERSATIONS = gql`
  query getAllConversations {
    directMessages {
      allConversations {
        directMessageConversationId
        lastMessage {
          ...DirectMessageFragment
        }
        latestMessageId
        latestReadMessageId
        latestReadMessageTime
        members {
          name
          userId
          username
          avatarUrl
        }
        unreadMessageCount
      }
    }
  }
  ${DirectMessageFragment}
`;

export default GET_ALL_CONVERSATIONS;
