import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import MessageTime from "./MessageTime";
import FileAttachmentsContainer from "./FileAttachmentsContainer";
import Mention from "../../widgets/NewsFlowWidget/Mention";

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 25,
  },
  root: {
    display: "flex",
    alignItems: "center",
    maxWidth: "80%",
    marginRight: "auto",
  },
  profile: {
    minWidth: 30,
    minHeight: 30,
    margin: "5px 10px auto 0",
  },
  profileImg: {
    width: 30,
    height: 30,
    objectFit: "cover",
    borderRadius: "50%",
    backgroundColor: "#EDEDED",
  },
  messageContainer: ({ myMessage }) => ({
    backgroundColor: myMessage ? "#0499FF" : theme.palette.common.white,
    border: "1px solid",
    borderColor: myMessage ? "#0499FF" : theme.palette.common.grey,
    borderRadius: 6,
    color: myMessage ? theme.palette.common.white : "#000",
    lineHeight: 1.7,
    padding: "3px 8px 2px 8px",
  }),
  messageContent: {
    whiteSpace: "pre-wrap",
  },
  messageInfo: {
    marginLeft: 40,
  },
  name: {
    fontSize: 12,
    fontWeight: 600,
  },
}));

const Message = ({ message, myMessage, onDownload, onImageClick }) => {
  const classes = useStyles({
    myMessage,
  });


  const renderTextWithMentions = (text, mentions) => {
    const mentionRegex = /<@m(\d+)>/g;
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = mentionRegex.exec(text)) !== null) {
      const mentionId = parseInt(match[1], 10);
      const mentionData = mentions.find((m) => m.mentionId === mentionId);
      
      if (match.index > lastIndex) {
        parts.push(text.substring(lastIndex, match.index));
      }

      if (mentionData) {
        parts.push(
          <Mention
            key={mentionId}
            mentionId={mentionId}
            mentions={mentionData}
            fontColor={myMessage ? "white" : "blue" }
          />
        );
      } else {
        parts.push(match[0]);
      }

      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < text.length) {
      parts.push(text.substring(lastIndex));
    }

    return parts;
  };

  const {
    messageTime,
    messageparts,
    author,
    authorUsername,
    mentions = [],
    files = [],
    avatarUrl,
  } = message;

  return (
    <Box className={classes.container}>
      <Box className={classes.messageInfo} alignItems="center" display="flex">
        <Typography className={classes.name}>{author}</Typography>
        <MessageTime dateTime={messageTime} />
      </Box>
      <Box className={classes.root}>        
        <Box className={classes.profile}>
          <img alt="Profile" className={classes.profileImg} src={avatarUrl} />
        </Box>
        <Box className={classes.messageContainer}>
          <Box className={classes.messageContent}>
            {messageparts &&
              messageparts.map((part, index) => (
                <Typography key={index} component="span">
                  {renderTextWithMentions(part.content, mentions)}
                </Typography>
              ))}
          </Box>
          <FileAttachmentsContainer
            files={files}
            onDownload={onDownload}
            onImageClick={onImageClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

Message.defaultProps = {};

Message.propTypes = {
  message: PropTypes.shape({
    author: PropTypes.string,
    authorUserId: PropTypes.number,
    authorUsername: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.shape({})),
    messageparts: PropTypes.arrayOf(PropTypes.shape({})),
    messageTime: PropTypes.string,
  }).isRequired,
  myMessage: PropTypes.bool.isRequired,
  onDownload: PropTypes.func.isRequired,
  onImageClick: PropTypes.func.isRequired,
};

export default Message;
