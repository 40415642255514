import { Box, Grid, List, ListItem, Rating, Typography } from "@mui/material";

const MetadataPanel = ({ fileMetadata, rating, setRating, text }) => (
  <Box>
    <Typography variant="h6" sx={{ color: "#111111", fontSize: "16px", fontWeight: "500", paddingBottom: "2px" }}>Den lokala löneprocessen - friskola</Typography>
    <Typography variant="body1" sx={{ color: "#111111", fontSize: text.length <= 130 ? "14px" : "13px", fontWeight: "400", paddingBottom: "5px" }}>{text}</Typography>
    <List>
      {Object.entries(fileMetadata).map(([key, value]) => (
        <ListItem key={key} sx={{ paddingLeft: "8px", paddingTop: '3px', paddingBottom: '3px', background: "#ffffff", borderBottom: '1px solid #f2f2f0' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body1" align="left" sx={{ fontSize: "14px", fontWeight: "400" }}>{key}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" align="left" sx={{ fontWeight: "500" }}>{value}</Typography>
            </Grid>
          </Grid>
        </ListItem>
      ))}
      <ListItem key="review" sx={{ paddingLeft: "8px", paddingTop: '3px', paddingBottom: '3px', background: "#ffffff", borderBottom: '1px solid #f2f2f0' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1" align="left">Betyg</Typography>
          </Grid>
          <Grid item xs={6}>
            <Rating name="overall-rating" value={rating} onChange={(event, newValue) => setRating(newValue)} />
          </Grid>
        </Grid>
      </ListItem>
    </List>
  </Box>
);

export default MetadataPanel;
