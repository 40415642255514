import { useLazyQuery } from "@apollo/client";
import { GET_ALL_CONVERSATIONS } from "../../graphql/queries/DirectMessages";

const extractAllConversations = (data) => data?.directMessages?.allConversations;

const useAllConversations = ({ onCompleted, onError } = {}) => {

  const [getAllConversationsQuery, { data, error, loading }] = useLazyQuery(
    GET_ALL_CONVERSATIONS,
    {
      errorPolicy: "none",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const conversations = extractAllConversations(data);
        console?.info("** Completed query: Conversations", { conversations });
        onCompleted?.(conversations);
      },
      onError: (error) => {
        console?.error("** Query error: Conversations", { error });
        onError?.(error);
      },
    }
  );

  const getAllConversations = async () => (
    await getAllConversationsQuery()
  );

  return [
    getAllConversations,
    {
      data: extractAllConversations(data),
      error,
      loading,
    }
  ];

};

export default useAllConversations;
