import { gql } from '@apollo/client';
import FolderFieldsFragment from "../fragments/FolderFields";
import FolderRecursiveFragment from "../fragments/FolderRecursive";

const getFolders = gql`
	query GetFolder($folderId: Int!) {
    folder(folderId: $folderId) {
      ...FolderFields
      ...FolderRecursive
    }
  }
  ${FolderFieldsFragment}
  ${FolderRecursiveFragment}
`;

export default getFolders;
