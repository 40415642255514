import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, ListItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChannelRow from "./ChannelRow";

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: "5px 0px",
    flexDirection: "column",
    alignItems: "flex-start",
    // borderRadius: 4,
    marginBottom: 2,
  },
  channelName: {
    fontSize: 12,
    fontWeight: 600,
  },
  listItemActive: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  listItemUnread: {
    //fontWeight: 600,
  },
  channelDesc: {
    fontSize: 11,
    color: "#888",
  },
  unreadMessageCount: {
    backgroundColor: theme.palette.component.badgeBackground,
    borderRadius: "50%",
    color: theme.palette.component.badgeColor,
    fontSize: 10,
    fontWeight: 700,
    margin: "auto",
    marginLeft: "1em",
    padding: "2px 7px",
    textAlign: "center",
    position: "absolute",
  },
}));

const ChannelListItem = ({
  activeChannelId,
  channel,
  onChannelSelected,
}) => {
  const classes = useStyles();

  const {
    latestMessageId,
    name: channelName,
    siteChannelId,
    unreadMessageCount,
    workspaceName,
  } = channel;

  const isActive = siteChannelId === activeChannelId;
  const isUnread = unreadMessageCount > 0;

  return (
    <ListItem
      key={siteChannelId}
      button
      disableRipple
      onClick={() => onChannelSelected(siteChannelId)}
      className={clsx([
        classes.listItem,
        isActive && classes.listItemActive,
        isUnread && classes.listItemUnread,
      ])}
    >
      <ChannelRow>
        <Box
          style={{ display: "flex", flexDirection: "column", paddingLeft: 10 }}
        >
          <Typography className={classes.channelName}>
            #{channelName}
            {isUnread && !isActive && (
              <span className={classes.unreadMessageCount}>
                {unreadMessageCount}
              </span>
            )}
          </Typography>
          <Typography className={classes.channelDesc}>
            {workspaceName}
          </Typography>
        </Box>
      </ChannelRow>
    </ListItem>
  );
};

ChannelListItem.defaultProps = {
  activeChannelId: null,
  channel: {},
};

ChannelListItem.propTypes = {
  activeChannelId: PropTypes.number,
  channel: PropTypes.shape({
    name: PropTypes.string,
    siteChannelId: PropTypes.number,
    workspaceName: PropTypes.string,
  }),
  onChannelSelected: PropTypes.func.isRequired,
};

export default ChannelListItem;
