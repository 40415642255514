import { createPortal } from "react-dom";
import { Box } from "@mui/material";
import TabMarker from "./TabMarker";

const panelStyles = {
  open: {
    background: "#f2f2f0",
    flex: "none",
    height: "100%",
    overflowX: "hidden",
    overflowY: 'auto',
    padding: 0,
    position: "relative",
    width: {
      xs: "100%",
      sm: 285,
      md: 300,
      lg: 350,
      xl: 400,
    },
  },
  closed: {
    background: "#f2f2f0",
    flex: "none",
    overflow: "hidden",
    padding: 0,
    position: "relative",
  }
};

const SidePanel = ({
  children,
  containerRef,
  onTabMarkerClick,
  open,
  placement,
  tabMarkerTooltip,
  tabMarkerIcon
}) => {

  let chevron;

  if (placement === "left") {
    chevron = open ? "left" : "right";
  }
  else if (placement === "right") {
    chevron = open ? "right" : "left";
  }

  return (
    <Box
      sx={panelStyles[open ? "open" : "closed"]}
    >
      {containerRef && createPortal(
        <TabMarker
          chevron={chevron}
          onClick={onTabMarkerClick}
          placement={placement}
          text={tabMarkerIcon}
          tooltip={tabMarkerTooltip}
        />,
        containerRef
      )}
      {open && (
        <Box
          sx={{
            border: "1px solid #ccc",
            height: "100%",
            padding: 2,
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default SidePanel;
