import { useState } from 'react';
import { Box, Button } from '@mui/material';
import OneStreamFlowModal from "../../../components/OneStream/OneStreamFlow/OnestreamFlowModal";
import { CommentsProvider, PaginationProvider } from "../../../context/FullscreenAnnotationModal";
import FullscreenAnnotationModal from './FullscreenAnnotationModal';
import FullscreenFileUploadModal from './FullscreenFileUploadModal';

const OneStreamUI = () => {
  const [openAnnotations, setOpenAnnotations] = useState(false);
  const [openOneStreamFlow, setOpenOneStreamFlow] = useState(false);
  const [openFileUpload, setOpenFileUpload] = useState(false);

  return (
    <Box>
      <Button onClick={() => setOpenAnnotations(true)}>AN</Button>
      <Button onClick={() => setOpenOneStreamFlow(true)}>WF</Button>
      <Button onClick={() => setOpenFileUpload(true)}>FU</Button>

      <PaginationProvider>
        <CommentsProvider>
          <FullscreenAnnotationModal
            onClose={() => setOpenAnnotations(false)}
            open={openAnnotations}
          />

          <OneStreamFlowModal
            onClose={() => setOpenOneStreamFlow(false)}
            open={openOneStreamFlow}
          />

          <FullscreenFileUploadModal
            onClose={() => setOpenFileUpload(false)}
            open={openFileUpload}
          />
        </CommentsProvider>
      </PaginationProvider>
    </Box>
  );
};

export default OneStreamUI;
